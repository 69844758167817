import React from "react";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pdfjs } from "react-pdf";
import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { Global } from "@emotion/react";
import { theme as oldTheme } from "../config";
import { DocumentProps, PDFData, PrescriptionStatus } from "../interfaces";
import { api, queryClient } from "../context";
import { Header } from "./Header";
import {
  BottomActionArea,
  ExtraWhiteSpace,
  MainActionButton,
  SecondaryActionButton,
} from "./BottomActionArea";
import { ColoredComponent, IconOptions } from "./ColoredContainer";
import { PrescriptionStatusChip } from "./PrescriptionStatusChip";
import { Modal } from "./Modal";
import { Body, NavigateBeforeButton, ShareDocumentsErrorModal } from ".";
import {
  DefaultWhiteText,
  FullScreen,
  StyledDocument,
  StyledPage,
  WaterMarkStyle,
} from "./styles";
import { printFile } from "../utils";
import { useMutation } from "@tanstack/react-query";
import { useIsScreenBiggerThanSM, useShareOrDownloadFiles } from "../hooks";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ScreenWrapper = styled(Box)<{ padding?: string }>((props) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: "16px",
  ...(props.padding && {
    padding: props.padding,
  }),
}));

const CenteredWrapper = styled(ScreenWrapper)({
  alignItems: "center",
  justifyContent: "center",
});

const CancelPrescriptionButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const GappedBottomActionArea = styled(BottomActionArea)({
  display: "flex",
  gap: "16px",
});

interface PDFRenderingProps {
  title: string;
  prevRoute: string;
  renderTextLayer?: boolean;
  renderAnnotationLayer?: boolean;
}

interface PrescriptionData {
  requestId: string;
  prescriptionId: string;
  prescriptionStatus?: PrescriptionStatus;
  holdingDate?: number;
  isFetching?: boolean;
  isCompoundedPrescription?: boolean;
}

export enum UserRole {
  DOCTOR = "doctor",
  CHEMIST = "chemist",
}

export interface PDFViewerProps {
  documentProps: DocumentProps;
  pdfRenderingProps: PDFRenderingProps;
  prescriptionData?: PrescriptionData;
  userRole?: UserRole;
}

const defaultLocationState = {
  documentProps: {
    documentUrl: "",
    documentName: "",
  },
  pdfRenderingProps: {
    title: "",
    prevRoute: "/app/solicitudes",
    renderTextLayer: false,
    renderAnnotationLayer: false,
  },
  prescriptionData: {} as PrescriptionData,
  userRole: UserRole.DOCTOR,
};

export const PDFViewer = () => {
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const {
    convertFiles,
    isConvertingFiles,
    shareOrDownloadFilesCallback,
    isDownloadFilesFallbackActive,
    setIsDownloadFilesFallbackActive,
  } = useShareOrDownloadFiles();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    documentProps: { documentUrl, documentName },
    pdfRenderingProps: {
      title,
      prevRoute,
      renderTextLayer = false,
      renderAnnotationLayer = false,
    },
    prescriptionData = {} as PrescriptionData,
    userRole = UserRole.DOCTOR,
  }: PDFViewerProps = location.state || defaultLocationState;

  const {
    requestId,
    prescriptionId,
    prescriptionStatus,
    holdingDate,
    isFetching,
    isCompoundedPrescription,
  } = prescriptionData;
  const [showWaterMark, setShowWaterMark] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { mutate: cancelPrescription, isPending } = useMutation({
    mutationFn: () =>
      api({
        method: "post",
        url: `/prescriptions/${prescriptionId}/cancel`,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["request", requestId],
      });
      queryClient.invalidateQueries({
        queryKey: ["requestSummary", requestId],
      });
      setShowCancelModal(false);
      navigate("/app/solicitudes/visualizacion-documento", {
        state: {
          ...location.state,
          prescriptionData: {
            ...prescriptionData,
            prescriptionStatus: PrescriptionStatus.CANCELED,
          },
        },
      });
    },
    onError: () => {
      setShowCancelModal(false);
      setShowErrorModal(true);
    },
  });
  const [numPages, setNumPages] = useState(1);
  const handleOnLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
      if (prescriptionStatus === PrescriptionStatus.CANCELED) {
        setShowWaterMark(true);
      }
    },
    [prescriptionStatus]
  );

  const handleMainActionClick = useCallback(async () => {
    const pdfData: PDFData[] = [
      {
        url: documentUrl,
        documentName,
      },
    ];
    const pdfFiles = await convertFiles(pdfData);
    await shareOrDownloadFilesCallback(pdfFiles, pdfData);
  }, [documentUrl, documentName, convertFiles, shareOrDownloadFilesCallback]);

  return (
    <>
      <Header
        title={title}
        titleColor="black"
        left={
          <NavigateBeforeButton
            onClick={() => {
              navigate(prevRoute);
            }}
          />
        }
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      {documentUrl ? (
        <Body>
          <ScreenWrapper padding={"16px 0px 0px 0px"}>
            {prescriptionStatus &&
              [PrescriptionStatus.DISPENSED, PrescriptionStatus.HELD].includes(
                prescriptionStatus
              ) && (
                <>
                  {userRole === UserRole.DOCTOR ? (
                    <ColoredComponent
                      color="#E9F5FE"
                      icon={IconOptions.STYLED_INFO}
                      text={
                        <Typography
                          style={{ color: oldTheme.pallete.text.black }}
                        >
                          {prescriptionStatus === PrescriptionStatus.DISPENSED
                            ? "Esta receta ya fue expendida"
                            : "Esta receta ya fue retenida"}
                        </Typography>
                      }
                    />
                  ) : (
                    <PrescriptionStatusChip
                      status={prescriptionStatus}
                      date={holdingDate}
                      code={prescriptionId}
                      isCompoundedPrescription={isCompoundedPrescription}
                    />
                  )}
                </>
              )}
            {prescriptionStatus === PrescriptionStatus.CANCELED && (
              <PrescriptionStatusChip
                status={prescriptionStatus}
                date={holdingDate}
                code={prescriptionId}
              />
            )}
            <CenteredWrapper>
              <StyledDocument
                file={documentUrl}
                loading={<CircularProgress size={24} color="primary" />}
                onLoadSuccess={handleOnLoadSuccess}
                blur={showWaterMark}
              >
                {numPages &&
                  Array.from(new Array(numPages), (_, pageIndex) => (
                    <React.Fragment key={`page_${pageIndex}`}>
                      <StyledPage
                        pageNumber={pageIndex + 1}
                        renderTextLayer={renderTextLayer}
                        renderAnnotationLayer={renderAnnotationLayer}
                      >
                        {showWaterMark && (
                          <WaterMarkStyle>ANULADA</WaterMarkStyle>
                        )}
                      </StyledPage>
                    </React.Fragment>
                  ))}
              </StyledDocument>
            </CenteredWrapper>
            {userRole === UserRole.DOCTOR &&
              prescriptionStatus &&
              prescriptionStatus !== PrescriptionStatus.CANCELED && (
                <CancelPrescriptionButtonContainer>
                  <SecondaryActionButton
                    onClick={() => setShowCancelModal(true)}
                    disabled={isFetching}
                  >
                    Anular receta
                  </SecondaryActionButton>
                </CancelPrescriptionButtonContainer>
              )}
            {(showCancelModal || isFetching) && (
              <Modal
                title="Anular receta"
                content="¿Estás seguro? Una vez anulada, no podrá ser utilizada por el paciente."
                isOpen={showCancelModal}
                onClose={() => setShowCancelModal(false)}
                buttonProps={{
                  buttonText: "Continuar",
                  onClick: () => {
                    cancelPrescription();
                  },
                  loading: isPending || isFetching,
                }}
              />
            )}
            {showErrorModal && (
              <Modal
                title="Ha ocurrido un error"
                content="No se pudo anular la receta."
                isOpen={showErrorModal}
                onClose={() => setShowErrorModal(false)}
                buttonProps={{
                  buttonText: "Cerrar",
                  onClick: () => {
                    setShowErrorModal(false);
                  },
                }}
              />
            )}
          </ScreenWrapper>
          <ShareDocumentsErrorModal
            isOpen={isDownloadFilesFallbackActive}
            onClose={() => setIsDownloadFilesFallbackActive(false)}
          />
          <ExtraWhiteSpace />
          <GappedBottomActionArea>
            <MainActionButton
              onClick={handleMainActionClick}
              disabled={isConvertingFiles}
            >
              {isConvertingFiles ? (
                <CircularProgress size={24} color="inherit" />
              ) : isScreenBiggerThanSM ? (
                "Descargar"
              ) : (
                "Compartir"
              )}
            </MainActionButton>
            {userRole === UserRole.CHEMIST && isScreenBiggerThanSM && (
              <MainActionButton
                onClick={() => {
                  printFile(documentUrl);
                }}
              >
                Imprimir
              </MainActionButton>
            )}
          </GappedBottomActionArea>
        </Body>
      ) : (
        <Body>
          <CenteredWrapper>
            <ColoredComponent
              color={oldTheme.pallete.errors.primary}
              icon={IconOptions.ERROR}
              text={
                <DefaultWhiteText>
                  Error al cargar el documento.
                </DefaultWhiteText>
              }
            />
          </CenteredWrapper>
        </Body>
      )}
    </>
  );
};

interface PDFViewerSkeletonProps {
  prevRoute: string;
  userRole?: UserRole;
}

PDFViewer.Skeleton = function PDFViewerSkeleton({
  prevRoute,
  userRole = UserRole.DOCTOR,
}: PDFViewerSkeletonProps) {
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const navigate = useNavigate();
  return (
    <FullScreen>
      <Header
        title={""}
        titleColor="black"
        left={
          <NavigateBeforeButton
            onClick={() => {
              navigate(prevRoute);
            }}
          />
        }
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />

      <CenteredWrapper>
        <CircularProgress size={24} color="primary" />
      </CenteredWrapper>

      <GappedBottomActionArea>
        <MainActionButton disabled>
          {<CircularProgress size={24} color="inherit" />}
        </MainActionButton>
        {userRole === UserRole.CHEMIST && isScreenBiggerThanSM && (
          <MainActionButton disabled>Imprimir</MainActionButton>
        )}
      </GappedBottomActionArea>
    </FullScreen>
  );
};

import { SvgIcon, SvgIconProps } from "@mui/material";

export const PurpleCircleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="405"
      height="600"
      viewBox="0 0 405 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M105 600C270.685 600 405 465.685 405 300C405 134.315 270.685 0 105 0C-60.6854 0 -195 134.315 -195 300C-195 465.685 -60.6854 600 105 600Z"
        fill="#7355FB"
        fill-opacity="0.08"
      />
    </svg>
  </SvgIcon>
);

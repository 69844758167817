import { useState } from "react";
import { useForm } from "react-hook-form";
import { isAxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import {
  CIDocForm,
  QuestionsChallengeForm,
  VerifyClaveUnicaLogin,
} from "./components";
import { Modal } from "@components/Modal";
import {
  CIDocFormData,
  Step,
  ValidationChallengePayload,
  ValidationChallengeResponse,
} from "@interfaces";
import { api, useDoctorContext } from "@contexts";

export const ValidationChallengeScreen: React.FC = () => {
  const doctor = useDoctorContext();
  const [step, setStep] = useState<Step>("validatingClaveUnicaLogin");
  const [showValidateDoctorDataModal, setShowValidateDoctorDataModal] =
    useState(false);
  const [showValidateDoctorDataInputs, setShowValidateDoctorDataInputs] =
    useState(false);
  const form = useForm<CIDocFormData>({
    defaultValues: {
      docNum: "",
      name: doctor.name,
      firstSurname: doctor.lastName,
      secondSurname: "",
      birthdate: doctor.birthdate,
    },
  });

  const {
    mutate: createValidationChallenge,
    isPending: isCreatingValidationChallenge,
    data: questions,
    variables: validationChallengePayload,
  } = useMutation({
    mutationFn: async (
      validationChallengePayload: ValidationChallengePayload
    ) => {
      const { preguntas } = await api<ValidationChallengeResponse>({
        method: "post",
        url: "/esign/create-validation-challenge",
        data: validationChallengePayload,
      });

      return preguntas;
    },
    onSuccess: () => {
      if (step === "ciDocForm") {
        setStep("questionsForm");
      }
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.data) {
        const { message: apiErrorMessage } = error.response.data;
        let frontErrorMessage: string =
          apiErrorMessage ??
          "Ha ocurrido un error, por favor intenta nuevamente.";
        // 1009 -> Some doctor data is not provided (we know it's the full name or the birthdate)
        if (error.response?.data.code === "1009") {
          setShowValidateDoctorDataModal(true);
          setShowValidateDoctorDataInputs(true);
          return;
        }
        // 1010 -> docNum is expired or doesnt coincide with person rut
        else if (error.response?.data.code === "1010") {
          // Error generated by ESIGN
          if (
            apiErrorMessage ===
            "La Cedula no esta vigente o Serie no corresponde al RUT consultado."
          ) {
            frontErrorMessage =
              "La cédula no está vigente o nº de documento no coincide con el RUT consultado.";
          }
        } else if (error.response?.data.code === "1014") {
          frontErrorMessage =
            "Excediste el número de intentos máximo. Vuelve a intentar en 24 horas.";
        }
        form.setError("docNum", {
          type: "manual",
          message: frontErrorMessage,
        });
      }
    },
  });

  if (step === "validatingClaveUnicaLogin")
    return <VerifyClaveUnicaLogin setStep={setStep} />;
  if (step === "ciDocForm")
    return (
      <>
        <CIDocForm
          form={form}
          showValidateDoctorDataInputs={showValidateDoctorDataInputs}
          createValidationChallenge={createValidationChallenge}
          isCreatingValidationChallenge={isCreatingValidationChallenge}
        />
        <Modal
          title="Se requiere información adicional"
          content="Algunos datos del Registro Nacional de Prestadores Individuales de Salud no fueron encontrados. Por favor, completa los nuevos campos requeridos."
          isOpen={showValidateDoctorDataModal}
          onClose={() => setShowValidateDoctorDataModal(false)}
          buttonProps={{
            buttonText: "Aceptar",
            onClick: () => setShowValidateDoctorDataModal(false),
          }}
        />
      </>
    );
  if (validationChallengePayload && step === "questionsForm") {
    return (
      <QuestionsChallengeForm
        questions={questions}
        setStep={setStep}
        createValidationChallenge={createValidationChallenge}
        isCreatingValidationChallenge={isCreatingValidationChallenge}
        validationChallengePayload={validationChallengePayload}
      />
    );
  }
  return null;
};

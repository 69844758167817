import { Box, Theme, Typography, styled, useMediaQuery } from "@mui/material";
import {
  BlueCircleIcon,
  PurpleCircleIcon,
  RedCircleIcon,
  AlternativesAndAvailabilityImg,
  DocumentTypesImg,
  InboxImg,
  PatientLinkImg,
  PinImg,
  PriceComparationImg,
} from "@icons";
import { theme as oldTheme } from "@config/theme";

const SectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "32px",
  padding: "64px 0px",
  [theme.breakpoints.up("md")]: {
    gap: "80px",
  },
}));

const SectionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "80px",
});

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  textAlign: "center",
  color: oldTheme.pallete.text.primaryDark,
  fontSize: "2rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.125rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.25rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "3rem",
  },
}));

const CardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "imgSide",
})<{ imgSide: FeatureExplanationCardProps["imgSide"]; overflow?: string }>(
  ({ theme, imgSide, overflow }) => ({
    display: "flex",
    flexDirection: "column-reverse",
    position: "relative",
    padding: "0px 32px",
    gap: "8px",
    ...(overflow && { overflow }),
    [theme.breakpoints.up("lg")]: {
      gap: "120px",
      flexDirection: imgSide === "left" ? "row" : "row-reverse",
    },
    [theme.breakpoints.up("xl")]: {
      gap: "156px",
    },
    justifyContent: "center",
    alignItems: "center",
  })
);

const StyledImg = styled("img")(({ theme }) => ({
  width: "324px",
  height: "auto",
  [theme.breakpoints.up("lg")]: {
    width: "460px",
  },
}));

const Description = styled("h5")(({ theme }) => ({
  fontWeight: 400,
  color: oldTheme.pallete.text.primaryDark,
  fontSize: "1rem",
  textAlign: "center",
  maxWidth: "510px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.125rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.up("lg")]: {
    textAlign: "left",
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "2rem",
  },
}));

const DescriptionHighlight = styled("span")({
  fontWeight: 600,
  color: oldTheme.pallete.text.primaryDark,
});

// Circle Icons
const StyledCircleIcon = styled("img")(({ theme }) => ({
  width: "250px",
  height: "auto",
  position: "absolute",
  zIndex: -1,
  [theme.breakpoints.up("lg")]: {
    width: "325px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "350px",
  },
}));

const StyledFirstRedCircleIcon = styled(StyledCircleIcon)(({ theme }) => ({
  top: "5%",
  left: "-25%",
  [theme.breakpoints.up(400)]: {
    left: "-10%",
  },
  [theme.breakpoints.up("sm")]: {
    left: "-5%",
  },
  [theme.breakpoints.up("md")]: {
    top: "-30%",
    left: "0",
  },
}));

const StyledBlueCircleIcon = styled(StyledCircleIcon)(({ theme }) => ({
  width: "350px",
  top: "0%",
  right: "-25%",
  [theme.breakpoints.up(400)]: {
    right: "-10%",
  },
  [theme.breakpoints.up("sm")]: {
    right: "-5%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "450px",
    top: "-50%",
    left: "40%",
  },
  [theme.breakpoints.up("xl")]: {
    width: "500px",
    top: "-60%",
  },
}));

const StyledPurpleCircleIcon = styled(StyledCircleIcon)(({ theme }) => ({
  top: "40%",
  left: "0%",
  [theme.breakpoints.up("lg")]: {
    top: "65%",
  },
}));

const StyledSecondRedCircleIcon = styled(StyledCircleIcon)(({ theme }) => ({
  transform: "rotate(180deg)",
  top: "90%",
  right: "0%",
  [theme.breakpoints.up("lg")]: {
    top: "60%",
  },
}));

interface CircleIconProps {
  icon: React.ElementType;
  StyledIcon: React.ElementType;
}

const circleIcons: Record<number, CircleIconProps> = {
  0: {
    icon: RedCircleIcon,
    StyledIcon: StyledFirstRedCircleIcon,
  },
  2: {
    icon: BlueCircleIcon,
    StyledIcon: StyledBlueCircleIcon,
  },
  3: {
    icon: PurpleCircleIcon,
    StyledIcon: StyledPurpleCircleIcon,
  },
  4: {
    icon: RedCircleIcon,
    StyledIcon: StyledSecondRedCircleIcon,
  },
};

interface FeatureExplanationCardProps {
  imgSide: "left" | "right";
  Img: React.ElementType;
  description: React.ReactNode;
  index: number;
}

const featuresInfo = [
  {
    Img: PatientLinkImg,
    description: (
      <>
        Comparte el{" "}
        <DescriptionHighlight>formulario para pacientes</DescriptionHighlight> y
        ellos completarán sus datos
      </>
    ),
  },
  {
    Img: InboxImg,
    description: (
      <>
        Recibe y gestiona las solicitudes de tus pacientes{" "}
        <DescriptionHighlight>desde un sólo lugar</DescriptionHighlight>
      </>
    ),
  },
  {
    Img: DocumentTypesImg,
    description: (
      <>
        <DescriptionHighlight>Crea fácilmente</DescriptionHighlight> recetas
        médicas, órdenes y certificados
      </>
    ),
  },
  {
    Img: AlternativesAndAvailabilityImg,
    description: (
      <>
        Revisa las{" "}
        <DescriptionHighlight>
          alternativas y disponibilidad
        </DescriptionHighlight>{" "}
        de los medicamentos en tiempo real
      </>
    ),
  },
  {
    Img: PriceComparationImg,
    description: (
      <>
        <DescriptionHighlight>Conoce y compara</DescriptionHighlight> el valor
        del medicamento en las farmacias
      </>
    ),
  },
  {
    Img: PinImg,
    description: (
      <>
        Emite recetas con{" "}
        <DescriptionHighlight>Firma Electrónica Avanzada</DescriptionHighlight>
      </>
    ),
  },
] as const;

const FeatureExplanationCard: React.FC<FeatureExplanationCardProps> = ({
  imgSide,
  Img,
  description,
  index,
}) => {
  const IconComponent = circleIcons[index]?.icon;
  const StyledIconComponent = circleIcons[index]?.StyledIcon;
  const isScreenBiggerThanLG = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg")
  );
  const overflow = index === 2 && !isScreenBiggerThanLG ? "hidden" : undefined;
  return (
    <CardContainer imgSide={imgSide} overflow={overflow}>
      <StyledImg as={Img} />
      <Description>{description}</Description>
      {IconComponent && StyledIconComponent && (
        <StyledIconComponent as={IconComponent} />
      )}
    </CardContainer>
  );
};

export const FeatureExplanationSection: React.FC = () => {
  return (
    <SectionWrapper>
      <SectionTitle>¿Cómo funciona MekiDoc?</SectionTitle>
      <SectionContainer>
        {featuresInfo.map(({ Img, description }, index) => (
          <FeatureExplanationCard
            key={`feature-explanation-card-${index}`}
            imgSide={index % 2 === 0 ? "left" : "right"}
            Img={Img}
            description={description}
            index={index}
          />
        ))}
      </SectionContainer>
    </SectionWrapper>
  );
};

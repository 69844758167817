import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import { theme as oldTheme } from "../config/theme";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";

const RadioContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
});

const InputLabel = styled("label")({
  fontWeight: 500,
  color: oldTheme.pallete.text.black,
});

const PaddedRadioGroup = styled(RadioGroup)({
  paddingInline: "8px",
});

const HelperText = styled(Typography)({
  fontSize: "14px",
  color: oldTheme.pallete.text.gray,
});

const Description = styled(HelperText)({
  paddingTop: "4px",
});

const RelativeContainer = styled(Box)({
  position: "relative",
});

const ErrorMessage = styled("span")({
  position: "absolute",
  fontSize: "12px",
  color: oldTheme.pallete.errors.primary,
});

interface Option {
  value: string;
  label: string;
}
interface RadioGroupProps {
  title?: string;
  parameterName: string;
  register: UseFormRegister<any>;
  control: Control<any>;
  options: Option[];
  defaultValue?: string;
  helperText?: string;
  description?: string;
  errors: FieldErrors<any>;
  errorText?: string;
}

export const RadioInput = ({
  title,
  parameterName,
  register,
  control,
  options,
  defaultValue = "",
  helperText,
  description,
  errors,
  errorText,
}: RadioGroupProps) => {
  return (
    <RadioContainer>
      {title && <InputLabel>{title}</InputLabel>}
      {helperText && <HelperText>{helperText}</HelperText>}
      <Controller
        name={parameterName}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <PaddedRadioGroup
            {...register(parameterName, { required: errorText ?? false })}
            {...field}
          >
            {options.map(({ value, label }) => (
              <FormControlLabel
                key={`${value}-${label}`}
                value={value}
                control={<Radio size="small" />}
                label={label}
              />
            ))}
            <RelativeContainer>
              {Boolean(errors[parameterName]) && (
                <ErrorMessage>
                  {errors[parameterName]?.message?.toString() ??
                    "Debes seleccionar una opción"}
                </ErrorMessage>
              )}
            </RelativeContainer>
          </PaddedRadioGroup>
        )}
      />
      {description && <Description>{description}</Description>}
    </RadioContainer>
  );
};

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Skeleton, Typography, styled } from "@mui/material";
import {
  DocumentProps,
  MedicalOrderPayload,
  requestTypeExtendedTranslation,
} from "@interfaces";
import { PDFViewer, PDFViewerProps, SecondaryActionButton } from "@components";
import { useGetPDFs } from "@hooks";
import { generatePdfName } from "@utils";
import { theme as oldTheme } from "../../../config";

const DetailsWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "8px 0px",
  gap: "8px",
});

const Row = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const DetailsTitle = styled(Typography)({
  fontWeight: 600,
});

const ButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const ShowDocumentButton = styled(SecondaryActionButton)({
  minWidth: "auto",
  justifyContent: "end",
  padding: "0px 4px",
});

const DetailsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  backgroundColor: "#ffffff",
  padding: "16px",
  gap: "8px",
  whiteSpace: "pre-line",
  color: oldTheme.pallete.text.black,
});

const DetailsSubtitle = styled("p")({
  fontWeight: 500,
  display: "inline",
});

const ProceduresList = styled("ul")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  paddingLeft: "16px",
});

const SkeletonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "auto",
  maxHeight: "56px",
});

interface Props {
  data: MedicalOrderPayload;
}

export const HistoricMedicalOrder = ({ data }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    id: requestId,
    patient,
    documentType,
    documents: medicalOrders,
  } = data;
  const {
    id: documentId,
    diagnosticHypothesis,
    documentDetails,
    indications,
  } = medicalOrders[0];
  const documentTitle = requestTypeExtendedTranslation[documentType];
  const [documentData, setDocumentData] = useState<DocumentProps>(
    {} as DocumentProps
  );

  const {
    data: pdfData,
    isPending,
    isSuccess,
    isError,
  } = useGetPDFs({
    requestId,
  });

  const onClose = () => setDocumentData({} as DocumentProps);

  if (Object.keys(documentData).length !== 0) {
    const prevRoute = location.pathname;
    if (isPending) {
      return <PDFViewer.Skeleton prevRoute={prevRoute} />;
    } // TODO: If isError, maybe add a modal with an error message
    else if (isError) {
      onClose();
    } else if (isSuccess) {
      const navigationState: PDFViewerProps = {
        documentProps: {
          ...documentData,
          documentUrl: pdfData[0].url,
        },
        pdfRenderingProps: { title: documentTitle, prevRoute },
      };
      navigate("/app/solicitudes/visualizacion-documento", {
        state: navigationState,
      });
    }
  }
  return (
    <DetailsWrapper>
      <Row>
        <DetailsTitle>Orden médica</DetailsTitle>
        <ButtonContainer>
          <ShowDocumentButton
            onClick={() =>
              setDocumentData({
                ...documentData,
                documentId: documentId,
                documentName: generatePdfName({
                  documentType,
                  patient,
                }),
              })
            }
            disabled={isError}
          >
            Ver documento
          </ShowDocumentButton>
        </ButtonContainer>
      </Row>
      <DetailsContainer>
        {diagnosticHypothesis && (
          <Typography>
            <DetailsSubtitle>Hipótesis diagnóstica:</DetailsSubtitle>{" "}
            {diagnosticHypothesis}
          </Typography>
        )}
        <ProceduresList>
          {documentDetails.map((documentDetail: string, index: number) => (
            <li key={index}>
              <Typography>{documentDetail}</Typography>
            </li>
          ))}
        </ProceduresList>

        {indications && (
          <Typography>
            <DetailsSubtitle>Indicaciones:</DetailsSubtitle> {indications}
          </Typography>
        )}
      </DetailsContainer>
    </DetailsWrapper>
  );
};

HistoricMedicalOrder.Skeleton = function HistoricMedicalOrderSkeleton() {
  return (
    <DetailsWrapper>
      <Row>
        <DetailsTitle>Orden médica</DetailsTitle>
        <ButtonContainer>
          <ShowDocumentButton>Ver documento</ShowDocumentButton>
        </ButtonContainer>
      </Row>
      <DetailsContainer>
        <SkeletonContainer>
          <Skeleton variant="text" width="300px" height="150px" />
        </SkeletonContainer>
      </DetailsContainer>
    </DetailsWrapper>
  );
};

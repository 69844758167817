import { Box } from "@mui/material";
import styled from "@emotion/styled";
import {
  DoctorBaseAttributes,
  PatientBaseAttributes,
} from "../../../interfaces";
import {
  CardInfoText,
  CardSectionSubtitle,
  GappedCardContainer,
} from "../styles";
import { format as formatDate } from "date-fns";
import { format as formatRut } from "rut.js";
import { separateDoctorSpecialty } from "../../../utils/getDoctorTitle";

type Props = {
  emissionDate: Date;
  doctorData: Omit<DoctorBaseAttributes, "birthdate">;
  patientData: Omit<PatientBaseAttributes, "email" | "phone">;
};

const DateContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  gap: "4px",
});

export const GeneralInfoCard: React.FC<Props> = ({
  emissionDate,
  doctorData,
  patientData,
}) => {
  const { mekidocAccessTitle, specialties } = separateDoctorSpecialty(
    doctorData.speciality
  );
  return (
    <GappedCardContainer>
      <DateContainer>
        <CardSectionSubtitle>Fecha emisión: </CardSectionSubtitle>
        <CardInfoText paddingTop={"0px"}>
          {formatDate(emissionDate, "dd/MM/yyyy")}
        </CardInfoText>
      </DateContainer>

      <Box>
        <CardSectionSubtitle>Datos doctor</CardSectionSubtitle>
        <CardInfoText>
          {doctorData.name} {doctorData.lastName}
        </CardInfoText>

        <CardInfoText>{formatRut(doctorData.rut)}</CardInfoText>

        <CardInfoText>
          {mekidocAccessTitle} (REG-SIS N°: {doctorData.registrationNumber})
        </CardInfoText>
        {specialties && <CardInfoText>{specialties}</CardInfoText>}
      </Box>

      <Box>
        <CardSectionSubtitle>Datos paciente</CardSectionSubtitle>

        <CardInfoText>
          {patientData.name} {patientData.firstSurname}{" "}
          {patientData.secondSurname}
        </CardInfoText>

        <CardInfoText>{formatRut(patientData.rut)}</CardInfoText>
      </Box>
    </GappedCardContainer>
  );
};

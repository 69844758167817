import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  Header,
  PatientRequestCommentAccordion,
  PatientSummaryAccordion,
  NavigateBeforeButton,
  FullScreenLoader,
} from "../../../components";
import { Patient, patientDefaultValues } from "../../../interfaces";
import { Global } from "@emotion/react";
import { useRequest } from "../../../hooks";

export function RequestManagement() {
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  const { data: request, isPending } = useRequest();
  const patient: Patient = useMemo(() => {
    return request ? request.patient : patientDefaultValues;
  }, [request]);
  if (isPending) return <RequestManagement.Skeleton />;
  return (
    <>
      <Header
        title={"Solicitud"}
        titleColor="black"
        left={
          <NavigateBeforeButton onClick={() => navigate(`/app/solicitudes`)} />
        }
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <PatientSummaryAccordion
          patient={patient}
          prevRoute={`/app/solicitudes/${requestId}/gestionar`}
          requestId={requestId}
          isOpen={false}
          canEdit={false}
        />
        <PatientRequestCommentAccordion
          request={request}
          canReject={true}
          isExpanded={true}
        />
        <BottomActionArea>
          <BottomActionButton
            onClick={() => navigate("./seleccionar-documento")}
          >
            Crear documento médico
          </BottomActionButton>
        </BottomActionArea>
      </Body>
    </>
  );
}

RequestManagement.Skeleton = function RequestManagementSkeleton() {
  return <FullScreenLoader title="Cargando información..." />;
};

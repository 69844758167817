import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { theme } from "../../../config";

export const GovernmentStrip = styled.div`
  width: 180px;
  height: 8px;
`;
export const BlueSpan = styled.span`
  background-color: #0f69b4;
  height: 8px;
  width: 37%;
  display: block;
  float: left;
`;
export const RedSpan = styled.span`
  background-color: #eb3c46;
  height: 8px;
  width: 63%;
  display: block;
  float: left;
`;
export const ClaveUnicaLogoContainer = styled.div``;

export const DescriptionText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: black;
  margin-top: -48px;
`;
export const InputLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 4px;
`;
export const SingleInput = styled(TextField)`
  font-size: 16px;
  font-weight: 400;
  input {
    height: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    padding: 12px;
    font-family: Poppins;
  }
`;
export const ErrorMessage = styled.span`
  color: ${theme.pallete.errors.primary};
  font-size: 12px;
`;
export const TLSEncryptionContainer = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 8px;
  padding: 8px;
`;
export const WrongCredentialsContainer = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 5px;
  padding: 16px;
  font-weight: 500;
`;
export const StatusHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StatusTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StatusTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.pallete.text.black};
`;
export const StatusSubTitle = styled.h4`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.pallete.text.black};
`;
export const TitleContainer = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;
export const LoadingTitleContainer = styled(TitleContainer)`
  max-width: 230px;
`;
export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 16px;
  gap: 24px;
`;
export const SuccessIcon = styled(CheckCircleIcon)`
  font-size: 57px;
  color: ${theme.pallete.success.primary};
`;

function firstLettersUpperCase(str: string): string {
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

export const getNameParts = (
  fullName: string
): {
  firstName: string;
  secondName: string;
  firstSurname: string;
  secondSurname: string;
  validateName: boolean;
} => {
  const tokens = fullName
    .trim()
    .split(" ")
    .filter((token) => token !== "");
  const names: string[] = [];
  const specialTokens = [
    "DA",
    "DE",
    "DEL",
    "LA",
    "LAS",
    "LOS",
    "MAC",
    "MC",
    "VAN",
    "VON",
    "Y",
    "I",
    "SAN",
    "SANTA",
  ];

  let prev = "";

  for (const token of tokens) {
    const upperToken = token.toUpperCase();
    if (specialTokens.includes(upperToken)) {
      prev += `${token} `;
    } else {
      names.push(prev + token);
      prev = "";
    }
  }

  let firstName = "";
  let secondName = "";
  let firstSurname = "";
  let secondSurname = "";
  let validateName = true;

  const namesNum = names.length;

  switch (namesNum) {
    case 0:
      break;
    case 1:
      firstName = names[0];
      break;
    case 2:
      firstName = names[0];
      firstSurname = names[1];
      break;
    case 3:
      firstName = names[0];
      firstSurname = names[1];
      secondSurname = names[2];
      break;
    case 4:
      firstName = names[0];
      secondName = names[1];
      firstSurname = names[2];
      secondSurname = names[3];
      validateName = false;
      break;
    default:
      firstName = `${names[0]} ${names[1]}`;
      secondName = names[2];
      firstSurname = names[3];
      names.splice(0, 4);
      secondSurname = names.join(" ");
      break;
  }

  firstSurname = firstSurname ? firstLettersUpperCase(firstSurname) : "";
  secondSurname = secondSurname ? firstLettersUpperCase(secondSurname) : "";
  firstName = firstName ? firstLettersUpperCase(firstName) : "";
  secondName = secondName ? firstLettersUpperCase(secondName) : "";

  return {
    firstName,
    secondName,
    firstSurname,
    secondSurname,
    validateName,
  };
};

import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UseFormReturn } from "react-hook-form";
import { Box, CircularProgress, Link, Typography, styled } from "@mui/material";
import { Global } from "@emotion/react";
import {
  CIDocFormData,
  Question,
  ValidationChallengePayload,
} from "@interfaces";
import {
  BirthdateInput,
  Body,
  BottomActionArea,
  BottomActionButton,
  ExtraWhiteSpace,
  Header,
  Modal,
  SingleFormInput,
  SnackBar,
  NavigateBeforeButton,
} from "@components";
import { capitalizeParametValue, handleNumericInputChange } from "@utils";
import { theme } from "@config";
import { FormContainer } from "@styles/styled";
import { StepLabel } from "../styles";
import { UseMutateFunction } from "@tanstack/react-query";

const StyledLink = styled(Link)({
  fontSize: "14px",
  color: theme.pallete.text.link,
  cursor: "pointer",
  padding: "8px 0px",
});

const ModalChildrenRoot = styled(Box)({
  marginTop: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  alignItems: "center",
});

const CIImgGroup = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
});

const CIImgTitle = styled(Typography)({
  color: theme.pallete.text.black,
  fontWeight: 500,
});

// TODO: if we don't want to set this values with px, we should take
// CIImg and its zoom as two different images with position absolute
const CIImg = styled("img")({
  width: "83.765%",
  marginLeft: "0.75rem",
});

const OldCIImg = styled("img")({
  width: "80%",
});

interface Props {
  form: UseFormReturn<CIDocFormData, any, undefined>;
  showValidateDoctorDataInputs: boolean;
  createValidationChallenge: UseMutateFunction<
    Question[],
    Error,
    ValidationChallengePayload,
    unknown
  >;
  isCreatingValidationChallenge: boolean;
}

export const CIDocForm: React.FC<Props> = ({
  form,
  showValidateDoctorDataInputs,
  createValidationChallenge,
  isCreatingValidationChallenge,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    clearErrors,
    trigger,
  } = form;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showUserIsVerifiedSnackbar, setShowUserIsVerifiedSnackbar] =
    useState(true);
  const [isCIDocModalVisible, setIsCIDocModalVisible] = useState(false);
  const [ciImgsSrcs, setCiImgsSrcs] = useState({ currentCi: "", oldCi: "" });
  const docNum = watch("docNum");

  const handleBack = useCallback(() => {
    const requestId = searchParams.get("requestId");
    const goBackUrl = requestId
      ? `/app/solicitudes/${requestId}/receta/crear`
      : "/app/solicitudes";
    navigate(goBackUrl);
  }, [navigate, searchParams]);

  const handleCIDocNumChange = useCallback(() => {
    clearErrors("docNum");
  }, [clearErrors]);

  const onSubmit = useCallback(
    (data: CIDocFormData) => {
      if (!data.docNum) {
        return;
      }
      if (
        showValidateDoctorDataInputs &&
        (!data.name ||
          !data.firstSurname ||
          !data.secondSurname ||
          !data.birthdate)
      ) {
        return;
      }
      const validationChallengePayload: ValidationChallengePayload = {
        ciDocNum: data.docNum.replaceAll(".", ""),
        ...(showValidateDoctorDataInputs && {
          validatedDoctorData: {
            name: data.name,
            firstSurname: data.firstSurname,
            secondSurname: data.secondSurname,
            birthdate: data.birthdate,
          },
        }),
      };
      createValidationChallenge(validationChallengePayload);
    },
    [showValidateDoctorDataInputs, createValidationChallenge]
  );

  useEffect(() => {
    const ciImg = new Image();
    const oldCiImg = new Image();
    ciImg.src = "/ci-doc.png";
    oldCiImg.src = "/ci-serie-num.png";
    ciImg.onload = () => {
      setCiImgsSrcs((prevState) => ({ ...prevState, currentCi: ciImg.src }));
    };
    oldCiImg.onload = () => {
      setCiImgsSrcs((prevState) => ({ ...prevState, oldCi: oldCiImg.src }));
    };
  }, []);

  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Header
        title="Verificación de identidad"
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Body>
        <StepLabel>Paso 2 de 3</StepLabel>
        <Typography>
          {showValidateDoctorDataInputs
            ? "Ingresa los datos de tu cédula de identidad"
            : "Ingresa el número de serie o documento de tu cédula de identidad"}
        </Typography>
        <FormContainer onSubmit={handleSubmit((data) => onSubmit(data))}>
          <SingleFormInput
            title="N° Serie/Documento"
            parameterName="docNum"
            placeholder=""
            errorText="Debes ingresar tu número de documento."
            register={register}
            errors={errors}
            inputType="number"
            numericInputType="document_number"
            onChange={handleCIDocNumChange}
            numericExternalValue={docNum}
            handleNumericExternalValueChange={(value) =>
              handleNumericInputChange(
                "docNum",
                String(value),
                clearErrors,
                setValue
              )
            }
          />

          <StyledLink
            onClick={(event) => {
              event.preventDefault();
              setIsCIDocModalVisible(true);
            }}
          >
            ¿Donde encuentro el nº de serie/documento?
          </StyledLink>

          {showValidateDoctorDataInputs && (
            <>
              <SingleFormInput
                title="Nombre completo"
                parameterName="name"
                errorText="Debes escribir tu nombre completo."
                register={register}
                errors={errors}
                onBlur={() =>
                  capitalizeParametValue("name", watch, setValue, trigger)
                }
              />
              <SingleFormInput
                title="Apellido paterno"
                parameterName="firstSurname"
                errorText="Debes escribir tu apellido paterno."
                register={register}
                errors={errors}
                onBlur={() =>
                  capitalizeParametValue(
                    "firstSurname",
                    watch,
                    setValue,
                    trigger
                  )
                }
              />
              <SingleFormInput
                title="Apellido materno"
                parameterName="secondSurname"
                errorText="Debes escribir tu apellido materno."
                register={register}
                errors={errors}
                autoComplete="off"
                onBlur={() =>
                  capitalizeParametValue(
                    "secondSurname",
                    watch,
                    setValue,
                    trigger
                  )
                }
              />
              <BirthdateInput control={control} errorManagement={{ errors }} />
            </>
          )}

          <ExtraWhiteSpace />

          <BottomActionArea>
            <BottomActionButton
              type="submit"
              disabled={isCreatingValidationChallenge}
            >
              {isCreatingValidationChallenge ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Siguiente"
              )}
            </BottomActionButton>
          </BottomActionArea>
        </FormContainer>
      </Body>
      <SnackBar
        snackBarState={{
          open: showUserIsVerifiedSnackbar,
          type: "success",
        }}
        onClose={() => setShowUserIsVerifiedSnackbar(false)}
        alerts={{
          success: "Identidad validada con clave única",
        }}
      />
      <Modal
        title=""
        onClose={() => {
          setIsCIDocModalVisible(false);
        }}
        isOpen={isCIDocModalVisible}
      >
        <ModalChildrenRoot>
          <CIImgGroup>
            <CIImgTitle>Cédula identidad actual</CIImgTitle>
            {ciImgsSrcs.currentCi ? (
              <CIImg src={ciImgsSrcs.currentCi} alt="Cédula identidad actual" />
            ) : (
              <Typography>Cargando...</Typography>
            )}
          </CIImgGroup>
          <CIImgGroup>
            <CIImgTitle>Cédula identidad antigua</CIImgTitle>
            {ciImgsSrcs.oldCi ? (
              <OldCIImg src={ciImgsSrcs.oldCi} alt="Cédula identidad antigua" />
            ) : (
              <Typography>Cargando...</Typography>
            )}
          </CIImgGroup>
        </ModalChildrenRoot>
      </Modal>
    </>
  );
};

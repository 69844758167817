import { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Typography, styled } from "@mui/material";
import { Global } from "@emotion/react";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  ExtraWhiteSpace,
  Header,
  NavigateBeforeButton,
  RadioInput,
  SingleFormInput,
  TextAreaFormInput,
  scrollToTop,
} from "../../components";
import { MedicineTreatmentForm } from "./components/MedicineTreatmentForm";
import { PrescriptionDetail, PrescriptionType } from "../../interfaces";
import { useAddPrescriptionDetail } from "../../hooks";
import { FormContainer } from "../../styles/styled";
import { theme as oldTheme } from "../../config";
import { queryClient } from "../../context";
import { useMedicineSearchContext } from "../MedicineSearch/context/MedicineSearchContext";

const MedicineDataContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  margin: "16px 0",
  padding: "16px 8px",
  backgroundColor: oldTheme.pallete.background.white,
  border: "1px solid #eaeaea",
});

const MedicineTitle = styled(Typography)({
  color: oldTheme.pallete.text.black,
  fontWeight: 500,
});
const MedicineDetail = styled(Typography)({
  fontSize: "14px",
  color: "#747474",
});

interface PrescriptionDetailExtended extends PrescriptionDetail {
  prescriptionType: PrescriptionType;
}

export function AddMedicineManually() {
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  const location = useLocation();
  const { searchedQuery = "" }: { searchedQuery: string } =
    location.state || {};

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<PrescriptionDetailExtended>({
    defaultValues: {
      activePrinciple: searchedQuery,
    },
  });
  const activePrinciple = watch("activePrinciple");
  const name = watch("name");
  const prescriptionType = watch("prescriptionType");

  const { searchResults, setSearchResults } = useMedicineSearchContext();
  const { mutate: addDetail, isPending: isAddingDetail } =
    useAddPrescriptionDetail();

  const [settingMedicineData, setSettingMedicineData] = useState(true);
  const [settingTreatment, setSettingTreatment] = useState<boolean>(true);

  const onMedicineDataSubmit = () => {
    setSettingMedicineData(false);
    scrollToTop();
  };

  const onSubmit = useCallback(
    (formData: PrescriptionDetail) => {
      setSettingTreatment(false);
      scrollToTop();
      const prescriptionDetail = {
        ...formData,
        prescriptionType,
      };
      addDetail(
        {
          requestId,
          prescriptionDetail,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["request", requestId] });
            navigate(`/app/solicitudes/${requestId}/receta/crear`, {
              state: {
                detailAdded: true,
                medicineType: prescriptionType === "HELD" ? "HELD" : "SIMPLE",
              },
            });
          },
        }
      );
      setSearchResults(undefined);
    },
    [requestId, prescriptionType, addDetail, navigate, setSearchResults]
  );

  const handleBack = useCallback(() => {
    if (settingMedicineData) {
      navigate(`/app/solicitudes/${requestId}/receta/crear/buscar`, {
        state: { searchResults },
      });
      return;
    } else if (settingTreatment) {
      setSettingMedicineData(true);
    } else {
      setSettingTreatment(true);
    }
    scrollToTop();
  }, [
    settingMedicineData,
    settingTreatment,
    requestId,
    searchResults,
    navigate,
  ]);

  return (
    <>
      <Header
        title="Agregar medicamento"
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        {settingMedicineData ? (
          <FormContainer onSubmit={handleSubmit(onMedicineDataSubmit)}>
            <SingleFormInput
              title="Medicamento"
              parameterName="activePrinciple"
              errorText="Debes ingresar un medicamento."
              register={register}
              errors={errors}
            />

            <TextAreaFormInput
              title="Especificaciones"
              parameterName="name"
              register={register}
              errors={errors}
              helperText="Ej: Dosis, forma farmacéutica, etc."
              minHeight="80px"
            />

            <RadioInput
              title="Tipo de receta"
              parameterName="prescriptionType"
              register={register}
              control={control}
              options={[
                { value: "SIMPLE", label: "Simple" },
                { value: "HELD", label: "Retenida" },
              ]}
              defaultValue="SIMPLE"
              helperText="No esta permitido emitir recetas cheque digitales."
              errors={errors}
              errorText="Debes seleccionar el tipo de receta."
            />

            <ExtraWhiteSpace />

            <BottomActionArea>
              <BottomActionButton type="submit">Siguiente</BottomActionButton>
            </BottomActionArea>
          </FormContainer>
        ) : (
          <>
            {settingTreatment && (
              <MedicineDataContainer>
                <MedicineTitle>{activePrinciple}</MedicineTitle>
                {name && <MedicineDetail>{name}</MedicineDetail>}
              </MedicineDataContainer>
            )}
            <MedicineTreatmentForm
              prefilledMedicineAttributes={{
                activePrinciple,
                name,
                prescriptionType,
              }}
              formManagement={{
                onSubmit,
                isLoading: isAddingDetail,
              }}
            />
          </>
        )}
      </Body>
    </>
  );
}

import { Box, Link, Typography, styled } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { FooterLandingIcon } from "@icons";
import {
  ispGuidelinesLink,
  needHelpMessage,
  privacyPolicyLink,
  termsAndConditionsLink,
} from "@constants";
import { contactUsLink } from "@utils";
import { theme as oldTheme } from "@config/theme";
import { MEKIDOC_EMAIL, MEKIDOC_PHONE } from "../../../environment";

const Container = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "380px",
  overflow: "hidden",
  background:
    "linear-gradient(91.49deg, #302D84 0.5%, #7355FB 53%, #42BAF0 100%)",
});

const Content = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  flex: "1",
  gap: "75px",
  justifyContent: "flex-start",
  padding: "48px 32px",
  [theme.breakpoints.up("sm")]: {
    padding: "48px 64px",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "space-between",
    padding: "48px 104px",
  },
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  [theme.breakpoints.up("md")]: { flexDirection: "row" },
}));

const InfoColumnContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: 700,
  color: theme.palette.common.white,
}));

const ContactRow = styled(Box)({
  display: "flex",
  gap: "12px",
  alignItems: "center",
});

const StyledLink = styled(Link)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.common.white,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const ContactIconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  borderRadius: "100%",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.common.white,
  padding: "8px",
  color: oldTheme.pallete.text.primary,
}));

const MekiDocIcon = styled("img")(({ theme }) => ({
  width: "192px",
  height: "34px",
  alignSelf: "center",
  [theme.breakpoints.up("md")]: {
    alignSelf: "flex-start",
  },
}));

const FooterIcon = styled(FooterLandingIcon)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
    width: "325px",
    height: "325px",
    position: "absolute",
    justifyContent: "flex-start",
    top: "calc(70% - 162.5px)",
    right: "64px",
    color: oldTheme.pallete.text.primary,
  },
  [theme.breakpoints.up("lg")]: {
    right: "128px",
  },
}));

const DetailLink: React.FC<{ href: string; children: React.ReactNode }> = ({
  href,
  children,
}) => {
  return (
    <StyledLink target="_blank" rel="noopener" href={href}>
      {children}
    </StyledLink>
  );
};

export const Footer: React.FC = () => {
  return (
    <Container>
      <Content>
        <InfoContainer>
          <InfoColumnContainer>
            <SectionTitle>¿NECESITAS AYUDA?</SectionTitle>
            <ContactRow>
              <ContactIconContainer>
                <MailOutlinedIcon />
              </ContactIconContainer>
              <DetailLink href={`mailto:${MEKIDOC_EMAIL}`}>
                {MEKIDOC_EMAIL}
              </DetailLink>
            </ContactRow>
            <ContactRow>
              <ContactIconContainer>
                <WhatsAppIcon />
              </ContactIconContainer>
              <DetailLink href={contactUsLink(needHelpMessage)}>
                {MEKIDOC_PHONE}
              </DetailLink>
            </ContactRow>
          </InfoColumnContainer>
          <InfoColumnContainer>
            <SectionTitle>INFORMACIÓN</SectionTitle>
            <DetailLink href={termsAndConditionsLink}>
              Términos y condiciones
            </DetailLink>
            <DetailLink href={privacyPolicyLink}>
              Políticas de privacidad
            </DetailLink>
            <DetailLink href={ispGuidelinesLink}>Orientaciones ISP</DetailLink>
          </InfoColumnContainer>
        </InfoContainer>
        <MekiDocIcon src="/landing/meki_doc_white_icon.svg" />
        <FooterIcon />
      </Content>
    </Container>
  );
};

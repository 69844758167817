import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { QueryClient } from "@tanstack/react-query";
import { millisecondsInSecond } from "date-fns";
import { Auth } from "aws-amplify";
import { FetchPayload } from "../interfaces";
import { BASE_URL } from "../environment";

const axiosClient = axios.create({ baseURL: BASE_URL });

export const api = async <T,>({
  ...options
}: AxiosRequestConfig): Promise<T> => {
  const config: AxiosRequestConfig = {
    ...options,
    headers: {},
  };
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      const jwtToken = user.signInUserSession.idToken.jwtToken;
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${jwtToken}`,
      };
    }
  } catch (err) {
    // We need to catch the possible error that is thrown by Auth.currentAuthenticatedUser()
    // This api is used on Public and Private routes for now. probably this should be done differently
    console.log("The user is not authed");
  }

  const onSuccess = (response: AxiosResponse<FetchPayload<T>>) => {
    return response.data.payload;
  };

  const onError = (error: any) => {
    console.error({
      msg: "Api - onError",
      error,
    });

    throw error;
  };
  return axiosClient(config).then(onSuccess).catch(onError);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: millisecondsInSecond * 20,
    },
  },
});

import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Global } from "@emotion/react";
import {
  CommonPrescription,
  DocumentProps,
  PrescriptionQFHeaderMapper,
  PrescriptionStatus,
  PrescriptionTypeMapper,
  RequestType,
} from "../../interfaces";
import {
  FullScreenLoader,
  Header,
  PrescriptionStatusChip,
  NavigateBeforeButton,
} from "../../components";
import {
  GeneralInfoCard,
  HolderInformation,
  PrescriptionBottomActionArea,
  PrescriptionInfo,
  SignatureCard,
} from "./components";
import { GappedBody, MarginContainer } from "./styles";
import { usePrescription } from "./hooks/usePrescription";
import {
  StyledDocument,
  StyledPage,
  WaterMarkStyle,
} from "../../components/styles";
import { CircularProgress } from "@mui/material";
import { generatePdfName } from "../../utils";
import LogRocket from "logrocket";
import { ENV } from "../../environment";

export const PrescriptionValidationScreen: React.FC = () => {
  const { data, isFetching } = usePrescription();
  const navigate = useNavigate();
  const handleBack = () => navigate("..");
  const [numPages, setNumPages] = useState(1);
  const handleOnLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    },
    []
  );
  if (isFetching) return <FullScreenLoader title="Cargando información..." />;
  if (!data) {
    handleBack();
    return null;
  }
  const { prescription, prescriptionPDFReadUrl, doctor, patient } = data;

  const isCompoundedPrescription =
    prescription.type === RequestType.COMPOUNDED_PRESCRIPTION;

  const prescriptionPDFName = generatePdfName({
    patient,
    documentType: isCompoundedPrescription
      ? RequestType.COMPOUNDED_PRESCRIPTION
      : RequestType.PRESCRIPTION,
    ...(!isCompoundedPrescription && {
      documentNameDetails: PrescriptionTypeMapper[prescription.type],
    }),
  });
  const prescriptionPDF: DocumentProps = {
    documentId: prescription.id,
    documentUrl: prescriptionPDFReadUrl,
    documentName: prescriptionPDFName,
  };
  const reducedPrescriptionData = {
    id: prescription.id,
    requestId: prescription.requestId,
    type: prescription.type,
    prescriptionStatus: prescription.prescriptionStatus,
    details: prescription.details,
    holdingDate: prescription.holdingDate,
  };
  const prescriptionPDFData = { prescriptionPDFReadUrl, prescriptionPDFName };
  identifyLogRocketSession(prescription);
  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Header
        title={PrescriptionQFHeaderMapper[prescription.prescriptionStatus]}
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <GappedBody>
        <MarginContainer margin={"16px 0px 0px 0px"}>
          <PrescriptionStatusChip
            code={prescription.id}
            status={prescription.prescriptionStatus}
            date={prescription.holdingDate}
            isCompoundedPrescription={isCompoundedPrescription}
          />
        </MarginContainer>
        {prescription.holding?.pharmaceuticalChemist && (
          <HolderInformation
            prescriptionHolding={prescription.holding}
            holderData={prescription.holding.pharmaceuticalChemist}
            status={prescription.prescriptionStatus}
            isCompoundedPrescription={isCompoundedPrescription}
          />
        )}
        <GeneralInfoCard
          emissionDate={new Date(prescription.createdAt)}
          doctorData={doctor}
          patientData={patient}
        />
        {prescription.prescriptionStatus !== PrescriptionStatus.CANCELED && (
          <>
            <PrescriptionInfo
              prescriptionData={reducedPrescriptionData}
              prescriptionPDF={prescriptionPDF}
            />
            <SignatureCard doctorData={doctor} />
            <PrescriptionBottomActionArea
              prescription={prescription}
              prescriptionPDFData={prescriptionPDFData}
            />
          </>
        )}
        {prescription.prescriptionStatus === PrescriptionStatus.CANCELED && (
          <StyledDocument
            file={prescriptionPDFReadUrl}
            loading={<CircularProgress size={24} color="primary" />}
            onLoadSuccess={handleOnLoadSuccess}
            blur
          >
            {numPages &&
              Array.from(new Array(numPages), (_, pageIndex) => (
                <React.Fragment key={`page_${pageIndex}`}>
                  <StyledPage
                    pageNumber={pageIndex + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  >
                    <WaterMarkStyle>ANULADA</WaterMarkStyle>
                  </StyledPage>
                </React.Fragment>
              ))}
          </StyledDocument>
        )}
      </GappedBody>
    </>
  );
};

function identifyLogRocketSession(prescription: CommonPrescription) {
  if (!(ENV !== "development" && process.env.REACT_APP_LOG_ROCKET_ID)) {
    return;
  }
  if (prescription.holding && prescription.holding.pharmaceuticalChemist) {
    const {
      holding,
      holding: { pharmaceuticalChemist },
    } = prescription;

    LogRocket.identify(pharmaceuticalChemist.code, {
      name: holding.pharmacyName,
      email: pharmaceuticalChemist.email,

      requestId: prescription.requestId,
      prescriptionId: prescription.id,
      prescriptionStatus: prescription.prescriptionStatus,

      pharmaceuticalChemistCode: pharmaceuticalChemist.code,
      pharmaceuticalChemistRut: pharmaceuticalChemist.rut,
      pharmaceuticalChemistName: `${pharmaceuticalChemist.name} ${pharmaceuticalChemist.lastName}`,
      pharmacyRut: holding.pharmacyRut,
      pharmacyName: holding.pharmacyName,
      holdingDate: holding.holdingDate,
    });
  } else {
    LogRocket.identify("Sesión de farmacia", {
      name: "Sesión de farmacia",
    });
  }
}

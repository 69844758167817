import { useQuery } from "@tanstack/react-query";
import { millisecondsInMinute } from "date-fns";
import { useParams } from "react-router-dom";
import { CommonPrescription, Doctor, Patient } from "../../../interfaces";
import { api } from "../../../context";
import { isAxiosError } from "axios";

interface PrescriptionData {
  patient: Patient;
  doctor: Omit<Doctor, "signature">;
  prescription: CommonPrescription;
  prescriptionPDFReadUrl: string;
}

export const usePrescription = (verificationCode?: string) => {
  const { prescriptionId } = useParams();
  return useQuery({
    queryKey: ["verify-prescription", verificationCode ?? prescriptionId],
    queryFn: () => {
      return api<PrescriptionData>({
        url: `/prescriptions/${verificationCode ?? prescriptionId}`,
      });
    },
    enabled: prescriptionId ? true : false,
    staleTime: 5 * millisecondsInMinute,
    retry: (_, error) => {
      if (isAxiosError(error) && error.response?.status === 404) {
        // 404 means the prescription is not in the system
        return false;
      }
      return true;
    },
  });
};

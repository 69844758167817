import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, lighten, styled, Typography } from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Global } from "@emotion/react";
import { Body, NavigateBeforeButton } from "@components";
import { LoginHeader } from "../styled";
import MekiDocIcon from "@icons/MekiDocIcon";
import { theme as oldTheme } from "@config/theme";

const HeaderContainer = styled(Box)({
  paddingTop: "48px",
});

const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "48px 16px",
  gap: "16px",
});

const AccessButton = styled(Alert)({
  backgroundColor: "#E8E5F5",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: lighten(oldTheme.pallete.text.primary, 0.8),
  },
  "& .MuiAlert-message": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  "& .MuiAlert-icon": {
    display: "none",
  },
});

const AccessInfoContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const AccessTitle = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: 500,
  color: oldTheme.pallete.text.primaryDark,
});

const AccessDetails = styled(Typography)({
  fontSize: "0.75rem",
  fontWeight: 400,
  color: oldTheme.pallete.text.black,
});

const StyledArrowForwardIosRoundedIcon = styled(ArrowForwardIosRoundedIcon)({
  marginLeft: "32px",
  marginRight: "4px",
  color: oldTheme.pallete.text.primaryDark,
});

export function MobileLogin() {
  const navigate = useNavigate();
  const handleBack = useCallback(() => {
    navigate("/");
  }, [navigate]);
  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <HeaderContainer>
          <LoginHeader>
            <NavigateBeforeButton onClick={handleBack} />
            <MekiDocIcon size={"medium"} />
          </LoginHeader>
        </HeaderContainer>
        <ButtonContainer>
          <AccessButton
            onClick={() => {
              navigate("/app");
            }}
          >
            <AccessInfoContainer>
              <AccessTitle>Acceso Doctor</AccessTitle>
              <AccessDetails>
                Emisión de recetas, órdenes y certificados
              </AccessDetails>
            </AccessInfoContainer>
            <StyledArrowForwardIosRoundedIcon />
          </AccessButton>
          <AccessButton
            onClick={() => {
              navigate("/app/farmacia");
            }}
          >
            <AccessInfoContainer>
              <AccessTitle>Acceso Farmacia</AccessTitle>
              <AccessDetails>Retención y dispensación de recetas</AccessDetails>
            </AccessInfoContainer>
            <StyledArrowForwardIosRoundedIcon />
          </AccessButton>
        </ButtonContainer>
      </Body>
    </>
  );
}

import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { CircularProgress, Typography, styled } from "@mui/material";
import { Global } from "@emotion/react";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  NavigateBeforeButton,
  SingleFormInput,
} from "@components";
import {
  LoginHeader,
  NewLoginContactUsParagraph,
  NewLoginContactUsText,
  NewLoginErrorContainer,
  NewLoginErrorTitle,
  NewLoginErrorWrapper,
  StyledErrorIcon,
} from "../Auth/styled";
import { useIsScreenBiggerThanSM } from "@hooks";
import { usePrescription } from "./hooks/usePrescription";
import { onContactUsClick } from "@utils";
import { prescriptionCodeErrorWsp } from "@constants";
import { theme as oldTheme } from "@config/theme";
import MekiDocIcon from "@icons/MekiDocIcon";

const ContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  paddingTop: "48px",
  gap: "32px",
  flex: "1",
});

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: "16px",
});

const Subtitle = styled(Typography)({
  color: oldTheme.pallete.background.gray,
});

type Inputs = {
  verificationCode: string;
};

export const PrescriptionCodeVerificationScreen: React.FC = () => {
  const navigate = useNavigate();
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Inputs>();

  const verificationCode = watch("verificationCode");
  const { refetch, isFetching, isError } = usePrescription(verificationCode);
  const [contactUsMessage, setContactUsMessage] = useState("");

  const onSubmit: SubmitHandler<Inputs> = useCallback(async () => {
    const { data, isError } = await refetch();
    if (isError) {
      setError("verificationCode", {
        type: "manual",
        message: "",
      });
    }
    if (verificationCode) {
      setContactUsMessage(prescriptionCodeErrorWsp(verificationCode));
    }
    if (data) {
      navigate(verificationCode);
    }
  }, [verificationCode, refetch, setError, navigate]);

  const handleCodeKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter") {
        clearErrors("verificationCode");
        return;
      }
      if (!verificationCode) return;
      // Remove focus from input (to hide keyboard on mobile)
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      onSubmit({ verificationCode });
    },
    [verificationCode, clearErrors, onSubmit]
  );

  const handleBack = useCallback(() => {
    isScreenBiggerThanSM ? navigate("/") : navigate("/app/mobile-login");
  }, [isScreenBiggerThanSM, navigate]);

  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <ContentContainer>
          <LoginHeader>
            <NavigateBeforeButton onClick={handleBack} />
            <MekiDocIcon size={"medium"} />
          </LoginHeader>
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <SingleFormInput
              title="Ingrese Código de Verificación"
              parameterName="verificationCode"
              errorText="Debes ingresar un código"
              register={register}
              errors={errors}
              inputType="text"
              onKeyDown={handleCodeKeyDown}
            />
            {!isError ? (
              <Subtitle>
                Para validar la receta y retenerla de ser necesario
              </Subtitle>
            ) : (
              <>
                {/* TODO: hacer un componente más reutilizable que tenga este
                tipo de errores */}
                <NewLoginErrorWrapper
                  color={oldTheme.pallete.background.lightRed}
                >
                  <NewLoginErrorContainer>
                    <StyledErrorIcon />
                    <NewLoginErrorTitle>
                      El código ingresado no es válido
                    </NewLoginErrorTitle>
                  </NewLoginErrorContainer>
                </NewLoginErrorWrapper>
                <NewLoginContactUsParagraph>
                  Si tienes problemas{" "}
                  <NewLoginContactUsText
                    onClick={() => onContactUsClick(contactUsMessage)}
                  >
                    contáctanos
                  </NewLoginContactUsText>
                </NewLoginContactUsParagraph>
              </>
            )}
            <BottomActionArea>
              <BottomActionButton
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={isFetching}
              >
                {isFetching ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Verificar"
                )}
              </BottomActionButton>
            </BottomActionArea>
          </FormContainer>
        </ContentContainer>
      </Body>
    </>
  );
};

import { theme as oldTheme } from "../../config/theme";
import { Box, styled, TextField, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const ContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  paddingTop: "48px",
  gap: "32px",
  flex: 1,
});

export const AuthContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
});

export const NewAuthContainer = styled(AuthContainer)({
  gap: "24px",
  flex: 1,
});

export const InputLabel = styled("label")({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "19px",
  marginBottom: "4px",
  color: oldTheme.pallete.text.black,
});

export const SingleInput = styled(TextField)({
  fontSize: "16px",
  fontWeight: 400,
  backgroundColor: oldTheme.pallete.background.white,
  input: {
    height: "32px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
    padding: "12px",
  },
});

export const CodeDescriptionText = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "center",
  color: oldTheme.pallete.text.black,
});

export const CodeContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
});

export const ErrorMessage = styled(Typography)({
  color: oldTheme.pallete.errors.primary,
  fontSize: "12px",
  position: "absolute",
  bottom: "-20px",
});

export const EmailNoRegisteredErrorContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: "0px 32px",
  color: oldTheme.pallete.errors.primary,
  fontSize: "14px",
});

export const NewLoginErrorWrapper = styled(Box)<{ color: string }>(
  ({ color }) => ({
    backgroundColor: color,
    fontWeight: 500,
    padding: "16px",
    borderRadius: "5px",
  })
);

export const NewLoginErrorContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const StyledErrorIcon = styled(WarningAmberIcon)({
  fontSize: "24px",
  color: oldTheme.pallete.errors.primary,
  marginRight: "12px",
});

export const NewLoginErrorTitle = styled(Typography)({
  fontSize: "14px",
  fontWeight: 400,
  color: oldTheme.pallete.text.black,
});

export const IncorrectCodeText = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  color: oldTheme.pallete.errors.primary,
  textAlign: "center",
  marginTop: "-32px",
});

export const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
});

export const RegisterContainer = styled(Box)({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "32px",
});

export const NewLoginRegisterText = styled(Typography)({
  textAlign: "left",
  color: oldTheme.pallete.text.gray,
});

export const NewLoginContactUsParagraph = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "23px",
  letterSpacing: "0.5px",
  textAlign: "center",
  color: oldTheme.pallete.text.black,
});

export const NewLoginContactUsText = styled("span")({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "17px",
  letterSpacing: "0.4px",
  textDecoration: "underline",
  textAlign: "center",
  color: oldTheme.pallete.text.link,
  cursor: "pointer",
});

export const MekiDocIconContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "32px",
});

export const LoginHeader = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr auto 1fr",
  alignItems: "center",
  marginBottom: "32px",
  "& > :first-child": {
    gridColumn: "1",
    maxWidth: "30px",
  },
  "& > :nth-child(2)": {
    gridColumn: "2",
    justifySelf: "center",
  },
});

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  styled,
  Drawer,
  AppBar,
  IconButton,
  Card,
  Typography,
  Divider,
  Box,
  Link,
  Backdrop,
  Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ShareIcon from "@mui/icons-material/Share";
import MekiDocIcon from "../../../icons/MekiDocIcon";
import { Body, LogoutModal } from "@components";
import { useAuth, useDoctorContext, useSidebarMenuContext } from "@contexts";
import { useEditDoctor, useIsScreenBiggerThanSM } from "@hooks";
import {
  getDoctorTitle,
  checkIfBiometricAuthIsAvailable,
  onContactUsClick,
} from "@utils";
import {
  needHelpMessage,
  unsuscribeMessage,
  termsAndConditionsLink,
} from "../../../constants";
import { theme as oldTheme } from "../../../config";

const StyledSidebarMenuListItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isTitle",
})((props: { onClick?: () => void; isTitle: string }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: props.isTitle === "true" ? "15px 16px" : "16px 8px 16px 16px",
  cursor: props.onClick ? "pointer" : "default",
  "&:hover": {
    backgroundColor: props.onClick
      ? oldTheme.pallete.background.lightGray
      : "inherit",
  },
}));

const SideBarMenuTitle = styled(Typography)({
  fontSize: "20px",
  fontWeight: "500",
  lineHeight: "27px",
  color: oldTheme.pallete.documentTypes.MEDICAL_ORDER,
});

const TextIconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const StyledRightArrow = styled(KeyboardArrowRightIcon)({
  transform: "scale(1.3)",
  color: oldTheme.pallete.text.black,
});

interface SidebarMenuListItemProps {
  text: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  showArrow?: boolean;
  isTitle?: boolean;
  switchComponent?: React.ReactNode;
}

const SidebarMenuListItem: React.FC<SidebarMenuListItemProps> = ({
  text,
  icon,
  onClick,
  showArrow,
  isTitle,
  switchComponent,
}) => {
  return (
    <StyledSidebarMenuListItem
      onClick={onClick}
      isTitle={isTitle ? "true" : "false"}
    >
      {isTitle ? (
        <SideBarMenuTitle>{text}</SideBarMenuTitle>
      ) : (
        <TextIconContainer>
          {icon}
          <Typography color={oldTheme.pallete.text.black}>{text}</Typography>
        </TextIconContainer>
      )}
      {showArrow && <StyledRightArrow />}
      {switchComponent}
    </StyledSidebarMenuListItem>
  );
};

const StyledBackDrop = styled(Backdrop)({
  zIndex: 9,
  backgroundColor: "rgba(0, 0, 0, 0.75)",
});

const StyledDrawer = styled(Drawer)({
  zIndex: 10,
});

const StyledAppBar = styled(AppBar)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  padding: "24px 16px",
  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
  backgroundColor: "white",
});

const CloseIconButton = styled(IconButton)({
  color: oldTheme.pallete.text.black,
  position: "absolute",
  right: "16px",
});

const SidebarMenuBody = styled(Body)({
  display: "flex",
  flexDirection: "column",
  padding: "16px 8px",
  gap: "16px",
  backgroundColor: oldTheme.pallete.background.lightGray,
});

const CenteredBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px 0px",
});

const StyledIconButton = styled(IconButton)({
  height: "24px",
  width: "24px",
  color: oldTheme.pallete.text.primary,
  background: oldTheme.pallete.text.primaryExtraLight,
});

const StyledShareIcon = styled(ShareIcon)({
  width: "16px",
  height: "16px",
});

const StyledAddIcon = styled(AddIcon)({
  width: "20px",
  height: "20px",
});

const StyledIosShareIcon = styled(IosShareIcon)({
  width: "16px",
  height: "16px",
});

const ClickableText = styled(Typography)({
  cursor: "pointer",
  fontWeight: "500",
  "&:hover": {
    textDecoration: "underline",
  },
});

const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const SectionTitle = styled(Typography)({
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "24px",
  color: oldTheme.pallete.text.lightBlack,
});

const StyledLink = styled(Link)({
  color: oldTheme.pallete.text.black,
  textDecoration: "none",
});

interface SidebarMenuProps {
  onPatientLinkClick: () => void;
  onNewDocumentClick: () => void;
  showAddToHomeScreenBaseConditions: boolean;
  setShowAddToHomeScreen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SidebarMenu: React.FC<SidebarMenuProps> = ({
  onPatientLinkClick,
  onNewDocumentClick,
  showAddToHomeScreenBaseConditions,
  setShowAddToHomeScreen,
}) => {
  const navigate = useNavigate();
  const doctor = useDoctorContext();
  const { biometricSignUp } = useAuth();
  const { mutate: editDoctor } = useEditDoctor({
    code: doctor.code,
  });
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const { isSidebarMenuOpen, setIsSidebarMenuOpen } = useSidebarMenuContext();
  const [showLogOutModal, setShowLogOutModal] = useState(false);

  const [isBiometricAuthAvailable, setIsBiometricAuthAvailable] =
    useState(false);
  const [switchChecked, setSwitchChecked] = useState(doctor.biometricIsEnabled);
  const handleSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSwitchChecked(checked);
    if (checked) {
      const signUpSuccess = await biometricSignUp(doctor);
      signUpSuccess
        ? editDoctor({ biometricIsEnabled: true })
        : setSwitchChecked(false);
    } else {
      editDoctor({
        biometricIsEnabled: false,
      });
    }
  };

  const handleClose = () => {
    setIsSidebarMenuOpen(false);
  };

  useEffect(() => {
    async function checkBiometricAuth() {
      const isBiometricAuthAvailable = await checkIfBiometricAuthIsAvailable();
      setIsBiometricAuthAvailable(isBiometricAuthAvailable);
    }
    checkBiometricAuth();
  }, []);

  useEffect(() => {
    setSwitchChecked(doctor.biometricIsEnabled);
  }, [doctor.biometricIsEnabled]);

  useEffect(() => {
    function changeScrollBehavior() {
      if (!isScreenBiggerThanSM) {
        if (isSidebarMenuOpen) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      }
    }
    changeScrollBehavior();
  }, [isScreenBiggerThanSM, isSidebarMenuOpen]);

  return (
    <>
      <StyledBackDrop open={isSidebarMenuOpen} onClick={handleClose} />
      <StyledDrawer
        variant="persistent"
        anchor="left"
        open={isSidebarMenuOpen}
        elevation={0}
        PaperProps={{
          variant: "outlined",
          sx: {
            width: "340px",
          },
        }}
        transitionDuration={200}
      >
        <StyledAppBar>
          <MekiDocIcon size={"small"} />
          <CloseIconButton onClick={handleClose}>
            <CloseIcon />
          </CloseIconButton>
        </StyledAppBar>
        <SidebarMenuBody>
          <Card variant="outlined">
            <SidebarMenuListItem
              text={`${getDoctorTitle(doctor.gender).title} ${doctor.name} ${
                doctor.lastName
              }`}
              isTitle={true}
            />
            <Divider />
            <SidebarMenuListItem
              icon={<StyledIconButton children={<StyledShareIcon />} />}
              text={"Formulario para pacientes"}
              onClick={onPatientLinkClick}
            />
            <Divider />
            <SidebarMenuListItem
              icon={<StyledIconButton children={<StyledAddIcon />} />}
              text={"Nuevo documento"}
              onClick={() => {
                handleClose();
                onNewDocumentClick();
              }}
            />
            <Divider />
            {showAddToHomeScreenBaseConditions && (
              <>
                <SidebarMenuListItem
                  icon={<StyledIconButton children={<StyledIosShareIcon />} />}
                  text={"Descargar app"}
                  onClick={() => {
                    handleClose();
                    setShowAddToHomeScreen(true);
                  }}
                />
                <Divider />
              </>
            )}
            <CenteredBox>
              <ClickableText
                variant="body1"
                color="primary"
                onClick={() => setShowLogOutModal(true)}
              >
                Cerrar sesión
              </ClickableText>
              <LogoutModal
                showLogOutModal={showLogOutModal}
                setShowLogOutModal={setShowLogOutModal}
              />
            </CenteredBox>
          </Card>
          <Section>
            <SectionTitle variant="h6">Mis datos</SectionTitle>
            <Card variant="outlined">
              <SidebarMenuListItem
                text={"Datos personales"}
                onClick={() => {
                  navigate("/app/datos-personales");
                }}
                showArrow
              />
              <Divider />
              <SidebarMenuListItem
                text={"Dirección consulta"}
                onClick={() => {
                  navigate("/app/datos-personales/cambiar-direccion");
                }}
                showArrow
              />
              {isBiometricAuthAvailable && (
                <>
                  <Divider />
                  <SidebarMenuListItem
                    text={"Acceso con huella o Face ID"}
                    switchComponent={
                      <Switch
                        checked={switchChecked}
                        onChange={handleSwitchChange}
                      />
                    }
                  />
                </>
              )}
            </Card>
          </Section>
          <Section>
            <SectionTitle variant="h6">Asistencia</SectionTitle>
            <Card variant="outlined">
              <SidebarMenuListItem
                text={"Contáctanos"}
                onClick={() => {
                  onContactUsClick(needHelpMessage);
                }}
                showArrow
              />
              <Divider />
              <StyledLink
                target="_blank"
                rel="noopener"
                href={termsAndConditionsLink}
              >
                <SidebarMenuListItem
                  text={"Términos y condiciones"}
                  onClick={() => {}}
                  showArrow
                />
              </StyledLink>

              <Divider />
              <SidebarMenuListItem
                text={"Darme de baja"}
                onClick={() => {
                  onContactUsClick(unsuscribeMessage);
                }}
                showArrow
              />
            </Card>
          </Section>
        </SidebarMenuBody>
      </StyledDrawer>
    </>
  );
};

import { SvgIcon, SvgIconProps } from "@mui/material";

export const MedicalCertificateLandingIcon: React.FC<SvgIconProps> = (
  props
) => (
  <SvgIcon {...props}>
    <svg
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1952 13.5713C18.2797 13.6556 18.3467 13.7557 18.3925 13.866C18.4382 13.9762 18.4618 14.0944 18.4618 14.2138C18.4618 14.3331 18.4382 14.4513 18.3925 14.5616C18.3467 14.6718 18.2797 14.7719 18.1952 14.8562L12.7502 20.3009C12.6659 20.3854 12.5658 20.4525 12.4555 20.4982C12.3452 20.544 12.2271 20.5675 12.1077 20.5675C11.9883 20.5675 11.8701 20.544 11.7599 20.4982C11.6496 20.4525 11.5495 20.3854 11.4652 20.3009L8.7427 17.5786C8.65832 17.4942 8.59139 17.394 8.54573 17.2838C8.50007 17.1736 8.47656 17.0554 8.47656 16.9361C8.47656 16.8168 8.50007 16.6986 8.54573 16.5884C8.59139 16.4782 8.65832 16.378 8.7427 16.2936C8.9131 16.1232 9.14422 16.0275 9.3852 16.0275C9.50453 16.0275 9.62268 16.051 9.73293 16.0967C9.84317 16.1423 9.94333 16.2093 10.0277 16.2936L12.1077 18.3753L16.9101 13.5713C16.9944 13.4868 17.0946 13.4197 17.2048 13.374C17.3151 13.3282 17.4333 13.3047 17.5527 13.3047C17.672 13.3047 17.7902 13.3282 17.9005 13.374C18.0107 13.4197 18.1109 13.4868 18.1952 13.5713Z"
        fill="#FC9C0F"
      />
      <path
        d="M5.74965 3.31445H3.93466C2.97193 3.31445 2.04864 3.69688 1.36788 4.3776C0.68713 5.05831 0.304688 5.98157 0.304688 6.94425V26.0007C0.304688 26.9634 0.68713 27.8866 1.36788 28.5673C2.04864 29.248 2.97193 29.6305 3.93466 29.6305H22.0845C23.0473 29.6305 23.9706 29.248 24.6513 28.5673C25.3321 27.8866 25.7145 26.9634 25.7145 26.0007V6.94425C25.7145 5.98157 25.3321 5.05831 24.6513 4.3776C23.9706 3.69688 23.0473 3.31445 22.0845 3.31445H20.2696V5.12935H22.0845C22.5659 5.12935 23.0276 5.32056 23.3679 5.66092C23.7083 6.00128 23.8995 6.46291 23.8995 6.94425V26.0007C23.8995 26.482 23.7083 26.9436 23.3679 27.284C23.0276 27.6244 22.5659 27.8156 22.0845 27.8156H3.93466C3.4533 27.8156 2.99165 27.6244 2.65127 27.284C2.3109 26.9436 2.11968 26.482 2.11968 26.0007V6.94425C2.11968 6.46291 2.3109 6.00128 2.65127 5.66092C2.99165 5.32056 3.4533 5.12935 3.93466 5.12935H5.74965V3.31445Z"
        fill="#FC9C0F"
      />
      <path
        d="M15.7378 2.41451C15.9784 2.41451 16.2093 2.51011 16.3795 2.68029C16.5496 2.85047 16.6453 3.08129 16.6453 3.32196V5.13685C16.6453 5.37752 16.5496 5.60834 16.3795 5.77852C16.2093 5.9487 15.9784 6.0443 15.7378 6.0443H10.2928C10.0521 6.0443 9.82129 5.9487 9.6511 5.77852C9.48091 5.60834 9.3853 5.37752 9.3853 5.13685V3.32196C9.3853 3.08129 9.48091 2.85047 9.6511 2.68029C9.82129 2.51011 10.0521 2.41451 10.2928 2.41451H15.7378ZM10.2928 0.599609C9.57075 0.599609 8.87827 0.886427 8.36771 1.39697C7.85714 1.90751 7.57031 2.59994 7.57031 3.32196V5.13685C7.57031 5.85886 7.85714 6.5513 8.36771 7.06184C8.87827 7.57238 9.57075 7.8592 10.2928 7.8592H15.7378C16.4598 7.8592 17.1523 7.57238 17.6628 7.06184C18.1734 6.5513 18.4602 5.85886 18.4602 5.13685V3.32196C18.4602 2.59994 18.1734 1.90751 17.6628 1.39697C17.1523 0.886427 16.4598 0.599609 15.7378 0.599609L10.2928 0.599609Z"
        fill="#FC9C0F"
      />
    </svg>
  </SvgIcon>
);

export const ENV = process.env.REACT_APP_ENV ?? "development";

export const DOMAIN_NAME =
  process.env.REACT_APP_DOC_APP_DOMAIN_NAME ?? "mekidoc.cl";

// Replace is needed on production (the api url is different and contains the domain)
export const BASE_URL =
  process.env.REACT_APP_API_URL?.replace(DOMAIN_NAME, "") ?? "";

export const PATIENT_APP_URL =
  process.env.REACT_APP_PATIENT_APP_URL ?? "https://paciente.mekidoc.cl";

export const MEKIDOC_EMAIL =
  process.env.REACT_APP_MEKIDOC_EMAIL ?? "contacto@mekidoc.cl";

export const MEKIDOC_PHONE =
  process.env.REACT_APP_MEKIDOC_PHONE ?? "+56 9 7948 4213";

export const MEKIDOC_GA4_TRACKING_ID =
  process.env.REACT_APP_MEKIDOC_GA4_TRACKING_ID ?? "";

export const MEKIDOC_GOOGLE_ADS_ID =
  process.env.REACT_APP_MEKIDOC_GOOGLE_ADS_ID ?? "";
export const DOCTOR_REGISTRATION_CONVERSION_ID =
  process.env.REACT_APP_DOCTOR_REGISTRATION_CONVERSION_ID ?? "";

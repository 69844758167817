import { SingleFormInput } from "../SingleFormInput";
import {
  UseFormRegister,
  FieldErrors,
  UseFormWatch,
  UseFormSetValue,
  UseFormTrigger,
  Path,
} from "react-hook-form";

interface EmailOwner {
  email: string;
  [key: string]: any;
}

interface EmailInputProps<T extends EmailOwner> {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  trigger: UseFormTrigger<T>;
}

export const EmailInput = <T extends EmailOwner>({
  register,
  errors,
  watch,
  setValue,
  trigger,
}: EmailInputProps<T>) => {
  return (
    <SingleFormInput
      title="Correo electrónico"
      parameterName="email"
      errorText="Debes escribir tu correo electrónico."
      register={register}
      errors={errors}
      inputType="email"
      onChange={() => {
        trigger("email" as Path<T>);
      }}
      onBlur={() => {
        const emailValue = watch("email" as Path<T>);
        if (emailValue) {
          setValue("email" as Path<T>, emailValue.toLowerCase());
        }
        trigger("email" as Path<T>);
      }}
      validate={(value) => {
        const emailRegex =
          /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
        if (emailRegex.exec(value)) {
          return true;
        } else {
          return "El correo electrónico está incompleto";
        }
      }}
    />
  );
};

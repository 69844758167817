interface GetFromLocalStorageProps {
  key: string;
  parseJson?: boolean;
}

export function getFromLocalStorage<T>({
  key,
  parseJson = false,
}: GetFromLocalStorageProps): T | string | null {
  const value = localStorage.getItem(key);
  if (!value) return null;
  return parseJson ? (JSON.parse(value) as T) : value;
}

interface SaveToLocalStorageProps<T> {
  key: string;
  value: T;
  stringifyJson?: boolean;
}

export function saveToLocalStorage<T>({
  key,
  value,
  stringifyJson = false,
}: SaveToLocalStorageProps<T>) {
  const data = stringifyJson
    ? JSON.stringify(value)
    : (value as unknown as string);
  localStorage.setItem(key, data);
}

export function removeFromLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function clearLocalStorage() {
  localStorage.clear();
}

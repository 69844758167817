import { chain } from "lodash";
import { communes } from "./communes";
import { regions } from "./regions";
import { provinces } from "./provinces";

export const regionsWithCommunes = regions.map((region) => ({
  ...region,
  communes: chain(provinces)
    .filter((province) => province.codigo_padre === region.codigo)
    .flatMap((province) =>
      communes.filter((commune) => commune.codigo_padre === province.codigo)
    )
    .sortBy("nombre")
    .value(),
}));

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Global } from "@emotion/react";
import { CircularProgress, styled } from "@mui/material";
import {
  Body,
  CodeInput,
  MekiDocSplashScreen,
  SecondaryActionButton,
  SnackBar,
} from "@components";
import { AuthFeedbackModal } from "../components";
import { useAuth } from "@contexts";
import { useVerificationCode } from "@hooks";
import { checkIfBiometricAuthIsAvailable, onContactUsClick } from "@utils";
import { AuthMethod, AuthStep } from "@interfaces";
import { VerifyCodeLocationState } from "../interfaces";
import { StyledSuccessIcon } from "@styles/styled";
import {
  ContentContainer,
  CodeContainer,
  CodeDescriptionText,
  IncorrectCodeText,
  ButtonContainer,
  MekiDocIconContainer,
  NewLoginContactUsParagraph,
  NewLoginContactUsText,
  StyledErrorIcon,
} from "../styled";
import MekiDocIcon from "../../../icons/MekiDocIcon";
import { authCodeErrorWsp } from "../../../constants";

const StyledActionButton = styled(SecondaryActionButton)({
  padding: "0px",
});

const dummyLocation = {
  doctor: { email: "", phone: "" },
  authMethod: AuthMethod.PHONE_NUMBER,
  accountCreated: false,
} as const;

export function VerifyAuthCode() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    doctor,
    doctor: { email, phone },
    authMethod: defaultAuthMethod,
    accountCreated,
  }: VerifyCodeLocationState = location.state || dummyLocation;
  const [authMethod, setAuthMethod] = useState(defaultAuthMethod);
  const [openSnackbar, setOpenSnackbar] = useState(accountCreated);
  const [started, setStarted] = useState(true);
  const [timer, setTimer] = useState(60000);
  const startRef = useRef<Date>();
  const intervalRef = useRef<NodeJS.Timer>();

  const {
    authChallengeFeedback: { message: authFeedback, authStep },
    handleAuthChallengeFeedback,
  } = useAuth();
  const feedbackIcon = (authStep: AuthStep) => {
    switch (authStep) {
      case AuthStep.SUCCESS:
        return <StyledSuccessIcon />;
      case AuthStep.ERROR:
        return <StyledErrorIcon />;
      default:
        return <CircularProgress size={24} color="primary" />;
    }
  };
  const { code, setCode, verifyCode, resendCode } = useVerificationCode();
  const [codeError, setCodeError] = useState<string>("");
  const maxRetriesError =
    "Máximo de intentos excedido, solicita un nuevo código";
  const unknownError = "Error verificando código, intentalo nuevamente";

  const onResendCodeClick = useCallback(async () => {
    setCode("");
    setCodeError("");
    await resendCode(email, phone, authMethod);
    setStarted(true);
    setTimer(60000);
  }, [setCode, resendCode, email, phone, authMethod]);

  const handleCodeChange = useCallback(
    async (value: string) => {
      setCode(value);
      if (value.length === 6) {
        try {
          handleAuthChallengeFeedback({
            authMethod,
            authStep: AuthStep.VALIDATING,
          });
          const success = await verifyCode(value);
          if (success) {
            await handleAuthChallengeFeedback({
              authMethod,
              authStep: AuthStep.SUCCESS,
            });
            const isBiometricAuthAvailable =
              await checkIfBiometricAuthIsAvailable();
            const showBiometricRegistration =
              doctor.showBiometricRegistration !== false &&
              isBiometricAuthAvailable;
            const navigateTo = showBiometricRegistration
              ? "/app/registro-biometrico"
              : accountCreated
              ? "/app/verificacion-identidad"
              : "/app/solicitudes";
            return navigate(navigateTo, { state: { accountCreated } });
          }
          if (!codeError) {
            handleAuthChallengeFeedback({
              resetToInitialState: true,
            });
            setCodeError("Código incorrecto");
          }
        } catch (error) {
          handleAuthChallengeFeedback({
            resetToInitialState: true,
          });
          const parsedError = String(error);
          if (parsedError.includes("NotAuthorizedException")) {
            setCodeError(maxRetriesError);
          } else {
            setCodeError(unknownError);
          }
        }
      } else {
        if (value.length > 0) {
          setCodeError("");
        }
      }
    },
    [
      doctor,
      authMethod,
      accountCreated,
      setCode,
      codeError,
      setCodeError,
      verifyCode,
      handleAuthChallengeFeedback,
      navigate,
    ]
  );

  const handleAuthMethodChange = useCallback(
    async (prevAuthMethod: AuthMethod) => {
      const newAuthMethod =
        prevAuthMethod === AuthMethod.PHONE_NUMBER
          ? AuthMethod.EMAIL
          : AuthMethod.PHONE_NUMBER;
      setAuthMethod(newAuthMethod);
      await resendCode(email, phone, newAuthMethod);
      setTimer(60000);
    },
    [resendCode, email, phone]
  );

  const disabledButton = useMemo(
    () => timer > 0 && codeError !== maxRetriesError,
    [timer, codeError]
  );

  useEffect(() => {
    if (started) {
      startRef.current = new Date();
      intervalRef.current = setTimeout(() => {
        if (timer < 0 || !startRef.current) {
          setStarted(false);
          clearTimeout(intervalRef.current);
        } else {
          setTimer(timer - (+new Date() - +startRef.current));
        }
      }, 1000);
    }
    return () => {
      clearTimeout(intervalRef.current);
    };
  }, [started, timer, startRef]);

  useEffect(() => {
    if (!doctor) {
      navigate("/app");
    }
  }, [navigate, doctor]);

  if (!doctor) {
    return <MekiDocSplashScreen />;
  }

  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <ContentContainer>
          <MekiDocIconContainer>
            <MekiDocIcon size={"medium"} />
          </MekiDocIconContainer>

          <CodeDescriptionText>
            Ingresa el código que te enviamos{" "}
            {authMethod === AuthMethod.PHONE_NUMBER
              ? `por SMS al ${phone}`
              : `a ${email}`}
          </CodeDescriptionText>

          <CodeContainer>
            <CodeInput
              code={code}
              handleCodeChange={handleCodeChange}
              numInputs={6}
              isValid={!codeError}
              disabled={code.length === 6 && !codeError}
              type={"number"}
            />

            {codeError && <IncorrectCodeText>{codeError}</IncorrectCodeText>}

            <ButtonContainer>
              <StyledActionButton
                onClick={onResendCodeClick}
                disabled={disabledButton}
              >
                Volver a enviar código{" "}
                {disabledButton
                  ? `(${new Date(timer)
                      .toISOString()
                      .split("T")[1]
                      .split(".")[0]
                      .slice(4)})`
                  : ""}
              </StyledActionButton>

              {authMethod === defaultAuthMethod ? (
                <StyledActionButton
                  onClick={() => handleAuthMethodChange(authMethod)}
                >
                  Probar de otra manera
                </StyledActionButton>
              ) : (
                <NewLoginContactUsParagraph>
                  Si tienes problemas{" "}
                  <NewLoginContactUsText
                    onClick={() => onContactUsClick(authCodeErrorWsp)}
                  >
                    contáctanos
                  </NewLoginContactUsText>
                </NewLoginContactUsParagraph>
              )}
            </ButtonContainer>
          </CodeContainer>

          {authFeedback && (
            <AuthFeedbackModal
              isOpen={true}
              authFeedback={authFeedback}
              authStep={authStep}
              feedbackIcon={feedbackIcon}
            />
          )}

          <SnackBar
            snackBarState={{
              open: openSnackbar,
              type: "success",
            }}
            onClose={() => setOpenSnackbar(false)}
            alerts={{
              success: "Cuenta creada con éxito",
            }}
          />
        </ContentContainer>
      </Body>
    </>
  );
}

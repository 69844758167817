import {
  ItemType,
  MedicineSuggestion,
  ProcedureSuggestion,
} from "@interfaces/Search";
import { static_assets_bucket_prefix } from "@config";

export const termsAndConditionsLink = `${static_assets_bucket_prefix}/assets/files/terms_and_conditions.pdf`;
export const privacyPolicyLink = `${static_assets_bucket_prefix}/assets/files/privacy_policies.pdf`;
export const ispGuidelinesLink =
  "https://www.ispch.cl/sites/default/files/InstructivoVRE02-03112020A.pdf";

export const needHelpMessage = "¡Hola! Les escribo porque necesito ayuda con ";
export const unsuscribeMessage =
  "Hola, me gustaría darme de baja de MekiDoc. ¿Podrían ayudarme con este proceso?";
export const anotherFAQMessage = "Hola, tengo una pregunta sobre ";

export const failedLoginUI =
  "Ha ocurrido un error al intentar iniciar sesión. Por favor, intentalo nuevamente.";
export const failedLoginWsp =
  "Hola, estoy teniendo problemas al iniciar sesión en MekiDoc. ¿Podrías darme más detalles de como solucionarlo?";

export const failedRegistrationWsp =
  "Hola, estoy teniendo problemas al registrarme en MekiDoc. Me dice que ya existe un doctor con mi correo o teléfono. ¿Podrías darme más detalles de como solucionarlo?";

export const authCodeErrorWsp =
  "Hola, estoy teniendo problemas con el código de verificación al iniciar sesión en MekiDoc. ¿Podrías darme más detalles de como solucionarlo?";

export const prescriptionCodeErrorWsp = (prescriptionId: string) =>
  `Hola, estoy teniendo problemas al disepensar/retener la receta con el código ${prescriptionId}. ¿Podrías ayudarme con esto?`;

export const mostSearchedMedicinesQueries: MedicineSuggestion[] = [
  {
    text: "Paracetamol · 500 mg · Comprimidos",
    itemType: ItemType.GENERIC,
    activePrincipleCount: 1,
  },
  {
    text: "Losartan · 50 mg · Comprimidos",
    itemType: ItemType.GENERIC,
    activePrincipleCount: 1,
  },
  {
    text: "Melatonina · 3 mg · Comprimidos",
    itemType: ItemType.GENERIC,
    activePrincipleCount: 1,
  },
  {
    text: "Pregabalina · 75 mg · Comprimidos",
    itemType: ItemType.GENERIC,
    activePrincipleCount: 1,
  },
  {
    text: "Escitalopram · 10 mg · Comprimidos",
    itemType: ItemType.GENERIC,
    activePrincipleCount: 1,
  },
  {
    text: "Quetiapina · 25 mg · Comprimidos",
    itemType: ItemType.GENERIC,
    activePrincipleCount: 1,
  },
  {
    text: "Atorvastatina · 20 mg · Comprimidos",
    itemType: ItemType.GENERIC,
    activePrincipleCount: 1,
  },
  {
    text: "Esomeprazol · 40 mg · Comprimidos",
    itemType: ItemType.GENERIC,
    activePrincipleCount: 1,
  },
  {
    text: "Sertralina · 50 mg · Comprimidos",
    itemType: ItemType.GENERIC,
    activePrincipleCount: 1,
  },
  {
    text: "Levocetirizina · 5 mg · Comprimidos",
    itemType: ItemType.GENERIC,
    activePrincipleCount: 1,
  },
];

export const mostSearchedProceduresQueries: ProcedureSuggestion[] = [
  {
    text: "Hemograma - 0301045",
    description:
      "Hemograma (incluye recuentos de leucocitos, eritrocitos, plaquetas, hemoglobina, hematocrito, fórmula leucocitaria, características de los elementos figurados y velocidad de eritrosedimentación)",
    code: "0301045",
    group: "03",
    subgroup: "01",
  },
  {
    text: "Perfil Bioquímico - 0302075",
    description:
      "Perfil Bioquímico (determinación automatizada de 12 parámetros)",
    code: "0302075",
    group: "03",
    subgroup: "02",
  },
  {
    text: "Perfil lipídico - 0302034",
    description:
      "Perfil lipídico (incluye mediciones de colesterol total, HDL-colesterol y triglicéridos con estimaciones por fórmula de LDL-colesterol, VLDL-colesterol y colesterol no-HDL)",
    code: "0302034",
    group: "03",
    subgroup: "02",
  },
  {
    text: "Orina completa - 0309022",
    description: "Orina completa, (incluye cód. 03-09-023 y 03-09-024)",
    code: "0309022",
    group: "03",
    subgroup: "09",
  },
  {
    text: "Tiroestimulante (TSH) - 0303024",
    description: "Tiroestimulante (TSH), hormona (Adulto, niño o R.N.)",
    code: "0303024",
    group: "03",
    subgroup: "03",
  },
  {
    text: "Tiroxina libre (T4L) - 0303026",
    description: "Tiroxina libre (T4L)",
    code: "0303026",
    group: "03",
    subgroup: "03",
  },
  {
    text: "Perfil Hepático - 0302076",
    description:
      "Perfil Hepático (incluye tiempo de protrombina, bilirrubina total y conjugada, fosfatasas alcalinas totales, GGT, transaminasas GOT/AST y GPT/ALT)",
    code: "0302076",
    group: "03",
    subgroup: "02",
  },
  {
    text: "Ecografía abdominal - 0404003",
    description:
      "Ecografía abdominal (incluye hígado, vía biliar, vesícula, páncreas, riñones, bazo, retroperitoneo y grandes vasos)",
    code: "0404003",
    group: "04",
    subgroup: "04",
  },
  {
    text: "Vitamina B12 - 0302077",
    description: "Vitamina B12 por inmunoensayo",
    code: "0302077",
    group: "03",
    subgroup: "02",
  },
  {
    text: "Vitamina D - 0302078",
    description:
      "25 OH Vitamina D Total por inmunoensayo (Quimioluminiscencia, Enzimoinmunoensayo, Radio inmunoensayo y otros)",
    code: "0302078",
    group: "03",
    subgroup: "02",
  },
];

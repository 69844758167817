import { AxiosError } from "axios";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { api, queryClient } from "@contexts";
import {
  CompoundedPrescriptionDetails,
  DocumentPDFDataResponse,
  MekidocEsignError,
  PinState,
  Request,
  RequestDetail,
  RequestType,
  pinErrorMapper,
  requestTypeNavigateParser,
} from "@interfaces";
import { removeFromLocalStorage } from "@utils";
import { flow, groupBy, filter } from "lodash/fp";

interface CreatePrescription {
  request: Request;
  requestDetailData: RequestDetail[];
  pin: string;
}

function createPrescription({
  request,
  requestDetailData,
  pin,
}: CreatePrescription) {
  const prescriptionsAttributes = {
    doctorCode: request.doctorCode,
    requestId: request.id,
  };
  // TODO: check typesafety of flow
  const groupedPrescriptions = flow(
    filter((detail: RequestDetail) => detail.prescriptionType !== "CHECK"),
    groupBy((detail) => {
      // Group "SIMPLE", "NOT_REQUIRED", null and undefined in the same group
      const simpleGroup = ["SIMPLE", "NOT_REQUIRED", null, undefined];
      if (simpleGroup.includes(detail.prescriptionType)) return "SIMPLE";
      else return detail.prescriptionType;
    })
  )(requestDetailData);
  const simplePrescriptions = groupedPrescriptions["SIMPLE"] ?? [];
  const heldPrescriptions = groupedPrescriptions["HELD"] ?? [];
  const managementSummary = requestDetailData
    .map((medicine: RequestDetail) => {
      return "name" in medicine && medicine.name
        ? medicine.name
        : medicine.activePrinciple;
    })
    .join(", ");
  return api<DocumentPDFDataResponse>({
    method: "post",
    url: `/requests/${request.id}/prescriptions`,
    data: {
      prescriptions: [
        ...(simplePrescriptions.length > 0
          ? [
              {
                ...prescriptionsAttributes,
                type: "SIMPLE",
                details: simplePrescriptions,
              },
            ]
          : []),
        ...heldPrescriptions.map((heldPrescription) => ({
          ...prescriptionsAttributes,
          type: "HELD",
          details: [heldPrescription],
        })),
      ],
      managementSummary,
      pin,
    },
  });
}

interface CreateCompoundedPrescription {
  requestId: string;
  compoundedPrescriptionDetails: CompoundedPrescriptionDetails;
  pin: string;
}

function createCompoundedPrescription({
  requestId,
  compoundedPrescriptionDetails,
  pin,
}: CreateCompoundedPrescription) {
  const { compoundedIngredients } = compoundedPrescriptionDetails;
  return api<DocumentPDFDataResponse>({
    method: "post",
    url: `/requests/${requestId}/compounded-prescriptions`,
    data: {
      compoundedPrescriptionDetails,
      managementSummary: compoundedIngredients,
      pin,
    },
  });
}

interface SignDocumentOnSuccessProps {
  requestType: RequestType;
  requestId: string;
  navigate: NavigateFunction;
}

function signDocumentOnSuccess({
  requestType,
  requestId,
  navigate,
}: SignDocumentOnSuccessProps) {
  return (data: DocumentPDFDataResponse) => {
    removeFromLocalStorage("requestType");
    removeFromLocalStorage("compoundedPrescriptionDetails");
    queryClient.invalidateQueries({ queryKey: ["requests"] });
    queryClient.invalidateQueries({ queryKey: ["request", requestId] });
    navigate(
      `/app/solicitudes/${requestId}/${requestTypeNavigateParser[requestType]}/exito`,
      {
        state: { documentPDFData: data.requestPDFData },
      }
    );
  };
}

interface SignDocumentOnErrorProps {
  error: AxiosError<{ message: string }>;
  setPinState: React.Dispatch<React.SetStateAction<PinState>>;
  setShowBlockedPinModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEsignErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}
function signDocumentOnError({
  error,
  setPinState,
  setShowBlockedPinModal,
  setEsignErrorMessage,
}: SignDocumentOnErrorProps) {
  const { mekidocError, esignError } = pinErrorMapper(
    error.response?.data?.message ?? ""
  );
  setPinState({
    pin: "",
    error: true,
    errorMessage: mekidocError,
  });
  if (mekidocError === MekidocEsignError.PIN_BLOCKED) {
    setShowBlockedPinModal(true);
    setEsignErrorMessage(esignError);
  }
}

interface SignDocumentProps {
  setPinState: React.Dispatch<React.SetStateAction<PinState>>;
  setShowBlockedPinModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEsignErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const useSignPrescription = ({
  setPinState,
  setShowBlockedPinModal,
  setEsignErrorMessage,
}: SignDocumentProps) => {
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  return useMutation({
    mutationFn: createPrescription,
    onSuccess: signDocumentOnSuccess({
      requestType: RequestType.PRESCRIPTION,
      requestId,
      navigate,
    }),
    onError: (error: AxiosError<{ message: string }>) =>
      signDocumentOnError({
        error,
        setPinState,
        setShowBlockedPinModal,
        setEsignErrorMessage,
      }),
  });
};

export const useSignCompoundedPrescription = ({
  setPinState,
  setShowBlockedPinModal,
  setEsignErrorMessage,
}: SignDocumentProps) => {
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  return useMutation({
    mutationFn: createCompoundedPrescription,
    onSuccess: signDocumentOnSuccess({
      requestType: RequestType.COMPOUNDED_PRESCRIPTION,
      requestId,
      navigate,
    }),
    onError: (error: AxiosError<{ message: string }>) =>
      signDocumentOnError({
        error,
        setPinState,
        setShowBlockedPinModal,
        setEsignErrorMessage,
      }),
  });
};

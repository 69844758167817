import { useMutation } from "@tanstack/react-query";
import { api } from "../../../context";
import { FullScreenLoader } from "../../../components";
import { Step } from "../../../interfaces";
import { useEffect } from "react";

interface VerifyLoginData {
  payload: { message: string };
}

interface Props {
  setStep: React.Dispatch<React.SetStateAction<Step>>;
}

export const VerifyClaveUnicaLogin: React.FC<Props> = ({ setStep }) => {
  const { mutate } = useMutation({
    mutationFn: () =>
      api<VerifyLoginData>({
        method: "post",
        url: "/esign/verify-clave-unica-login",
      }),
    onSuccess: () => {
      setStep("ciDocForm");
    },
    onError: () => {
      // TODO: show alert of error and go back after a couple seconds (for this we need the id)
    },
  });
  useEffect(() => {
    // Notice that this will run twice in StricMode :(
    mutate();
  }, [mutate]);

  return <FullScreenLoader title={"Verificando identidad"} />;
};

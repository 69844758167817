import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAddPrescriptionDetail } from "./useAddPrescriptionDetail";
import { createRequestFn } from "./useCreateRequest";
import {
  DocumentsPayload,
  NewRequestPayload,
  RequestDetail,
  RequestStatus,
  RequestType,
  requestTypeNavigateParser,
} from "@interfaces";
import { queryClient } from "@contexts";
import { useMutation } from "@tanstack/react-query";

function repeatRequestNavigationStateResolver(
  requestSummary: DocumentsPayload
) {
  const { documentType, documents } = requestSummary;
  switch (documentType) {
    case RequestType.COMPOUNDED_PRESCRIPTION:
      const {
        details: {
          compoundedIngredients,
          posologyQuantity,
          posologyFormat,
          frequencyQuantity,
          frequencyPeriod,
          durationQuantity,
          durationPeriod,
          consumptionType,
          indications,
        },
      } = documents[0];
      return {
        compoundedIngredients,
        posologyQuantity,
        posologyFormat,
        frequencyQuantity,
        frequencyPeriod,
        durationQuantity,
        durationPeriod,
        consumptionType,
        indications,
      };
    case RequestType.MEDICAL_CERTIFICATE:
      return { repeatedDocumentDetails: documents[0].documentDetails };
    case RequestType.MEDICAL_ORDER:
      return {
        repeatedDiagnosticHypothesis: documents[0].diagnosticHypothesis,
        repeatedDocumentDetails: documents[0].documentDetails,
        repeatedIndications: documents[0].indications,
      };
    default:
      return {};
  }
}

export const useRepeatRequest = () => {
  const navigate = useNavigate();
  const { mutate: createRequest, isPending: isCreatingRequest } = useMutation({
    mutationFn: createRequestFn,
  });
  const { mutate: addDetail, isPending: isAddingDetail } =
    useAddPrescriptionDetail();

  const handleRepeatRequest = useCallback(
    async (requestSummary: DocumentsPayload) => {
      if (!requestSummary) return;
      const { doctorCode, patient, documentType, documents } = requestSummary;
      const newRequestPayload: NewRequestPayload = {
        doctorCode,
        patient,
        request: {
          documentType,
          doctorCode,
          status: RequestStatus.DRAFT,
          comment: "",
        },
      };
      // Repeat Prescription
      if (documentType === RequestType.PRESCRIPTION) {
        createRequest(newRequestPayload, {
          onSuccess: (requestResponse) => {
            const { request } = requestResponse;
            const { id: requestId } = request;
            documents.forEach(({ details }) => {
              details.forEach((detail) => {
                addDetail({
                  requestId,
                  prescriptionDetail: detail,
                });
                request.details = [
                  ...(request.details ?? []),
                  detail as unknown as RequestDetail, // TODO: Doing the Prescription Detail Refactor this will be removed
                ];
              });
            });
            queryClient.setQueryData(["request", requestId], request);
            navigate(
              `/app/solicitudes/${requestId}/${requestTypeNavigateParser[documentType]}/crear`
            );
          },
        });
      } else {
        // Repeat Compounded Prescriptions, Medical Order and Medical Certificate
        createRequest(newRequestPayload, {
          onSuccess: (requestResponse) => {
            const { request } = requestResponse;
            const { id: requestId } = request;
            queryClient.setQueryData(["request", requestId], request);
            const navigationState =
              repeatRequestNavigationStateResolver(requestSummary);
            navigate(
              `/app/solicitudes/${requestId}/${requestTypeNavigateParser[documentType]}/crear`,
              {
                state: navigationState,
              }
            );
          },
        });
      }
    },
    [createRequest, addDetail, navigate]
  );
  return { handleRepeatRequest, isCreatingRequest, isAddingDetail };
};

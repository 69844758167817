import { FormControl, styled } from "@mui/material";
import { Control, Controller, FieldErrors, Path } from "react-hook-form";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { esES } from "@mui/x-date-pickers/locales";
import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";
import { theme } from "../../config";
import {
  ErrorMessage,
  InputLabel,
  RelativeContainer,
} from "../../styles/styled";

registerLocale("es", es);

const StyledDateField = styled(DateField, {
  shouldForwardProp: (prop) => prop !== "error",
})((props: { error: string | undefined }) => ({
  fontFamily: "Poppins",
  fontSize: "16px",
  background: theme.pallete.background.white,
  ...(props.error && {
    border: "1px solid red",
    borderRadius: "4px",
  }),
  ".Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23) !important",
  },
  ".Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: `${theme.pallete.text.primary} !important`,
  },
}));

interface BirthdateAttribute {
  birthdate: string;
  [key: string]: any;
}

interface BirthdateInputProps<T extends BirthdateAttribute> {
  control: Control<T, any>;
  errorManagement: {
    errors: FieldErrors<T>;
  };
}

export const BirthdateInput = <T extends BirthdateAttribute>({
  control,
  errorManagement: { errors },
}: BirthdateInputProps<T>) => {
  const inputName = "birthdate" as Path<T>;
  const errorMessage = errors.birthdate?.message?.toString();
  return (
    <FormControl fullWidth>
      <InputLabel>Fecha de nacimiento</InputLabel>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={es}
        localeText={
          esES.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <Controller
          name={inputName}
          control={control}
          defaultValue={undefined}
          rules={{
            required: "Debes seleccionar una fecha.",
            validate: (value) => {
              const date = new Date(value);
              if (date.toString() === "Invalid Date") {
                return "Fecha incompleta o inválida";
              }
              if (date.getFullYear() < 1900) {
                return "La fecha es demasiado antigua";
              }
              if (date > new Date()) {
                return "La fecha ingresada aún no ha ocurrido";
              }
              return true;
            },
          }}
          render={({ field: { onChange, value } }) => (
            <StyledDateField
              value={value ? new Date(value) : null}
              format="dd/MM/yyyy"
              clearable={true}
              minDate={new Date("1900-01-01")}
              maxDate={new Date()}
              onChange={(date) => onChange(date)}
              error={errorMessage}
              slotProps={{
                // Disable focused blue border on error
                ...(errorMessage && {
                  textField: {
                    focused: false,
                  },
                }),
              }}
            />
          )}
        />
      </LocalizationProvider>
      {errorMessage && (
        <RelativeContainer>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </RelativeContainer>
      )}
    </FormControl>
  );
};

import styled from "@emotion/styled";
import { RequestStatus } from "../../../interfaces/";

interface ContainerProps {
  color: string;
  background: string;
  fontSize: string;
}

const Container = styled.div<ContainerProps>`
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  font-size: ${(props) => props.fontSize};
  font-weight: 500;
  line-height: 15px;
  border-radius: 24px;
  padding: 2px 8px;
`;

interface StatusTagProps {
  status: RequestStatus;
  fontSize?: string;
}

const STATUS = {
  DRAFT: "Borrador",
  PENDING: "Pendiente",
  ACCEPTED: "Emitida",
  REJECTED: "Rechazada",
} as Record<RequestStatus, string>;

const STATUS_COLOR: Record<RequestStatus, string> = {
  DRAFT: "#F29B01",
  PENDING: "#F29B01",
  ACCEPTED: "#2E7D32",
  REJECTED: "#D32F2F",
};

const STATUS_BACKGROUND: Record<RequestStatus, string> = {
  DRAFT: "#FFF0D6",
  PENDING: "#FFF0D6",
  ACCEPTED: "#EAF2EA",
  REJECTED: "#fbeaea",
};

export function StatusTag({ status, fontSize }: StatusTagProps) {
  return (
    <Container
      color={STATUS_COLOR[status]}
      background={STATUS_BACKGROUND[status]}
      fontSize={fontSize || "12px"}
    >
      {STATUS[status]}
    </Container>
  );
}

import { useQuery } from "@tanstack/react-query";
import { api } from "../context";
import { Request } from "../interfaces";
import { millisecondsInSecond } from "date-fns";

export const useRequests = () => {
  return useQuery({
    queryKey: ["requests"],
    queryFn: () =>
      api<Request[]>({
        url: `/requests`,
      }),
    refetchInterval: millisecondsInSecond * 20,
  });
};

import { useNavigate } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import { BasicButtonOverrides } from "../styles";
import { theme as oldTheme } from "@config/theme";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  justifyContent: "center",
  flex: 0,
  marginTop: "48px",
  width: "100%",
  alignItems: "center",
  gap: "64px",
  [theme.breakpoints.up(750)]: {
    marginTop: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    gap: "16px",
  },
}));

const InfoSection = styled(Box)(({ theme }) => ({
  flex: 1,
  maxWidth: "462px",
  [theme.breakpoints.up("md")]: {
    maxWidth: "550px",
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "650px",
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: "750px",
  },
}));

export const LoginButton = styled(BasicButtonOverrides)({
  width: "200px",
  color: oldTheme.pallete.background.white,
  backgroundColor: oldTheme.pallete.text.primary,
  borderRadius: "56px",
  boxShadow:
    "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
  "&:hover": {
    cursor: "pointer",
    color: oldTheme.pallete.background.white,
    backgroundColor: oldTheme.pallete.documentTypes.MEDICAL_ORDER,
  },
});

const MainTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: oldTheme.pallete.text.primaryDark,
  fontSize: "2rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.25rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "3rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "3.75rem",
  },
}));

const MainTitleHighlight = styled("span")({
  fontWeight: 600,
  color: oldTheme.pallete.text.primary,
});

const Subtitle = styled("h5")(({ theme }) => ({
  fontWeight: 400,
  color: oldTheme.pallete.text.primaryDark,
  fontSize: "1rem",
  width: "100%",
  [theme.breakpoints.up(750)]: {
    fontWeight: 500,
    width: "80%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.75rem",
  },
}));

const Img = styled("img")(({ theme }) => ({
  position: "relative",
  width: "284px",
  height: "270px",
  [theme.breakpoints.up(520)]: {
    width: "357px",
    height: "340px",
  },
  [theme.breakpoints.up(750)]: {
    width: "315px",
    height: "300px",
    position: "absolute",
    right: 0,
  },
  [theme.breakpoints.up("md")]: {
    width: "357px",
    height: "340px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "400px",
    height: "380px",
  },
  [theme.breakpoints.up("xl")]: {
    width: "465px",
    height: "443px",
  },
}));

export const Hero: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <InfoSection>
        <MainTitle>
          Crea y comparte{" "}
          <MainTitleHighlight>tus documentos</MainTitleHighlight> médicos
        </MainTitle>
        <Subtitle>
          Emite recetas, certificados y órdenes médicas desde tu celular o
          computador. Totalmente GRATIS.
        </Subtitle>
        <LoginButton onClick={() => navigate("/app")}>
          Comienza ahora
        </LoginButton>
      </InfoSection>
      <Img src="/landing/docs_landing_img.png" />
    </Container>
  );
};

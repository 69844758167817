import { useCallback, useState } from "react";
import { AlertColor, Box, Link, Typography, styled } from "@mui/material";
import { theme as oldTheme } from "../../../config";
import { useDoctorContext } from "../../../context";
import { Modal, SnackBar } from "../../../components";
import { shareDoctorLink } from "../../../utils";

const Container = styled(Box)({
  paddingTop: "24px",
});

const Text = styled(Typography)({
  fontSize: "14px",
  color: oldTheme.pallete.text.black,
});

const StyledLink = styled(Link)({
  fontWeight: 600,
  color: oldTheme.pallete.text.link,
  cursor: "pointer",
  textDecoration: "underline",
});

export function ShareLink() {
  const doctor = useDoctorContext();
  const [showCopiedLinkModal, setShowCopiedLinkModal] = useState(false);
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    type: "success" as AlertColor,
  });

  const onShare = useCallback(async () => {
    await shareDoctorLink({
      doctorCode: doctor.code,
      setShowCopiedLinkModal,
      setSnackBarState,
    });
  }, [doctor.code]);

  return (
    <>
      <Container>
        <Text>
          Si quieres que el paciente complete sus datos{" "}
          <StyledLink onClick={onShare}>haz click aquí</StyledLink>
        </Text>
      </Container>
      <Modal
        title="Link copiado"
        content="Pégalo en WhatsApp o en un correo para enviarlo al paciente."
        isOpen={showCopiedLinkModal}
        onClose={() => setShowCopiedLinkModal(false)}
        buttonProps={{
          buttonText: "Aceptar",
          onClick: () => {
            setShowCopiedLinkModal(false);
          },
        }}
      />
      <SnackBar
        snackBarState={snackBarState}
        onClose={() => setSnackBarState({ ...snackBarState, open: false })}
        alerts={{
          success: "Link copiado, compártelo con tu paciente",
          error: "Ocurrió un error al copiar el link",
        }}
      />
    </>
  );
}

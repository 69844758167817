import { Gender } from "../interfaces";

export const getDoctorTitle = (gender: Gender | undefined) => {
  if (gender === Gender.MALE) {
    return { title: "Dr.", preposition: "al" };
  } else {
    return { title: "Dra.", preposition: "a la" };
  }
};

export function separateDoctorSpecialty(doctorRecords: string) {
  const titlesAndSpecialties = doctorRecords.split(",");
  const mekidocAccessTitle = titlesAndSpecialties.find(
    (record) =>
      record.toLowerCase().includes("cirujan") ||
      record.toLowerCase().includes("título profesional") // TODO: Remove this when the app is ready
  );
  const specialties = titlesAndSpecialties
    .filter(
      (record) =>
        record !== mekidocAccessTitle &&
        !record.toLowerCase().includes("químic")
    )
    .join(", ");
  return { mekidocAccessTitle, specialties };
}

import { useState } from "react";
import { IconButton, styled } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { LogoutModal } from "./";
import { theme as OldTheme } from "../config";

const StyledIconButton = styled(IconButton)({
  color: OldTheme.pallete.text.black,
});

export function LogOutButton() {
  const [showLogOutModal, setShowLogOutModal] = useState(false);
  return (
    <>
      <StyledIconButton onClick={() => setShowLogOutModal(true)}>
        <LogoutIcon fontSize="small" />
      </StyledIconButton>
      {showLogOutModal && (
        <LogoutModal
          showLogOutModal={showLogOutModal}
          setShowLogOutModal={setShowLogOutModal}
        />
      )}
    </>
  );
}

import { useNavigate } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import { BottomActionButton, Header } from "../../components";
import { theme as oldTheme } from "../../config";
import { Global } from "@emotion/react";

export const DescriptionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  gap: 16,
});

export const DescriptionText = styled(Typography)({
  fontSize: 20,
  fontWeight: 500,
  textAalign: "center",
  color: oldTheme.pallete.text.black,
});

export function NotFound() {
  const navigate = useNavigate();

  return (
    <>
      <Header title={"MekiDoc"} titleColor="black" />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <DescriptionContainer>
        <DescriptionText>Esta página no existe</DescriptionText>
        <BottomActionButton onClick={() => navigate("/app/solicitudes")}>
          Volver al inicio
        </BottomActionButton>
      </DescriptionContainer>
    </>
  );
}

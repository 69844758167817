import styled from "@emotion/styled";
import { Autocomplete as AutocompleteMui, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import { theme } from "../../config/theme";

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

export const TitleHeader = styled.h2`
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: ${theme.pallete.text.black};
`;

export const TitleSubheader = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${theme.pallete.text.black};
`;

export const PatientTitleContainer = styled(TitleContainer)``;

export const ContactContainer = styled(TitleContainer)``;

interface InputLabelProps {
  disabled?: boolean;
}

export const InputLabel = styled.label<InputLabelProps>`
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  padding-bottom: 4px;
  color: ${(props) =>
    props.disabled ? theme.pallete.text.disabled : theme.pallete.text.black};
`;

export const SingleInput = styled(TextField)`
  background-color: ${theme.pallete.background.white};
  font-size: 16px;
  font-weight: 400;
  input {
    height: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    padding: 12px;
    font-family: Poppins;
  }
`;

export const InputExample = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: ${theme.pallete.text.gray};
  padding-top: 4px;
`;

export const ErrorMessage = styled.span`
  color: ${theme.pallete.errors.primary};
  font-size: 12px;
`;

export const Autocomplete = styled(AutocompleteMui)`
  .MuiOutlinedInput-root {
    height: 56px;
    padding: 0;
    padding-left: 4px;
  }
  .MuiOutlinedInput-input {
    font-size: 16px;
  }
`;

export const StyledAutocompleteTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 56px;
    padding: 0;
    padding-left: 4px;
    font-family: Poppins;
  }
  .MuiOutlinedInput-input {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    padding: 12px;
  }
  .MuiOutlinedInput-input:disabled {
    color: ${theme.pallete.text.disabled};
    -webkit-text-fill-color: ${theme.pallete.text.disabled};
  }
`;

export const SelectedFilesContainer = styled.label`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
`;

export const SelectedFile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  width: 100%;
  padding: 8px 0px;
`;

export const SelectedFileTitle = styled.p`
  font-size: 14px;
  padding-left: 8px;
  font-weight: 400;
  line-height: 24px;
  color: ${theme.pallete.text.black};
`;

export const SelectedFileSummary = styled(SelectedFile)`
  justify-content: flex-start;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 18px;
`;

export const Datepicker = styled(DatePicker)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${theme.pallete.text.gray};
  border-radius: 4px;
`;

export const WhiteTextField = styled(TextField)`
  background: white;
`;

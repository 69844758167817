import { useState, useCallback } from "react";
import { Auth } from "aws-amplify";
import { AuthMethod, AuthStep, ChallengeType } from "@interfaces";
import { useAuth } from "@contexts";
import { getDetectedDeviceData } from "@utils";

interface VerificationCodeHook {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  verifyCode: (code: string) => Promise<boolean>;
  resendCode: (
    email: string,
    phone: string,
    authMethod: AuthMethod
  ) => Promise<void>;
}

export function useVerificationCode(): VerificationCodeHook {
  const [code, setCode] = useState<string>("");
  const { user, signIn, startAuthChallenge, handleAuthChallengeFeedback } =
    useAuth();

  const verifyCode = useCallback(
    async (verificationCode: string): Promise<boolean> => {
      const success = await signIn(verificationCode);
      return success;
    },
    [signIn]
  );

  const resendCode = useCallback(
    async (email: string, phone: string, authMethod: AuthMethod) => {
      try {
        handleAuthChallengeFeedback({ authMethod, authStep: AuthStep.PENDING });
        await Auth.sendCustomChallengeAnswer(user, "resend code", {
          authMethod,
          challenge: ChallengeType.RESENDING_AUTH_CODE,
          ...getDetectedDeviceData(),
        });
        console.log("Resent code");
        handleAuthChallengeFeedback({
          resetToInitialState: true,
          changeAfterDelay: true,
        });
      } catch (error) {
        console.log({
          msg: "Error resending code",
          error,
        });
        const parsedError = String(error);
        if (parsedError.includes("NotAuthorizedException")) {
          startAuthChallenge({
            email,
            authMethod,
          });
        } else {
          handleAuthChallengeFeedback({
            authMethod,
            authStep: AuthStep.ERROR,
            changeAfterDelay: true,
          });
        }
      }
    },
    [user, startAuthChallenge, handleAuthChallengeFeedback]
  );

  return {
    code,
    setCode,
    verifyCode,
    resendCode,
  };
}

import { PropsWithChildren } from "react";
import { Box, lighten, styled } from "@mui/material";
import { theme as oldTheme } from "../config";
import { ReactComponent as ShareIcon } from "../assets/icons/shareIconWhite.svg";
import AddIcon from "@mui/icons-material/Add";

const ActionButtonContainer = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  color: oldTheme.pallete.text.primaryDark,
  backgroundColor: oldTheme.pallete.text.primaryExtraLight,
  "&:hover": {
    backgroundColor: lighten(oldTheme.pallete.text.primary, 0.8),
    cursor: "pointer",
  },
  borderRadius: "8px",
  padding: "16px",
});

const Column = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  height: "100%",
  maxWidth: "149px",
  [theme.breakpoints.up(541)]: {
    maxWidth: "none",
  },
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
}));

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const IconContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "48px",
  height: "48px",
  borderRadius: "50%",
  backgroundColor: oldTheme.pallete.text.primary,
});

export const ShareLinkIcon = styled(ShareIcon)({
  width: "24px",
  height: "24px",
  color: "white",
});

export const NewDocumentIcon = styled(AddIcon)({
  width: "24px",
  height: "24px",
  color: "white",
});

interface Props {
  onClick: () => void;
  icon?: React.ReactNode;
}

export const HomeActionButton: React.FC<PropsWithChildren<Props>> = ({
  onClick,
  icon,
  children,
}) => {
  return (
    <ActionButtonContainer onClick={onClick}>
      <Column>
        {icon && (
          <IconWrapper>
            <IconContainer>{icon}</IconContainer>
          </IconWrapper>
        )}
        {children}
      </Column>
    </ActionButtonContainer>
  );
};

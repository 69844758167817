import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, Box, FormControl, styled } from "@mui/material";
import { Global } from "@emotion/react";
import {
  NewRequestPayload,
  Patient,
  PatientBaseAttributes,
  RequestStatus,
} from "@interfaces";
import { FormContainer } from "@styles/styled";
import {
  ErrorMessage,
  InputLabel,
  SingleInput,
  WhiteTextField,
} from "./styled";
import {
  BirthdateInput,
  Body,
  BottomActionArea,
  BottomActionButton,
  ExtraWhiteSpace,
  FullScreenLoader,
  Header,
  InputFieldWrapper,
  SingleFormInput,
  SingleFormInputSkeleton,
  scrollToTop,
  NavigateBeforeButton,
  PhoneInput,
} from "@components";
import { ShareLink } from "./components/ShareLink";
import { useDoctorContext, useFetch } from "@contexts";
import { useCreateRequest } from "@hooks";
import {
  capitalizeParametValue,
  getBirthdateDetails,
  handleNumericInputChange,
  parseDecimalInput,
  replaceCurrentPhoneCode,
} from "@utils";
import { validate, format } from "rut.js";
import regionsWithCommunes from "../../data/regionsWithCommunes.json";
import axios from "axios";
import { getNameParts } from "@utils/names";

const Column = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const regions = regionsWithCommunes.map((region: any) => region.region);

const formInitialState: PatientBaseAttributes = {
  rut: "",
  name: "",
  firstSurname: "",
  secondSurname: "",
  birthdate: "",
  weight: "",
  email: "",
  phone: "",
  region: "Metropolitana de Santiago",
  commune: "",
  street: "",
};

export function AddPatientInfo() {
  const navigate = useNavigate();
  const fetch = useFetch();
  const location = useLocation();
  const { documentType, searchedRut = "" } = location.state || {};

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    trigger,
  } = useForm<PatientBaseAttributes>({ defaultValues: { rut: searchedRut } });

  const doctor = useDoctorContext();
  const [settingPatientData, setSettingPatientData] = useState<boolean>(true);
  const [loadingPatientData, setLoadingPatientData] = useState<boolean>(false);
  const [showAdditionalFields, setShowAdditionalFields] =
    useState<boolean>(false);

  const [patientCode, setPatientCode] = useState<string>("");
  const [formState, setFormState] = useState(formInitialState);
  const { mutate: createRequest, isPending } = useCreateRequest();

  // RUT:
  const patientInputRut = watch("rut");
  const [showRutError, setShowRutError] = useState<boolean>(false);

  // birthdate:
  const birthdate = watch("birthdate");
  const birthdateDetails = useMemo(() => {
    return getBirthdateDetails(birthdate);
  }, [birthdate]);

  // Regions and communes:
  const selectedRegion = watch("region");
  const selectedCommune = watch("commune");
  // Show only communes for selected region:
  const communes = useMemo(() => {
    const foundRegion = regionsWithCommunes.find(
      (r) => r.region === selectedRegion
    );
    return foundRegion ? foundRegion.communes.sort() : [];
  }, [selectedRegion]);

  // Phone:
  const [phoneInputRequired, setPhoneInputRequired] = useState<boolean>(false);
  const [currentPhoneCode, setCurrentPhoneCode] = useState({
    name: "Chile",
    icon: "🇨🇱",
    prefix: "+56",
  });

  const weight = watch("weight");

  // Handle back browsing:
  const handleBack = useCallback(() => {
    if (settingPatientData) {
      if (!showAdditionalFields) {
        navigate("/app/nuevo-documento/paciente", { state: { documentType } });
      } else {
        setShowAdditionalFields(false);
      }
    } else {
      setSettingPatientData(true);
    }
    scrollToTop();
  }, [
    settingPatientData,
    setSettingPatientData,
    showAdditionalFields,
    setShowAdditionalFields,
    documentType,
    navigate,
  ]);

  const fetchPatientData = useCallback(async () => {
    setLoadingPatientData(true);
    try {
      const patient = await fetch<Patient>(
        `/patients?rut=${format(patientInputRut)}&doctorCode=${doctor.code}`
      );

      // Automatically fills inputs:
      if (patient) {
        const patientBaseAttributes: PatientBaseAttributes = {
          rut: patient.rut,
          name: patient.name,
          firstSurname: patient.firstSurname,
          secondSurname: patient.secondSurname,
          birthdate: patient.birthdate,
          weight: "",
          email: patient.email,
          phone: patient.phone,
          region: patient.region,
          commune: patient.commune,
          street: patient.street,
        };
        setPatientCode(patient.code);
        setFormState(patientBaseAttributes);
        setShowRutError(false);
        Object.entries(patientBaseAttributes).forEach(([key, value]) => {
          if (key === "rut") return;
          setValue(key as keyof PatientBaseAttributes, value);
        });
        if (patient.phone) setPhoneInputRequired(true);
      }
    } catch (error) {
      try {
        const patientRutData = await axios.get(
          `https://api.boostr.cl/rut/name/${patientInputRut}.json`
        );
        const { firstName, secondName, firstSurname, secondSurname } =
          getNameParts(patientRutData.data.data.name);
        const patientBaseAttributes: PatientBaseAttributes = {
          rut: `${patientRutData.data.data.document}-${patientRutData.data.data.dv}`,
          name: `${firstName} ${secondName}`.trim(),
          firstSurname: firstSurname,
          secondSurname: secondSurname,
          birthdate: "",
          weight: "",
          email: "",
          phone: "",
          region: "",
          commune: "",
          street: "",
        };
        setFormState(patientBaseAttributes);
        setShowRutError(false);
        Object.entries(patientBaseAttributes).forEach(([key, value]) => {
          if (key === "rut") return;
          setValue(key as keyof PatientBaseAttributes, value);
        });
      } catch (boostrError) {
        console.error("Error fetching from api.boostr.cl:", boostrError);
      }
    } finally {
      setLoadingPatientData(false);
      setShowAdditionalFields(true);
    }
  }, [
    patientInputRut,
    doctor.code,
    fetch,
    setFormState,
    setValue,
    setShowAdditionalFields,
  ]);

  const handleRutOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormState(formInitialState);
      setValue("rut", e.target.value);
      clearErrors("rut");
      setShowRutError(false);
      setShowAdditionalFields(false);

      Object.entries(formState).forEach(([key, _]) => {
        if (key === "rut") return;
        setValue(key as keyof PatientBaseAttributes, "");
        clearErrors(key as keyof PatientBaseAttributes);
      });
    },
    [
      formState,
      setFormState,
      setValue,
      setShowRutError,
      setShowAdditionalFields,
      clearErrors,
    ]
  );

  const handleRutBlur = useCallback(() => {
    if (!patientInputRut) return;
    if (!validate(patientInputRut)) {
      setShowRutError(true);
      return;
    }
    setValue("rut", format(patientInputRut));
    clearErrors("rut");
    setShowRutError(false);
  }, [patientInputRut, setValue, clearErrors, setShowRutError]);

  const handleOnSubmitRut = useCallback(() => {
    if (!patientInputRut) return;
    if (!validate(patientInputRut)) {
      setShowRutError(true);
      return;
    }
    fetchPatientData();
    scrollToTop();
  }, [patientInputRut, fetchPatientData]);

  const handleRutKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter") return;
      if (!patientInputRut) return;
      // Remove focus from input (to hide keyboard on mobile)
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      if (!validate(patientInputRut)) {
        setShowRutError(true);
        return;
      }
      setValue("rut", format(patientInputRut));
      clearErrors("rut");
      setShowRutError(false);
      handleOnSubmitRut();
    },
    [patientInputRut, setValue, clearErrors, setShowRutError, handleOnSubmitRut]
  );

  // TODO: Use zod to validate form
  const validateForm = useCallback(
    (data: PatientBaseAttributes) => {
      let isValid = true;
      const notRequiredKeys = [
        "weight",
        ...(phoneInputRequired ? [] : ["phone"]),
      ];
      Object.keys(formState).forEach((key) => {
        if (
          key in data &&
          !data[key as keyof PatientBaseAttributes] &&
          !notRequiredKeys.includes(key)
        ) {
          isValid = false;
        }
      });
      return isValid;
    },
    [formState, phoneInputRequired]
  );

  const onPatientDataSubmit = useCallback(
    (formData: PatientBaseAttributes) => {
      setFormState((prevState) => {
        console.log("prevState", prevState);
        console.log("onPatientDataSubmit", formData);
        return {
          ...prevState,
          ...formData,
          phone: phoneInputRequired
            ? replaceCurrentPhoneCode(formData.phone, currentPhoneCode.prefix)
            : "",
          region: formData.region !== "" ? formData.region : prevState.region,
        };
      });
      setSettingPatientData(false);
      scrollToTop();
    },
    [currentPhoneCode, phoneInputRequired, setFormState, setSettingPatientData]
  );

  const onSubmit: SubmitHandler<PatientBaseAttributes> = useCallback(
    async (data) => {
      if (!validateForm(data)) return;
      const newRequestPayload: NewRequestPayload = {
        doctorCode: doctor.code,
        patient: {
          ...data,
          rut: format(data.rut),
          weight: data.weight && data.weight.replace(/,/g, "."),
          phone: phoneInputRequired ? currentPhoneCode.prefix + data.phone : "",
          streetNumber: "",
          chronicDiseases: "",
          allergies: "",
          code: patientCode,
        },
        request: {
          documentType: documentType,
          doctorCode: doctor.code,
          status: RequestStatus.DRAFT,
          comment: "",
        },
      };

      createRequest(newRequestPayload);
    },
    [
      currentPhoneCode,
      phoneInputRequired,
      patientCode,
      documentType,
      doctor,
      validateForm,
      createRequest,
    ]
  );

  // Fill in entries when the formState has already been established:
  useEffect(() => {
    if (formState.rut && !loadingPatientData) {
      setShowAdditionalFields(true);
      console.log("formState", formState);
      Object.entries(formState).forEach(([key, value]) => {
        setValue(key as keyof PatientBaseAttributes, value);
      });
    }
  }, [formState, loadingPatientData, setShowAdditionalFields, setValue]);

  if (isPending) {
    return <FullScreenLoader title="Cargando..." />;
  }

  return (
    <>
      <Header
        title={settingPatientData ? "Datos del paciente" : "Datos de contacto"}
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          {settingPatientData ? (
            <>
              {loadingPatientData ? (
                <>
                  <SingleFormInputSkeleton />
                  <SingleFormInputSkeleton />
                  <SingleFormInputSkeleton />
                  <SingleFormInputSkeleton />
                </>
              ) : patientInputRut && showAdditionalFields ? (
                <>
                  <SingleFormInput
                    title="Nombre"
                    parameterName="name"
                    errorText="Debes escribir el nombre del paciente."
                    register={register}
                    errors={errors}
                    onBlur={() =>
                      capitalizeParametValue("name", watch, setValue, trigger)
                    }
                  />

                  <SingleFormInput
                    title="Apellido paterno"
                    parameterName="firstSurname"
                    errorText="Debes escribir el apellido paterno del paciente."
                    register={register}
                    errors={errors}
                    onBlur={() =>
                      capitalizeParametValue(
                        "firstSurname",
                        watch,
                        setValue,
                        trigger
                      )
                    }
                  />

                  <SingleFormInput
                    title="Apellido materno"
                    parameterName="secondSurname"
                    errorText="Debes escribir el apellido materno del paciente."
                    register={register}
                    errors={errors}
                    autoComplete="off"
                    onBlur={() =>
                      capitalizeParametValue(
                        "secondSurname",
                        watch,
                        setValue,
                        trigger
                      )
                    }
                  />

                  <BirthdateInput
                    control={control}
                    errorManagement={{ errors }}
                  />

                  {birthdateDetails.weightNeeded && (
                    <SingleFormInput
                      title="Peso"
                      parameterName="weight"
                      inputType="number"
                      numericInputType="decimal"
                      numericExternalValue={weight}
                      handleNumericExternalValueChange={(value) =>
                        handleNumericInputChange(
                          "weight",
                          String(value),
                          clearErrors,
                          setValue
                        )
                      }
                      units="kg"
                      errorText="Debes ingresar el peso del paciente."
                      register={register}
                      errors={errors}
                      validate={(value: string) =>
                        parseDecimalInput(value) > 0 ||
                        "Debes ingresar una cantidad válida"
                      }
                    />
                  )}

                  <ExtraWhiteSpace />

                  <BottomActionArea>
                    <BottomActionButton
                      onClick={handleSubmit(onPatientDataSubmit)}
                      disabled={!patientInputRut}
                    >
                      Siguiente
                    </BottomActionButton>
                  </BottomActionArea>
                </>
              ) : null}
              {!showAdditionalFields && (
                <>
                  {!loadingPatientData && (
                    <Column>
                      {!loadingPatientData && (
                        <FormControl fullWidth>
                          <InputLabel
                            disabled={
                              showAdditionalFields || loadingPatientData
                            }
                          >
                            RUT del paciente
                          </InputLabel>
                          <SingleInput
                            placeholder=""
                            {...register("rut", {
                              required: "Debes escribir el RUT del paciente.",
                            })}
                            onBlur={handleRutBlur}
                            onKeyDown={handleRutKeyDown}
                            onChange={handleRutOnChange}
                            disabled={
                              showAdditionalFields || loadingPatientData
                            }
                            error={showRutError || !!errors["rut"]}
                          />
                          {showRutError && !!!errors.rut && (
                            <ErrorMessage>RUT no válido</ErrorMessage>
                          )}
                          {!showRutError && !!errors.rut && (
                            <ErrorMessage>{errors.rut.message}</ErrorMessage>
                          )}
                        </FormControl>
                      )}
                      <ShareLink />
                    </Column>
                  )}

                  <BottomActionArea>
                    <BottomActionButton
                      onClick={handleSubmit(handleOnSubmitRut)}
                      disabled={!patientInputRut || loadingPatientData}
                    >
                      Siguiente
                    </BottomActionButton>
                  </BottomActionArea>
                </>
              )}
            </>
          ) : (
            <>
              <SingleFormInput
                title="Correo electrónico"
                parameterName="email"
                errorText="Debes escribir el correo electrónico del paciente."
                // description="A este correo notificaremos al paciente."
                register={register}
                errors={errors}
                inputType="email"
                onBlur={() => {
                  const emailValue = watch("email");
                  if (emailValue) {
                    setValue("email", emailValue.toLowerCase());
                  }
                  trigger("email");
                }}
                validate={(value) => {
                  const emailRegex =
                    /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
                  if (emailRegex.exec(value)) {
                    return true;
                  } else {
                    return "El correo electrónico está incompleto";
                  }
                }}
              />

              {phoneInputRequired && (
                <PhoneInput
                  register={register}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  trigger={trigger}
                  clearErrors={clearErrors}
                  phoneCodeState={{
                    currentPhoneCode,
                    setCurrentPhoneCode,
                  }}
                />
              )}

              <InputFieldWrapper label="Región" error={errors.region?.message}>
                <Controller
                  name={"region"}
                  control={control}
                  defaultValue="Metropolitana de Santiago"
                  rules={{
                    required: "Debes seleccionar la región del paciente.",
                  }}
                  render={({ ...props }) => (
                    <Autocomplete
                      disablePortal
                      value={selectedRegion}
                      options={regions}
                      getOptionLabel={(region) => region}
                      renderInput={(params) => (
                        <WhiteTextField
                          error={Boolean(errors.region)}
                          {...params}
                        />
                      )}
                      onChange={(_, data) => {
                        setValue("commune", "");
                        if (data) clearErrors("region");
                        props.field.onChange(data);
                      }}
                    />
                  )}
                />
              </InputFieldWrapper>

              <InputFieldWrapper label="Comuna" error={errors.commune?.message}>
                <Controller
                  name={"commune"}
                  control={control}
                  rules={{
                    required: "Debes seleccionar la comuna del paciente.",
                  }}
                  render={({ ...props }) => (
                    <Autocomplete
                      disablePortal
                      value={selectedCommune}
                      options={communes}
                      getOptionLabel={(commune) => commune}
                      renderInput={(params) => (
                        <WhiteTextField
                          error={Boolean(errors.commune)}
                          {...params}
                        />
                      )}
                      onChange={(_, data) => {
                        if (data) clearErrors("commune");
                        props.field.onChange(data);
                      }}
                    />
                  )}
                />
              </InputFieldWrapper>

              <SingleFormInput
                title="Dirección"
                parameterName="street"
                errorText="Debes escribir la dirección del paciente."
                // description="Ej: Av. Providencia 123"
                register={register}
                errors={errors}
              />

              <ExtraWhiteSpace />

              <BottomActionArea>
                <BottomActionButton type="submit">Siguiente</BottomActionButton>
              </BottomActionArea>
            </>
          )}
        </FormContainer>
      </Body>
    </>
  );
}

import { SvgIcon, SvgIconProps } from "@mui/material";

export const PrescriptionLandingIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.51562 3.32209C7.51562 2.60004 7.80246 1.90757 8.31302 1.39701C8.82359 0.886441 9.51606 0.599609 10.2381 0.599609L15.6831 0.599609C16.4051 0.599609 17.0976 0.886441 17.6082 1.39701C18.1187 1.90757 18.4056 2.60004 18.4056 3.32209V5.13708C18.4056 5.85913 18.1187 6.5516 17.6082 7.06217C17.0976 7.57273 16.4051 7.85956 15.6831 7.85956H10.2381C9.51606 7.85956 8.82359 7.57273 8.31302 7.06217C7.80246 6.5516 7.51562 5.85913 7.51562 5.13708V3.32209ZM16.5906 3.32209C16.5906 3.08141 16.495 2.85058 16.3248 2.6804C16.1546 2.51021 15.9238 2.4146 15.6831 2.4146H10.2381C9.99742 2.4146 9.7666 2.51021 9.59641 2.6804C9.42622 2.85058 9.33061 3.08141 9.33061 3.32209V5.13708C9.33061 5.37776 9.42622 5.60859 9.59641 5.77877C9.7666 5.94896 9.99742 6.04457 10.2381 6.04457H15.6831C15.9238 6.04457 16.1546 5.94896 16.3248 5.77877C16.495 5.60859 16.5906 5.37776 16.5906 5.13708V3.32209Z"
        fill="#E94964"
      />
      <path
        d="M3.88779 3.31445H5.70278V5.12944H3.88779C3.40642 5.12944 2.94477 5.32066 2.6044 5.66104C2.26402 6.00142 2.0728 6.46306 2.0728 6.94443V26.0018C2.0728 26.4832 2.26402 26.9448 2.6044 27.2852C2.94477 27.6256 3.40642 27.8168 3.88779 27.8168H22.0377C22.519 27.8168 22.9807 27.6256 23.3211 27.2852C23.6614 26.9448 23.8527 26.4832 23.8527 26.0018V6.94443C23.8527 6.46306 23.6614 6.00142 23.3211 5.66104C22.9807 5.32066 22.519 5.12944 22.0377 5.12944H20.2227V3.31445H22.0377C23.0004 3.31445 23.9237 3.6969 24.6045 4.37765C25.2852 5.0584 25.6676 5.9817 25.6676 6.94443V26.0018C25.6676 26.9645 25.2852 27.8878 24.6045 28.5686C23.9237 29.2493 23.0004 29.6318 22.0377 29.6318H3.88779C2.92506 29.6318 2.00176 29.2493 1.32101 28.5686C0.640255 27.8878 0.257813 26.9645 0.257812 26.0018V6.94443C0.257812 5.9817 0.640255 5.0584 1.32101 4.37765C2.00176 3.6969 2.92506 3.31445 3.88779 3.31445Z"
        fill="#E94964"
      />
      <path
        d="M8.36203 17.447L13.4717 12.3367C13.7401 12.0683 14.0587 11.8555 14.4094 11.7102C14.76 11.565 15.1358 11.4902 15.5154 11.4902C15.8949 11.4902 16.2708 11.565 16.6214 11.7102C16.9721 11.8555 17.2907 12.0683 17.559 12.3367C17.8274 12.6051 18.0403 12.9237 18.1856 13.2743C18.3308 13.625 18.4056 14.0008 18.4056 14.3803C18.4056 14.7598 18.3308 15.1357 18.1856 15.4863C18.0403 15.8369 17.8274 16.1555 17.559 16.4239L12.4508 21.5327C12.1825 21.8012 11.864 22.0143 11.5134 22.1597C11.1627 22.3051 10.7869 22.38 10.4073 22.3802C10.0278 22.3803 9.65186 22.3057 9.30111 22.1606C8.95037 22.0155 8.63163 21.8028 8.36311 21.5345C8.0946 21.2662 7.88155 20.9477 7.73613 20.5971C7.59072 20.2465 7.51579 19.8707 7.51562 19.4911C7.51546 19.1115 7.59005 18.7357 7.73516 18.3849C7.88026 18.0342 8.09375 17.7155 8.36203 17.447ZM14.9572 18.004L17.0475 15.9138C17.2514 15.7131 17.4136 15.474 17.5247 15.2104C17.6358 14.9467 17.6936 14.6636 17.6947 14.3775C17.6958 14.0914 17.6403 13.8078 17.5313 13.5433C17.4223 13.2787 17.262 13.0384 17.0597 12.8361C16.8573 12.6338 16.6169 12.4735 16.3523 12.3646C16.0877 12.2557 15.8042 12.2002 15.5181 12.2014C15.2319 12.2026 14.9489 12.2605 14.6852 12.3716C14.4215 12.4827 14.1825 12.645 13.9818 12.849L11.8916 14.9392L14.9572 18.004Z"
        fill="#E94964"
      />
      <path
        d="M8.36203 17.447L13.4717 12.3367C13.7401 12.0683 14.0587 11.8555 14.4094 11.7102C14.76 11.565 15.1358 11.4902 15.5154 11.4902C15.8949 11.4902 16.2708 11.565 16.6214 11.7102C16.9721 11.8555 17.2907 12.0683 17.559 12.3367C17.8274 12.6051 18.0403 12.9237 18.1856 13.2743C18.3308 13.625 18.4056 14.0008 18.4056 14.3803C18.4056 14.7598 18.3308 15.1357 18.1856 15.4863C18.0403 15.8369 17.8274 16.1555 17.559 16.4239L12.4508 21.5327C12.1825 21.8012 11.864 22.0143 11.5134 22.1597C11.1627 22.3051 10.7869 22.38 10.4073 22.3802C10.0278 22.3803 9.65186 22.3057 9.30111 22.1606C8.95037 22.0155 8.63163 21.8028 8.36311 21.5345C8.0946 21.2662 7.88155 20.9477 7.73613 20.5971C7.59072 20.2465 7.51579 19.8707 7.51562 19.4911C7.51546 19.1115 7.59005 18.7357 7.73516 18.3849C7.88026 18.0342 8.09375 17.7155 8.36203 17.447ZM14.9572 18.004L17.0475 15.9138C17.2514 15.7131 17.4136 15.474 17.5247 15.2104C17.6358 14.9467 17.6936 14.6636 17.6947 14.3775C17.6958 14.0914 17.6403 13.8078 17.5313 13.5433C17.4223 13.2787 17.262 13.0384 17.0597 12.8361C16.8573 12.6338 16.6169 12.4735 16.3523 12.3646C16.0877 12.2557 15.8042 12.2002 15.5181 12.2014C15.2319 12.2026 14.9489 12.2605 14.6852 12.3716C14.4215 12.4827 14.1825 12.645 13.9818 12.849L11.8916 14.9392L14.9572 18.004Z"
        stroke="#E94964"
      />
    </svg>
  </SvgIcon>
);

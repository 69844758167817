import { SvgIcon, SvgIconProps } from "@mui/material";

const FooterLandingIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="325"
      height="325"
      viewBox="0 0 325 325"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.9134 265.056L77.4105 279.553C108.229 310.371 141.418 325.507 176.977 324.96C212.537 324.778 245.908 309.095 277.091 277.912C308.274 246.729 324.322 213.358 325.233 177.798C326.328 142.421 311.648 109.505 281.194 79.0517L202.143 0L105.038 97.1045L145.521 137.588L203.784 79.3252L242.626 118.167C259.95 135.491 267.518 154.912 265.329 176.43C263.141 197.948 253.294 217.461 235.787 234.967C217.734 253.02 197.948 263.141 176.43 265.329C154.912 267.517 135.491 259.95 118.167 242.626L101.755 226.214L62.9134 265.056Z"
          fill="white"
        />
        <circle
          cx="45.7754"
          cy="202.327"
          r="32.2364"
          transform="rotate(45 45.7754 202.327)"
          fill="white"
        />
      </g>
    </svg>
  </SvgIcon>
);

export default FooterLandingIcon;

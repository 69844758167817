import { Modal } from "@components";
import { Box, styled, Typography } from "@mui/material";

const ModalContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
});

const Bold = styled("span")({
  fontWeight: 600,
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ShareDocumentsErrorModal: React.FC<Props> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      title="No se pudo compartir el documento"
      content={
        <ModalContent>
          <Typography>
            Tu dispositivo no tiene los permisos necesarios para compartir.
          </Typography>
          <Typography>
            <Bold>Descargamos el archivo en tu navegador</Bold> para que puedas
            compartirlo desde ahí
          </Typography>
        </ModalContent>
      }
      isOpen={isOpen}
      onClose={onClose}
      buttonProps={{
        buttonText: "Aceptar",
        onClick: onClose,
      }}
    />
  );
};

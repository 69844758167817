import { SvgIcon, SvgIconProps } from "@mui/material";

export const SecurityAndPrivacyIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width={67}
      height={66}
      viewBox="0 0 67 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.9868 0H17.0139L0.52687 16.4364C0.50212 16.883 -1.48495 61.182 33.3383 65.9882C33.3925 65.9965 33.4456 66 33.4998 66C33.554 66 33.6082 65.9965 33.6612 65.9894C68.4833 61.182 66.4962 16.883 66.4727 16.4364L49.9868 0ZM2.85691 17.6114C2.80033 24.1772 3.89405 59.4177 33.4998 63.6311C63.1326 59.4141 64.2039 24.176 64.1426 17.6114C58.249 11.7237 51.3017 4.77632 49.196 2.35714H17.8047C15.2622 4.77632 8.31481 11.7237 2.85691 17.6114Z"
        fill="#7355FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0951 25.9286H44.0303C43.4422 20.6332 38.9471 16.5 33.4998 16.5C28.0524 16.5 23.5585 20.6332 22.9704 25.9286H22.9032C20.9527 25.9286 19.3663 27.5149 19.3663 29.4643V45.9643C19.3663 47.9136 20.9527 49.5 22.9032 49.5H44.0939C46.0445 49.5 47.6308 47.9136 47.6308 45.9643V29.4643C47.632 27.5149 46.0457 25.9286 44.0951 25.9286ZM41.6484 25.9286C41.0744 21.9367 37.6448 18.8571 33.4998 18.8571C29.3559 18.8571 25.9263 21.9367 25.3523 25.9286H41.6484ZM44.0951 47.1429C44.7445 47.1429 45.2737 46.6137 45.2737 45.9643V29.4643C45.2737 28.8149 44.7457 28.2857 44.0951 28.2857H22.9044C22.2562 28.2857 21.7258 28.8149 21.7258 29.4643V45.9643C21.7258 46.6137 22.255 47.1429 22.9044 47.1429H44.0951Z"
        fill="#7355FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4998 33C31.5504 33 29.9652 34.5864 29.9652 36.5357C29.9652 38.0702 30.9517 39.3655 32.3212 39.8534V41.25C32.3212 41.9006 32.8492 42.4286 33.4998 42.4286C34.1503 42.4286 34.6783 41.9006 34.6783 41.25V39.8534C36.0455 39.3655 37.0343 38.069 37.0343 36.5357C37.0343 34.5864 35.4491 33 33.4998 33ZM34.6772 36.5357C34.6772 35.8863 34.148 35.3571 33.4998 35.3571C32.8504 35.3571 32.3224 35.8863 32.3224 36.5357C32.3224 37.1851 32.8504 37.7143 33.4998 37.7143C34.148 37.7143 34.6772 37.1851 34.6772 36.5357Z"
        fill="#7355FB"
      />
    </svg>
  </SvgIcon>
);

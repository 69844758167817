import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { Global } from "@emotion/react";
import { FormContainer } from "@styles/styled";
import { MarginContainer } from "./styles";
import {
  CompoundedPrescriptionDetails,
  PrescriptionQFButtonMapper,
  PrescriptionQFHeaderMapper,
  PrescriptionStatus,
  RequestType,
} from "@interfaces";
import {
  Body,
  BottomActionArea,
  ExtraWhiteSpace,
  FullScreenLoader,
  Header,
  PrescriptionStatusChip,
  SingleFormInput,
  TermsAndConditionsCheckBox,
  TextAreaFormInput,
  NavigateBeforeButton,
  BottomActionButton,
} from "@components";
import { api, queryClient } from "@contexts";
import { usePrescription } from "./hooks/usePrescription";
import { handleNumericInputChange } from "@utils";
import { AxiosError } from "axios";
import { validate, format } from "rut.js";
import { useMutation } from "@tanstack/react-query";
import { theme as oldTheme } from "../../config";

const HoldingMedicineInfoContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const HoldingMedicineInfoTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: "1.25rem",
  color: oldTheme.pallete.text.black,
});

type Inputs = {
  qfRut: string;
  qfEmail: string;
  pharmacyRut: string;
  pharmacyName: string;
  dispensedMedicine?: string;
  dispensedQuantity?: number | string;
  details?: string;
};

interface LocationState {
  prescriptionManagment: PrescriptionStatus;
}

interface ChangeStatusData {
  pharmaceuticalChemistData: { rut: string; email: string };
  prescriptionHoldingData: {
    pharmacyRut: string;
    pharmacyName: string;
    activePrinciple?: string;
    concentration?: string;
    quantityToDispense?: string;
    details?: string;
  };
  status: PrescriptionStatus;
}

interface ChangeStatusMutation {
  prescriptionId: string;
  requestData: ChangeStatusData;
}

export const QFDataFormScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { prescriptionManagment }: LocationState = location.state;
  const { prescriptionId = "" } = useParams();
  const { data: prescriptionData } = usePrescription();
  const [termsChecked, setTermsChecked] = useState(false);
  const [checkBoxError, setCheckBoxError] = useState(false);
  const handleBack = () => navigate(-1);

  const { mutate, isPending } = useMutation({
    mutationFn: ({ prescriptionId, requestData }: ChangeStatusMutation) =>
      api({
        method: "post",
        url: `/prescriptions/${prescriptionId}/change-status`,
        data: requestData,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["verify-prescription", prescriptionId],
      });
      navigate("..");
    },
    onError: (
      error: AxiosError<{
        message: string;
      }>
    ) => {
      if (
        error.response?.status === 400 &&
        error.response?.data?.message ===
          "RUT is not associated with a valid pharmaceutical chemist"
      ) {
        setError("qfRut", {
          type: "manual",
          message: "El RUT no pertenece a un químico farmacéutico válido",
        });
      }
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
  } = useForm<Inputs>();

  const isCompoundedPrescription = useMemo(() => {
    if (!prescriptionData) return false;
    const { type: prescriptionType } = prescriptionData.prescription;
    return prescriptionType === RequestType.COMPOUNDED_PRESCRIPTION;
  }, [prescriptionData]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsChecked(event.target.checked);
    setCheckBoxError(false);
  };

  const onSubmit = useCallback(
    async (data: Inputs) => {
      if (!termsChecked) {
        setCheckBoxError(true);
        return;
      }

      let prescriptionHoldingData = {
        pharmacyRut: data.pharmacyRut,
        pharmacyName: data.pharmacyName,
        activePrinciple: data.dispensedMedicine,
        concentration: data.dispensedMedicine,
        quantityToDispense: String(data.dispensedQuantity),
        details: data.details,
      };

      if (isCompoundedPrescription) {
        const compoundedPrescriptionDetails = prescriptionData?.prescription
          .details as CompoundedPrescriptionDetails;
        const { compoundedIngredients } = compoundedPrescriptionDetails;
        prescriptionHoldingData = {
          ...prescriptionHoldingData,
          activePrinciple: compoundedIngredients,
          concentration: compoundedIngredients,
        };
      }

      mutate({
        prescriptionId,
        requestData: {
          pharmaceuticalChemistData: {
            rut: data.qfRut,
            email: data.qfEmail,
          },
          prescriptionHoldingData,
          status: prescriptionManagment,
        },
      });
    },
    [
      mutate,
      termsChecked,
      prescriptionId,
      prescriptionManagment,
      isCompoundedPrescription,
      prescriptionData,
    ]
  );

  const qfRut = watch("qfRut");
  const pharmacyRut = watch("pharmacyRut");
  const qfEmail = watch("qfEmail");
  const dispensedQuantity = watch("dispensedQuantity");

  // Ruts validation
  const handleRutBlur = useCallback(
    (rut: string, parameterName: "qfRut" | "pharmacyRut") => {
      if (!rut) return;
      if (!validate(rut)) {
        setError(parameterName, {
          type: "manual",
          message: "RUT no válido",
        });
        return;
      }
      setValue(parameterName, format(rut));
      clearErrors(parameterName);
    },
    [setValue, setError, clearErrors]
  );

  const handleRutKeyDown = useCallback(
    (
      e: React.KeyboardEvent<HTMLInputElement>,
      rut: string,
      parameterName: "qfRut" | "pharmacyRut"
    ) => {
      if (e.key !== "Enter") {
        clearErrors(parameterName);
        return;
      }
      if (!rut) return;
      // Remove focus from input (to hide keyboard on mobile)
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      if (!validate(rut)) {
        setError(parameterName, {
          type: "manual",
          message: "RUT no válido",
        });
        return;
      }
      setValue(parameterName, format(rut));
    },
    [setValue, setError, clearErrors]
  );

  if (!prescriptionData) {
    handleBack();
    return null;
  }
  const { prescription } = prescriptionData;

  if (isPending) {
    return (
      <FullScreenLoader
        title={`${
          prescriptionManagment === PrescriptionStatus.HELD
            ? "Reteniendo receta..."
            : "Dispensando receta..."
        }`}
      />
    );
  }

  // TODO: Hacer las validaciones del form con ZOD
  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Header
        title={PrescriptionQFHeaderMapper[prescriptionManagment]}
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Body>
        <MarginContainer margin={"16px 0px 0px 0px"}>
          <PrescriptionStatusChip
            code={prescription.id}
            status={prescription.prescriptionStatus}
            date={prescription.holdingDate}
            isCompoundedPrescription={isCompoundedPrescription}
          />
        </MarginContainer>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <SingleFormInput
            title="RUT Químico Farmaceútico"
            parameterName="qfRut"
            errorText="Debes ingresar tu RUT"
            register={register}
            errors={errors}
            inputType="text"
            onBlur={(e) => handleRutBlur(e.target.value, "qfRut")}
            onKeyDown={(e) => handleRutKeyDown(e, qfRut, "qfRut")}
          />
          <SingleFormInput
            title="Correo electrónico"
            parameterName="qfEmail"
            errorText="Debes ingresar tu email"
            register={register}
            errors={errors}
            inputType="email"
            onBlur={() => {
              if (qfEmail) {
                setValue("qfEmail", qfEmail.toLowerCase());
              }
              trigger("qfEmail");
            }}
            validate={(value) => {
              const emailRegex =
                /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
              if (emailRegex.exec(value)) {
                return true;
              } else {
                return "El correo electrónico está incompleto";
              }
            }}
          />
          <SingleFormInput
            title="Rut farmacia"
            parameterName="pharmacyRut"
            errorText="Debes ingresar el RUT de la farmacia"
            register={register}
            errors={errors}
            inputType="text"
            onBlur={(e) => handleRutBlur(e.target.value, "pharmacyRut")}
            onKeyDown={(e) => handleRutKeyDown(e, pharmacyRut, "pharmacyRut")}
          />
          <SingleFormInput
            title="Nombre farmacia"
            parameterName="pharmacyName"
            errorText="Debes ingresar el nombre de la farmacia"
            register={register}
            errors={errors}
            inputType="text"
          />
          {prescriptionManagment === PrescriptionStatus.HELD &&
            (isCompoundedPrescription ? (
              <SingleFormInput
                title="Cantidad dispensada"
                parameterName="dispensedQuantity"
                errorText="Debes ingresar la cantidad dispensada"
                register={register}
                errors={errors}
                inputType="text"
              />
            ) : (
              <HoldingMedicineInfoContainer>
                <HoldingMedicineInfoTitle>
                  Detalle Medicamentos
                </HoldingMedicineInfoTitle>

                <SingleFormInput
                  title="Medicamento dispensado"
                  parameterName="dispensedMedicine"
                  errorText="Debes ingresar el nombre del medicamento"
                  register={register}
                  errors={errors}
                  inputType="text"
                />

                <SingleFormInput
                  title="Cajas dispensadas"
                  parameterName="dispensedQuantity"
                  errorText="Debes ingresar la cantidad de cajas dispensadas"
                  register={register}
                  errors={errors}
                  inputType="number"
                  numericInputType="integer"
                  numericExternalValue={dispensedQuantity}
                  handleNumericExternalValueChange={(value) =>
                    handleNumericInputChange(
                      "dispensedQuantity",
                      String(value),
                      clearErrors,
                      setValue
                    )
                  }
                />
              </HoldingMedicineInfoContainer>
            ))}
          {prescriptionManagment === PrescriptionStatus.DISPENSED && (
            <TextAreaFormInput
              title="Comentarios"
              parameterName="details"
              errorText="Debes ingresar un comentario para realizar la dispensación"
              register={register}
              errors={errors}
            />
          )}

          <TermsAndConditionsCheckBox
            termsChecked={termsChecked}
            handleCheckboxChange={handleCheckboxChange}
            error={checkBoxError}
          />

          <ExtraWhiteSpace />

          <BottomActionArea>
            <BottomActionButton
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
            >
              {isPending ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                PrescriptionQFButtonMapper[prescriptionManagment]
              )}
            </BottomActionButton>
          </BottomActionArea>
        </FormContainer>
      </Body>
    </>
  );
};

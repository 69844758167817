import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { format as rutFormat } from "rut.js";
import { format } from "date-fns";
import { TooltipComponent } from "@components";
import {
  RequestStatus,
  RequestType,
  StatusPerRequestType,
  StatusTranslation,
  requestTypeSimplifiedTranslation,
} from "@interfaces";
import { theme as oldTheme } from "../../../config/";

const Card = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: oldTheme.pallete.background.white,
  boxShadow: "0px 0px 20px 1px #0000000f",
  borderRadius: "4px",
  padding: "6px",
  textDecoration: "none",
  cursor: "pointer",
});

const Column = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  width: "100%",
  height: "100%",
  gap: "4px",
  marginRight: "4px",
});

const Row = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: "100%",
  minHeight: "20px",
});

const IncompleteRow = styled(Row, {
  shouldForwardProp: (prop) => prop !== "width" && prop !== "isPending",
})(({ width, isPending }: { width: string; isPending?: boolean }) => ({
  width: width,
  ...(isPending === false && {
    justifyContent: "flex-end",
  }),
}));

const RequestCardType = styled("span", {
  shouldForwardProp: (prop) => prop !== "isPending",
})(({ isPending }: { isPending: boolean }) => ({
  fontSize: "12px",
  fontWeight: isPending ? 500 : 400,
  color: isPending
    ? oldTheme.pallete.text.primary
    : oldTheme.pallete.text.black,
}));

const RequestCardTime = styled("span", {
  shouldForwardProp: (prop) => prop !== "isPending",
})(({ isPending }: { isPending: boolean }) => ({
  fontSize: "12px",
  fontWeight: 400,
  textAlign: "right",
  color: isPending
    ? oldTheme.pallete.text.primary
    : oldTheme.pallete.text.black,
}));

const RequestCardPatient = styled("span", {
  shouldForwardProp: (prop) => prop !== "isPending",
})(({ isPending }: { isPending: boolean }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: "16px",
  fontWeight: isPending ? 500 : 400,
  color: oldTheme.pallete.text.black,
}));

const PendingDot = styled("span")({
  width: "14px",
  height: "14px",
  borderRadius: "50%",
  backgroundColor: oldTheme.pallete.text.primary,
});

const RequestCardDetails = styled("span", {
  shouldForwardProp: (prop) => prop !== "isPending",
})(({ isPending }: { isPending: boolean }) => ({
  fontSize: "14px",
  lineHeight: "19px",
  fontWeight: isPending ? 500 : 400,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: oldTheme.pallete.text.black,
}));

const StyledRightArrow = styled(KeyboardArrowRightIcon)({
  color: oldTheme.pallete.text.black,
});

interface RequestCardProps {
  id: string;
  name: string;
  lastName: string;
  rut: string;
  requestedAt: Date;
  status: RequestStatus;
  requestType: RequestType;
  managementSummary?: string;
}
export function RequestCard({
  id,
  name,
  lastName,
  rut,
  requestedAt,
  status,
  requestType,
  managementSummary,
}: RequestCardProps) {
  const navigate = useNavigate();
  const onCardClick = useCallback(() => {
    if (status === RequestStatus.PENDING)
      navigate(`/app/solicitudes/${id}/gestionar`);
    else navigate(`/app/solicitudes/${id}/resumen`, { state: { requestType } });
  }, [id, requestType, status, navigate]);

  const parseRequestedAt = useCallback(() => {
    if (new Date().toDateString() === requestedAt.toDateString()) {
      return format(requestedAt, "HH:mm");
    }
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (yesterday.toDateString() === requestedAt.toDateString()) {
      return "Ayer";
    }
    return format(requestedAt, "dd/MM");
  }, [requestedAt]);

  const isAccepted = status === RequestStatus.ACCEPTED;
  const isPending = status === RequestStatus.PENDING;
  const cardDetails = isPending
    ? rutFormat(rut)
    : managementSummary ?? rutFormat(rut);
  return (
    <Card onClick={onCardClick}>
      <Column>
        <IncompleteRow width={"91%"}>
          <RequestCardType isPending={isPending}>
            {isAccepted
              ? `${requestTypeSimplifiedTranslation[requestType]} · ${StatusPerRequestType[requestType][status]}`
              : `${StatusTranslation[status]}`}
          </RequestCardType>
          <RequestCardTime isPending={isPending}>
            {parseRequestedAt()}
          </RequestCardTime>
        </IncompleteRow>
        <Row>
          <IncompleteRow width={"87.5%"}>
            <RequestCardPatient isPending={isPending}>
              {name} {lastName}
            </RequestCardPatient>
          </IncompleteRow>
          <IncompleteRow width={"48px"} isPending={isPending}>
            {isPending && <PendingDot />}
            <StyledRightArrow />
          </IncompleteRow>
        </Row>
        <IncompleteRow width={"91%"}>
          <TooltipComponent title={cardDetails}>
            <RequestCardDetails isPending={isPending}>
              {cardDetails}
            </RequestCardDetails>
          </TooltipComponent>
        </IncompleteRow>
      </Column>
    </Card>
  );
}

import { useMutation } from "@tanstack/react-query";
import { api } from "../context";
import { PrescriptionDetail } from "../interfaces";

interface AddPrescriptionDetailProps {
  requestId: string;
  prescriptionDetail: PrescriptionDetail;
}

export const useAddPrescriptionDetail = () => {
  return useMutation({
    mutationFn: ({
      requestId,
      prescriptionDetail,
    }: AddPrescriptionDetailProps) => {
      return api<PrescriptionDetail>({
        method: "post",
        url: `/requests/${requestId}/detail`,
        data: JSON.stringify(prescriptionDetail),
      });
    },
  });
};

import { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Global } from "@emotion/react";
import { Body, SearchHeader } from "@components";
import { PreliminarySearchResults } from "@components/Search";
import { Procedure, RequestType, Suggestion } from "@interfaces";
import { mostSearchedProceduresQueries } from "@constants";
import { parseProcedureSuggestions } from "./utils/parse";
import { meiliProcedureSearchConfig } from "@config";

interface LocationState {
  attachedProcedures?: string[];
  medicalOrderStarted?: boolean;
}

export const SearchProcedure = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    attachedProcedures = [],
    medicalOrderStarted = false,
  }: LocationState = location.state || {};
  const { requestId = "" } = useParams();

  const [searchText, setSearchText] = useState<string>("");
  const prevSearchText = useRef<string>(searchText);

  const [showPreliminaryResults, setShowPreliminaryResults] = useState<boolean>(
    Boolean(searchText)
  );
  const [preliminaryResults, setPreliminaryResults] = useState<Suggestion[]>(
    []
  );
  const [proceduresResults, setProceduresResults] = useState<Procedure[]>([]);

  const client = useMemo(() => meiliProcedureSearchConfig.client, []);
  const search = useCallback(async () => {
    if (!searchText) return;
    const filteredSearchText = searchText.replace(/\bde\b/g, "");
    try {
      const params = new URLSearchParams();
      params.set("query", filteredSearchText.toLowerCase());

      const suggestions = await client
        .index(meiliProcedureSearchConfig.indexes.suggestions)
        .search(filteredSearchText, {
          limit: 100,
          sort: ["description:asc"],
        });

      const proceduresSuggestions = suggestions.hits.map(
        ({ code, description, group, subgroup }) =>
          ({
            code,
            description,
            group,
            subgroup,
          } as Procedure)
      );
      setProceduresResults(proceduresSuggestions);

      const parsedSuggestions = proceduresSuggestions.map((procedure) => ({
        text: parseProcedureSuggestions(procedure),
      }));
      setPreliminaryResults(parsedSuggestions);
    } catch (error) {
      console.log(error);
      setPreliminaryResults([]);
    }
  }, [searchText, client]);

  useEffect(() => {
    void search();
  }, [search]);

  const handleBack = useCallback(() => {
    if (medicalOrderStarted) {
      navigate(`/app/solicitudes/${requestId}/orden-medica/crear`, {
        state: { repeatedDocumentDetails: attachedProcedures },
      });
    } else {
      navigate(`/app/nuevo-documento/paciente`, {
        state: {
          documentType: RequestType.MEDICAL_ORDER,
        },
      });
    }
  }, [attachedProcedures, medicalOrderStarted, navigate, requestId]);

  const onPreliminarySearchItemClick = useCallback(
    (item: Suggestion) => {
      let procedure: Procedure | undefined;

      const emptySearchText = searchText.length === 0;
      // Clicking on a most searched procedure suggestion
      if (emptySearchText) {
        const procedureSuggestion = mostSearchedProceduresQueries.find(
          (commonResult) => item.text === commonResult.text
        );
        if (procedureSuggestion) {
          procedure = { ...procedureSuggestion } as Procedure;
        }
      } else {
        // Clicking on a searched procedure suggestion
        procedure = proceduresResults.find(
          (procedure) => item.text === parseProcedureSuggestions(procedure)
        );
      }

      if (procedure) {
        const existingProcedure = procedure; // TODO: Delete this variable, it is necesary to typescript
        const isProcedureAttached = attachedProcedures.some(
          (attachedProcedure: string) =>
            attachedProcedure === parseProcedureSuggestions(existingProcedure)
        );

        const newAttachedProcedures = !isProcedureAttached
          ? [
              ...attachedProcedures,
              parseProcedureSuggestions(existingProcedure),
            ]
          : attachedProcedures;

        navigate(`/app/solicitudes/${requestId}/orden-medica/crear`, {
          state: { repeatedDocumentDetails: newAttachedProcedures },
        });
      }
    },
    [
      attachedProcedures,
      navigate,
      proceduresResults,
      requestId,
      searchText.length,
    ]
  );

  useEffect(() => {
    if (
      searchText.length === 0 ||
      searchText.length < prevSearchText.current.length
    ) {
      setShowPreliminaryResults(true);
    }
    prevSearchText.current = searchText;
  }, [searchText, searchText.length]);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScrollPos) {
        // Remove focus from input (to hide keyboard on mobile)
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <>
      <SearchHeader
        searchText={searchText}
        setSearchText={setSearchText}
        onClick={() => setShowPreliminaryResults(true)}
        handleBack={handleBack}
        placeholder="Busca examen o procedimiento"
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        {showPreliminaryResults && (
          <PreliminarySearchResults
            searchText={searchText}
            preliminarySearchResults={preliminaryResults}
            onItemClick={onPreliminarySearchItemClick}
            showMostSearchedQueries={searchText.length === 0}
            mostSearchedQueries={mostSearchedProceduresQueries}
            addManuallyItemText="Agregar manualmente"
            locationState={{ attachedProcedures }}
          />
        )}
      </Body>
    </>
  );
};

import { MEKIDOC_PHONE } from "../environment";

export function contactUsLink(message: string) {
  const mekiDocContactPhone = MEKIDOC_PHONE.replace(/\s/g, "");
  const encodedMessage = encodeURIComponent(message);
  return `https://wa.me/${mekiDocContactPhone}?text=${encodedMessage}`;
}

export async function onContactUsClick(message: string) {
  const whatsappUrl = contactUsLink(message);
  window.open(whatsappUrl, "_blank");
}

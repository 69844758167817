import { SvgIcon, SvgIconProps } from "@mui/material";

export const IdentityVerificationIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width={72}
      height={63}
      viewBox="0 0 72 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.4104 29.5076C31.6698 27.0236 34.569 22.4564 34.569 17.1806C34.569 9.28457 28.1478 2.86328 20.2518 2.86328C12.3558 2.86328 5.93447 9.28457 5.93447 17.1806C5.93447 22.4565 8.83375 27.0235 13.0931 29.5076C5.63025 31.8879 0.208008 38.8787 0.208008 47.1218V58.7259C0.208008 61.0811 2.12291 62.9997 4.48175 62.9997H36.0229C38.378 62.9997 40.2966 61.0848 40.2966 58.7259V47.1218C40.2966 38.8822 34.8739 31.8882 27.4115 29.5076H27.4104ZM8.79838 17.1806C8.79838 10.8631 13.9347 5.72674 20.2522 5.72674C26.5697 5.72674 31.706 10.8631 31.706 17.1806C31.706 23.498 26.5697 28.6344 20.2522 28.6344C13.9347 28.6344 8.79838 23.498 8.79838 17.1806ZM37.433 58.7222C37.433 59.499 36.7994 60.1325 36.0227 60.1325H4.48157C3.70485 60.1325 3.07128 59.499 3.07128 58.7222V47.1181C3.07128 38.5063 10.0796 31.4942 18.6952 31.4942H21.8128C30.4246 31.4942 37.4367 38.5025 37.4367 47.1181V58.7222H37.433Z"
        fill="#7355FB"
      />
      <path
        d="M66.0658 11.4538C69.2227 11.4538 71.7927 8.88387 71.7927 5.72692C71.7927 2.56996 69.2227 0 66.0658 0C62.9088 0 60.3389 2.56996 60.3389 5.72692C60.3389 8.88387 62.9088 11.4538 66.0658 11.4538ZM66.0658 2.86346C67.6443 2.86346 68.9292 4.14839 68.9292 5.72692C68.9292 7.30544 67.6443 8.59037 66.0658 8.59037C64.4873 8.59037 63.2023 7.30544 63.2023 5.72692C63.2023 4.14839 64.4873 2.86346 66.0658 2.86346Z"
        fill="#7355FB"
      />
      <path
        d="M66.0658 14.3174C62.9088 14.3174 60.3389 16.8873 60.3389 20.0443C60.3389 23.2013 62.9088 25.7712 66.0658 25.7712C69.2227 25.7712 71.7927 23.2013 71.7927 20.0443C71.7927 16.8873 69.2227 14.3174 66.0658 14.3174ZM66.0658 22.9078C64.4873 22.9078 63.2023 21.6228 63.2023 20.0443C63.2023 18.4658 64.4873 17.1808 66.0658 17.1808C67.6443 17.1808 68.9292 18.4658 68.9292 20.0443C68.9292 21.6228 67.6443 22.9078 66.0658 22.9078Z"
        fill="#7355FB"
      />
      <path
        d="M44.5899 8.59051H56.0438C56.8348 8.59051 57.4755 7.94981 57.4755 7.15878C57.4755 6.36775 56.8348 5.72705 56.0438 5.72705H44.5899C43.7989 5.72705 43.1582 6.36775 43.1582 7.15878C43.1582 7.94981 43.7989 8.59051 44.5899 8.59051Z"
        fill="#7355FB"
      />
      <path
        d="M44.5899 22.9074H56.0438C56.8348 22.9074 57.4755 22.2667 57.4755 21.4757C57.4755 20.6846 56.8348 20.0439 56.0438 20.0439H44.5899C43.7989 20.0439 43.1582 20.6846 43.1582 21.4757C43.1582 22.2667 43.7989 22.9074 44.5899 22.9074Z"
        fill="#7355FB"
      />
      <path
        d="M61.7718 34.3613H41.7276C40.9366 34.3613 40.2959 35.002 40.2959 35.7931C40.2959 36.5841 40.9366 37.2248 41.7276 37.2248H61.7718C64.1413 37.2248 66.067 39.1505 66.067 41.52V50.1103C66.067 52.4798 64.1413 54.4055 61.7718 54.4055H44.5911C43.8001 54.4055 43.1594 55.0462 43.1594 55.8373C43.1594 56.6283 43.8001 57.269 44.5911 57.269H61.7718C65.7163 57.269 68.9305 54.0548 68.9305 50.1103V41.52C68.9305 37.5755 65.7163 34.3613 61.7718 34.3613Z"
        fill="#7355FB"
      />
    </svg>
  </SvgIcon>
);

import { styled } from "@mui/material";
import React from "react";
import { PwaPrompt } from "react-ios-pwa-prompt-ts";

const StyledPwaPrompt = styled(PwaPrompt, {
  shouldForwardProp: (prop) => prop !== "maxWidth",
})<{ maxWidth?: number }>(({ maxWidth }) => ({
  maxWidth: maxWidth ? `${maxWidth}px` : "580px",
}));

interface PwaPromptProps {
  isOpen: boolean;
  onClose: () => void;
  delay?: number;
  maxWidth?: number;
}

export const DownloadPWAModal: React.FC<PwaPromptProps> = ({
  isOpen,
  onClose,
  delay,
  maxWidth,
}) => {
  return (
    <StyledPwaPrompt
      copyTitle="Descargar App"
      copyClosePrompt="Cancelar"
      copyBody="Puedes añadir MekiDoc a tu pantalla de inicio para acceder más rápido."
      copyShareButtonLabel='Presiona el botón "compartir" en la barra inferior.'
      copyAddHomeButtonLabel='Selecciona "Agregar a la pantalla de inicio".'
      isOpen={isOpen}
      onClose={onClose}
      delay={delay}
      maxWidth={maxWidth}
    />
  );
};

export function numberFormat(input: number): string {
  return String(input.toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

export function extractNumber(value: string): number {
  const match = value.match(/(\d+([,.]\d+)?)/);
  if (match) {
    return parseFloat(match[0].replace(",", "."));
  }
  return 0;
}

export function parseDecimalInput(value: string | number) {
  const decimalWithDot = String(value).replace(/,/g, ".");
  return parseFloat(decimalWithDot);
}

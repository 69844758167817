import { Dispatch, SetStateAction, ChangeEvent, useCallback } from "react";
import {
  AppBar as AppBarMui,
  Box,
  FormControl,
  IconButton,
  OutlinedInput,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { scrollToTop, NavigateBeforeButton } from "@components";
import { theme } from "../config";

const AppBar = styled(AppBarMui)({
  width: "100%",
  top: 0,
  zIndex: 1,
  padding: "12px",
  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
  backgroundColor: "white",
});

const StyledForm = styled("form")({
  width: "100%",
});

const HeaderContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "12px",
});

const TextInput = styled(OutlinedInput)({
  height: "40px",
  fontSize: "16px",
  paddingRight: 0,
  "& input": {
    height: "35px",
    padding: "9px",
  },
  color: theme.pallete.text.gray,
});

interface SearchHeaderProps {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  onClick: () => void;
  handleBack: () => void;
  placeholder: string;
}

function handleChange(setter: Dispatch<SetStateAction<string>>) {
  return function (event: ChangeEvent<HTMLInputElement>) {
    setter(event.target.value);
  };
}

export function SearchHeader({
  searchText,
  setSearchText,
  onClick,
  handleBack,
  placeholder,
}: SearchHeaderProps) {
  const handleEmptySearch = useCallback(() => {
    setSearchText("");
    scrollToTop();
  }, [setSearchText]);

  const handleDelete = useCallback(() => {
    setSearchText("");
    scrollToTop();
  }, [setSearchText]);

  return (
    <AppBar position="sticky" color="transparent">
      <HeaderContainer>
        <NavigateBeforeButton onClick={handleBack} />
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            e.currentTarget.querySelector("input")?.blur();
          }}
        >
          <FormControl fullWidth>
            <TextInput
              value={searchText}
              onChange={handleChange(setSearchText)}
              onClick={onClick}
              placeholder={placeholder}
              endAdornment={
                searchText ? (
                  <IconButton onClick={() => handleDelete()}>
                    <CloseIcon style={{ color: theme.pallete.text.black }} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleEmptySearch()}>
                    <SearchIcon style={{ color: theme.pallete.text.black }} />
                  </IconButton>
                )
              }
            />
          </FormControl>
        </StyledForm>
      </HeaderContainer>
    </AppBar>
  );
}

export const awsconfig = {
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_AWS_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_AMPLIFY_AUTH_FLOW_TYPE,
  },
};
export const static_assets_bucket_prefix =
  process.env.REACT_APP_STATIC_ASSETS_BUCKET_PREFIX ?? "";

import {
  Alert,
  Box,
  CircularProgress,
  Typography,
  lighten,
  styled,
} from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { theme as oldTheme } from "../../../config";
import { useStartEsignFlow } from "../../../hooks";

const StyledAlert = styled(Alert)({
  display: "flex",
  alignItems: "center",
  position: "sticky",
  justifySelf: "flex-end",
  alignSelf: "center",
  maxWidth: "95%",
  margin: "16px 0px",
  backgroundColor: "#E8E5F5",
  "&:hover": {
    backgroundColor: lighten(oldTheme.pallete.text.primary, 0.8),
    cursor: "pointer",
  },
  "& .MuiAlert-message": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "4px",
  },
  "& .MuiAlert-icon": {
    color: oldTheme.pallete.text.primary,
  },
});

const InfoContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const Title = styled(Typography)({
  color: "#302D84",
});

const InfoText = styled(Typography)({
  fontSize: "0.75rem",
  color: oldTheme.pallete.text.black,
});

export const PendingFeaAuthAlert: React.FC = () => {
  const {
    startEsignFlowMutation: { mutate: startEsignFlow },
    isStartingEsignFlow,
  } = useStartEsignFlow();
  return (
    <StyledAlert severity="info" onClick={() => startEsignFlow({})}>
      <InfoContainer>
        <Title>Firma electrónica pendiente</Title>
        <InfoText>
          Este proceso se hace solo una vez para poder emitir recetas
        </InfoText>
      </InfoContainer>
      {isStartingEsignFlow ? (
        <CircularProgress
          size={24}
          sx={{ marginLeft: "32px", marginRight: "4px" }}
        />
      ) : (
        <ArrowForwardIosRoundedIcon
          sx={{
            marginLeft: "32px",
            marginRight: "4px",
            color: oldTheme.pallete.text.primaryDark,
          }}
        />
      )}
    </StyledAlert>
  );
};

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { api } from "../context";
import { DocumentsPayload } from "../interfaces";

export const useRequestSummary = () => {
  const { requestId = "" } = useParams();
  return useQuery({
    queryKey: ["requestSummary", requestId],
    queryFn: () =>
      api<DocumentsPayload>({
        method: "get",
        url: `/requests/${requestId}/summary`,
      }),
    enabled: Boolean(requestId),
  });
};

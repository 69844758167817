import { IconButton, styled } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { theme as OldTheme } from "../../config";

const StyledIconButton = styled(IconButton)({
  padding: 0,
});
const StyledNavigateBeforeIcon = styled(NavigateBeforeIcon)({
  color: OldTheme.pallete.text.black,
  fontSize: "30px",
});

interface NavigateBeforeButtonProps {
  onClick: () => void;
}

export const NavigateBeforeButton = ({
  onClick,
}: NavigateBeforeButtonProps) => {
  return (
    <StyledIconButton onClick={onClick}>
      <StyledNavigateBeforeIcon />
    </StyledIconButton>
  );
};

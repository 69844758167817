import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Skeleton, Typography, styled } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ItemType,
  MedicineSearch,
  SearchGeneric,
  SearchProduct,
} from "@interfaces";
import { SecondaryActionButton } from "@components";
import { SearchedMedicineCard } from "./components";
import { parseMedicinesText, sortMedicineFuseResult } from "@utils";
import { theme } from "@config";
import Fuse from "fuse.js";

const SuggestionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  margin: "0px -16px",
});

const SectionTitle = styled(Typography)({
  fontSize: "14px",
  fontWeight: 600,
  color: theme.pallete.text.black,
  padding: "12px 8px",
});

const CenterdColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
  color: theme.pallete.text.gray,
});

const EmptyRequest = styled(CenterdColumn)({
  paddingTop: "30vh",
  gap: "16px",
});

const StyledInfoIcon = styled(InfoOutlinedIcon)({
  fontSize: 24,
});

const AlternativeViewerListItem = styled(Box)({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "space-between",
  padding: "0px",
  cursor: "pointer",
  border: "1px solid #eaeaea",
  borderBottom: "none",
  "&:last-child": {
    borderBottom: "1px solid #eaeaea",
  },
});

const SkeletonsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "768px",
  width: "100%",
  backgroundColor: "#F5F5F5",
});

const SkeletonText = styled(Skeleton)({
  width: "150px",
  height: "20px",
  margin: "12px 8px",
});

function sortProducts(products: SearchProduct[], searchText: string) {
  const renamedProducts = parseMedicinesText(products) as {
    text: string;
    medicine: SearchProduct;
  }[];

  const fuse = new Fuse(renamedProducts, {
    keys: ["text"],
    includeScore: true,
    threshold: 0.1, // TODO: Tantear si este valor es el correcto
  });

  const [activePrinciple, concentration] = searchText
    .split("·")
    .map((part) => part.trim());
  const searchedMedicine = `${activePrinciple} · ${concentration}`;

  const filteredRenamedProducts = fuse.search(searchedMedicine);

  const bestMatches = sortMedicineFuseResult(filteredRenamedProducts);
  const bestMatchIds = new Set(bestMatches.map((product) => product.id));
  const otherAlternatives = products.filter(
    (product) => !bestMatchIds.has(product.id)
  );
  return [...bestMatches, ...otherAlternatives];
}

interface MedicineSearchResultsProps {
  searchText: string;
  data: MedicineSearch;
  onGenericMedicineClick: (product: SearchGeneric) => void;
  onMedicineClick: (product: SearchProduct) => void;
}

export function MedicineSearchResults(props: MedicineSearchResultsProps) {
  const navigate = useNavigate();
  const { data, searchText, onGenericMedicineClick, onMedicineClick } = props;

  const products = (data?.finalSearchResults.processedMedicines.filter(
    (medicine) => medicine.type !== ItemType.GENERIC
  ) ?? []) as SearchProduct[];
  const productsPerPAC: Record<string, number> = {};
  products.forEach((product) => {
    const pac = `${product.activePrinciple} ${product.concentration}`;
    if (productsPerPAC[pac]) {
      productsPerPAC[pac]++;
    } else {
      productsPerPAC[pac] = 1;
    }
  });

  const generics = (data?.finalSearchResults.processedMedicines.filter(
    (medicine) => {
      if (!medicine.activePrinciple || !medicine.concentration) return false;
      const activePrinciples = medicine.activePrinciple.split(";");
      const onlyOneProductHasSamePac =
        productsPerPAC[
          `${medicine.activePrinciple} ${medicine.concentration}`
        ] === 1;

      return (
        medicine.type === ItemType.GENERIC &&
        activePrinciples.length < 4 &&
        !onlyOneProductHasSamePac
      );
    }
  ) ?? []) as SearchGeneric[];

  const noResult = products.length === 0 && generics.length === 0;

  const handleAddMedicineManually = useCallback(() => {
    navigate("../añadir-manual", {
      state: {
        searchedQuery: searchText,
      },
    });
  }, [navigate, searchText]);

  if (noResult) {
    return (
      <EmptyRequest>
        <CenterdColumn>
          <StyledInfoIcon />
          No hay resultados para tu búsqueda
        </CenterdColumn>
        <SecondaryActionButton
          onClick={handleAddMedicineManually}
          textDecoration="underline"
        >
          Agregar medicamento manualmente
        </SecondaryActionButton>
      </EmptyRequest>
    );
  }

  return (
    <SuggestionContainer>
      {generics.length > 0 && <SectionTitle>Principio activo</SectionTitle>}
      <SearchResultsGenericMedicine
        generics={generics}
        onClick={onGenericMedicineClick}
      />
      {products.length > 0 && <SectionTitle>Productos</SectionTitle>}
      <SearchResultsMedicines
        products={products}
        searchText={searchText}
        onClick={onMedicineClick}
      />
    </SuggestionContainer>
  );
}

interface SearchResultsGenericMedicineProps {
  generics: SearchGeneric[];
  onClick: (product: SearchGeneric) => void;
}

const SearchResultsGenericMedicine: React.FC<
  SearchResultsGenericMedicineProps
> = ({ generics, onClick }) => {
  return (
    <div>
      {generics.map((generic) => {
        return (
          <AlternativeViewerListItem
            key={`${generic.activePrinciple}-${generic.concentration}`}
          >
            <SearchedMedicineCard
              key={`${generic.activePrinciple}-${generic.concentration}`}
              medicine={generic}
              onClick={() => onClick(generic)}
            />
          </AlternativeViewerListItem>
        );
      })}
    </div>
  );
};

interface SearchResultsMedicinesProps {
  products: SearchProduct[];
  searchText: string;
  onClick: (product: SearchProduct) => void;
}

const SearchResultsMedicines: React.FC<SearchResultsMedicinesProps> = ({
  products,
  searchText,
  onClick,
}) => {
  function renderMedicineCard(product: SearchProduct) {
    return (
      <AlternativeViewerListItem key={product.id}>
        <SearchedMedicineCard
          key={product.id}
          medicine={product}
          onClick={() => onClick(product)}
        />
      </AlternativeViewerListItem>
    );
  }

  function filterCENABASTOnlyIfAnotherProductExists(
    products: SearchProduct[]
  ): SearchProduct[] {
    const productsSet = new Set(products.map((product) => product.name));
    // Filter CENABAST products only if there is another product with the same name
    const noCenabastProducts = products.filter((product) => {
      const isCenabastProduct = product.name.includes("CENABAST");
      if (!isCenabastProduct) {
        return true;
      }
      const nameWithoutCENABAST = product.name.replace("CENABAST", "").trim();
      return !productsSet.has(nameWithoutCENABAST);
    });
    return noCenabastProducts;
  }

  const sortedProducts = sortProducts(products, searchText);

  return (
    <div>
      {filterCENABASTOnlyIfAnotherProductExists(sortedProducts).map(
        renderMedicineCard
      )}
    </div>
  );
};

MedicineSearchResults.Skeleton = () => {
  return (
    <SkeletonsContainer>
      <SkeletonText variant="text" />
      <SearchedMedicineCard.Skeleton showImageSkeleton={false} />
      <SkeletonText variant="text" />
      <SearchedMedicineCard.Skeleton />
      <SearchedMedicineCard.Skeleton />
      <SearchedMedicineCard.Skeleton />
      <SearchedMedicineCard.Skeleton />
      <SearchedMedicineCard.Skeleton />
    </SkeletonsContainer>
  );
};

import { useCallback, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import ReactSignatureCanvas from "react-signature-canvas";
import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { Global } from "@emotion/react";
import { theme } from "../config";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  Header,
  NavigateBeforeButton,
  SecondaryActionButton,
} from ".";
import { api, queryClient, useDoctorContext } from "../context";
import { DocumentCreationButtonAction, RequestType } from "../interfaces";
import { useMutation } from "@tanstack/react-query";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  gap: "0.5rem",
  paddingTop: "1rem",
});

const SignTitle = styled(Typography)({
  fontWeight: 400,
  fontSize: "1rem",
  color: theme.pallete.text.gray,
});

const ButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

const SignatureWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const SignatureCanvasContainer = styled(Box)({
  border: "1px solid #c5c5c5",
  backgroundColor: "#ffffff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "348px",
  padding: "4px",
});

const SignatureImg = styled("img")({
  width: "50%",
  height: "50%",
});

const CleanSignButton = styled(SecondaryActionButton)({
  fontSize: "0.875rem",
  minWidth: "85px",
  padding: "8px",
});

const ErrorMessage = styled(Typography)({
  color: theme.pallete.errors.primary,
  fontSize: "0.75rem",
  paddingTop: "0.25rem",
});

interface Props {
  documentType: RequestType;
  handleCreateDocument: () => void;
  handleBack: () => void;
}

export function DigitalSign({
  documentType,
  handleCreateDocument: createDocument,
  handleBack,
}: Props) {
  const doctor = useDoctorContext();

  const [signatureCanvas, setSignatureCanvas] =
    useState<ReactSignatureCanvas>();
  const [digitalSignUrl, setDigitalSignUrl] = useState(doctor.signature ?? "");
  const [canSubmit, setCanSubmit] = useState(Boolean(digitalSignUrl));

  const {
    mutate: updateSignAndCreateDocument,
    isPending,
    isError,
  } = useMutation({
    mutationFn: (signature: string) =>
      api({
        url: `/doctors/${doctor.code}`,
        method: "put",
        data: { signature },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["doctor"],
      });
      createDocument();
    },
  });

  const handleClear = useCallback(() => {
    signatureCanvas?.clear();
    setDigitalSignUrl("");
    setCanSubmit(false);
  }, [signatureCanvas]);

  const handleSave = useCallback(async () => {
    const newSignatureDataUrl = signatureCanvas
      ?.getTrimmedCanvas()
      .toDataURL("image/png");
    if (newSignatureDataUrl) {
      setDigitalSignUrl(newSignatureDataUrl);
      updateSignAndCreateDocument(newSignatureDataUrl);
    } else {
      createDocument();
    }
  }, [signatureCanvas, updateSignAndCreateDocument, createDocument]);

  return (
    <>
      <Header
        title="Firma digital"
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <Container>
          <SignTitle>Dibuja tu firma en el recuadro blanco</SignTitle>
          <SignatureWrapper>
            <ButtonContainer>
              <CleanSignButton
                onClick={handleClear}
                disabled={isPending || !canSubmit}
              >
                Limpiar Firma
              </CleanSignButton>
            </ButtonContainer>
            <SignatureCanvasContainer>
              {digitalSignUrl ? (
                <SignatureImg src={digitalSignUrl} alt="firma digital" />
              ) : (
                <SignatureCanvas
                  ref={(data: any) => {
                    setSignatureCanvas(data);
                  }}
                  onBegin={() => setCanSubmit(true)}
                  canvasProps={{
                    style: {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                />
              )}
            </SignatureCanvasContainer>
            {isError && (
              <ErrorMessage>
                Ocurrió un error al firmar el documento, por favor intentalo de
                nuevo
              </ErrorMessage>
            )}
          </SignatureWrapper>
          <BottomActionArea>
            <BottomActionButton
              onClick={handleSave}
              disabled={isPending || !canSubmit}
            >
              {isPending ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                DocumentCreationButtonAction[documentType]
              )}
            </BottomActionButton>
          </BottomActionArea>
        </Container>
      </Body>
    </>
  );
}

import { useLocation, useNavigate } from "react-router-dom";
import {
  DocumentsPayload,
  RequestStatus,
  RequestType,
} from "../../../interfaces";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  FullScreenLoader,
  Header,
  PatientSummaryAccordion,
  NavigateBeforeButton,
} from "../../../components";
import { StatusTag } from "../components";
import { HistoricSkeleton } from "./HistoricSkeleton";
import {
  HistoricCompoundedPrescription,
  HistoricMedicalCertificate,
  HistoricMedicalOrder,
  HistoricPrescription,
} from ".";
import { Global } from "@emotion/react";
import { PatientRequestCommentAccordion } from "../../../components/PatientRequestCommentAccordion";
import { Box, Skeleton, Typography, styled } from "@mui/material";
import { useRequest, useRequestSummary } from "../../../hooks";
import { theme as oldTheme } from "../../../config";
import { useRepeatRequest } from "../../../hooks/useRepeatRequest";

const HeaderWhiteSpace = styled(Box)({
  height: "20px",
});

const StatusContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: "4px",
});

const Title = styled(Typography)({
  fontWeight: 600,
});

const RejectionWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "8px 0px",
  gap: "8px",
});

const RejectionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  backgroundColor: "#ffffff",
  padding: "16px",
  gap: "16px",
  whiteSpace: "pre-line",
  color: oldTheme.pallete.text.gray,
});

export function HistoricRequest() {
  const navigate = useNavigate();
  const location = useLocation();
  const { requestType } = location.state || {};
  const { data: request } = useRequest();
  const { data: requestSummary, isFetching: isFetchingRequestSummary } =
    useRequestSummary();
  const { handleRepeatRequest, isCreatingRequest, isAddingDetail } =
    useRepeatRequest();
  const isRepeatingDocument = isCreatingRequest || isAddingDetail;
  if (isRepeatingDocument) {
    return <FullScreenLoader title="Cargando..." />;
  }

  if (request && requestSummary) {
    return (
      <>
        <Header
          title="Resumen solicitud"
          titleColor="black"
          left={
            <NavigateBeforeButton
              onClick={() => navigate(`/app/solicitudes`)}
            />
          }
        />
        <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
        <Body>
          <HeaderWhiteSpace />

          <StatusContainer>
            <Title>Estado solicitud:</Title>
            <StatusTag status={requestSummary.status} fontSize={"16px"} />
          </StatusContainer>

          <PatientSummaryAccordion
            patient={requestSummary.patient}
            isOpen={false}
            canEdit={false}
          />

          <PatientRequestCommentAccordion
            request={request}
            canReject={false}
            isExpanded={true}
          />

          {requestSummary.status === RequestStatus.REJECTED && (
            <RejectionWrapper>
              <Title>Razón de rechazo:</Title>
              <RejectionContainer>
                <Typography>{requestSummary.indications}</Typography>
              </RejectionContainer>
            </RejectionWrapper>
          )}

          {requestSummary.status === RequestStatus.ACCEPTED && (
            <>
              <RenderHistoricComponent
                requestSummary={requestSummary}
                isFetchingRequestSummary={isFetchingRequestSummary}
              />
              <BottomActionArea>
                <BottomActionButton
                  onClick={() => handleRepeatRequest(requestSummary)}
                  disabled={!Boolean(requestSummary) || isRepeatingDocument}
                >
                  Repetir solicitud
                </BottomActionButton>
              </BottomActionArea>
            </>
          )}
        </Body>
      </>
    );
  } else {
    return <HistoricRequest.Skeleton requestType={requestType} />;
  }
}

HistoricRequest.Skeleton = function HistoricRequestSkeleton({
  requestType,
}: {
  requestType: RequestType;
}) {
  const navigate = useNavigate();
  return (
    <>
      <Header
        title={"Resumen solicitud"}
        titleColor="black"
        left={
          <NavigateBeforeButton onClick={() => navigate(`/app/solicitudes`)} />
        }
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <HeaderWhiteSpace />

        <StatusContainer>
          <Title>Estado solicitud:</Title>
          <Skeleton variant="rectangular" height={24} width={90} />
        </StatusContainer>

        <PatientSummaryAccordion.Skeleton />

        <PatientRequestCommentAccordion.Skeleton />

        <HistoricSkeleton requestType={requestType} />
      </Body>
    </>
  );
};

interface RenderHistoricComponentProps {
  requestSummary: DocumentsPayload;
  isFetchingRequestSummary: boolean;
}

export function RenderHistoricComponent({
  requestSummary,
  isFetchingRequestSummary,
}: RenderHistoricComponentProps) {
  switch (requestSummary.documentType) {
    case RequestType.PRESCRIPTION:
      return (
        <HistoricPrescription
          data={requestSummary}
          isFetching={isFetchingRequestSummary}
        />
      );
    case RequestType.COMPOUNDED_PRESCRIPTION:
      return (
        <HistoricCompoundedPrescription
          data={requestSummary}
          isFetching={isFetchingRequestSummary}
        />
      );
    case RequestType.MEDICAL_CERTIFICATE:
      return <HistoricMedicalCertificate data={requestSummary} />;
    case RequestType.MEDICAL_ORDER:
      return <HistoricMedicalOrder data={requestSummary} />;
    default:
      return <></>;
  }
}

import { SvgIcon, SvgIconProps } from "@mui/material";

export const FeaIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width={63}
      height={75}
      viewBox="0 0 63 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.6446 22.583H5.35592C2.70041 22.583 0.541992 24.744 0.541992 27.3995V43.9919C0.541992 46.6474 2.70041 48.8084 5.35592 48.8084H57.6446C60.3001 48.8084 62.4585 46.6474 62.4585 43.9919V27.3995C62.4585 24.744 60.3001 22.583 57.6446 22.583ZM59.8422 43.9893C59.8422 45.2032 58.8559 46.1896 57.6446 46.1896H5.35592C4.14459 46.1896 3.15826 45.2032 3.15826 43.9893V27.3969C3.15826 26.183 4.14459 25.1967 5.35592 25.1967H57.6446C58.8559 25.1967 59.8422 26.183 59.8422 27.3969V43.9893Z"
        fill="#7355FB"
      />
      <path
        d="M21.6294 32.4052C21.2683 31.7799 20.4677 31.5653 19.8425 31.9264L17.2524 33.4203V30.4273C17.2524 29.7052 16.6663 29.1191 15.9442 29.1191C15.2221 29.1191 14.6361 29.7052 14.6361 30.4273V33.4203L12.046 31.9264C11.4233 31.568 10.6201 31.7773 10.2591 32.4052C9.89542 33.0305 10.11 33.831 10.7379 34.1947L13.328 35.6886L10.7379 37.1851C10.1126 37.5487 9.89804 38.3467 10.2591 38.9746C10.4998 39.3932 10.9419 39.6287 11.3919 39.6287C11.6143 39.6287 11.8393 39.5737 12.046 39.4534L14.6361 37.9595V40.9525C14.6361 41.6746 15.2221 42.2606 15.9442 42.2606C16.6663 42.2606 17.2524 41.6746 17.2524 40.9525V37.9595L19.8425 39.4534C20.0491 39.5737 20.2741 39.6287 20.4965 39.6287C20.9465 39.6287 21.3887 39.3932 21.6294 38.9746C21.993 38.3493 21.7785 37.5487 21.1506 37.1851L18.5605 35.6886L21.1506 34.1947C21.7759 33.831 21.9904 33.0305 21.6294 32.4052Z"
        fill="#7355FB"
      />
      <path
        d="M37.1841 32.4052C36.8256 31.7799 36.0251 31.5653 35.3971 31.9264L32.807 33.4203V30.4273C32.807 29.7052 32.221 29.1191 31.4989 29.1191C30.7768 29.1191 30.1908 29.7052 30.1908 30.4273V33.4203L27.6007 31.9264C26.9754 31.568 26.1748 31.7773 25.8138 32.4052C25.4501 33.0305 25.6646 33.831 26.2925 34.1947L28.8826 35.6886L26.2925 37.1851C25.6673 37.5487 25.4527 38.3467 25.8138 38.9746C26.0545 39.3932 26.4966 39.6287 26.9466 39.6287C27.169 39.6287 27.394 39.5737 27.6007 39.4534L30.1908 37.9595V40.9525C30.1908 41.6746 30.7768 42.2606 31.4989 42.2606C32.221 42.2606 32.807 41.6746 32.807 40.9525V37.9595L35.3971 39.4534C35.6038 39.5737 35.8288 39.6287 36.0512 39.6287C36.5012 39.6287 36.9434 39.3932 37.1841 38.9746C37.5477 38.3493 37.3332 37.5487 36.7053 37.1851L34.1152 35.6886L36.7053 34.1947C37.3332 33.831 37.5477 33.0305 37.1841 32.4052Z"
        fill="#7355FB"
      />
      <path
        d="M52.7362 32.4052C52.3751 31.7773 51.5745 31.5653 50.9493 31.9264L48.3539 33.4229V30.4273C48.3539 29.7052 47.7679 29.1191 47.0458 29.1191C46.3237 29.1191 45.7377 29.7052 45.7377 30.4273V33.4203L43.1476 31.9264C42.5249 31.568 41.7217 31.7773 41.3606 32.4052C40.997 33.0305 41.2115 33.831 41.8394 34.1947L44.4321 35.6886L41.8394 37.1851C41.2141 37.5487 40.9996 38.3467 41.3606 38.9746C41.6013 39.3932 42.0409 39.6287 42.4935 39.6287C42.7159 39.6287 42.9409 39.5737 43.1476 39.4534L45.7377 37.9595V40.9525C45.7377 41.6746 46.3237 42.2606 47.0458 42.2606C47.7679 42.2606 48.3539 41.6746 48.3539 40.9525V37.9569L50.9493 39.4534C51.1559 39.5737 51.3809 39.6287 51.6033 39.6287C52.0533 39.6287 52.4955 39.3932 52.7362 38.9746C53.0998 38.3493 52.8853 37.5487 52.2574 37.1851L49.6647 35.6886L52.2574 34.1947C52.8853 33.831 53.0998 33.0305 52.7362 32.4052Z"
        fill="#7355FB"
      />
      <path
        d="M7.47107 19.9673V9.45256C7.47107 5.68253 10.5373 2.61626 14.3074 2.61626H48.6903C52.4577 2.61626 55.524 5.68253 55.524 9.45256V19.9673H57.6458C57.8132 19.9673 57.9754 20.0066 58.1403 20.0197V9.45256C58.1403 4.24097 53.9019 0 48.6903 0H14.31C9.09839 0 4.85742 4.24097 4.85742 9.45256V20.017C5.02486 20.0066 5.18707 19.9647 5.35713 19.9647H7.47107V19.9673Z"
        fill="#7355FB"
      />
      <path
        d="M55.5273 51.422V58.9411H7.47173V51.422H5.35518C5.18512 51.422 5.02291 51.3801 4.85547 51.3696V65.5472C4.85547 70.7588 9.09644 74.9997 14.308 74.9997H48.691C53.9026 74.9997 58.1409 70.7588 58.1409 65.5472V51.3696C57.9761 51.3827 57.8139 51.422 57.6465 51.422H55.5273ZM55.5273 65.5498C55.5273 69.3198 52.461 72.3861 48.6936 72.3861H14.3106C10.5406 72.3861 7.47435 69.3198 7.47435 65.5498V61.56H55.5273V65.5498Z"
        fill="#7355FB"
      />
      <path
        d="M27.0884 68.2818H35.9104C36.6325 68.2818 37.2186 67.6957 37.2186 66.9737C37.2186 66.2516 36.6325 65.6655 35.9104 65.6655H27.0884C26.3663 65.6655 25.7803 66.2516 25.7803 66.9737C25.7803 67.6957 26.3663 68.2818 27.0884 68.2818Z"
        fill="#7355FB"
      />
    </svg>
  </SvgIcon>
);

import { Box, InputLabel, Typography, styled } from "@mui/material";
import { theme } from "../config";
import { PropsWithChildren } from "react";

interface InputFieldWrapperProps {
  label: string;
  error?: string;
}

const Container = styled(Box)({
  position: "relative",
});

const StyledInputLabel = styled(InputLabel)({
  fontWeight: 500,
  color: theme.pallete.text.black,
  paddingBottom: "0.25rem",
});

const ErrorMessage = styled(Typography)({
  position: "absolute",
  fontSize: "0.75rem",
  color: theme.pallete.errors.primary,
});

export const InputFieldWrapper: React.FC<
  PropsWithChildren<InputFieldWrapperProps>
> = ({ label, error, children }) => {
  return (
    <Container>
      {Boolean(label) && <StyledInputLabel>{label}</StyledInputLabel>}
      {children}
      {Boolean(error) && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

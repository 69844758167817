import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material";
import { BasicButtonOverrides } from "../styles";
import { useAuth } from "@contexts";
import { useIsScreenBiggerThanSM } from "@hooks";
import { theme as oldTheme } from "@config/theme";

const MekiDocIcon = styled("img")(({ theme }) => ({
  width: "128px",
  height: "23px",
  [theme.breakpoints.up("md")]: {
    width: "165px",
    height: "30px",
  },
}));

const HeaderContainer = styled("header")({
  display: "flex",
  width: "100%",
  height: "44px",
  justifyContent: "space-between",
  alignItems: "center",
});

const CallToActionButtonsContainer = styled("div")({
  display: "flex",
  gap: "32px",
});

const PharmacyAccessButton = styled(BasicButtonOverrides)({
  color: oldTheme.pallete.text.secondaryDark,
  backgroundColor: "transparent",
});

const LoginButton = styled(BasicButtonOverrides)(({ theme }) => ({
  width: "100px",
  color: oldTheme.pallete.text.secondaryDark,
  backgroundColor: oldTheme.pallete.background.white,
  boxShadow:
    "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
  "&:hover": {
    cursor: "pointer",
    color: oldTheme.pallete.background.white,
    backgroundColor: oldTheme.pallete.text.primary,
  },
  [theme.breakpoints.up("sm")]: {
    width: "138px",
  },
}));

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const { isUserAuthed } = useAuth();
  const handleLoginButtonClick = useCallback(() => {
    if (isScreenBiggerThanSM) navigate("/app");
    else navigate("/app/mobile-login");
  }, [isScreenBiggerThanSM, navigate]);
  return (
    <HeaderContainer>
      <MekiDocIcon src="/landing/meki_doc_icon.svg" />
      <CallToActionButtonsContainer>
        {isScreenBiggerThanSM && !isUserAuthed && (
          <PharmacyAccessButton onClick={() => navigate("/app/farmacia")}>
            Farmacias
          </PharmacyAccessButton>
        )}
        {!isUserAuthed && (
          <LoginButton onClick={handleLoginButtonClick} variant="outlined">
            {isScreenBiggerThanSM ? "Doctores" : "Iniciar"}
          </LoginButton>
        )}
      </CallToActionButtonsContainer>
    </HeaderContainer>
  );
};

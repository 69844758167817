import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/material";
import {
  CardInfoPreLineText,
  CardInfoRow,
  CardInfoText,
  GappedCardContainer,
} from "../styles";
import {
  CommonPrescription,
  DocumentProps,
  HistoricRequestCardDetails,
  PrescriptionDetail,
  RequestType,
} from "@interfaces";
import { PDFViewerProps, SecondaryActionButton, UserRole } from "@components";
import { getUnitForQuantity, numberToSpanishWord } from "@utils";
import { theme } from "../../../config";

const GappedBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "2px",
});

const ShowPrescriptionButton = styled(SecondaryActionButton)({
  width: "90px",
  minWidth: "auto",
  justifyContent: "flex-start",
  padding: "0px",
});

const DetailSubtitle = styled(CardInfoText)({
  color: theme.pallete.text.gray,
});

export const DetailsCard: React.FC<{
  prescriptionData: Pick<
    CommonPrescription,
    | "requestId"
    | "id"
    | "type"
    | "prescriptionStatus"
    | "details"
    | "holdingDate"
  >;
  prescriptionPDF: DocumentProps;
}> = ({
  prescriptionData: {
    requestId,
    id: prescriptionId,
    type: prescriptionType,
    prescriptionStatus,
    details: prescriptionDetails,
    holdingDate,
  },
  prescriptionPDF,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrescription, setShowPrescription] = useState(false);
  const isCompoundedPrescription =
    prescriptionType === RequestType.COMPOUNDED_PRESCRIPTION;
  const prescriptionTitle = isCompoundedPrescription
    ? "Receta magistral"
    : prescriptionType === "SIMPLE"
    ? "Receta simple"
    : "Receta retenida";
  const reducedPrescriptionData = {
    requestId,
    prescriptionId,
    prescriptionStatus,
    holdingDate,
    isCompoundedPrescription,
  };
  if (showPrescription) {
    const navigationState: PDFViewerProps = {
      documentProps: prescriptionPDF,
      pdfRenderingProps: {
        title: prescriptionTitle,
        prevRoute: location.pathname,
      },
      prescriptionData: reducedPrescriptionData,
      userRole: UserRole.CHEMIST,
    };
    navigate("/app/visualizacion-documento", {
      state: navigationState,
    });
  }

  return (
    <GappedCardContainer padding={"8px 16px"} gap={"8px"}>
      {Array.isArray(prescriptionDetails) ? (
        prescriptionDetails.map((data) => (
          <MedicineDetail key={data.id} data={data} />
        ))
      ) : (
        <MedicineDetail data={prescriptionDetails} />
      )}
      <ShowPrescriptionButton
        onClick={() => setShowPrescription((prevState) => !prevState)}
      >
        Ver Receta
      </ShowPrescriptionButton>
    </GappedCardContainer>
  );
};

const MedicineDetail: React.FC<{
  data: HistoricRequestCardDetails;
}> = ({
  data: {
    activePrinciple,
    concentration,
    compoundedIngredients,
    posologyQuantity,
    posologyFormat,
    frequencyQuantity,
    frequencyPeriod,
    durationQuantity,
    durationPeriod,
    quantityToDispense = 0,
  },
}) => {
  const { dose, treatment, dispensation } = getPrescriptionDetails({
    posologyQuantity,
    posologyFormat,
    frequencyQuantity,
    frequencyPeriod,
    durationQuantity,
    durationPeriod,
    quantityToDispense,
  });
  return (
    <GappedBox>
      <CardInfoText paddingTop={"0px"}>
        {activePrinciple} {concentration}
      </CardInfoText>

      {compoundedIngredients && (
        <CardInfoPreLineText paddingTop={"0px"}>
          {compoundedIngredients}
        </CardInfoPreLineText>
      )}

      <CardInfoRow>
        <DetailSubtitle>{dose}</DetailSubtitle>
        <DetailSubtitle>{treatment}</DetailSubtitle>
      </CardInfoRow>

      {Boolean(dispensation) && (
        <CardInfoRow>
          <DetailSubtitle>{dispensation}</DetailSubtitle>
        </CardInfoRow>
      )}
    </GappedBox>
  );
};

function getPrescriptionDetails({
  posologyQuantity,
  posologyFormat,
  frequencyQuantity,
  frequencyPeriod,
  durationQuantity,
  durationPeriod,
  quantityToDispense,
}: Pick<
  PrescriptionDetail,
  | "posologyQuantity"
  | "posologyFormat"
  | "frequencyQuantity"
  | "frequencyPeriod"
  | "durationQuantity"
  | "durationPeriod"
  | "quantityToDispense"
>) {
  const parsedPosologyFormat = getUnitForQuantity(
    posologyFormat,
    posologyQuantity
  );
  const parsedFrequencyPeriod = getUnitForQuantity(
    frequencyPeriod,
    frequencyQuantity
  );
  const parsedDurationPeriod = getUnitForQuantity(
    durationPeriod,
    durationQuantity
  );

  const isTabletOrCapsule = ["comprimido", "cápsula"].includes(posologyFormat);
  const parsedQuantityToDispense = quantityToDispense
    ? isTabletOrCapsule
      ? `Cantidad a dispensar: ${quantityToDispense} (${numberToSpanishWord(
          quantityToDispense
        )}) ${getUnitForQuantity("caja", quantityToDispense)}`
      : `Cantidad a dispensar: ${quantityToDispense} (${numberToSpanishWord(
          quantityToDispense
        )}) ${getUnitForQuantity("unidad", quantityToDispense)}`
    : "";

  return {
    dose: `${posologyQuantity} ${parsedPosologyFormat} cada ${frequencyQuantity} ${parsedFrequencyPeriod}`,
    treatment: `por ${durationQuantity} ${parsedDurationPeriod}`,
    dispensation: parsedQuantityToDispense,
  };
}

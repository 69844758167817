import { Box, Typography, lighten, styled } from "@mui/material";
import { theme as oldTheme } from "../config";
import format from "date-fns/format";
import {
  PrescriptionStatus,
  PrescriptionStatusChipActionMapper,
  PrescriptionStatusChipSeverityMapper,
  PrescriptionStatusChipTitleMapper,
  PrescriptionSeverity,
  CompoundedPrescriptionStatusChipTitleMapper,
} from "../interfaces";
import { CardInfoRow } from "../screens/PrescriptionValidation/styles";

type PrescriptionSeverityObj = { severity: PrescriptionSeverity };

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "status",
})<PrescriptionSeverityObj>(({ theme, severity }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  padding: "8px 16px",
  background:
    severity === "success"
      ? lighten(theme.palette.success.light, 0.9)
      : severity === "error"
      ? lighten(theme.palette.error.light, 0.9)
      : lighten(theme.palette.info.light, 0.9),
}));

const InfoContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
});

const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "severity",
})<PrescriptionSeverityObj>(({ theme, severity }) => ({
  fontWeight: 600,
  color:
    severity === "success"
      ? theme.palette.success.light
      : severity === "error"
      ? theme.palette.error.light
      : theme.palette.info.light,
}));

const InfoText = styled(Typography)({
  color: oldTheme.pallete.text.lightBlack,
});

type Props = {
  code: string;
  status: PrescriptionStatus;
  date?: number;
  isCompoundedPrescription?: boolean;
};

export const PrescriptionStatusChip: React.FC<Props> = ({
  code,
  status,
  date,
  isCompoundedPrescription,
}) => {
  const prescriptionSeverity = PrescriptionStatusChipSeverityMapper[status];
  return (
    <Container severity={prescriptionSeverity}>
      <Title severity={prescriptionSeverity}>
        {isCompoundedPrescription
          ? CompoundedPrescriptionStatusChipTitleMapper[status]
          : PrescriptionStatusChipTitleMapper[status]}
      </Title>

      <InfoContainer>
        {status !== PrescriptionStatus.ISSUED && date && (
          <CardInfoRow>
            <InfoText>
              {`Fecha ${PrescriptionStatusChipActionMapper[status]}: `}
              {format(date, "dd/MM/yyyy HH:mm:ss")}
            </InfoText>
          </CardInfoRow>
        )}
        {status !== PrescriptionStatus.CANCELED && (
          <CardInfoRow>
            <InfoText>Código verificación: {code}</InfoText>
          </CardInfoRow>
        )}
      </InfoContainer>
    </Container>
  );
};

import React, { createContext, useContext, useMemo, useState } from "react";
import { MedicineSearch } from "@interfaces";

interface MedicineSearchState {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  searchResults: MedicineSearch | undefined;
  setSearchResults: React.Dispatch<
    React.SetStateAction<MedicineSearch | undefined>
  >;
}

const MedicineSearchContext = createContext<MedicineSearchState>({
  searchResults: undefined,
  setSearchResults: () => {},
  searchText: "",
  setSearchText: () => {},
});

export function useMedicineSearchContext() {
  return useContext(MedicineSearchContext);
}

interface MedicineSearchProviderProps {
  children: React.ReactNode;
}

export function MedicineSearchProvider({
  children,
}: MedicineSearchProviderProps) {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<MedicineSearch>();

  const value = useMemo(
    () => ({
      searchText,
      setSearchText,
      searchResults,
      setSearchResults,
    }),
    [searchText, searchResults]
  );

  return (
    <MedicineSearchContext.Provider value={value}>
      {children}
    </MedicineSearchContext.Provider>
  );
}

import React, { createContext, useContext, useState } from "react";

export const SidebarMenuContext = createContext({
  isSidebarMenuOpen: false,
  setIsSidebarMenuOpen: (open: boolean) => {},
});

export const useSidebarMenuContext = () => {
  return useContext(SidebarMenuContext);
};

interface SidebarMenuContextProviderProps {
  children: React.ReactNode;
}

export const SidebarMenuContextProvider = ({
  children,
}: SidebarMenuContextProviderProps) => {
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);

  return (
    <SidebarMenuContext.Provider
      value={{ isSidebarMenuOpen, setIsSidebarMenuOpen }}
    >
      {children}
    </SidebarMenuContext.Provider>
  );
};

import { Modal } from "@components";
import { AuthStep } from "@interfaces/Auth";
import {
  AuthFeedbackCenteredContainer,
  AuthFeedbackMessage,
} from "@styles/styled";

interface AuthFeedbackModalProps {
  isOpen: boolean;
  authFeedback: string;
  authStep: AuthStep;
  feedbackIcon: (authStep: AuthStep) => JSX.Element;
}

export const AuthFeedbackModal: React.FC<AuthFeedbackModalProps> = ({
  isOpen,
  authFeedback,
  authStep,
  feedbackIcon,
}: AuthFeedbackModalProps) => {
  return (
    <Modal isOpen={isOpen}>
      <AuthFeedbackCenteredContainer>
        <AuthFeedbackMessage>{authFeedback}</AuthFeedbackMessage>
        {feedbackIcon(authStep)}
      </AuthFeedbackCenteredContainer>
    </Modal>
  );
};

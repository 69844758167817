import { PrescriptionHolding, PrescriptionStatus } from "./Prescription";
import { RequestType } from "./Request";

export interface CompoundedPrescriptionDetails {
  compoundedIngredients: string;
  posologyQuantity: number;
  posologyFormat: string;
  frequencyQuantity: number;
  frequencyPeriod: string;
  durationQuantity: number;
  durationPeriod: string;
  consumptionType: string;
  indications?: string;
}

export interface CompoundedPrescription {
  id: string;
  doctorCode: string;
  patientCode: string;
  createdAt: number;
  requestId: string;
  details: CompoundedPrescriptionDetails;
  type: RequestType.COMPOUNDED_PRESCRIPTION;
  prescriptionStatus: PrescriptionStatus;
  s3uri?: string;
  holding?: PrescriptionHolding;
  holdingDate?: number;
}

export const CompoundedPrescriptionStatusChipTitleMapper: Record<
  PrescriptionStatus,
  string
> = {
  [PrescriptionStatus.ISSUED]: "Receta magistral válida",
  [PrescriptionStatus.DISPENSED]: "Receta magistral dispensada",
  [PrescriptionStatus.HELD]: "Receta magistral retenida",
  [PrescriptionStatus.CANCELED]: "Receta magistral anulada",
};

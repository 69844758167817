import { Body } from "./Container";
import { theme } from "../config";
import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { Global } from "@emotion/react";
import { PropsWithChildren } from "react";

const CenteredContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  gap: "1rem",
});

const Title = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 400,
});

interface Props {
  title?: string;
}

export const FullScreenLoader: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
}) => {
  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <CenteredContainer>
          {title && <Title>{title}</Title>}
          {children}
          <CircularProgress
            size={24}
            style={{ color: theme.pallete.text.primary }}
          />
        </CenteredContainer>
      </Body>
    </>
  );
};

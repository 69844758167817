import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(-1000, -1000);
  }, [pathname]);

  return <></>;
}

export function scrollToTop() {
  window.scrollTo({
    top: -1000,
    left: -1000,
    behavior: "smooth",
  });
  setTimeout(() => {
    window.scrollTo({
      top: -1000,
      left: -1000,
      behavior: "smooth",
    });
  }, 100);
}

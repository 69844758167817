import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete, CircularProgress, FormControl } from "@mui/material";
import { Global } from "@emotion/react";
import { Patient, PatientBaseAttributes } from "@interfaces";
import { FormContainer } from "@styles/styled";
import {
  ErrorMessage,
  InputLabel,
  SingleInput,
  WhiteTextField,
} from "./styled";
import {
  BirthdateInput,
  Body,
  BottomActionArea,
  BottomActionButton,
  ExtraWhiteSpace,
  Header,
  InputFieldWrapper,
  SingleFormInput,
  scrollToTop,
  NavigateBeforeButton,
  PhoneInput,
} from "@components";
import { useEditPatient } from "@hooks";
import {
  capitalizeParametValue,
  getBirthdateDetails,
  handleNumericInputChange,
  parseDecimalInput,
  replaceCurrentPhoneCode,
} from "@utils";
import { validate, format } from "rut.js";
import regionsWithCommunes from "../../data/regionsWithCommunes.json";

const regions = regionsWithCommunes.map((region: any) => region.region);

interface LocationState {
  patient?: Patient;
  prevRoute?: string;
  requestId?: string;
}

export function EditPatientInfo() {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    trigger,
  } = useForm<PatientBaseAttributes>();

  const navigate = useNavigate();
  const location = useLocation();
  const {
    patient = {} as Patient,
    prevRoute = "",
    requestId = "",
  }: LocationState = location.state || {};
  const [formState, setFormState] = useState(patient);
  const [settingPatientData, setSettingPatientData] = useState<boolean>(true);
  const { mutate: editPatient, isPending } = useEditPatient(
    prevRoute,
    requestId
  );

  // RUT:
  const patientInputRut = watch("rut");
  const [showRutError, setShowRutError] = useState<boolean>(false);

  // birthdate:
  const birthdate = watch("birthdate");
  const birthdateDetails = useMemo(() => {
    return getBirthdateDetails(birthdate);
  }, [birthdate]);

  // Regions and communes:
  const selectedRegion = watch("region");
  const selectedCommune = watch("commune");
  // Show only communes for selected region:
  const communes = useMemo(() => {
    const foundRegion = regionsWithCommunes.find(
      (r) => r.region === selectedRegion
    );
    return foundRegion ? foundRegion.communes.sort() : [];
  }, [selectedRegion]);

  // Phone:
  const phoneInputRequired = Boolean(patient.phone);
  const [currentPhoneCode, setCurrentPhoneCode] = useState({
    name: "Chile",
    icon: "🇨🇱",
    prefix: "+56",
  });

  const weight = watch("weight");

  // Handle back browsing:
  const handleBack = useCallback(() => {
    if (settingPatientData) {
      navigate(prevRoute);
    } else {
      setSettingPatientData(true);
    }
    scrollToTop();
  }, [settingPatientData, prevRoute, setSettingPatientData, navigate]);

  const handleRutOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue("rut", e.target.value);
      clearErrors("rut");
      setShowRutError(false);
    },
    [setValue, clearErrors, setShowRutError]
  );

  const handleRutBlur = useCallback(() => {
    if (!patientInputRut) return;
    if (!validate(patientInputRut)) {
      setShowRutError(true);
      return;
    }
    setValue("rut", format(patientInputRut));
    clearErrors("rut");
    setShowRutError(false);
  }, [patientInputRut, setValue, clearErrors, setShowRutError]);

  const handleRutKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter") return;
      if (!patientInputRut) return;
      // Remove focus from input (to hide keyboard on mobile)
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      if (!validate(patientInputRut)) {
        setShowRutError(true);
        return;
      }
      setValue("rut", format(patientInputRut));
      clearErrors("rut");
      setShowRutError(false);
    },
    [patientInputRut, setValue, clearErrors, setShowRutError]
  );

  // TODO: Use zod to validate form
  const validateForm = useCallback(
    (data: PatientBaseAttributes) => {
      let isValid = true;
      const notRequiredKeys = [
        "weight",
        ...(phoneInputRequired ? [] : ["phone"]),
      ];
      Object.keys(formState).forEach((key) => {
        if (
          key in data &&
          !data[key as keyof PatientBaseAttributes] &&
          !notRequiredKeys.includes(key)
        ) {
          isValid = false;
        }
      });
      return isValid;
    },
    [formState, phoneInputRequired]
  );

  const onPatientDataSubmit = useCallback(
    (formData: PatientBaseAttributes) => {
      setFormState((prevState) => {
        console.log("prevState", prevState);
        console.log("onPatientDataSubmit", formData);
        return {
          ...prevState,
          ...formData,
          weight: formData.weight && formData.weight.replace(/,/g, "."),
          phone: phoneInputRequired
            ? replaceCurrentPhoneCode(formData.phone, currentPhoneCode.prefix)
            : "",
          region: formData.region !== "" ? formData.region : prevState.region,
        };
      });
      setSettingPatientData(false);
      scrollToTop();
    },
    [currentPhoneCode, phoneInputRequired, setFormState, setSettingPatientData]
  );

  const onSubmit: SubmitHandler<PatientBaseAttributes> = useCallback(
    async (data) => {
      const updateAttributes = {
        rut: data.rut,
        name: data.name,
        firstSurname: data.firstSurname,
        secondSurname: data.secondSurname,
        birthdate: data.birthdate,
        weight: data.weight && data.weight.replace(/,/g, "."),
        email: data.email,
        phone: phoneInputRequired ? currentPhoneCode.prefix + data.phone : "",
        region: data.region,
        commune: data.commune,
        street: data.street,
      } as Patient;
      if (!validateForm(updateAttributes)) return;
      editPatient({
        patientCode: patient.code,
        updates: updateAttributes,
      });
    },
    [currentPhoneCode, phoneInputRequired, patient, validateForm, editPatient]
  );

  // Fill in entries when the formState has already been established:
  useEffect(() => {
    if (formState.rut) {
      console.log("formState", formState);
      Object.entries(formState).forEach(([key, value]) => {
        setValue(key as keyof PatientBaseAttributes, value);
      });
    }
  }, [formState, setValue]);

  return (
    <>
      <Header
        title={settingPatientData ? "Datos del paciente" : "Datos de contacto"}
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          {settingPatientData ? (
            <>
              <FormControl fullWidth>
                <InputLabel>RUT del paciente</InputLabel>
                <SingleInput
                  placeholder=""
                  {...register("rut", {
                    required: "Debes escribir el RUT del paciente.",
                  })}
                  onBlur={handleRutBlur}
                  onKeyDown={handleRutKeyDown}
                  onChange={handleRutOnChange}
                  error={showRutError || !!errors["rut"]}
                />
                {showRutError && !!!errors.rut && (
                  <ErrorMessage>RUT no válido</ErrorMessage>
                )}
                {!showRutError && !!errors.rut && (
                  <ErrorMessage>{errors.rut.message}</ErrorMessage>
                )}
              </FormControl>

              <SingleFormInput
                title="Nombre"
                parameterName="name"
                errorText="Debes escribir el nombre del paciente."
                register={register}
                errors={errors}
                onBlur={() =>
                  capitalizeParametValue("name", watch, setValue, trigger)
                }
              />

              <SingleFormInput
                title="Apellido paterno"
                parameterName="firstSurname"
                errorText="Debes escribir el apellido paterno del paciente."
                register={register}
                errors={errors}
                onBlur={() =>
                  capitalizeParametValue(
                    "firstSurname",
                    watch,
                    setValue,
                    trigger
                  )
                }
              />

              <SingleFormInput
                title="Apellido materno"
                parameterName="secondSurname"
                errorText="Debes escribir el apellido materno del paciente."
                register={register}
                errors={errors}
                autoComplete="off"
                onBlur={() =>
                  capitalizeParametValue(
                    "secondSurname",
                    watch,
                    setValue,
                    trigger
                  )
                }
              />

              <BirthdateInput control={control} errorManagement={{ errors }} />

              {birthdateDetails.weightNeeded && (
                <SingleFormInput
                  title="Peso"
                  parameterName="weight"
                  inputType="number"
                  numericInputType="decimal"
                  numericExternalValue={weight}
                  handleNumericExternalValueChange={(value) =>
                    handleNumericInputChange(
                      "weight",
                      String(value),
                      clearErrors,
                      setValue
                    )
                  }
                  units="kg"
                  errorText="Debes ingresar el peso del paciente."
                  register={register}
                  errors={errors}
                  validate={(value: string) =>
                    parseDecimalInput(value) > 0 ||
                    "Debes ingresar una cantidad válida"
                  }
                />
              )}

              <ExtraWhiteSpace />

              <BottomActionArea>
                <BottomActionButton
                  onClick={handleSubmit(onPatientDataSubmit)}
                  disabled={!patientInputRut}
                >
                  Siguiente
                </BottomActionButton>
              </BottomActionArea>
            </>
          ) : (
            <>
              <SingleFormInput
                title="Correo electrónico"
                parameterName="email"
                errorText="Debes escribir el correo electrónico del paciente."
                // description="A este correo notificaremos al paciente."
                register={register}
                errors={errors}
                inputType="email"
                onBlur={() => {
                  const emailValue = watch("email");
                  if (emailValue) {
                    setValue("email", emailValue.toLowerCase());
                  }
                  trigger("email");
                }}
                validate={(value) => {
                  const emailRegex =
                    /^([a-zA-Z0-9_\-+.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
                  if (emailRegex.exec(value)) {
                    return true;
                  } else {
                    return "El correo electrónico está incompleto";
                  }
                }}
              />

              {phoneInputRequired && (
                <PhoneInput
                  register={register}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  trigger={trigger}
                  clearErrors={clearErrors}
                  phoneCodeState={{
                    currentPhoneCode,
                    setCurrentPhoneCode,
                  }}
                />
              )}

              <InputFieldWrapper label="Región" error={errors.region?.message}>
                <Controller
                  name={"region"}
                  control={control}
                  defaultValue="Metropolitana de Santiago"
                  rules={{
                    required: "Debes seleccionar la región del paciente.",
                  }}
                  render={({ ...props }) => (
                    <Autocomplete
                      disablePortal
                      value={selectedRegion}
                      options={regions}
                      getOptionLabel={(region) => region}
                      renderInput={(params) => (
                        <WhiteTextField
                          error={Boolean(errors.region)}
                          {...params}
                        />
                      )}
                      onChange={(_, data) => {
                        setValue("commune", "");
                        if (data) clearErrors("region");
                        props.field.onChange(data);
                      }}
                    />
                  )}
                />
              </InputFieldWrapper>

              <InputFieldWrapper label="Comuna" error={errors.commune?.message}>
                <Controller
                  name={"commune"}
                  control={control}
                  rules={{
                    required: "Debes seleccionar la comuna del paciente.",
                  }}
                  render={({ ...props }) => (
                    <Autocomplete
                      disablePortal
                      value={selectedCommune}
                      options={communes}
                      getOptionLabel={(commune) => commune}
                      renderInput={(params) => (
                        <WhiteTextField
                          error={Boolean(errors.commune)}
                          {...params}
                        />
                      )}
                      onChange={(_, data) => {
                        if (data) clearErrors("commune");
                        props.field.onChange(data);
                      }}
                    />
                  )}
                />
              </InputFieldWrapper>

              <SingleFormInput
                title="Dirección"
                parameterName="street"
                errorText="Debes escribir la dirección del paciente."
                // description="Ej: Av. Providencia 123"
                register={register}
                errors={errors}
              />

              <ExtraWhiteSpace />

              <BottomActionArea>
                <BottomActionButton
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={isPending}
                >
                  {isPending ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Guardar"
                  )}
                </BottomActionButton>
              </BottomActionArea>
            </>
          )}
        </FormContainer>
      </Body>
    </>
  );
}

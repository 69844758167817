import {
  PrescriptionDetail,
  SearchGeneric,
  SearchProduct,
} from "../../../interfaces";

export function generatePrescriptionDetail(
  medicine: SearchProduct | SearchGeneric,
  formData: PrescriptionDetail
) {
  const { prescriptionType } = medicine;
  console.log({
    msg: "Adding detail to prescription",
    formData,
    medicine,
    prescriptionType,
  });
  const isSearchProduct = medicine ? "name" in medicine : false;
  const otherProps = isSearchProduct
    ? {
        laboratory: (medicine as SearchProduct).laboratory,
        quantity: (medicine as SearchProduct).quantity,
        slug: (medicine as SearchProduct).slug,
      }
    : {};
  const prescriptionDetail = {
    ...formData,
    posologyQuantity: Number(formData.posologyQuantity),
    frequencyQuantity: Number(formData.frequencyQuantity),
    durationQuantity: Number(formData.durationQuantity),
    unit: medicine.unit,
    prescriptionType,
    medicine: medicine,
    ...otherProps,
  };
  return prescriptionDetail;
}

import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, TextField, styled } from "@mui/material";
import { queryClient, useDoctorContext, useFetch } from "../context";
import { theme } from "../config";
import { Modal } from ".";
import { Request } from "../interfaces";
import { useMutation } from "@tanstack/react-query";

const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "32px",
  position: "relative",
  marginBottom: "36px",
});

const CommentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const CommentLabel = styled("label")({
  display: "block",
  fontWeight: 500,
  color: theme.pallete.text.black,
  padding: "16px 0px 8px 0px",
});

const CommentsInput = styled(TextField)({
  marginTop: 0,
  "& .MuiInputBase-root": {
    padding: "8px 12px",
  },
  "& textarea": {
    minHeight: 86,
  },
});

const ErrorMessage = styled("span")({
  color: theme.pallete.errors.primary,
  fontSize: 12,
  position: "absolute",
  bottom: -20,
});

interface CancelRequestProps {
  request: Request;
  onClose: () => void;
  isOpen: boolean;
}

interface RejectRequestPayload {
  comment: string;
}

export const CancelRequestModal: React.FC<CancelRequestProps> = ({
  request,
  onClose,
  isOpen,
}) => {
  const fetch = useFetch();
  const navigate = useNavigate();
  const doctor = useDoctorContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      comment: "",
    },
  });

  const closeAndResetModal = useCallback(() => {
    clearErrors();
    onClose();
  }, [clearErrors, onClose]);

  const { mutate: rejectRequest, isPending: rejectingRequest } = useMutation({
    mutationFn: (data: RejectRequestPayload) =>
      fetch(`/requests/${request.id}/reject`, {
        method: "POST",
        body: JSON.stringify({
          doctor: doctor,
          doctorComment: data.comment,
          patient: request.patient,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["requests"],
      });
      queryClient.invalidateQueries({
        queryKey: ["request", request.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["requestSummary", request.id],
      });
      navigate(`/app/solicitudes/${request.id}/resumen`);
      closeAndResetModal();
    },
    onError: (error) => {
      console.log({
        msg: "Error canceling request",
        error,
      });
    },
  });

  const onSubmit = useCallback(
    (data: RejectRequestPayload) => {
      if (!data.comment) return;
      rejectRequest(data);
    },
    [rejectRequest]
  );

  return (
    <Modal
      title="Rechazar solicitud"
      isOpen={isOpen}
      onClose={closeAndResetModal}
      buttonProps={{
        buttonText: "Enviar",
        onClick: handleSubmit(onSubmit),
        loading: rejectingRequest,
      }}
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <CommentContainer>
          <CommentLabel>Escribe un mensaje para el paciente</CommentLabel>
          <CommentsInput
            multiline
            error={!!errors.comment}
            {...register("comment", {
              required: "Debes ingresar un mensaje",
            })}
          />
          {errors.comment && (
            <ErrorMessage>{errors.comment.message}</ErrorMessage>
          )}
        </CommentContainer>
      </FormContainer>
    </Modal>
  );
};

import { Button, styled } from "@mui/material";

export const BasicButtonOverrides = styled(Button)({
  borderRadius: "100px",
  fontSize: "1rem",
  textAlign: "center",
  textTransform: "none",
  border: "none",
  "&:hover": {
    cursor: "pointer",
    color: "inherit",
    backgroundColor: "inherit",
    boxShadow: "none",
  },
});

import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { Box, FormControl, TextField, Typography, styled } from "@mui/material";
import { theme as oldTheme } from "../config";

const InputLabel = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  paddingBottom: "4px",
});

const ItalicLabel = styled("span")({
  fontStyle: "italic",
  fontWeight: 400,
});

const TextArea = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "minHeight",
})(({ minHeight }: { minHeight: string }) => ({
  backgroundColor: oldTheme.pallete.background.white,
  minHeight: minHeight,
  "& .MuiInputBase-root": {
    minHeight: minHeight,
    paddingTop: "10px",
  },
  "& textarea": {
    height: minHeight,
    minHeight: minHeight,
  },
}));

const HelperText = styled(Typography)({
  fontSize: "14px",
  color: oldTheme.pallete.text.gray,
  marginBottom: "4px",
});

const RelativeContainer = styled(Box)({
  position: "relative",
});
const ErrorMessage = styled("span")({
  position: "absolute",
  fontSize: "12px",
  color: oldTheme.pallete.errors.primary,
});

interface TextAreaFormInputProps {
  title?: string;
  parameterName: string;
  errorText?: string;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  helperText?: string;
  minHeight?: string;
}
export const TextAreaFormInput: React.FC<TextAreaFormInputProps> = ({
  title,
  parameterName,
  errorText,
  register,
  errors,
  helperText,
  minHeight,
}) => {
  return (
    <FormControl>
      {title && (
        <InputLabel>
          {title} {!Boolean(errorText) && <ItalicLabel>(Opcional)</ItalicLabel>}
        </InputLabel>
      )}
      {helperText && <HelperText>{helperText}</HelperText>}
      <TextArea
        variant="outlined"
        error={Boolean(errors[parameterName])}
        {...register(parameterName, {
          required: errorText ?? false,
        })}
        multiline
        minHeight={minHeight ?? "200px"}
      />

      <RelativeContainer>
        {Boolean(errors[parameterName]) && (
          <ErrorMessage>
            {errors[parameterName]?.message?.toString() ??
              "Este item es obligatorio"}
          </ErrorMessage>
        )}
      </RelativeContainer>
    </FormControl>
  );
};

import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { pdfjs } from "react-pdf";
import { Box, CircularProgress, styled } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";
import { Global } from "@emotion/react";
import { theme as oldTheme } from "../../config";
import {
  PDFData,
  RequestType,
  DocumentCreationSuccessTitle,
} from "@interfaces";
import { DefaultWhiteText, StyledDocument, StyledPage } from "../styles";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  ColoredComponent,
  ExtraWhiteSpace,
  IconOptions,
  SecondaryActionButton,
  ShareDocumentsErrorModal,
} from "@components";
import {
  useGetPDFs,
  useIsScreenBiggerThanSM,
  useRequest,
  useShareOrDownloadFiles,
} from "@hooks";
import { handlePagesManagement } from "@utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ScreenWrapper = styled(Box)<{ padding?: string }>((props) => ({
  minHeight: "60vh",
  gap: "16px",
  ...(props.padding && {
    padding: props.padding,
  }),
}));

const CenteredWrapper = styled(ScreenWrapper)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  marginTop: "16px",
});

const Bold = styled("span")({
  fontWeight: 600,
});

interface Props {
  requestType: RequestType;
}

export const DocumentCreationSuccess: React.FC<Props> = ({ requestType }) => {
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const {
    convertFiles,
    isConvertingFiles,
    shareOrDownloadFilesCallback,
    isDownloadFilesFallbackActive,
    setIsDownloadFilesFallbackActive,
  } = useShareOrDownloadFiles();
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  const location = useLocation();
  const { documentPDFData }: { documentPDFData?: PDFData[] } =
    location.state || {};
  const { data: request } = useRequest();
  const {
    data: pdfData,
    isPending,
    isError,
  } = useGetPDFs({
    requestId,
    documentPDFData,
  });
  const [numPagesArray, setNumPagesArray] = useState<number[]>([]);

  const handleMainActionClick = useCallback(async () => {
    if (request && pdfData) {
      const pdfFiles = await convertFiles(pdfData);
      await shareOrDownloadFilesCallback(pdfFiles, pdfData);
    }
  }, [request, pdfData, convertFiles, shareOrDownloadFilesCallback]);

  const onGoHome = useCallback(() => {
    navigate("/app/solicitudes", {
      state: {
        documentCreated: true,
      },
    });
  }, [navigate]);

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      event.preventDefault();
      onGoHome();
    };
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [onGoHome]);

  if (isPending) {
    return (
      <CenteredWrapper>
        <CircularProgress size={24} color="primary" />
      </CenteredWrapper>
    );
  }

  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <ScreenWrapper padding={"16px 0px 0px 0px"}>
          {isError ? (
            <ColoredComponent
              color={oldTheme.pallete.errors.primary}
              icon={IconOptions.ERROR}
              text={
                <DefaultWhiteText>
                  Error al obtener el documento.
                </DefaultWhiteText>
              }
            />
          ) : (
            <>
              <ColoredComponent
                color={oldTheme.pallete.success.primary}
                icon={IconOptions.SUCCESS}
                text={
                  <DefaultWhiteText>
                    {DocumentCreationSuccessTitle[requestType].document}{" "}
                    <Bold>
                      {DocumentCreationSuccessTitle[requestType].action}
                    </Bold>{" "}
                    por correo electrónico
                  </DefaultWhiteText>
                }
              />
              <CenteredWrapper>
                {pdfData?.map(({ url }, index) => (
                  <StyledDocument
                    key={index}
                    file={url}
                    loading={<CircularProgress size={24} color="primary" />}
                    onLoadSuccess={({ numPages }) => {
                      setNumPagesArray((prevNumPagesArray) =>
                        handlePagesManagement(
                          prevNumPagesArray,
                          index,
                          numPages
                        )
                      );
                    }}
                  >
                    {numPagesArray[index] &&
                      Array.from(
                        new Array(numPagesArray[index]),
                        (_, pageIndex) => (
                          <StyledPage
                            key={pageIndex}
                            pageNumber={pageIndex + 1}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                          />
                        )
                      )}
                  </StyledDocument>
                ))}
              </CenteredWrapper>
            </>
          )}
        </ScreenWrapper>
        <ShareDocumentsErrorModal
          isOpen={isDownloadFilesFallbackActive}
          onClose={() => setIsDownloadFilesFallbackActive(false)}
        />
        <ExtraWhiteSpace />
        <BottomActionArea>
          <SecondaryActionButton
            useBackground
            onClick={handleMainActionClick}
            disabled={isConvertingFiles}
          >
            {isConvertingFiles ? (
              <CircularProgress size={24} color="inherit" />
            ) : isScreenBiggerThanSM ? (
              <>
                Descargar <DownloadIcon />
              </>
            ) : (
              <>
                Compartir <ShareIcon />
              </>
            )}
          </SecondaryActionButton>
          <BottomActionButton onClick={onGoHome} size="small">
            Ir al inicio
          </BottomActionButton>
        </BottomActionArea>
      </Body>
    </>
  );
};

import { useNavigate } from "react-router-dom";
import { Body, Header } from "../../components";
import { Global } from "@emotion/react";
import { styled, Box, Link as MuiLink } from "@mui/material";
import { format as formatDate } from "date-fns";
import { format as formatRut } from "rut.js";
import parsePhoneNumber from "libphonenumber-js";
import { theme as OldTheme } from "../../config";
import {
  InformationCard,
  InformationCardTitle,
  InformationCardText,
  InformationCardSection,
  NavigateBeforeButton,
} from "../../components";
import {
  getBirthdateInfo,
  onContactUsClick,
  separateDoctorSpecialty,
} from "../../utils";
import { useDoctorContext } from "../../context";
import { needHelpMessage } from "../../constants";

const ContactUsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "16px",
  color: OldTheme.pallete.text.black,
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "23px",
  letterSpacing: "0.5px",
});

const Link = styled(MuiLink)({
  textAlign: "center",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "23px",
  letterSpacing: "0.5px",
  color: OldTheme.pallete.text.link,
});

export const PersonalData = () => {
  const navigate = useNavigate();
  const {
    rut,
    name,
    lastName,
    speciality,
    birthdate,
    registrationNumber,
    email,
    phone,
  } = useDoctorContext();
  const { years: age } = getBirthdateInfo(birthdate);
  const { mekidocAccessTitle, specialties } =
    separateDoctorSpecialty(speciality);

  return (
    <>
      <Header
        title="Datos personales"
        titleColor="black"
        left={
          <NavigateBeforeButton onClick={() => navigate("/app/solicitudes")} />
        }
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <Box padding="16px 0px">
          <InformationCard>
            <InformationCardSection>
              <InformationCardTitle>Tus datos</InformationCardTitle>
              <InformationCardText>
                {name} {lastName}
              </InformationCardText>
              <InformationCardText>{formatRut(rut)}</InformationCardText>
              <InformationCardText>
                {age} años · {formatDate(new Date(birthdate), "dd/MM/yyyy")}
              </InformationCardText>
              <InformationCardText>
                {mekidocAccessTitle} (REG-SIS N°: {registrationNumber})
              </InformationCardText>
              {specialties && (
                <InformationCardText>{specialties}</InformationCardText>
              )}
            </InformationCardSection>
            <InformationCardSection>
              <InformationCardTitle>Datos de contacto</InformationCardTitle>
              <InformationCardText>{email}</InformationCardText>
              <InformationCardText>
                {parsePhoneNumber(phone)?.formatInternational()}
              </InformationCardText>
            </InformationCardSection>
          </InformationCard>
        </Box>
        <ContactUsContainer>
          Si necesitas editar tus datos{" "}
          <Link
            onClick={() => {
              onContactUsClick(needHelpMessage);
            }}
          >
            contáctanos
          </Link>
        </ContactUsContainer>
      </Body>
    </>
  );
};

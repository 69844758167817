import { Box, Button, ButtonProps, styled } from "@mui/material";
import { theme as oldTheme } from "../config/theme";

export const BottomActionArea = styled(Box)({
  position: "sticky",
  bottom: 0,
  left: 0,
  display: "flex",
  justifyContent: "space-evenly",
  width: "calc(100% + 32px)",
  background: oldTheme.pallete.background.transparentWhite,
  borderTop: "1px solid #eaeaea",
  margin: "-8px -16px",
  marginTop: "auto",
  padding: "16px 16px 24px",
  zIndex: 999,
});

export function ContainedButton(props: Omit<ButtonProps, "variant">) {
  return <Button variant="contained" {...props} />;
}

export const BottomActionButton = styled(ContainedButton)(({ size }) => ({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "center",
  textTransform: "none",
  backgroundColor: oldTheme.pallete.text.primary,
  border: "none",
  borderRadius: "100px",
  padding: "12px 32px",
  minWidth: size
    ? size === "small"
      ? "150px"
      : size === "medium"
      ? "250px"
      : "350px"
    : "250px",
  "&:focus, &:hover": {
    cursor: "pointer",
    backgroundColor: oldTheme.pallete.text.primary,
  },
  "&.Mui-disabled": {
    backgroundColor: oldTheme.pallete.text.disabled,
    // Add before pseudo elemento to avoid transparency on disabled state
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      borderRadius: "inherit",
      zIndex: -1,
    },
  },
}));

export const MainActionButton = styled(Button)(({ variant, size }) => ({
  display: "flex",
  width: "100%",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "center",
  textTransform: "none",
  color: oldTheme.pallete.text.primaryDark,
  background: oldTheme.pallete.text.primaryExtraLight,
  ...(variant === "outlined" && {
    border: `0.8px solid ${oldTheme.pallete.text.primaryDark};`,
  }),
  borderRadius: "100px",
  padding: "12px 32px",
  maxWidth: size
    ? size === "small"
      ? "150px"
      : size === "medium"
      ? "250px"
      : "350px"
    : "250px",
  minWidth: "150px",
  "&:hover, &:focus": {
    cursor: "pointer",
    background: oldTheme.pallete.text.primaryLight,
  },
  "&:disabled": {
    background: oldTheme.pallete.text.disabled,
  },
}));

export const SecondaryActionButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "useBackground",
})<{ useBackground?: boolean; textDecoration?: string }>(
  ({ useBackground, textDecoration }) => ({
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19px",
    textAlign: "center",
    textTransform: "none",
    ...(textDecoration && { textDecoration }),
    color: oldTheme.pallete.text.primary,
    background: useBackground
      ? oldTheme.pallete.text.primaryExtraLight
      : "transparent",
    border: "none",
    borderRadius: "100px",
    padding: "12px 32px",
    minWidth: "150px",
    "&:focus, &:hover": {
      cursor: "pointer",
      background: useBackground
        ? oldTheme.pallete.text.primaryLight
        : oldTheme.pallete.text.primaryExtraLight,
      ...(textDecoration && { textDecoration }),
    },
    "&.Mui-disabled": {
      color: oldTheme.pallete.text.disabled,
    },
  })
);

export const ButtonsContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  width: "100%",
  gap: "12px",
  paddingBottom: "12px",
});

export const ButtonsColumnContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: "12px",
  paddingBottom: "12px",
});

export const ExtraWhiteSpace = styled(Box)({
  height: "91px",
});

export const LargeExtraWhiteSpace = styled(Box)({
  height: "250px",
});

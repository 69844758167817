import { useCallback, useMemo, useState } from "react";
import { Box, IconButton, Skeleton, Typography, styled } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  ExternalPrice,
  ItemType,
  SearchGeneric,
  SearchProduct,
} from "@interfaces";
import { Modal } from "@components";
import {
  numberFormat,
  pharmacies,
  trimmedLaboratory,
  trimString,
  onContactUsClick,
  cleanAndFormatProductCardText,
  productSubCategoriesDetails,
} from "@utils";
import { format, min as minDate } from "date-fns";
import { min } from "lodash";
import { theme as oldTheme, theme } from "../../../config";

const MedicineWrapper = styled("button", {
  shouldForwardProp: (prop) => prop !== "isButton",
})<{ isButton: boolean }>(({ isButton }) => ({
  width: "100%",
  padding: "0px",
  border: "none",
  pointerEvents: isButton ? "auto" : "none",
  cursor: isButton ? "pointer" : "default",
  background: oldTheme.pallete.background.white,
}));

const MedicineContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  backgroundColor: "#FFFFFF",
  padding: "8px",
});

const Row = styled(Box)({
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
});

const SpacedRow = styled(Row)({
  justifyContent: "space-between",
});

const PriceRow = styled(SpacedRow)({
  width: "auto",
  justifyContent: "flex-end",
  alignSelf: "flex-end",
});

const ArrowForwardIcon = styled(ArrowForwardIosIcon)({
  width: "20px",
  height: "20px",
  color: oldTheme.pallete.text.primary,
});

const DataContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isTreatmentCard" && prop !== "isSkeleton",
})<{ isTreatmentCard?: boolean; isSkeleton?: boolean }>(
  ({ isTreatmentCard = false, isSkeleton = false }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    gap: "4px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: "0px 8px 0px 0px",
    ...(isTreatmentCard && {
      padding: "8px 8px 8px 0px",
    }),
    ...(isSkeleton && {
      paddingLeft: "16px",
    }),
  })
);

const MedicineName = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 600,
  color: oldTheme.pallete.text.black,
  maxWidth: "100%",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  paddingLeft: "8px",
});

const MedicineDescription = styled(Typography)<{ color?: string }>(
  ({ color }) => ({
    fontSize: "0.875rem",
    fontWeight: 400,
    color: color ?? oldTheme.pallete.text.gray,
    maxWidth: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingLeft: "8px",
  })
);

const MedicinePriceContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "8px",
});

const MedicinePriceText = styled(Typography)({
  fontSize: "0.875rem",
  fontWeight: 400,
  textAlign: "right",
  whiteSpace: "nowrap",
  color: oldTheme.pallete.text.gray,
});

const MedicinePriceAmount = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 600,
  textAlign: "right",
  whiteSpace: "nowrap",
  color: oldTheme.pallete.text.black,
  marginTop: "-2px",
  alignSelf: "flex-end",
});

const MedicineNoPriceInfo = styled(MedicinePriceAmount)({
  fontSize: "0.875rem",
  fontWeight: 500,
});

const MedicineImage = styled("img")({
  width: "100%",
  maxWidth: "100px",
  maxHeight: "100px",
  objectFit: "contain",
  display: "inline-block",
  padding: "0 16px",
  margin: "0px 8px",
  pointerEvents: "auto",
  cursor: "pointer",
});

const TagContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  paddingBottom: "12px",
});

interface TagProps {
  backgroundColor: string;
  color: string;
}

const Tag = styled(Box)<TagProps>((props) => ({
  display: "flex",
  justifyContent: "center",
  width: "90%",
  background: props.backgroundColor,
  color: props.color,
  padding: "6px",
}));

const TagText = styled(Typography)({
  fontSize: "0.75rem",
  fontWeight: 500,
  textWrap: "nowrap",
});

const TagSpan = styled("span")<{ color: string }>((props) => ({
  color: props.color,
  fontWeight: 600,
}));

const StyledIconButton = styled(IconButton)({
  pointerEvents: "auto",
  cursor: "pointer",
  padding: "0px",
});

const StyledInfoIcon = styled(InfoOutlinedIcon)({
  fontSize: "24px",
  color: theme.pallete.text.black,
});

const ModalContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "16px 0px 12px 0px",
});

const LastUpdateMessage = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
});

const ModalContent = styled(Typography)({
  fontSize: "14px",
  fontWeight: 400,
  marginTop: "24px",
});

const ContactUsText = styled("span")({
  color: "#006BD6",
  textDecoration: "underline",
  cursor: "pointer",
});

const ComparativeTableContainer = styled(Box)({
  padding: "0px 16px",
  border: "1px solid #eaeaea",
  borderRadius: "8px",
  color: oldTheme.pallete.text.black,
});

const ComparativeTableRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  minHeight: "62px",
  padding: "8px",
  borderBottom: "1px solid #eaeaea",
  "&:last-child": {
    borderBottom: "none",
  },
});

const ComparativeTableDataRow = styled(Box)({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  overflow: "hidden",
});

const MedicineInfoColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 0",
  minWidth: "0",
  justifyContent: "center",
  alignItems: "flex-start",
});

const MedicinePriceColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: "0 0 auto",
});

const PharmacyInfo = styled("span", {
  shouldForwardProp: (prop) => prop !== "isMinPrice",
})<{ isMinPrice: boolean }>(({ isMinPrice }) => ({
  fontSize: "14px",
  fontWeight: 600,
  color: isMinPrice
    ? oldTheme.pallete.text.primary
    : oldTheme.pallete.text.black,
}));

const NameInfo = styled("span")({
  display: "block",
  textAlign: "left",
  width: "100%",
  fontSize: "12px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: oldTheme.pallete.text.gray,
});

const ExpensivePrice = styled("span")({
  fontSize: "12px",
  textDecorationLine: "line-through",
  textAlign: "right",
});

const Price = styled("span", {
  shouldForwardProp: (prop) => prop !== "isMinPrice",
})<{ isMinPrice: boolean }>(({ isMinPrice }) => ({
  fontSize: "16px",
  fontWeight: 500,
  whiteSpace: "nowrap",
  color: isMinPrice
    ? oldTheme.pallete.text.primary
    : oldTheme.pallete.text.black,
}));

const PriceNotFoundContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "8px 0px",
});

const PriceNotFound = styled("span", {
  shouldForwardProp: (prop) => prop !== "isSeller",
})<{ isSeller?: boolean }>(({ isSeller }) => ({
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "left",
  whiteSpace: isSeller === true ? "normal" : "nowrap",
  color:
    isSeller === true
      ? oldTheme.pallete.text.black
      : oldTheme.pallete.text.gray,
}));

const StyledPremiumIcon = styled(WorkspacePremiumIcon)({
  fontSize: "32px",
  color: oldTheme.pallete.text.primary,
  backgroundColor: oldTheme.pallete.text.primaryExtraLight,
  borderRadius: "50%",
  padding: "4px",
  marginRight: "8px",
  marginLeft: "-12px",
});

const RowNumber = styled("span")({
  fontSize: "14px",
  fontWeight: 600,
  color: oldTheme.pallete.text.black,
  marginRight: "16px",
});

interface MedicineCardProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  medicine: SearchProduct | SearchGeneric;
  isTreatmentCard?: boolean;
}
export function SearchedMedicineCard({
  medicine,
  isTreatmentCard = false,
  ...props
}: MedicineCardProps) {
  const isGeneric = (medicine.type ?? "") === ItemType.GENERIC;
  if (isGeneric) {
    return (
      <MedicineWrapper isButton={!isTreatmentCard} {...props}>
        <GenericMedicineCard
          genericMedicine={medicine as SearchGeneric}
          isTreatmentCard={isTreatmentCard}
        />
      </MedicineWrapper>
    );
  } else {
    return (
      <MedicineWrapper isButton={!isTreatmentCard} {...props}>
        <ProductCard
          product={medicine as SearchProduct}
          isTreatmentCard={isTreatmentCard}
        />
      </MedicineWrapper>
    );
  }
}

interface SearchedMedicineCardSkeletonProps {
  showImageSkeleton?: boolean;
}

SearchedMedicineCard.Skeleton = ({
  showImageSkeleton = true,
}: SearchedMedicineCardSkeletonProps) => {
  return (
    <MedicineContainer
      style={{ borderBottom: "1px solid #eaeaea", maxHeight: "128px" }}
    >
      <SpacedRow>
        {showImageSkeleton && (
          <Skeleton
            variant="rounded"
            width={100}
            height={100}
            style={{ marginLeft: "16px" }}
          />
        )}
        <DataContainer isSkeleton={true}>
          <Skeleton width="40%" height="24px" />
          <Skeleton width="60%" height="20px" />
          <Skeleton width="30%" height="20px" />
          <Skeleton
            variant="text"
            width="30%"
            height="20px"
            sx={{ alignSelf: "flex-end" }}
          />
        </DataContainer>
      </SpacedRow>
    </MedicineContainer>
  );
};

interface ProductCardProps {
  product: SearchProduct;
  isTreatmentCard: boolean;
}

function ProductCard({ product, isTreatmentCard }: ProductCardProps) {
  const showProductActivePrinciple =
    product.name &&
    product.activePrinciple &&
    product.name !== product.activePrinciple;
  const showProductCompleteFormat = isTreatmentCard && product.completeFormat;

  const productMinPrice = min([product.price, product.discount?.price]);
  const showSincePrice = !isTreatmentCard;

  const productSubCategories = productSubCategoriesDetails(product);
  const showCheckPrescriptionBanner = product.prescriptionType === "CHECK";
  const showLowStockBanner =
    (product.availability.status ?? "") === "SOLD_OUT" &&
    productSubCategories.isMedicine &&
    !productSubCategories.isInAnotherAllowedSubCategories &&
    !showCheckPrescriptionBanner;

  const [imageSrc, setImageSrc] = useState(
    () => product.imagesUrl.find(Boolean) ?? "/placeholder_medicine_image.png"
  );
  const [showFullImage, setShowFullImage] = useState(false);
  const handleImageClick = useCallback(() => {
    setShowFullImage((prev) => !prev);
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const changeModalOpenState = () => setModalOpen((prev) => !prev);
  return (
    <MedicineContainer>
      <SpacedRow>
        <MedicineImage
          src={imageSrc}
          alt={product.name}
          onClick={handleImageClick}
          onError={() => {
            setImageSrc("/placeholder_medicine_image.png");
          }}
        />
        <DataContainer isTreatmentCard={isTreatmentCard}>
          <SpacedRow>
            <MedicineName>{trimString(product.name)}</MedicineName>
            {isTreatmentCard && (
              <StyledIconButton>
                <StyledInfoIcon onClick={changeModalOpenState}></StyledInfoIcon>
              </StyledIconButton>
            )}
          </SpacedRow>

          {(showProductActivePrinciple || showProductCompleteFormat) && (
            <MedicineDescription>
              {cleanAndFormatProductCardText(
                `${
                  showProductActivePrinciple
                    ? trimString(product.activePrinciple)
                    : ""
                }${
                  showProductCompleteFormat
                    ? ` · ${trimString(product.completeFormat, 50)}`
                    : ""
                }`
              )}
            </MedicineDescription>
          )}

          <MedicineDescription>
            {cleanAndFormatProductCardText(
              `${trimString(product.concentration)} · ${trimmedLaboratory(
                product.laboratory
              )}`
            )}
          </MedicineDescription>

          {product.quantity && product.unit && (
            <MedicineDescription color={oldTheme.pallete.text.black}>
              {`${product.quantity} ${product.unit}`}
            </MedicineDescription>
          )}

          {showSincePrice && (
            <PriceRow>
              <MedicinePriceContainer>
                {productMinPrice ? (
                  <>
                    <MedicinePriceText>Desde</MedicinePriceText>
                    <MedicinePriceAmount>
                      ${numberFormat(productMinPrice)}
                    </MedicinePriceAmount>
                  </>
                ) : (
                  <MedicineNoPriceInfo>No encontrado</MedicineNoPriceInfo>
                )}
              </MedicinePriceContainer>
            </PriceRow>
          )}
        </DataContainer>
        {!isTreatmentCard && <ArrowForwardIcon />}
      </SpacedRow>
      {showCheckPrescriptionBanner && (
        <TagContainer>
          <Tag backgroundColor={"#D3EAFD"} color={"#2196F3"}>
            <TagText>
              Medicamento con <TagSpan color={"#2196F3"}>receta cheque</TagSpan>
            </TagText>
          </Tag>
        </TagContainer>
      )}
      {showLowStockBanner && (
        <TagContainer>
          <Tag
            backgroundColor={"#FBEAEA"}
            color={oldTheme.pallete.errors.primary}
          >
            <TagText>
              <TagSpan color={oldTheme.pallete.errors.primary}>
                Baja disponibilidad
              </TagSpan>{" "}
              en el mercado
            </TagText>
          </Tag>
        </TagContainer>
      )}
      {isTreatmentCard && <ComparativeTable medicine={product} />}
      <LastUpdateModal
        medicine={product}
        modalOpen={modalOpen}
        changeModalOpenState={changeModalOpenState}
      />
      {isTreatmentCard && showFullImage && (
        <Modal isOpen={showFullImage} onClose={handleImageClick}>
          <img
            src={imageSrc}
            alt={product.name}
            style={{ width: "100%", height: "auto", objectFit: "contain" }}
            onError={() => {
              setImageSrc("/placeholder_medicine_image.png");
            }}
          />
        </Modal>
      )}
    </MedicineContainer>
  );
}

interface GenericMedicineCardProps {
  genericMedicine: SearchGeneric;
  isTreatmentCard: boolean;
}

function GenericMedicineCard({
  genericMedicine,
  isTreatmentCard,
}: GenericMedicineCardProps) {
  const { activePrinciple, completeFormat, concentration, price } =
    genericMedicine;
  const showSincePrice = !isTreatmentCard && price;
  const [modalOpen, setModalOpen] = useState(false);
  const changeModalOpenState = () => setModalOpen((prev) => !prev);
  return (
    <MedicineContainer>
      <SpacedRow>
        <DataContainer isTreatmentCard={isTreatmentCard}>
          <SpacedRow>
            <MedicineName>{trimString(activePrinciple)}</MedicineName>
            {isTreatmentCard && (
              <StyledIconButton>
                <StyledInfoIcon onClick={changeModalOpenState}></StyledInfoIcon>
              </StyledIconButton>
            )}
          </SpacedRow>
          {completeFormat && (
            <MedicineDescription>
              {trimString(completeFormat, 50)}
            </MedicineDescription>
          )}

          <SpacedRow>
            <MedicineDescription>
              {trimString(concentration)}
            </MedicineDescription>
            {showSincePrice && (
              <PriceRow>
                <MedicinePriceContainer>
                  <MedicinePriceText>Desde</MedicinePriceText>
                  <MedicinePriceAmount>
                    ${numberFormat(price)}
                  </MedicinePriceAmount>
                </MedicinePriceContainer>
              </PriceRow>
            )}
          </SpacedRow>
        </DataContainer>
        {!isTreatmentCard && <ArrowForwardIcon />}
      </SpacedRow>
      {isTreatmentCard && <ComparativeTable medicine={genericMedicine} />}
      <LastUpdateModal
        medicine={genericMedicine}
        modalOpen={modalOpen}
        changeModalOpenState={changeModalOpenState}
      />
    </MedicineContainer>
  );
}

interface LastUpdateModalProps {
  medicine: SearchProduct | SearchGeneric;
  modalOpen: boolean;
  changeModalOpenState: () => void;
}

function LastUpdateModal({
  medicine,
  modalOpen,
  changeModalOpenState,
}: LastUpdateModalProps) {
  const getOldestUpdate = useCallback(
    (medicine: SearchProduct | SearchGeneric) => {
      return minDate(
        medicine.externalPrices.map(({ lastUpdate }) => {
          if (isNaN(Date.parse(lastUpdate))) return new Date();
          return new Date(lastUpdate);
        })
      );
    },
    []
  );

  const lastUpdateMessage = useMemo(() => {
    const date = getOldestUpdate(medicine);
    if (!date) return "";
    const dateObj = new Date(date);
    const dateStr = format(dateObj, "dd.MM.yyyy");
    const timeStr = format(dateObj, "HH:mm");
    return `Actualizado el ${dateStr} a las ${timeStr} hrs. Los precios pueden haber variado desde entonces.`;
  }, [medicine, getOldestUpdate]);

  return (
    <Modal
      title="Precios online"
      isOpen={modalOpen}
      onClose={changeModalOpenState}
    >
      <ModalContentContainer>
        <LastUpdateMessage>
          {lastUpdateMessage}
          <ModalContent>
            Buscamos los productos en farmaciameki.cl, cruzverde.cl,
            farmaciasahumada.cl, salcobrand.cl y drsimi.cl y te mostramos los
            precios de lista y descuentos generales de lo que logramos
            encontrar.
          </ModalContent>
          <ModalContent>
            Los descuentos pueden cambiar según el día de la semana, el convenio
            del cliente y/o el medio de pago.
          </ModalContent>
          <ModalContent>
            Si un producto no lo encontramos no significa que no exista en esas
            farmacias.
          </ModalContent>
          <ModalContent>
            Si tienes una farmacia y quieres aparecer,{" "}
            <ContactUsText
              onClick={() =>
                onContactUsClick(
                  "Hola, me gustaría que mi farmacia aparezca en MekiDoc. ¿Podrías darme más detalles de como realizar la incorporación?"
                )
              }
            >
              escríbenos aquí.
            </ContactUsText>
          </ModalContent>
        </LastUpdateMessage>
      </ModalContentContainer>
    </Modal>
  );
}

function ComparativeTable({
  medicine,
}: {
  medicine: SearchProduct | SearchGeneric;
}) {
  const formatPrice = (price: number) => `$${numberFormat(price)}`;

  const getMedicineDetailsFromSeller = useCallback(
    (sellerKey: string) => {
      const sellerInfo = medicine?.externalPrices.find(
        (sellerInfo: ExternalPrice) => sellerInfo.seller === sellerKey
      ) as ExternalPrice | undefined;

      const medicineDetails = {
        medicineName: "",
        amount: sellerInfo ? sellerInfo.amount : "No encontrado",
        saleAmount: sellerInfo ? sellerInfo.saleAmount : undefined,
      };

      if (sellerInfo?.product) {
        const { name, activePrinciple, laboratory, quantity, unit } =
          sellerInfo.product;
        medicineDetails.medicineName = trimString(
          `${name && name !== activePrinciple ? name : laboratory} ${
            quantity !== 0 && unit !== "" ? ` · ${quantity} ${unit}` : ""
          }`,
          256
        );
      }
      return medicineDetails;
    },
    [medicine]
  );

  const { sortedDataWithPrice, sortedDataWithoutPrice, minPrice } =
    useMemo(() => {
      const sellersPrices = Object.entries(pharmacies).map(
        ([sellerKey, sellerFormattedName]) => {
          const { medicineName, amount, saleAmount } =
            getMedicineDetailsFromSeller(sellerKey);
          return {
            seller: sellerFormattedName,
            medicineName,
            amount: typeof amount === "number" ? amount : Infinity,
            saleAmount: saleAmount ?? Infinity,
          };
        }
      );

      const minPrice = sellersPrices.reduce((min, seller) => {
        const minSellerPrice = Math.min(seller.amount, seller.saleAmount);
        return Math.min(min, minSellerPrice);
      }, Infinity);

      const sortedCompareTableData = sellersPrices.sort((a, b) => {
        const minPriceA = Math.min(a.amount, a.saleAmount);
        const minPriceB = Math.min(b.amount, b.saleAmount);
        return minPriceA - minPriceB;
      });

      const { sortedDataWithPrice, sortedDataWithoutPrice } =
        sortedCompareTableData.reduce(
          (
            acc: {
              sortedDataWithPrice: typeof sortedCompareTableData;
              sortedDataWithoutPrice: typeof sortedCompareTableData;
            },
            row
          ) => {
            if (row.amount === Infinity) {
              acc.sortedDataWithoutPrice.push(row);
            } else {
              acc.sortedDataWithPrice.push(row);
            }
            return acc;
          },
          { sortedDataWithPrice: [], sortedDataWithoutPrice: [] }
        );

      return { sortedDataWithPrice, sortedDataWithoutPrice, minPrice };
    }, [getMedicineDetailsFromSeller]);

  return (
    <ComparativeTableContainer>
      {sortedDataWithPrice.map((item, index) => {
        const isMinPrice = Math.min(item.amount, item.saleAmount) === minPrice;
        const medicineName =
          item.seller === pharmacies.farmaciaMeki
            ? item.medicineName
            : item.medicineName?.replace(/cenabast/i, "");
        return (
          <ComparativeTableRow key={item.seller}>
            {isMinPrice ? (
              <StyledPremiumIcon />
            ) : (
              <RowNumber>{index + 1}.</RowNumber>
            )}
            <ComparativeTableDataRow>
              <MedicineInfoColumn>
                <PharmacyInfo isMinPrice={isMinPrice}>
                  {item.seller}
                </PharmacyInfo>
                {medicineName && <NameInfo>{medicineName}</NameInfo>}
              </MedicineInfoColumn>
              <MedicinePriceColumn>
                {item.saleAmount !== Infinity &&
                item.saleAmount !== item.amount ? (
                  <>
                    <ExpensivePrice>{formatPrice(item.amount)}</ExpensivePrice>
                    <Price isMinPrice={isMinPrice}>
                      {formatPrice(item.saleAmount)}
                    </Price>
                  </>
                ) : (
                  <Price isMinPrice={isMinPrice}>
                    {formatPrice(item.amount)}
                  </Price>
                )}
              </MedicinePriceColumn>
            </ComparativeTableDataRow>
          </ComparativeTableRow>
        );
      })}
      {sortedDataWithoutPrice.length > 0 && (
        <PriceNotFoundContainer>
          <PriceNotFound>No pudimos encontrar el producto en:</PriceNotFound>
          <PriceNotFound isSeller={true}>
            {sortedDataWithoutPrice.map(({ seller }) => seller).join(", ")}
          </PriceNotFound>
        </PriceNotFoundContainer>
      )}
    </ComparativeTableContainer>
  );
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormControl, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { Global } from "@emotion/react";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  DigitalSign,
  FullScreenLoader,
  Header,
  InputFieldWrapper,
  PatientSummaryAccordion,
  scrollToTop,
  NavigateBeforeButton,
  ExtraWhiteSpace,
  AuthRequiredModal,
} from "@components";
import {
  DocumentCreationLoaderTitle,
  MedicalCertificate,
  Patient,
  RequestStatus,
  RequestType,
  patientDefaultValues,
} from "@interfaces";
import { useDoctorContext } from "@contexts";
import { useCreateDocument, useRequest } from "@hooks";
import { separateDoctorSpecialty } from "@utils";
import { theme } from "../../config/theme";

import { format } from "rut.js";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { esES } from "@mui/x-date-pickers/locales";
import es from "date-fns/locale/es";

const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: "20px",
});

const Input = styled(TextField)({
  backgroundColor: theme.pallete.background.white,
  fontSize: "1rem",
  fontWeight: 400,
  "& .MuiInputBase-root": {
    padding: "8px 12px",
    fontSize: "1rem",
    fontWeight: 400,
  },
  "& textarea": {
    minHeight: "200px",
  },
});

const StyledDateField = styled(DateField)<{ error: boolean }>({
  backgroundColor: theme.pallete.background.white,
  ...(props) =>
    props.error && {
      border: "1px solid red",
      borderRadius: "4px",
    },
});

interface LocationState {
  repeatedDocumentDetails?: string;
}

export function MedicalCertificateCreate() {
  const doctor = useDoctorContext();
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  const { data: request } = useRequest();
  const location = useLocation();
  const { repeatedDocumentDetails }: LocationState = location.state || {};
  const [showFeaAuthModal, setShowFeaAuthModal] = useState<boolean>(false);

  const patient: Patient = useMemo(() => {
    return request ? request.patient : patientDefaultValues;
  }, [request]);

  const isDraft = useMemo(() => {
    return request?.status === RequestStatus.DRAFT;
  }, [request]);

  const placeholderComponents = useMemo(() => {
    const { mekidocAccessTitle } = separateDoctorSpecialty(doctor.speciality);
    const dynamicPlaceholder = `Yo, ${doctor.name} ${
      doctor.lastName
    }, ${mekidocAccessTitle}, certifico que el paciente ${patient.name} ${
      patient.firstSurname
    } ${patient.secondSurname} (${format(patient.rut)})`;
    const placeholderPostfix =
      "Extiendo el presente certificado para los fines que el paciente estime conveniente.";
    return { dynamicPlaceholder, placeholderPostfix };
  }, [doctor, patient]);

  const certificatePlaceholder = useMemo(() => {
    if (!patient.name) return "";
    return `${placeholderComponents.dynamicPlaceholder} 


${placeholderComponents.placeholderPostfix}`;
  }, [placeholderComponents, patient.name]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<MedicalCertificate>({
    defaultValues: {
      effectiveDate: new Date().toISOString(),
      documentDetails: repeatedDocumentDetails ?? certificatePlaceholder,
    },
  });

  const { mutate: createDocument, isPending } = useCreateDocument(
    RequestType.MEDICAL_CERTIFICATE
  );
  const [showDigitalSign, setShowDigitalSign] = useState(false);
  const effectiveDate = watch("effectiveDate");
  const documentDetails = watch("documentDetails");

  const handleDocumentDataSubmit = useCallback(() => {
    if (!doctor.isFeaAuthenticated) {
      setShowFeaAuthModal(true);
      return;
    }
    setShowDigitalSign(true);
    scrollToTop();
  }, [doctor]);

  const onDigitalSignSubmit = useCallback(async () => {
    setShowDigitalSign(false);
    const textToFilter = [
      placeholderComponents.dynamicPlaceholder,
      placeholderComponents.placeholderPostfix,
    ];
    let managementSummary = documentDetails;
    textToFilter.forEach((text) => {
      managementSummary = managementSummary.replace(text, "");
    });
    createDocument({
      document: { effectiveDate, documentDetails },
      managementSummary: managementSummary.trim(),
    });
  }, [placeholderComponents, effectiveDate, documentDetails, createDocument]);

  const handleBack = useCallback(() => {
    if (isDraft) {
      navigate(`/app/nuevo-documento/paciente`, {
        state: { documentType: RequestType.MEDICAL_CERTIFICATE },
      });
    } else {
      navigate(`/app/solicitudes/${requestId}/gestionar/seleccionar-documento`);
    }
  }, [navigate, isDraft, requestId]);

  useEffect(() => {
    function updatePlaceholder() {
      if (!documentDetails)
        setValue(
          "documentDetails",
          repeatedDocumentDetails ?? certificatePlaceholder
        );
    }
    updatePlaceholder();
  }, [
    documentDetails,
    repeatedDocumentDetails,
    certificatePlaceholder,
    setValue,
  ]);

  if (showDigitalSign) {
    return (
      <DigitalSign
        documentType={RequestType.MEDICAL_CERTIFICATE}
        handleCreateDocument={onDigitalSignSubmit}
        handleBack={() => setShowDigitalSign(false)}
      />
    );
  }

  if (isPending)
    return (
      <FullScreenLoader
        title={DocumentCreationLoaderTitle[RequestType.MEDICAL_CERTIFICATE]}
      />
    );

  return (
    <>
      <Header
        title={"Certificado médico"}
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <PatientSummaryAccordion
          patient={patient}
          prevRoute={`/app/solicitudes/${requestId}/certificado/crear`}
          requestId={requestId}
          isOpen={isDraft}
          canEdit={isDraft}
        />
        <FormContainer onSubmit={handleSubmit(handleDocumentDataSubmit)}>
          <InputFieldWrapper
            label="Fecha de certificado"
            error={errors.effectiveDate?.message}
          >
            <FormControl fullWidth>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={es}
                localeText={
                  esES.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
              >
                <Controller
                  name="effectiveDate"
                  control={control}
                  defaultValue={undefined}
                  rules={{
                    required: "Debes seleccionar una fecha.",
                    validate: (value) => {
                      const date = new Date(value);
                      if (date.toString() === "Invalid Date") {
                        return "Fecha incompleta o inválida";
                      }
                      if (date.getFullYear() < 1900) {
                        return "La fecha es demasiado antigua";
                      }
                      return true;
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <StyledDateField
                      minDate={new Date("1900-01-01")}
                      value={value ? new Date(value) : null}
                      onChange={(date) => onChange(date)}
                      clearable={true}
                      format="dd/MM/yyyy"
                      error={
                        errors.effectiveDate?.message ===
                        "Debes seleccionar una fecha."
                      }
                      slotProps={{
                        // Disable the blue border on focus when there is an error
                        ...(errors.effectiveDate &&
                          errors.effectiveDate.message ===
                            "Debes seleccionar una fecha." && {
                            textField: {
                              focused: false,
                            },
                          }),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </InputFieldWrapper>

          <InputFieldWrapper
            label="Detalle certificado médico"
            error={errors.documentDetails?.message}
          >
            <FormControl fullWidth>
              <Input
                multiline
                error={!!errors.documentDetails}
                {...register("documentDetails", {
                  required:
                    "Debes agregar la razón e indicaciones del certificado médico.",
                })}
              />
            </FormControl>
          </InputFieldWrapper>

          <ExtraWhiteSpace />

          <BottomActionArea>
            <BottomActionButton type="submit">Siguiente</BottomActionButton>
          </BottomActionArea>
        </FormContainer>
      </Body>
      <AuthRequiredModal
        requestId={requestId}
        isOpen={showFeaAuthModal}
        onClose={() => setShowFeaAuthModal(false)}
      />
    </>
  );
}

import {
  AuthFeedbacks,
  AuthInitialStateFeedback,
  AuthMethod,
  ManageAuthFeedback,
} from "@interfaces/Auth";
import { client } from "@passwordless-id/webauthn";
import { isMobile, isMobileOnly } from "react-device-detect";

export function resolveAuthChallengeFeedback({
  authMethod,
  authStep,
  resetToInitialState,
}: ManageAuthFeedback) {
  if (resetToInitialState || !authMethod || !authStep) {
    return AuthInitialStateFeedback;
  } else {
    return AuthFeedbacks[authMethod][authStep];
  }
}

export async function checkIfBiometricAuthIsAvailable() {
  try {
    const canUseLocalAuthenticator = await client.isLocalAuthenticator();
    const isClientAvailable = client.isAvailable();
    return isMobile && canUseLocalAuthenticator && isClientAvailable;
  } catch (error) {
    console.error("Error verifying biometric auth availability", error);
    return false;
  }
}

export function resolveNoBiometricAuthMethod() {
  return isMobileOnly ? AuthMethod.PHONE_NUMBER : AuthMethod.EMAIL;
}

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";
import { queryClient, useSidebarMenuContext } from "../context";
import { Modal } from "./";

interface LogoutModalProps {
  showLogOutModal: boolean;
  setShowLogOutModal: (value: boolean) => void;
}

export const LogoutModal: React.FC<LogoutModalProps> = ({
  showLogOutModal,
  setShowLogOutModal,
}) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { setIsUserAuthed } = useAuth();
  const { setIsSidebarMenuOpen } = useSidebarMenuContext();
  const onConfirmLogOutClick = useCallback(() => {
    const logoutLogic = async () => {
      setShowLogOutModal(false);
      await logout();
      queryClient.resetQueries();
      setIsUserAuthed(false);
      setIsSidebarMenuOpen(false);
      navigate("/");
      document.body.style.overflow = "auto";
    };
    logoutLogic();
  }, [
    setShowLogOutModal,
    logout,
    navigate,
    setIsUserAuthed,
    setIsSidebarMenuOpen,
  ]);
  return (
    <Modal
      title="Cerrar sesión"
      content="¿Estás seguro que deseas salir?"
      isOpen={showLogOutModal}
      onClose={() => setShowLogOutModal(false)}
      buttonProps={{
        buttonText: "Cerrar sesión",
        onClick: onConfirmLogOutClick,
      }}
    ></Modal>
  );
};

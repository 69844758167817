export const theme = {
  pallete: {
    text: {
      primary: "#7355FB",
      primaryLight: "#c7bbfd",
      primaryExtraLight: "#F1EEFF",
      primaryDark: "#302D84",
      secondaryDark: "#211F5C",
      black: "#414046",
      lightBlack: "#414046",
      gray: "#757475",
      lightGray: "#C5C5C5",
      success: "#00A650",
      disabled: "#0000001F",
      link: "#1b72e8",
    },
    background: {
      primary: "#F5F8FB",
      secondary: "#E5EDF6",
      gray: "#757475",
      lightGray: "#f9f9f9",
      lightGreen: "rgba(67, 160, 71, 0.1)",
      lightRed: "rgba(226, 35, 26, 0.1)",
      white: "#FFFFFF",
      transparentWhite: "rgba(255, 255, 255, 0.8)",
    },
    success: {
      primary: "#4CAF50",
    },
    errors: {
      primary: "#D32F2F",
    },
    info: {
      primary: "#2196F3",
      light: "#E2EFF8",
    },
    documentTypes: {
      PRESCRIPTION: "#41BBF0",
      COMPOUNDED_PRESCRIPTION: "#7BECFE",
      MEDICAL_CERTIFICATE: "#7355FB",
      MEDICAL_ORDER: "#25257F",
    },
  },
};

import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Skeleton, styled, Typography } from "@mui/material";
import { Patient } from "@interfaces";
import { Accordion, SecondaryActionButton } from "@components";
import { getBirthdateDetails } from "@utils";
import { format } from "rut.js";
import { theme } from "../config";

const AccordionContainer = styled(Box)({
  padding: "16px 0px",
});

const DetailColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
});

const PatientInfoColumn = styled(DetailColumn)({
  paddingTop: "8px",
});

const DetailText = styled(Typography)({
  textWrap: "wrap",
  color: theme.pallete.text.black,
});

const NameTitle = styled(Typography)({
  fontWeight: 600,
  textAlign: "left",
});

const ManageRequestContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "16px",
});

interface PatientSummaryAccordionProps {
  patient: Patient;
  prevRoute?: string;
  requestId?: string;
  isOpen: boolean;
  canEdit: boolean;
}

export function PatientSummaryAccordion({
  patient,
  prevRoute = "/app/nuevo-documento/paciente",
  requestId,
  isOpen,
  canEdit,
}: PatientSummaryAccordionProps) {
  const navigate = useNavigate();
  const patientFullName = useMemo(() => {
    return `${patient.name} ${patient.firstSurname} ${patient.secondSurname}`;
  }, [patient]);
  const birthdateDetails = useMemo(() => {
    return getBirthdateDetails(patient.birthdate);
  }, [patient.birthdate]);
  return (
    <AccordionContainer>
      <Accordion title="Paciente" subtitle={patientFullName} expanded={isOpen}>
        <DetailColumn>
          <PatientInfoColumn>
            <DetailText>{format(patient.rut)}</DetailText>
            <div>
              {patient.birthdate && (
                <DetailText>
                  {birthdateDetails.age
                    ? `${birthdateDetails.age} · ${birthdateDetails.formattedBirthdate}`
                    : birthdateDetails.formattedBirthdate}
                </DetailText>
              )}
            </div>
            {patient.weight && <DetailText>{patient.weight} kg</DetailText>}
          </PatientInfoColumn>

          <PatientInfoColumn>
            <NameTitle>Datos de contacto</NameTitle>
            <DetailText>{patient.email}</DetailText>
            {patient.phone && <DetailText>{patient.phone}</DetailText>}
            <DetailText>
              {patient.street}, {patient.commune}
            </DetailText>
            <DetailText>{patient.region}</DetailText>
          </PatientInfoColumn>

          {canEdit && (
            <ManageRequestContainer>
              <SecondaryActionButton
                onClick={() =>
                  navigate("/app/nuevo-documento/paciente/editar", {
                    state: {
                      patient,
                      prevRoute,
                      requestId,
                    },
                  })
                }
              >
                Editar datos
              </SecondaryActionButton>
            </ManageRequestContainer>
          )}
        </DetailColumn>
      </Accordion>
    </AccordionContainer>
  );
}

PatientSummaryAccordion.Skeleton = function PatientSummaryAccordionSkeleton() {
  return (
    <AccordionContainer>
      <Accordion
        title="Paciente"
        subtitle={<Skeleton variant="text" width={200} />}
      >
        <DetailColumn>
          <PatientInfoColumn>
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={130} />
            <Skeleton variant="text" width={175} />
          </PatientInfoColumn>

          <PatientInfoColumn>
            <NameTitle>Datos de contacto</NameTitle>
            <Skeleton variant="text" width={175} />
            <Skeleton variant="text" width={130} />
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={175} />
          </PatientInfoColumn>

          <ManageRequestContainer>
            <SecondaryActionButton onClick={() => {}}>
              Editar datos
            </SecondaryActionButton>
          </ManageRequestContainer>
        </DetailColumn>
      </Accordion>
    </AccordionContainer>
  );
};

import styled from "@emotion/styled";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { format as rutFormat } from "rut.js";
import { theme } from "../../../config";
import {
  RequestType,
  RequestTypeColorMapper,
  requestTypeExtendedTranslation,
} from "../../../interfaces";
import { Skeleton } from "@mui/material";

//* : Se agrega como string para evitar: Warning: Received `false` for a non-boolean attribute `documentcard`.
interface CardContainerProps {
  documentcard: string;
}

interface CardTitleProps {
  showEllipsis?: boolean;
}

interface DocumentTypeBannerProps {
  documentType: RequestType;
}

const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  align-items: center;
  background-color: ${theme.pallete.background.white};
  box-shadow: 0px 0px 20px 1px #0000000f;
  min-height: ${(props) => (props.documentcard === "true" ? "60px" : "82px")};
  padding: ${(props) =>
    props.documentcard === "true" ? "0px 6px" : "6px 6px 6px 12px"};
  ${(props) =>
    props.documentcard === "false" && "border-bottom: 1px solid #eaeaea;"}
  cursor: pointer;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`;

const Column = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-right: 4px;
`;

const Row = styled.div`
  width: 100%;
  height: 100%;
  min-height: 20px;
  display: flex;
  align-items: center;
`;

const CardTitle = styled.span<CardTitleProps>`
  max-width: 300px;
  max-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  color: ${theme.pallete.text.black};
`;

const CardRut = styled.span`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.5px;
  color: ${theme.pallete.text.gray};
`;

const ArrowColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledRightArrow = styled(KeyboardArrowRightIcon)`
  color: ${theme.pallete.text.black};
`;

const DocumentTypeBanner = styled.div<DocumentTypeBannerProps>`
  background-color: ${({ documentType }) =>
    RequestTypeColorMapper[documentType]};
  height: 60px;
  width: 8px;
  margin: -6px 8px -6px -8px;
  position: relative;
  overflow: hidden;
  border-radius: 4px 0 0 4px;
`;

interface CardProps {
  name?: string;
  lastName?: string;
  rut?: string;
  documentType?: RequestType;
  onClick?: () => void;
}
export function Card({
  name,
  lastName,
  rut,
  documentType,
  onClick,
}: CardProps) {
  const cardTitle = documentType
    ? requestTypeExtendedTranslation[documentType]
    : `${name} ${lastName}`;

  return (
    <CardContainer
      documentcard={documentType ? "true" : "false"}
      onClick={onClick}
    >
      {documentType && <DocumentTypeBanner documentType={documentType} />}
      <Column>
        <Row>
          <CardTitle>{cardTitle}</CardTitle>
        </Row>
        {rut && (
          <Row>
            <CardRut>{rutFormat(rut)}</CardRut>
          </Row>
        )}
      </Column>
      <ArrowColumn>
        <StyledRightArrow />
      </ArrowColumn>
    </CardContainer>
  );
}

Card.Skeleton = function CardSkeleton() {
  return (
    <CardContainer documentcard={"false"}>
      <Column>
        <Row>
          <CardTitle>
            <Skeleton variant="text" width={200} />
          </CardTitle>
        </Row>
        <Row>
          <CardRut>
            <Skeleton variant="text" width={115} />
          </CardRut>
        </Row>
      </Column>
      <ArrowColumn>
        <StyledRightArrow />
      </ArrowColumn>
    </CardContainer>
  );
};

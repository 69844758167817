import { Auth } from "aws-amplify";
import { createContext, useContext } from "react";

interface IFetchContext {
  <T>(pathname: string, init?: RequestInit): Promise<T>;
}
const FetchContext = createContext<IFetchContext>(
  undefined as unknown as IFetchContext
);

export function useFetch() {
  const context = useContext(FetchContext);
  if (!context) throw new Error("useFetch outside provider");
  return context;
}

interface FetchProviderProps {
  children: React.ReactNode;
}
export function FetchProvider({ children }: FetchProviderProps) {
  async function customFetch<T>(
    pathname: string,
    init?: RequestInit
  ): Promise<T> {
    if (!init) {
      init = {} as RequestInit;
    }
    init.headers = new Headers(init.headers);

    try {
      const user = await Auth.currentAuthenticatedUser();

      if (user) {
        const jwtToken = user.signInUserSession.idToken.jwtToken;
        init.headers.set("Authorization", `Bearer ${jwtToken}`);
      }
    } catch (error) {
      console.log({
        msg: "customFetch - error",
        error,
      });
    }

    const response = await fetch(
      (process.env.REACT_APP_API_URL! + pathname).replace("mekidoc.cl", ""),
      init
    );
    if (!response.ok) {
      const error = new FetchError("fetch error");
      const data = await response.text();
      error.data = JSON.parse(data);
      throw error;
    }
    try {
      if (response.status === 204) return null as any;
      const json = await response.json();
      return json as T;
    } catch (e) {
      const error = new FetchError("invalid json");
      error.data = await response.text();
      throw error;
    }
  }
  return (
    <FetchContext.Provider value={customFetch}>
      {children}
    </FetchContext.Provider>
  );
}

class FetchError extends Error {
  data: any;
}
export function isFetchError(error: any): error is FetchError {
  return error instanceof FetchError;
}

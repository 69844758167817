import styled from "@emotion/styled";
import { RequestType } from "../../../interfaces";
import { HistoricPrescription } from "./HistoricPrescription";
import { HistoricCompoundedPrescription } from "./HistoricCompoundedPrescription";
import { HistoricMedicalCertificate } from "./HistoricMedicalCertificate";
import { HistoricMedicalOrder } from "./HistoricMedicalOrder";

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  requestType: RequestType;
}

const historicSkeletons: Record<RequestType, () => React.JSX.Element> = {
  [RequestType.PRESCRIPTION]: () => <HistoricPrescription.Skeleton />,
  [RequestType.COMPOUNDED_PRESCRIPTION]: () => (
    <HistoricCompoundedPrescription.Skeleton />
  ),
  [RequestType.MEDICAL_CERTIFICATE]: () => (
    <HistoricMedicalCertificate.Skeleton />
  ),
  [RequestType.MEDICAL_ORDER]: () => <HistoricMedicalOrder.Skeleton />,
};

export function HistoricSkeleton({ requestType }: Props) {
  const SkeletonComponent = historicSkeletons[requestType];
  return (
    <SkeletonContainer>
      {SkeletonComponent && SkeletonComponent()}
    </SkeletonContainer>
  );
}

import { CardInfoText, CardSectionTitle, GappedCardContainer } from "../styles";
import { DoctorBaseAttributes, Gender } from "../../../interfaces";

export const SignatureCard: React.FC<{
  doctorData: Pick<DoctorBaseAttributes, "name" | "lastName" | "gender">;
}> = ({ doctorData }) => {
  const genderString = doctorData.gender === Gender.MALE ? "Dr. " : "Dra. ";
  return (
    <GappedCardContainer gap={"8px"}>
      <CardInfoText>
        Documento emitido por MekiDoc con Firma Electrónica Avanzada - Esign
      </CardInfoText>

      <div>
        <CardSectionTitle>Firmante</CardSectionTitle>
        <CardInfoText>{`${genderString} ${doctorData.name} ${doctorData.lastName}`}</CardInfoText>
      </div>
    </GappedCardContainer>
  );
};

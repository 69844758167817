import { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  useLocation,
  useNavigate,
  useParams,
  Link as RouterLink,
} from "react-router-dom";
import { Box, FormControl, IconButton, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Global } from "@emotion/react";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  DigitalSign,
  FullScreenLoader,
  Header,
  PatientSummaryAccordion,
  SingleFormInput,
  scrollToTop,
  NavigateBeforeButton,
  TextAreaFormInput,
  ExtraWhiteSpace,
  AuthRequiredModal,
  MainActionButton,
  InputFieldWrapper,
} from "@components";
import {
  DocumentCreationLoaderTitle,
  MedicalOrder,
  Patient,
  RequestStatus,
  RequestType,
  patientDefaultValues,
} from "@interfaces";
import { useDoctorContext } from "@contexts";
import { useCreateDocument, useRequest } from "@hooks";
import { theme } from "@config";
import { eraseProcedureCode } from "./utils/parse";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { esES } from "@mui/x-date-pickers/locales";
import es from "date-fns/locale/es";

const StyledProcedureCard = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px",
  borderRadius: "4px",
  minHeight: "58px",
  fontSize: "14px",
  backgroundColor: theme.pallete.background.white,
});

interface ProcedureCardProps {
  procedure: string;
  onClose: () => void;
}

const ProcedureCard = ({ procedure, onClose }: ProcedureCardProps) => {
  return (
    <StyledProcedureCard>
      <Box>{procedure}</Box>
      <IconButton onClick={onClose}>
        <CloseIcon color="primary" />
      </IconButton>
    </StyledProcedureCard>
  );
};

const ProceduresTitle = styled(Box)({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "23px",
  margin: "16px 0",
  color: theme.pallete.text.black,
});

const ProcedureCardsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const MainActionButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  padding: "8px 0px",
});

const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: "20px",
  marginTop: "20px",
});

const StyledDateField = styled(DateField)<{ error: boolean }>({
  backgroundColor: theme.pallete.background.white,
  ...(props) =>
    props.error && {
      border: "1px solid red",
      borderRadius: "4px",
    },
});

interface LocationState {
  repeatedDocumentDetails?: string[];
  repeatedDiagnosticHypothesis?: string;
  repeatedIndications?: string;
  attachedProcedures?: string[];
}

export function MedicalOrderCreate() {
  const doctor = useDoctorContext();
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  const { data: request } = useRequest();
  const location = useLocation();
  const {
    repeatedDiagnosticHypothesis,
    repeatedDocumentDetails,
    repeatedIndications,
  }: LocationState = location.state || {};
  const [attachedProcedures, setAttachedProcedures] = useState<string[]>(
    repeatedDocumentDetails ?? []
  );
  const [showFeaAuthModal, setShowFeaAuthModal] = useState<boolean>(false);
  const [showSummaryView, setShowSummaryView] = useState<boolean>(true);
  const patient: Patient = useMemo(() => {
    return request ? request.patient : patientDefaultValues;
  }, [request]);
  const isDraft = useMemo(() => {
    return request?.status === RequestStatus.DRAFT;
  }, [request]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<MedicalOrder>({
    defaultValues: {
      documentDetails: repeatedDocumentDetails ?? [],
      diagnosticHypothesis: repeatedDiagnosticHypothesis ?? "",
      indications: repeatedIndications ?? "",
      effectiveDate: new Date().toISOString(),
    },
  });

  const { mutate: createDocument, isPending } = useCreateDocument(
    RequestType.MEDICAL_ORDER
  );
  const [showDigitalSign, setShowDigitalSign] = useState(false);
  const diagnosticHypothesis = watch("diagnosticHypothesis");
  const indications = watch("indications");
  const effectiveDate = watch("effectiveDate");

  const onProcedureCardClose = useCallback((procedure: string) => {
    setAttachedProcedures((prevProcedures) =>
      prevProcedures.filter((prevProcedure) => prevProcedure !== procedure)
    );
  }, []);

  const handleDocumentDataSubmit = useCallback(() => {
    if (!doctor.isFeaAuthenticated) {
      setShowFeaAuthModal(true);
      return;
    }
    setShowDigitalSign(true);
    scrollToTop();
  }, [doctor]);

  const onDigitalSignSubmit = useCallback(async () => {
    setShowDigitalSign(false);
    createDocument({
      document: {
        effectiveDate,
        documentDetails: attachedProcedures,
        diagnosticHypothesis: diagnosticHypothesis ?? "",
        indications: indications ?? "",
      },
      managementSummary: attachedProcedures
        .map((procedure) => eraseProcedureCode(procedure))
        .join(", "),
    });
  }, [
    createDocument,
    effectiveDate,
    attachedProcedures,
    diagnosticHypothesis,
    indications,
  ]);

  const handleBack = useCallback(() => {
    if (!showSummaryView) {
      setShowSummaryView(true);
      return;
    }
    if (isDraft) {
      navigate(`/app/nuevo-documento/paciente`, {
        state: { documentType: RequestType.MEDICAL_ORDER },
      });
    } else {
      navigate(`/app/solicitudes/${requestId}/gestionar/seleccionar-documento`);
    }
  }, [isDraft, navigate, requestId, showSummaryView]);

  if (showDigitalSign) {
    return (
      <DigitalSign
        documentType={RequestType.MEDICAL_ORDER}
        handleCreateDocument={onDigitalSignSubmit}
        handleBack={() => setShowDigitalSign(false)}
      />
    );
  }

  if (isPending)
    return (
      <FullScreenLoader
        title={DocumentCreationLoaderTitle[RequestType.MEDICAL_ORDER]}
      />
    );
  return (
    <>
      <Header
        title={"Resumen orden"}
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        {showSummaryView ? (
          <>
            <PatientSummaryAccordion
              patient={patient}
              prevRoute={`/app/solicitudes/${requestId}/orden-medica/crear`}
              requestId={requestId}
              isOpen={isDraft && attachedProcedures.length === 0}
              canEdit={isDraft}
            />
            <RouterLink
              to={"buscar"}
              state={{ attachedProcedures, medicalOrderStarted: true }}
            >
              <MainActionButtonContainer>
                <MainActionButton variant="outlined" size="large">
                  Agregar exámenes
                </MainActionButton>
              </MainActionButtonContainer>
            </RouterLink>
            {attachedProcedures.length > 0 && (
              <>
                <ProceduresTitle>Exámenes y procedimientos</ProceduresTitle>
                <ProcedureCardsContainer>
                  {attachedProcedures.map((procedure) => (
                    <ProcedureCard
                      key={procedure}
                      procedure={procedure}
                      onClose={() => {
                        onProcedureCardClose(procedure);
                      }}
                    />
                  ))}
                </ProcedureCardsContainer>
              </>
            )}
            <ExtraWhiteSpace />
            <BottomActionArea>
              <BottomActionButton
                onClick={() => {
                  setShowSummaryView(false);
                }}
                disabled={attachedProcedures.length === 0}
              >
                Siguiente
              </BottomActionButton>
            </BottomActionArea>
          </>
        ) : (
          <FormContainer onSubmit={handleSubmit(handleDocumentDataSubmit)}>
            <SingleFormInput
              title="Hipótesis diagnóstica"
              parameterName="diagnosticHypothesis"
              register={register}
              errors={errors}
            />
            <TextAreaFormInput
              title="Indicaciones"
              parameterName="indications"
              register={register}
              errors={errors}
              minHeight="80px"
            />
            <InputFieldWrapper
              label="Fecha de orden"
              error={errors.effectiveDate?.message}
            >
              <FormControl fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={es}
                  localeText={
                    esES.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                >
                  <Controller
                    name="effectiveDate"
                    control={control}
                    defaultValue={undefined}
                    rules={{
                      required: "Debes seleccionar una fecha.",
                      validate: (value) => {
                        const date = new Date(value);
                        if (date.toString() === "Invalid Date") {
                          return "Fecha incompleta o inválida";
                        }
                        if (date.getFullYear() < 1900) {
                          return "La fecha es demasiado antigua";
                        }
                        return true;
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StyledDateField
                        minDate={new Date("1900-01-01")}
                        value={value ? new Date(value) : null}
                        onChange={(date) => onChange(date)}
                        clearable={true}
                        format="dd/MM/yyyy"
                        error={
                          errors.effectiveDate?.message ===
                          "Debes seleccionar una fecha."
                        }
                        slotProps={{
                          // Disable the blue border on focus when there is an error
                          ...(errors.effectiveDate &&
                            errors.effectiveDate.message ===
                              "Debes seleccionar una fecha." && {
                              textField: {
                                focused: false,
                              },
                            }),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </InputFieldWrapper>

            <ExtraWhiteSpace />
            <BottomActionArea>
              <BottomActionButton type="submit">Siguiente</BottomActionButton>
            </BottomActionArea>
          </FormContainer>
        )}
      </Body>
      <AuthRequiredModal
        requestId={requestId}
        isOpen={showFeaAuthModal}
        onClose={() => setShowFeaAuthModal(false)}
      />
    </>
  );
}

import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarOrigin,
  styled,
} from "@mui/material";
import { theme as oldTheme } from "../config/theme";
import { millisecondsInSecond } from "date-fns";

const StyledSnackBar = styled(Snackbar)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  padding: "0px 32px 0px 16px",
  marginBottom: "70px",
});

export const StyledAlert = styled(Alert)({
  display: "flex",
  alignItems: "center",
  "&.MuiAlert-standardSuccess": {
    backgroundColor: oldTheme.pallete.text.success,
    color: oldTheme.pallete.background.primary,
  },
  "&.MuiAlert-standardError": {
    backgroundColor: oldTheme.pallete.errors.primary,
    color: oldTheme.pallete.background.primary,
  },
  ".MuiAlert-icon": {
    color: oldTheme.pallete.background.primary,
  },
});

interface Props {
  snackBarState: {
    open: boolean;
    type: AlertColor;
  };
  autoHideDuration?: number;
  onClose?: () => void;
  anchorOrigin?: SnackbarOrigin;
  alerts?: Partial<Record<AlertColor, string>>;
  style?: React.CSSProperties;
}

// TODO: Deberíamos hacer toasts más imperativos, que no tengamos que declarar el componente en cada archivo y
// manejar cuando se muestra o no. Podríamos basarnos en como funciona https://www.npmjs.com/package/react-toastify,
// y construirlo usando este mismo snack bar en un contexto, con zustand para manejar el estado de iniciar una
export function SnackBar({
  snackBarState,
  autoHideDuration,
  onClose,
  anchorOrigin,
  alerts,
  style,
}: Props) {
  return (
    <StyledSnackBar
      open={snackBarState.open}
      autoHideDuration={autoHideDuration ?? 2 * millisecondsInSecond}
      onClose={onClose}
      anchorOrigin={
        anchorOrigin ?? { vertical: "bottom", horizontal: "center" }
      }
      style={style}
    >
      {alerts && (
        <StyledAlert severity={snackBarState.type}>
          {alerts[snackBarState.type]}
        </StyledAlert>
      )}
    </StyledSnackBar>
  );
}

import { SearchGeneric, SearchProduct } from "@interfaces";
import Fuse from "fuse.js";

export function calculateQuantityToDispense(
  quantity: number,
  frequency: number,
  duration: number,
  frequencyUnit: string,
  durationUnit: string
): number {
  const conversionFactors: Record<string, number> = {
    hora: 1,
    día: 24,
    semana: 24 * 7,
    mes: 24 * 30,
    año: 24 * 365,
  };

  const frequencyInHours =
    frequency * conversionFactors[frequencyUnit.toLowerCase()];
  const durationInHours =
    duration * conversionFactors[durationUnit.toLowerCase()];

  // Calculate the total quantity of pills to dispense
  const quantityToDispense = quantity * (durationInHours / frequencyInHours);
  return Math.ceil(quantityToDispense);
}

export function trimString(str: string, maxLength: number = 30): string {
  if (!str) return "";
  const trimmedStr = str.replace(/null/g, "").trim();
  const ellipsisStr =
    trimmedStr.length > maxLength
      ? trimmedStr.substring(0, maxLength) + "..."
      : trimmedStr;
  return ellipsisStr;
}

export function trimmedLaboratory(laboratory: string): string {
  const lab = laboratory.includes("Laboratorio")
    ? laboratory.replace(/Laboratorio\s*/g, "Lab.").trim()
    : laboratory;

  const cleanLab = lab
    .replace(/null/g, "")
    .replace(/· $/, "")
    .replace(/^· /, "");

  const labEllipsis =
    cleanLab.length > 30 ? cleanLab.substring(0, 22) + "..." : cleanLab;
  return labEllipsis;
}

export function parseMedicinesText(
  medicines: (SearchProduct | SearchGeneric)[],
  maxLength: number = 40
) {
  return medicines.map((medicine) => {
    const name = "name" in medicine ? medicine.name : medicine.activePrinciple;
    const concentration = medicine.concentration ?? "";
    const trimmedText =
      trimString(name, maxLength) +
      " · " +
      trimString(concentration, maxLength);

    return {
      text: trimmedText,
      medicine: medicine,
    };
  });
}

export function sortMedicineFuseResult(
  fuseResult: Fuse.FuseResult<{
    text: string;
    medicine: SearchProduct;
  }>[]
) {
  return fuseResult
    .map((result) => result.item)
    .filter(({ medicine }) => medicine.availability.status !== "SOLD_OUT")
    .sort((a, b) => {
      const scoreA = fuseResult.find((r) => r.item === a)?.score || 0;
      const scoreB = fuseResult.find((r) => r.item === b)?.score || 0;

      const extractNumber = (str: string) => {
        const match = str.match(/\d+/);
        return match ? parseInt(match[0], 10) : Infinity;
      };
      const numA = extractNumber(a.text);
      const numB = extractNumber(b.text);

      // Sort by fuse score
      if (scoreA !== scoreB) {
        return scoreA - scoreB;
      } else if (numA !== numB) {
        // If the scores are equal, sort by number (concetration)
        return numA - numB;
      } else {
        // If the scores and numbers are equal, sort alphabetically
        return a.text.localeCompare(b.text);
      }
    })
    .map((item) => item.medicine);
}

export function cleanAndFormatProductCardText(text: string) {
  if (!text) return "";
  // Replace all instances of 'null' and 'undefined' with an empty string
  let cleanedAndFormattedText = text.replace(/null|undefined/g, "").trim();
  // Remove floating '· ' at the beginning and ' ·' at the end
  cleanedAndFormattedText = cleanedAndFormattedText
    .replace(/^·\s*/, "")
    .replace(/\s*·$/, "");
  // Replace multiple '·' with a single '·'
  cleanedAndFormattedText = cleanedAndFormattedText.replace(/\s*·\s*/g, " · ");
  // Remove consecutive '· ·' with a single '·'
  cleanedAndFormattedText = cleanedAndFormattedText.replace(/·\s*·+/g, "·");
  // Remove trailing '·' if it is the last character
  cleanedAndFormattedText = cleanedAndFormattedText.replace(/·\s*$/, "");
  return cleanedAndFormattedText;
}

export function productSubCategoriesDetails(product: SearchProduct) {
  const allowedSubCategoriesNames = [
    "Suplementos y multivitamínicos",
    "Vitaminas y minerales",
    "Probióticos",
  ];
  const productSubCategoriesNames = product.subCategories?.map(
    (subCategory) => subCategory.name
  );
  const isInAnotherAllowedSubCategories =
    productSubCategoriesNames &&
    productSubCategoriesNames.some((subCategoryName) =>
      allowedSubCategoriesNames.includes(subCategoryName)
    );

  return { isMedicine: product.isMedicine, isInAnotherAllowedSubCategories };
}

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { api } from "../context";
import { Request } from "../interfaces";
import { millisecondsInMinute } from "date-fns";

export const useRequest = () => {
  const { requestId = "" } = useParams();
  return useQuery({
    queryKey: ["request", requestId],
    queryFn: () =>
      api<Request>({
        url: `/requests/${requestId}`,
      }),
    staleTime: millisecondsInMinute * 5,
  });
};

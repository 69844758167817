import { cloneElement } from "react";
import {
  Box,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Body, BottomActionArea, BottomActionButton } from "@components";
import { theme as oldTheme } from "../../config/theme";
import { Global } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import { DocumentsSvg, HomeSvg, PatientLinkSvg, PinSvg } from "./svg";

const SuccessAuthContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "60vh",
  fontSize: "1.25rem",
  fontWeight: 500,
  textAlign: "center",
  color: oldTheme.pallete.text.black,
  backgroundColor: oldTheme.pallete.background.white,
});

const TransparentCircle = styled("img", {
  shouldForwardProp: (prop) => prop !== "imgSide",
})<{ imgSide: "left" | "right" }>(({ theme, imgSide }) => ({
  position: "absolute",
  zIndex: -1,
  ...(imgSide === "left" && {
    left: "0",
    width: "180px",
    transform: "translateY(50%)",
    [theme.breakpoints.up("sm")]: {
      width: "250px",
      transform: "translateY(35%)",
    },
  }),
  ...(imgSide === "right" && {
    right: "0",
    width: "260px",
    transform: "translateY(175%)",
    [theme.breakpoints.up("sm")]: {
      width: "320px",
    },
  }),
}));

const MekidocFeaturesWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  padding: "24px 0px 48px 0px",
});

const MekidocFeaturesContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "48px",
});

const SectionTitle = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: 500,
  textAlign: "center",
  color: oldTheme.pallete.text.primaryDark,
});

const CardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "gap" && prop !== "maxWidth",
})<{ gap?: string; maxWidth: string }>(({ gap, maxWidth }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: gap ?? "16px",
  maxWidth: maxWidth,
}));

const Description = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 400,
  textAlign: "center",
  color: oldTheme.pallete.text.black,
});

const Bold = styled("span")({
  fontSize: "1rem",
  fontWeight: 600,
});

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  fontSize: "80px",
  color: oldTheme.pallete.text.primary,
  marginBottom: "24px",
});

interface FeaturesInfoProps {
  description: JSX.Element;
  component: JSX.Element;
  containerMaxWidth: string;
}

const featuresInfo = [
  {
    description: (
      <Description>
        Comparte el <Bold>formulario para pacientes</Bold> y ellos completarán
        sus datos
      </Description>
    ),
    component: <PatientLinkSvg />,
    containerMaxWidth: "320px",
  },
  {
    description: (
      <Description>
        Recibe y gestiona las solicitudes de tus pacientes en{" "}
        <Bold>un sólo lugar</Bold>
      </Description>
    ),
    component: <HomeSvg />,
    containerMaxWidth: "300px",
  },
  {
    description: (
      <Description>
        Revisa y <Bold>crea fácilmente</Bold> documentos médicos
      </Description>
    ),
    component: <DocumentsSvg />,
    containerMaxWidth: "300px",
  },
  {
    description: (
      <Description>
        Emite recetas con <Bold>Firma Electrónica Avanzada</Bold>
      </Description>
    ),
    component: <PinSvg />,
    containerMaxWidth: "280px",
  },
] as const;

export const FeatureExplanationCard: React.FC<FeaturesInfoProps> = ({
  description,
  component,
  containerMaxWidth,
}) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const StyledFeatureComponent = cloneElement(component, {
    width: isSmUp ? "400px" : "300px",
    height: "auto",
  });
  return (
    <CardContainer maxWidth={containerMaxWidth}>
      {description}
      {StyledFeatureComponent}
    </CardContainer>
  );
};

export function SuccessfulAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const { redirectTo }: { redirectTo: string } = location.state || {
    redirectTo: "/app/solicitudes",
  };
  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <SuccessAuthContainer>
        <StyledCheckCircleIcon />
        <CardContainer gap="8px" maxWidth="300px">
          Verificación exitosa.
          <Description>Ya puedes emitir tu primer documento médico</Description>
        </CardContainer>
      </SuccessAuthContainer>
      <Body>
        <TransparentCircle
          src="/esign/success/transparent_circle_left.svg"
          imgSide="left"
        />
        <TransparentCircle
          src="/esign/success/transparent_circle_right.svg"
          imgSide="right"
        />
        <MekidocFeaturesWrapper>
          <SectionTitle>¿Cómo funciona MekiDoc?</SectionTitle>
          <MekidocFeaturesContainer>
            {featuresInfo.map(
              ({ description, component, containerMaxWidth }, i) => (
                <FeatureExplanationCard
                  key={i}
                  description={description}
                  component={component}
                  containerMaxWidth={containerMaxWidth}
                />
              )
            )}
          </MekidocFeaturesContainer>
        </MekidocFeaturesWrapper>
        <BottomActionArea>
          <BottomActionButton
            onClick={() => {
              navigate(redirectTo);
            }}
          >
            Comenzar
          </BottomActionButton>
        </BottomActionArea>
      </Body>
    </>
  );
}

export type Step = "validatingClaveUnicaLogin" | "ciDocForm" | "questionsForm";

export interface CIDocFormData {
  docNum: string;
  name: string;
  firstSurname: string;
  secondSurname: string;
  birthdate: string;
}

interface Option {
  id: string;
  descripcion: string;
}

export interface Question {
  id: string;
  descripcion: string;
  alternativas: Option[];
}

interface ValidatedDoctorData {
  name: string;
  firstSurname: string;
  secondSurname: string;
  birthdate: string;
}
export interface ValidationChallengePayload {
  ciDocNum: string;
  validatedDoctorData?: ValidatedDoctorData;
}

export interface ValidationChallengeResponse {
  preguntas: Question[];
}

export interface PinState {
  pin: string;
  error: boolean;
  errorMessage: string;
}

export const defaultPinState: PinState = {
  pin: "",
  error: false,
  errorMessage: "",
};

export enum MekidocEsignError {
  PIN_INCORRECT = "PIN incorrecto",
  PIN_BLOCKED = "PIN bloqueado",
  UNKNOWN = "Ha ocurrido un error",
}

interface PinError {
  mekidocError: MekidocEsignError;
  esignError: string;
}
export function pinErrorMapper(errorMessage: string): PinError {
  let mekidocError: MekidocEsignError;
  if (!errorMessage) {
    mekidocError = MekidocEsignError.UNKNOWN;
  } else if (errorMessage.includes("Firma Bloqueada")) {
    mekidocError = MekidocEsignError.PIN_BLOCKED;
  } else {
    mekidocError = MekidocEsignError.PIN_INCORRECT;
  }
  return { mekidocError, esignError: errorMessage };
}

export function extractBlockingTime(errorMessage: string): string {
  const regex = /(\d+h:\d+m:\d+s)/;
  const match = errorMessage.match(regex);
  return match ? match[0] : "30 min";
}

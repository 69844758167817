import { Box, styled } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutline";
import { theme as oldTheme } from "../config";

const ColoredContainer = styled(Box)<{ color?: string }>((props) => ({
  display: "flex",
  alignContent: "center",
  justifyContent: "flex-start",
  alignItems: "center",
  widht: "100%",
  borderRadius: "4px",
  padding: "16px",
  backgroundColor: props.color,
}));

const DefaultInfoIcon = styled(InfoOutlinedIcon)({
  fontSize: "24px",
  marginRight: "12px",
  color: "white",
});

const StyledInfoIcon = styled(DefaultInfoIcon)({
  color: oldTheme.pallete.info.primary,
});

const DefaultSuccessIcon = styled(CheckCircleOutlinedIcon)({
  fontSize: "24px",
  marginRight: "12px",
  color: "white",
});

const DefaultErrorIcon = styled(ErrorOutlinedIcon)({
  fontSize: "24px",
  marginRight: "12px",
  color: "white",
});

export enum IconOptions {
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  STYLED_INFO = "STYLED_INFO",
}

const DefaultIconRecord: Record<IconOptions, React.ReactNode> = {
  [IconOptions.INFO]: <DefaultInfoIcon />,
  [IconOptions.SUCCESS]: <DefaultSuccessIcon />,
  [IconOptions.ERROR]: <DefaultErrorIcon />,
  [IconOptions.STYLED_INFO]: <StyledInfoIcon />,
};

interface ColoredComponentProps {
  color?: string;
  icon?: IconOptions;
  text?: React.ReactNode;
}

export function ColoredComponent({ color, icon, text }: ColoredComponentProps) {
  return (
    <ColoredContainer color={color}>
      {icon && <>{DefaultIconRecord[icon]}</>}
      {text}
    </ColoredContainer>
  );
}

import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { api, queryClient } from "../context";
import {
  Document,
  DocumentCreationEndpoint,
  DocumentPDFDataResponse,
  RequestType,
  requestTypeNavigateParser,
} from "../interfaces";

interface CreateDocumentPayload {
  document: Document;
  managementSummary?: string;
}

export const useCreateDocument = (documentType: RequestType) => {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const createDocument = ({
    document,
    managementSummary,
  }: CreateDocumentPayload) => {
    return api<DocumentPDFDataResponse>({
      method: "post",
      url: `/requests/${requestId}/${DocumentCreationEndpoint[documentType]}`,
      data: {
        document,
        managementSummary,
      },
    });
  };
  return useMutation({
    mutationFn: createDocument,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["requests"] });
      queryClient.invalidateQueries({
        queryKey: ["request", requestId],
      });
      navigate(
        `/app/solicitudes/${requestId}/${requestTypeNavigateParser[documentType]}/exito`,
        {
          state: {
            documentPDFData: data.requestPDFData,
          },
        }
      );
    },
  });
};

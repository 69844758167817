import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import {
  Body,
  BottomActionArea,
  BottomActionButton,
  Header,
  Modal,
  SnackBar,
  NavigateBeforeButton,
} from "../../../components";
import { StepLabel } from "./../styles";
import { FormContainer } from "../../../styles/styled";
import styled from "@emotion/styled";
import { Global } from "@emotion/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { api } from "../../../context";
import { isAxiosError } from "axios";
import {
  Question,
  SnackbarState,
  Step,
  ValidationChallengePayload,
} from "../../../interfaces";
import { theme } from "../../../config";
import { millisecondsInSecond } from "date-fns";

interface Props {
  questions?: Question[];
  setStep: React.Dispatch<React.SetStateAction<Step>>;
  createValidationChallenge: UseMutateFunction<
    Question[],
    Error,
    ValidationChallengePayload,
    unknown
  >;
  isCreatingValidationChallenge: boolean;
  validationChallengePayload: ValidationChallengePayload;
}

interface Answer {
  pregunta: number;
  respuesta: number;
}

const CenteredContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  gap: "1rem",
});

const PaddedRadioGroup = styled(RadioGroup)({
  paddingInline: "16px",
});

const Title = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 400,
});

export const QuestionsChallengeForm: React.FC<Props> = ({
  questions,
  setStep,
  createValidationChallenge,
  isCreatingValidationChallenge,
  validationChallengePayload,
}) => {
  const [searchParams] = useSearchParams();
  const [showRetriesExceededModal, setShowRetriesExceededModal] =
    useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
    reset,
  } = useForm({ mode: "onChange" });
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    isVisible: false,
    severity: "error",
  });

  const { mutate: validateChallenge, isPending } = useMutation({
    mutationFn: (respuestas: Answer[]) =>
      api({
        method: "post",
        url: "/esign/validate-validation-challenge",
        data: { respuestas },
      }),
    onSuccess: () => {
      const requestId = searchParams.get("requestId");
      const queryParams = requestId ? `?requestId=${requestId}` : "";
      navigate(`../crear-pin${queryParams}`);
    },
    onError: async (err) => {
      // 1015 -> Invalid Answers
      if (isAxiosError(err) && err.response?.data?.code === "1015") {
        reset();
        setSnackbarState((prevState) => ({ ...prevState, isVisible: true }));
        createValidationChallenge(validationChallengePayload, {
          // 1010 -> ciDoc doesnt match person. This should't happen on this screen
          onError(error) {
            if (isAxiosError(error) && error.response?.data?.code !== "1010") {
              setShowRetriesExceededModal(true);
            }
          },
        });
      }
    },
  });
  const navigate = useNavigate();
  const handleBack = useCallback(() => {
    if (showRetriesExceededModal) {
      return navigate("/app/solicitudes");
    }
    setStep("ciDocForm");
  }, [navigate, setStep, showRetriesExceededModal]);

  const onSubmit = async (data: Record<string, string>) => {
    const answers: Answer[] = Object.entries(data).map(([key, value]) => ({
      pregunta: Number(key),
      respuesta: Number(value),
    }));
    validateChallenge(answers);
  };
  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Header
        title="Verificación de identidad"
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Body>
        {isCreatingValidationChallenge && (
          <CenteredContainer>
            <Title>Buscando nuevas preguntas de seguridad</Title>
            <CircularProgress
              size={24}
              style={{ color: theme.pallete.text.primary }}
            />
          </CenteredContainer>
        )}
        {!isCreatingValidationChallenge && (
          <>
            <StepLabel>Paso 3 de 3</StepLabel>
            <Typography>Responde estas preguntas de seguridad</Typography>
            <FormContainer onSubmit={handleSubmit(onSubmit)}>
              {questions?.map(({ id: qID, descripcion, alternativas }) => (
                <div key={qID}>
                  <Typography fontWeight={500} marginBottom={"16px"}>
                    {qID}. {descripcion}
                  </Typography>
                  <Controller
                    control={control}
                    name={`${qID}`}
                    defaultValue=""
                    render={({ field }) => (
                      <PaddedRadioGroup
                        {...register(`${qID}`, { required: true })}
                        {...field}
                      >
                        {alternativas.map(({ id: answerID, descripcion }) => (
                          <FormControlLabel
                            key={`${qID}-${answerID}`}
                            value={answerID}
                            control={<Radio size="small" />}
                            label={descripcion}
                          />
                        ))}
                      </PaddedRadioGroup>
                    )}
                  />
                </div>
              ))}
              <BottomActionArea>
                <BottomActionButton
                  type="submit"
                  disabled={!isValid || isPending}
                >
                  {isPending ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Siguiente"
                  )}
                </BottomActionButton>
              </BottomActionArea>
            </FormContainer>
          </>
        )}
      </Body>
      <SnackBar
        snackBarState={{
          open: snackbarState.isVisible,
          type: snackbarState.severity,
        }}
        onClose={() =>
          setSnackbarState((prevState) => ({ ...prevState, isVisible: false }))
        }
        autoHideDuration={3 * millisecondsInSecond}
        alerts={{
          error: "Algunas respuestas estaban incorrectas. Inténtalo nuevamente",
        }}
      />
      {showRetriesExceededModal && (
        <Modal
          title="Superaste el máximo de intentos"
          isOpen={showRetriesExceededModal}
          buttonProps={{
            buttonText: "Volver al inicio",
            onClick: () => {
              navigate("/app/solicitudes");
            },
          }}
        >
          <DialogContent style={{ padding: "16px 0px" }}>
            <Typography textAlign={"center"} marginBottom={"16px"}>
              Puedes volver a intentarlo después de 24 hrs.
            </Typography>
          </DialogContent>
        </Modal>
      )}
    </>
  );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import { Suggestion } from "@interfaces";
import { theme } from "@config";
import { useNavigate } from "react-router-dom";

const SuggestionsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  margin: "0px -16px",
});

const SuggestionRow = styled("button")({
  border: "none",
  background: "white",
  padding: "12px 16px",
  width: "100%",
  cursor: "pointer",
  display: "flex",
  justifyContent: "flex-start",
  gap: "16px",
  alignItems: "center",
  borderBottom: "1px solid #eaeaea",
  "&:hover": {
    background: theme.pallete.background.lightGray,
  },
});

const CenteredSuggestionRow = styled(SuggestionRow)({
  justifyContent: "center",
  gap: "8px",
});

const SuggestionTitle = styled(Typography)({
  fontSize: "14px",
  textAlign: "left",
  color: theme.pallete.text.gray,
});

const MainSuggestionTitle = styled(SuggestionTitle)({
  color: theme.pallete.text.primary,
  fontWeight: 500,
});

const SecondarySuggestionTitle = styled(SuggestionTitle)({
  color: theme.pallete.text.gray,
  fontWeight: 400,
});

const IconContainer = styled(Box)({
  display: "flex",
  fontSize: "24px",
  width: "24px",
  height: "24px",
  color: theme.pallete.text.gray,
});

const MainIconContainer = styled(IconContainer)({
  color: theme.pallete.text.primary,
});

const MostCommonQueriesContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  borderBottom: "1px solid #eaeaea",
  padding: "12px 8px",
  background: "#f5f5f5",
});

const MostCommonQueriesTitle = styled(Typography)({
  fontSize: "14px",
  fontWeight: 600,
  color: theme.pallete.text.black,
});

interface Props {
  searchText: string;
  preliminarySearchResults: Suggestion[];
  onItemClick: (item: Suggestion) => void;
  showMostSearchedQueries: boolean;
  mostSearchedQueries: Suggestion[];
  addManuallyItemText: string;
  locationState?: any;
}

export function PreliminarySearchResults({
  searchText,
  preliminarySearchResults,
  onItemClick,
  showMostSearchedQueries,
  mostSearchedQueries,
  addManuallyItemText,
  locationState,
}: Props) {
  const navigate = useNavigate();

  const suggestionsInitialState = useMemo(() => {
    return {
      preliminarySearchResults: preliminarySearchResults.slice(0, 8),
      showMoreResultsButton: preliminarySearchResults.length > 8,
    };
  }, [preliminarySearchResults]);

  const [preliminarySearchResultsToShow, setPreliminarySearchResultsToShow] =
    useState<Suggestion[]>(suggestionsInitialState.preliminarySearchResults);
  const [showMoreResultsButton, setShowMoreResultsButton] = useState(
    suggestionsInitialState.showMoreResultsButton
  );

  const handleAddManually = useCallback(() => {
    navigate("../añadir-manual", {
      state: {
        searchedQuery: searchText,
        ...locationState,
      },
    });
  }, [locationState, navigate, searchText]);

  const handleShowMoreResults = useCallback(() => {
    setPreliminarySearchResultsToShow(preliminarySearchResults);
    setShowMoreResultsButton(false);
  }, [preliminarySearchResults]);

  useEffect(() => {
    function limitSuggestionsOnSearchTextChange() {
      setPreliminarySearchResultsToShow(
        suggestionsInitialState.preliminarySearchResults
      );
      setShowMoreResultsButton(suggestionsInitialState.showMoreResultsButton);
    }
    limitSuggestionsOnSearchTextChange();
  }, [searchText, suggestionsInitialState]);

  if (showMostSearchedQueries && mostSearchedQueries) {
    return (
      <PreliminarySearchResults.MostSearchedQueries
        onItemClick={onItemClick}
        mostSearchedQueries={mostSearchedQueries}
        addManuallyItemText={addManuallyItemText}
        handleAddManually={handleAddManually}
      />
    );
  }

  return (
    <SuggestionsContainer>
      {preliminarySearchResultsToShow.map((item, i) => (
        <SuggestionRow
          key={`preliminary-${i}`}
          onClick={() => onItemClick(item)}
        >
          <IconContainer>
            <SearchIcon />
          </IconContainer>
          <SuggestionTitle>{item.text}</SuggestionTitle>
        </SuggestionRow>
      ))}
      {showMoreResultsButton && (
        <CenteredSuggestionRow onClick={handleShowMoreResults}>
          <SecondarySuggestionTitle>
            Mostrar más resultados
          </SecondarySuggestionTitle>
          <IconContainer>
            <KeyboardArrowDownIcon />
          </IconContainer>
        </CenteredSuggestionRow>
      )}
      {addManuallyItemText && (
        <CenteredSuggestionRow onClick={handleAddManually}>
          <MainSuggestionTitle>{addManuallyItemText}</MainSuggestionTitle>
          <MainIconContainer>
            <AddIcon />
          </MainIconContainer>
        </CenteredSuggestionRow>
      )}
    </SuggestionsContainer>
  );
}

PreliminarySearchResults.MostSearchedQueries = ({
  onItemClick,
  mostSearchedQueries,
  addManuallyItemText,
  handleAddManually,
}: {
  onItemClick: (item: Suggestion) => void;
  mostSearchedQueries: Suggestion[];
  addManuallyItemText: string;
  handleAddManually: () => void;
}) => {
  return (
    <SuggestionsContainer>
      <MostCommonQueriesContainer>
        <MostCommonQueriesTitle>Búsquedas populares</MostCommonQueriesTitle>
      </MostCommonQueriesContainer>
      {mostSearchedQueries.map((item: Suggestion, i: number) => (
        <SuggestionRow
          key={`preliminary-${i}`}
          onClick={() => onItemClick(item)}
        >
          <IconContainer>
            <SearchIcon />
          </IconContainer>
          <SuggestionTitle>{item.text}</SuggestionTitle>
        </SuggestionRow>
      ))}
      {addManuallyItemText && (
        <CenteredSuggestionRow onClick={handleAddManually}>
          <MainSuggestionTitle>{addManuallyItemText}</MainSuggestionTitle>
          <MainIconContainer>
            <AddIcon />
          </MainIconContainer>
        </CenteredSuggestionRow>
      )}
    </SuggestionsContainer>
  );
};

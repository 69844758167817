import React from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  BottomActionButton,
  ButtonsColumnContainer,
  SecondaryActionButton,
} from "@components";
import { theme as oldTheme } from "../config";

const StyledDialog = styled(Dialog)({
  ".MuiDialog-paper": {
    maxWidth: "400px",
    borderRadius: "8px",
  },
});

const DialogContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "24px 24px 12px 24px",
});

const CenteredDialogContainer = styled(DialogContainer)({
  alignItems: "center",
  justifyContent: "center",
  padding: "24px 12px 12px 12px",
});

const StyledDialogTitle = styled(DialogTitle)<{ fontSize: string }>(
  ({ fontSize }) => ({
    fontSize,
    fontWeight: 500,
    textAlign: "center",
    color: oldTheme.pallete.text.black,
    padding: "0px 32px",
  })
);

export const DialogContent = styled("span")<{ fontSize: string }>(
  ({ fontSize }) => ({
    fontSize,
    fontWeight: 400,
    padding: "24px 0px",
    color: oldTheme.pallete.text.black,
    textAlign: "center",
    display: "block",
    wordWrap: "break-word",
    whiteSpace: "normal",
  })
);

const ModalCloseButton = styled(IconButton)({
  position: "absolute",
  top: "12px",
  right: "8px",
  color: oldTheme.pallete.text.black,
});

interface ModalButtonProps {
  buttonText: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
}

export enum ModalStyleType {
  CENTERED = "centered",
  DEFAULT = "default",
}

export interface ModalProps {
  title?: React.ReactNode;
  content?: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  buttonProps?: ModalButtonProps;
  secondaryButtonProps?: ModalButtonProps;
  modalStyles?: {
    title?: string;
    content?: string;
    type?: ModalStyleType;
  };
}

export const defaultModalState = {
  title: "",
  content: "",
  isOpen: false,
  onClose: () => {},
  buttonProps: {
    buttonText: "",
    onClick: () => {},
    loading: false,
    disabled: false,
  },
  secondaryButtonProps: {
    buttonText: "",
    onClick: () => {},
    loading: false,
    disabled: false,
  },
  modalStyles: {
    title: "20px",
    content: "16px",
    type: ModalStyleType.DEFAULT,
  },
};

export const Modal: React.FC<ModalProps> = ({
  title,
  content,
  isOpen,
  onClose,
  children,
  buttonProps,
  secondaryButtonProps,
  modalStyles,
}) => {
  const styles = {
    title: modalStyles?.title || defaultModalState.modalStyles.title,
    content: modalStyles?.content || defaultModalState.modalStyles.content,
    type: modalStyles?.type || defaultModalState.modalStyles.type,
  };
  const SelectedDialogContainer =
    styles.type === "centered" ? CenteredDialogContainer : DialogContainer;
  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <SelectedDialogContainer>
        {title && (
          <StyledDialogTitle fontSize={styles.title}>{title}</StyledDialogTitle>
        )}
        {onClose && (
          <ModalCloseButton onClick={onClose}>
            <CloseIcon />
          </ModalCloseButton>
        )}

        {content && (
          <DialogContent fontSize={styles.content}>{content}</DialogContent>
        )}

        {children}
        {buttonProps && (
          <ButtonsColumnContainer>
            <BottomActionButton
              onClick={buttonProps.onClick}
              disabled={buttonProps.loading || buttonProps.disabled}
            >
              {buttonProps.loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                `${buttonProps.buttonText}`
              )}
            </BottomActionButton>
            {secondaryButtonProps && (
              <SecondaryActionButton onClick={secondaryButtonProps.onClick}>
                {secondaryButtonProps.buttonText}
              </SecondaryActionButton>
            )}
          </ButtonsColumnContainer>
        )}
      </SelectedDialogContainer>
    </StyledDialog>
  );
};

import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Global } from "@emotion/react";
import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { AuthStep } from "@interfaces";
import {
  Body,
  BottomActionButton,
  ColoredComponent,
  IconOptions,
  SecondaryActionButton,
} from "@components";
import { AuthFeedbackModal } from "../components";
import { useAuth, useDoctorContext } from "@contexts";
import { useEditDoctor } from "@hooks";
import { StyledErrorIcon, StyledSuccessIcon } from "@styles/styled";
import MekidocIcon from "../../../icons/MekiDocIcon";
import { theme as oldTheme } from "../../../config";

const BiometricWrapper = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "48px 0px 32px 0px",
});

const BiometricContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "32px",
});

const BiometricTextContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "16px",
});

const BiometricText = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  color: oldTheme.pallete.text.black,
});

const BiometricIconsContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: "16px",
});

const BiometricDisclaimer = styled(Typography)({
  fontSize: "14px",
  color: "#0D3C61",
});

const ButtonsContainer = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-evenly",
});

export const BiometricRegistration: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accountCreated = false }: { accountCreated: boolean } =
    location.state || {};

  const doctor = useDoctorContext();
  const { mutate: editDoctor } = useEditDoctor({
    code: doctor.code,
  });

  const {
    authChallengeFeedback: { message: authFeedback, authStep },
    biometricSignUp,
  } = useAuth();
  const feedbackIcon = (authStep: AuthStep) => {
    switch (authStep) {
      case AuthStep.SUCCESS:
        return <StyledSuccessIcon />;
      case AuthStep.ERROR:
        return <StyledErrorIcon />;
      default:
        return <CircularProgress size={24} color="primary" />;
    }
  };

  const biometricRedirectResolver = useCallback(() => {
    const navigateTo = accountCreated
      ? "/app/verificacion-identidad"
      : "/app/solicitudes";
    return navigate(navigateTo);
  }, [accountCreated, navigate]);

  const onBiometricSignUp = useCallback(async () => {
    const signUpSuccess = await biometricSignUp(doctor);
    if (signUpSuccess) {
      editDoctor({
        showBiometricRegistration: false,
        biometricIsEnabled: true,
      });
    }
    return biometricRedirectResolver();
  }, [doctor, biometricSignUp, editDoctor, biometricRedirectResolver]);

  const cancelBiometricSignUp = useCallback(() => {
    editDoctor({
      showBiometricRegistration: false,
    });
    return biometricRedirectResolver();
  }, [editDoctor, biometricRedirectResolver]);

  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <BiometricWrapper>
          <BiometricContainer>
            <MekidocIcon size={"medium"} />
            <BiometricTextContainer>
              <BiometricText>
                ¿Quieres activar tu huella o Face ID para entrar la próxima vez?
              </BiometricText>
            </BiometricTextContainer>

            <BiometricIconsContainer>
              <img src="/biometric/fingerPrint.svg" alt="huella" />
              <img src="/biometric/faceId.svg" alt="faceID" />
            </BiometricIconsContainer>

            <ColoredComponent
              color="#E9F5FE"
              icon={IconOptions.STYLED_INFO}
              text={
                <BiometricDisclaimer>
                  Todas las huellas registradas en tu dispositivo tendrán acceso
                  a la aplicación
                </BiometricDisclaimer>
              }
            />
          </BiometricContainer>
          <ButtonsContainer>
            <SecondaryActionButton
              onClick={cancelBiometricSignUp}
              sx={{ minWidth: "auto" }}
            >
              No gracias
            </SecondaryActionButton>
            <BottomActionButton
              onClick={onBiometricSignUp}
              size="small"
              sx={{ minWidth: "auto" }}
            >
              Activar
            </BottomActionButton>
          </ButtonsContainer>
        </BiometricWrapper>

        {authFeedback && (
          <AuthFeedbackModal
            isOpen={true}
            authFeedback={authFeedback}
            authStep={authStep}
            feedbackIcon={feedbackIcon}
          />
        )}
      </Body>
    </>
  );
};

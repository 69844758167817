import {
  addMonths,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { getUnitForQuantity } from "./units";

interface GetDifferenceAndCurrentAnniversaryProps {
  startDate: Date;
  endDate: Date;
  differenceFn: (dateLeft: Date, dateRight: Date) => number;
  addFn: (date: Date, amount: number) => Date;
}

function getDifferenceAndCurrentAnniversary({
  startDate,
  endDate,
  differenceFn,
  addFn,
}: GetDifferenceAndCurrentAnniversaryProps) {
  let units = differenceFn(endDate, startDate);
  let currentAnniversary = addFn(startDate, units);
  if (currentAnniversary > endDate) {
    units--;
    currentAnniversary = addFn(startDate, units);
  }
  return { units, currentAnniversary };
}

export function getBirthdateInfo(date: string) {
  const birthdate = new Date(date);
  const now = new Date();
  const { units: years, currentAnniversary: currentYearAnniversary } =
    getDifferenceAndCurrentAnniversary({
      startDate: birthdate,
      endDate: now,
      differenceFn: differenceInYears,
      addFn: addYears,
    });
  const { units: months, currentAnniversary: currentMonthAnniversary } =
    getDifferenceAndCurrentAnniversary({
      startDate: currentYearAnniversary,
      endDate: now,
      differenceFn: differenceInMonths,
      addFn: addMonths,
    });
  const days = differenceInDays(now, currentMonthAnniversary) + 1;
  return {
    years,
    months: years <= 4 ? months : 0,
    days: years <= 1 && months <= 3 ? days : 0,
  };
}

export function formatAge(date: string) {
  const { years, months, days } = getBirthdateInfo(date);
  const formattedAge = [];

  if (years > 0)
    formattedAge.push(`${years} ${getUnitForQuantity("año", years)}`);

  if (months > 0)
    formattedAge.push(`${months} ${getUnitForQuantity("mes", months)}`);

  if (days > 0) formattedAge.push(`${days} ${getUnitForQuantity("día", days)}`);

  return formattedAge.join(", ");
}

export function getBirthdateDetails(birthdate: string | undefined) {
  const defaultReturn = {
    age: "",
    formattedBirthdate: "",
    weightNeeded: false,
  };
  if (!birthdate) return defaultReturn;
  try {
    if (new Date(birthdate) >= new Date()) return defaultReturn;
    const formattedBirthdate = parseRNPIDoctorBirthdate(birthdate);
    const { years } = getBirthdateInfo(birthdate);
    const weightNeeded = years <= 15;
    return {
      age: formatAge(birthdate),
      formattedBirthdate,
      weightNeeded,
    };
  } catch (error) {
    console.log(error);
    return defaultReturn;
  }
}

export function parseRNPIDoctorBirthdate(birthdate: string) {
  try {
    const formattedBirthdate = new Date(birthdate)
      .toISOString()
      .replace(/T.*/, "")
      .split("-")
      .reverse()
      .join("/");
    return formattedBirthdate;
  } catch (error) {
    return "";
  }
}

import { useEffect, useRef } from "react";
import {
  Checkbox as MuiCheckbox,
  FormControl,
  Typography,
  styled,
} from "@mui/material";
import { theme as oldTheme } from "../config";
import { privacyPolicyLink, termsAndConditionsLink } from "../constants";

const StyledFormControl = styled(FormControl)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledCheckbox = styled(MuiCheckbox)<{ error: boolean }>((props) => ({
  color: props.error
    ? oldTheme.pallete.errors.primary
    : oldTheme.pallete.text.lightGray,
  "&.MuiCheckbox-root": {
    padding: "0",
    marginRight: "12px",
  },
}));

const TermAndConditionsText = styled(Typography)<{ error: boolean }>(
  (props) => ({
    fontSize: "12px",
    color: props.error
      ? oldTheme.pallete.errors.primary
      : oldTheme.pallete.text.gray,
  })
);

const Link = styled("a")<{ error: boolean }>((props) => ({
  fontSize: "12px",
  textDecorationLine: "underline",
  textAlign: "center",
  color: props.error
    ? oldTheme.pallete.errors.primary
    : oldTheme.pallete.text.gray,
  cursor: "pointer",
}));

interface CheckboxProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: React.ReactNode;
  error?: boolean;
}

export const Checkbox = ({
  checked,
  onChange,
  label,
  error,
}: CheckboxProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (error) {
      if (!ref.current) return;
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [ref, error]);
  return (
    <StyledFormControl>
      <StyledCheckbox
        checked={checked}
        onChange={onChange}
        error={error || false}
        ref={ref}
      />
      {label}
    </StyledFormControl>
  );
};

interface TermsAndConditionsCheckBoxProps {
  termsChecked: boolean;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
}

export const TermsAndConditionsCheckBox = ({
  termsChecked,
  handleCheckboxChange,
  error,
}: TermsAndConditionsCheckBoxProps) => {
  return (
    <Checkbox
      checked={termsChecked}
      onChange={handleCheckboxChange}
      error={error}
      label={
        <TermAndConditionsText error={error}>
          Acepto los{" "}
          <Link
            target="_blank"
            rel="noopener"
            href={termsAndConditionsLink}
            error={error}
          >
            términos y condiciones
          </Link>{" "}
          y las{" "}
          <Link
            target="_blank"
            rel="noopener"
            href={privacyPolicyLink}
            error={error}
          >
            políticas de privacidad
          </Link>
        </TermAndConditionsText>
      }
    />
  );
};

import { Box, styled } from "@mui/material";
import MekidocIcon from "../icons/MekiDocIcon";

const Root = styled(Box)({
  backgroundColor: "white",
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
});

export const MekiDocSplashScreen: React.FC = () => {
  return (
    <Root>
      <MekidocIcon size={"medium"} />
    </Root>
  );
};

import { Box, styled } from "@mui/material";
import OtpInput from "react-otp-input";
import { theme as oldTheme } from "../config/theme";

const CodeInputsWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

interface CodeInputStyleProps {
  isValid: boolean;
  addSpace: boolean;
  disabled: boolean;
  secretInputs?: boolean;
}

const StyledCodeInput = styled("input")<CodeInputStyleProps>(
  ({ isValid, addSpace, disabled, secretInputs }) => ({
    fontSize: "20px",
    textAlign: "center",
    width: "36px !important",
    height: "48px",
    margin: "4px 3px",
    borderRadius: "4px",
    backgroundColor: oldTheme.pallete.background.white,
    color: oldTheme.pallete.text.black,
    border: isValid ? "1px solid #c8c8c8" : "0.8px solid red",
    "&:focus": {
      border: "1px solid #0a7abe",
      outline: "1px solid #c8c8c8",
    },
    ...(addSpace && { marginRight: "16px" }),
    ...(disabled && {
      backgroundColor: "#f7f7f7",
      borderColor: "lightgray",
      cursor: "not-allowed",
    }),
    ...(secretInputs && {
      "-webkit-text-security": "disc",
      "text-security": "disc",
    }),
    // Remove spinners from number inputs
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield",
  })
);

interface CodeInputProps {
  code: string;
  handleCodeChange: (value: string) => Promise<void> | void;
  numInputs: number;
  isValid?: boolean;
  disabled?: boolean;
  type?: "text" | "number";
  secretInputs?: boolean;
}

export function CodeInput({
  code,
  handleCodeChange,
  numInputs,
  isValid,
  disabled,
  type,
  secretInputs,
}: CodeInputProps) {
  return (
    <CodeInputsWrapper>
      <OtpInput
        inputType={type ? type : "text"}
        numInputs={numInputs}
        value={code}
        onChange={handleCodeChange}
        renderInput={(props, index) => (
          <StyledCodeInput
            {...props}
            isValid={isValid ?? true}
            addSpace={numInputs === 6 && index === 2}
            disabled={disabled ?? false}
            secretInputs={secretInputs}
          />
        )}
      />
    </CodeInputsWrapper>
  );
}

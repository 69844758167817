import { Box, Paper, styled, SvgIconProps, Typography } from "@mui/material";
import {
  MedicalCertificateLandingIcon,
  MedicalOrderLandingIcon,
  PrescriptionLandingIcon,
} from "@icons";
import { theme as oldTheme } from "@config/theme";

const SectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  padding: "132px 32px 80px",
  marginTop: "-96px",
  gap: "48px",
  background:
    "linear-gradient(100.36deg, #302D84 0%, #7355FB 21%, #3BA8D8 100%)",
  [theme.breakpoints.up(750)]: {
    padding: "48px 32px 80px",
    marginTop: "0px",
  },
  [theme.breakpoints.up("md")]: {
    width: "auto",
    maxWidth: "95%",
    padding: "48px 32px",
    margin: "80px 0",
    borderRadius: "38px",
  },
  [theme.breakpoints.up("xxl")]: {
    maxWidth: "80%",
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  textAlign: "center",
  color: oldTheme.pallete.background.white,
  fontSize: "2rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.125rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.25rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "3rem",
  },
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "48px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    width: "100%",
    gap: "32px",
    justifyContent: "space-between",
  },
}));

const CardContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "16px 16px 32px",
  gap: "16px",
  borderRadius: "16px",
  [theme.breakpoints.up("sm")]: {
    width: "500px",
    height: "160px",
  },
  [theme.breakpoints.up("md")]: {
    width: "300px",
    height: "232px",
    padding: "16px",
  },
  [theme.breakpoints.up(1000)]: {
    height: "200px",
  },
}));

const CardRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 500,
  color: oldTheme.pallete.text.secondaryDark,
  [theme.breakpoints.up("md")]: {
    fontSize: "1.75rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2rem",
  },
}));

const CardDescription = styled(Typography)({
  color: oldTheme.pallete.text.secondaryDark,
});

interface Props {
  title: string;
  description: string;
  Icon: React.FC<SvgIconProps>;
}

const cardsInfo = [
  {
    title: "Recetas",
    description:
      "Prescribe recetas simples y retenidas, conociendo la disponibilidad y alternativas de los medicamentos",
    Icon: PrescriptionLandingIcon,
  },
  {
    title: "Órdenes",
    description:
      "Emite órdenes de exámenes y procedimientos a medida o desde el catálogo Fonasa",
    Icon: MedicalOrderLandingIcon,
  },
  {
    title: "Certificados",
    description:
      "Crea todo tipo de certificados para tus pacientes utilizando plantillas personalizadas",
    Icon: MedicalCertificateLandingIcon,
  },
] as const;

const FeatureCard: React.FC<Props> = ({ title, description, Icon }) => {
  return (
    <CardContainer elevation={0}>
      <CardRow>
        <CardTitle>{title}</CardTitle>
        <Icon sx={{ width: "32px", height: "32px" }} />
      </CardRow>
      <CardDescription>{description}</CardDescription>
    </CardContainer>
  );
};

export const FeatureCardsSection: React.FC = () => {
  return (
    <SectionWrapper>
      <SectionTitle>Olvídate del talonario</SectionTitle>
      <SectionContainer>
        {cardsInfo.map(({ title, description, Icon }, index) => (
          <FeatureCard
            key={`feature-card-${index}`}
            title={title}
            description={description}
            Icon={Icon}
          />
        ))}
      </SectionContainer>
    </SectionWrapper>
  );
};

import React, { useRef } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import styled from "@emotion/styled";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";
import { theme } from "../config";
import { getUnitForQuantity } from "../utils";

const SelectContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 4px;
  display: block;
`;

const StyledFormHelperText = styled(FormHelperText)`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  color: ${theme.pallete.errors.primary};
  padding-top: 56px;
  margin: 0px;
  position: absolute;
`;

const ErrorStyle = {
  borderColor: "red",
  boxShadow: "0 0 0 1px red",
};

interface Props {
  control: Control<any>;
  name: string;
  options: string[];
  selectedValue: string;
  relatedInputQuantity?: number;
  label?: string;
  rules?: RegisterOptions;
  disabled?: boolean;
  placeholder?: string;
}

export function SelectorOptions({
  control,
  name,
  options,
  selectedValue = "",
  relatedInputQuantity = 1,
  label,
  rules = {
    required: true,
  },
  disabled = false,
  placeholder,
}: Props) {
  const selectorRef = useRef<HTMLDivElement | null>(null);
  return (
    <FormControl fullWidth>
      {label && <Label>{label}</Label>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, ref, ...field },
          fieldState: { error },
        }) => (
          <SelectContainer>
            <StyledSelect
              ref={selectorRef}
              value={getUnitForQuantity(selectedValue, relatedInputQuantity)}
              onChange={onChange}
              style={{
                fontFamily: "Poppins",
                backgroundColor: theme.pallete.background.white,
                ...(error ? ErrorStyle : {}),
              }}
              disabled={disabled}
              displayEmpty
              renderValue={(value) =>
                value === "" ? placeholder : (value as React.ReactNode)
              }
              onClick={() => {
                selectorRef.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }}
            >
              {placeholder && (
                <MenuItem value="" disabled style={{ fontFamily: "Poppins" }}>
                  {placeholder}
                </MenuItem>
              )}
              {options.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  style={{ fontFamily: "Poppins" }}
                >
                  {getUnitForQuantity(option, relatedInputQuantity)}
                </MenuItem>
              ))}
            </StyledSelect>
            {error && (
              <StyledFormHelperText>
                {error?.message || "Obligatorio"}
              </StyledFormHelperText>
            )}
          </SelectContainer>
        )}
      />
    </FormControl>
  );
}

import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Global } from "@emotion/react";
import { Box, CircularProgress, styled, Typography } from "@mui/material";
import { useAuth } from "@contexts";
import {
  Doctor,
  DoctorBaseAttributes,
  Gender,
  VerificationMethod,
} from "@interfaces";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  EmailInput,
  ExtraWhiteSpace,
  FullScreenLoader,
  Header,
  PhoneInput,
  TermsAndConditionsCheckBox,
  NavigateBeforeButton,
} from "@components";
import {
  getBirthdateInfo,
  resolveNoBiometricAuthMethod,
  onContactUsClick,
  parseRNPIDoctorBirthdate,
  separateDoctorSpecialty,
} from "@utils";
import {
  NewLoginContactUsParagraph,
  NewLoginContactUsText,
} from "../Auth/styled";
import { theme as oldTheme } from "../../config";
import { format } from "rut.js";

const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  marginTop: "16px",
  flex: 1,
});

const SectionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "16px",
  gap: "8px",
  backgroundColor: oldTheme.pallete.background.white,
  borderRadius: "4px",
  border: `1px solid ${oldTheme.pallete.text.lightGray}`,
});

const SectionTitle = styled(Typography)({
  fontSize: "16px",
  fontWeight: 600,
  color: oldTheme.pallete.text.black,
});

const FieldText = styled(Typography)({
  textAlign: "left",
  color: oldTheme.pallete.text.lightBlack,
});

export function RegistrationForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { getDoctorFromCommonStack, createDoctor } = useAuth();
  const [validatingData, setValidatingData] = useState<boolean>(false);
  const [creatingDoctor, setCreatingDoctor] = useState<boolean>(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [checkBoxError, setCheckBoxError] = useState(false);

  const {
    rut = "",
    name = "",
    lastName = "",
    speciality = "",
    university = "",
    region = "",
    commune = "",
    street = "",
    gender = Gender.FEMALE,
    birthdate = "",
    registrationNumber = "",
  }: DoctorBaseAttributes = location.state || {};
  const parsedBirthdate = parseRNPIDoctorBirthdate(birthdate);
  const { years: age } = getBirthdateInfo(birthdate);
  const { mekidocAccessTitle, specialties } =
    separateDoctorSpecialty(speciality);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
  } = useForm<Doctor>({
    defaultValues: {
      rut,
      name,
      lastName,
      speciality,
      university,
      region,
      commune,
      street,
      gender,
      birthdate: Boolean(parsedBirthdate) ? birthdate : "",
      registrationNumber,
    },
  });

  const [currentPhoneCode, setCurrentPhoneCode] = useState({
    name: "Chile",
    icon: "🇨🇱",
    prefix: "+56",
  });

  const validateForm = useCallback(
    async (data: Doctor) => {
      const fieldNames: (keyof Doctor)[] = ["phone", "email"];
      let isValid = true;
      fieldNames.forEach((fieldName) => {
        if (!data[fieldName]) {
          isValid = false;
        }
      });
      const doctor = await getDoctorFromCommonStack(
        VerificationMethod.EMAIL,
        data.email
      );
      if (doctor) {
        setError("email", {
          type: "manual",
          message: "Ya existe un usuario con este correo electrónico.",
        });
        isValid = false;
      }
      return isValid;
    },
    [getDoctorFromCommonStack, setError]
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsChecked(event.target.checked);
    setCheckBoxError(false);
  };

  const onSubmit: SubmitHandler<Doctor> = useCallback(
    async (data) => {
      if (!termsChecked) {
        setCheckBoxError(true);
        return;
      }
      setValidatingData(true);
      const parsedData = {
        ...data,
        phone: currentPhoneCode.prefix + data.phone,
      };
      const validForm = await validateForm(parsedData);
      if (!validForm) {
        setValidatingData(false);
        return;
      }
      const doctorData = {
        doctor: {
          ...parsedData,
        },
      };
      try {
        setCreatingDoctor(true);
        console.log("Creating doctor request: ", doctorData);
        const authMethod = resolveNoBiometricAuthMethod();
        const doctor = await createDoctor(doctorData, authMethod);
        navigate(`/app/codigo`, {
          state: { doctor, authMethod, accountCreated: true },
        });
      } catch (error) {
        console.error(error);
      } finally {
        setCreatingDoctor(false);
      }
    },
    [navigate, validateForm, createDoctor, termsChecked, currentPhoneCode]
  );

  if (creatingDoctor) {
    return <FullScreenLoader title="Creando usuario..." />;
  }

  return (
    <>
      <Header
        title="Crear cuenta"
        titleColor="black"
        left={<NavigateBeforeButton onClick={() => navigate("/app")} />}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <SectionContainer>
            <SectionTitle>Tus datos</SectionTitle>
            <FieldText>
              {name} {lastName}
            </FieldText>
            <FieldText>{format(rut)}</FieldText>
            {parsedBirthdate && (
              <FieldText>
                {age} años · {parsedBirthdate}
              </FieldText>
            )}
            <FieldText>
              {mekidocAccessTitle} (REG-SIS N°: {registrationNumber})
            </FieldText>
            {specialties && <FieldText>{specialties}</FieldText>}
          </SectionContainer>

          <EmailInput
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
            trigger={trigger}
          />

          <PhoneInput
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
            trigger={trigger}
            clearErrors={clearErrors}
            phoneCodeState={{
              currentPhoneCode,
              setCurrentPhoneCode,
            }}
            showTooltip={true}
          />

          <TermsAndConditionsCheckBox
            termsChecked={termsChecked}
            handleCheckboxChange={handleCheckboxChange}
            error={checkBoxError}
          />

          <NewLoginContactUsParagraph>
            Si necesitas ayuda{" "}
            <NewLoginContactUsText
              onClick={() =>
                onContactUsClick(
                  "Hola, estoy teniendo problemas al registrarme en MekiDoc. Me dice que ya existe un doctor con mi correo o teléfono. ¿Podrías darme más detalles de como solucionarlo?"
                )
              }
            >
              contáctanos
            </NewLoginContactUsText>
          </NewLoginContactUsParagraph>

          <ExtraWhiteSpace />

          <BottomActionArea>
            <BottomActionButton type="submit" disabled={validatingData}>
              {validatingData ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Siguiente"
              )}
            </BottomActionButton>
          </BottomActionArea>
        </FormContainer>
      </Body>
    </>
  );
}

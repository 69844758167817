import { styled, Box, Card, Typography } from "@mui/material";
import { theme as OldTheme } from "../config";

export const StyledInformationCard = styled(Card)({
  padding: "16px",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

export const InformationCard: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <StyledInformationCard variant="outlined">{children}</StyledInformationCard>
  );
};

export const InformationCardSection = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

export const InformationCardTitle = styled(Typography)({
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "23px",
  letterSpacing: "0.5x",
  color: OldTheme.pallete.text.black,
});

export const InformationCardText = styled(Typography)({
  fontSize: "16px",
  lineHeight: "23px",
  letterSpacing: "0.16px",
  color: OldTheme.pallete.text.black,
});

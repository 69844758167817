import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Link, styled } from "@mui/material";
import { theme as oldTheme } from "@config/theme";
import { contactUsLink } from "@utils";
import { anotherFAQMessage, unsuscribeMessage } from "@constants";

const SectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  background: oldTheme.pallete.text.primaryExtraLight,
  padding: "64px 32px",
  gap: "48px",
  [theme.breakpoints.up("md")]: {
    gap: "80px",
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  textAlign: "center",
  color: oldTheme.pallete.text.primaryDark,
  fontSize: "2rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.125rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.25rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "3rem",
  },
}));

const ExpandIcon = styled(ExpandMoreIcon)({
  color: oldTheme.pallete.text.primary,
});

const AccordionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  maxWidth: "100%",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "85%",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "760px",
  },
}));

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))({
  padding: "16px 0px",
  borderRadius: "8px",
  "&::before": {
    display: "none",
  },
  color: oldTheme.pallete.text.primaryDark,
});

const QuestionText = styled(Typography)(({ theme }) => ({
  maxWidth: "95%",
  fontWeight: 500,
  fontSize: "1rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25rem",
  },
}));

const AnswerContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const AnswerText = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
}));

type AnswerLinkProps = {
  href: string;
  text?: string;
};

const AnswerLink = ({ href, text }: AnswerLinkProps): JSX.Element => (
  <Link target="_blank" rel="noopener" href={href}>
    {text ?? href}
  </Link>
);

const AnswerList = styled("ul")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  paddingLeft: "32px",
});

const dataTreatmentAnswers = [
  <>
    Verificar la identidad del doctor para que únicamente aquellos que estén
    inscritos en el registro nacional de prestadores de la salud individual (
    <AnswerLink href="https://rnpi.superdesalud.gob.cl/#" text="RNPI" />) puedan
    hacer uso de la aplicación.
  </>,
  <>
    Crear una Firma Electrónica Avanzada para que los médicos y cirujanos
    dentistas puedan firmar los documentos emitidos. Para ello utilizamos un
    proveedor acreditado por el Ministerio de Economía para emitir los
    certificados de FEA, puedes conocer más información{" "}
    <AnswerLink
      href="https://www.entidadacreditadora.gob.cl"
      text="pinchando aquí"
    />
    .
  </>,
  <>
    Emitir recetas médicas de acuerdo a la regulación vigente. Para esto se
    solicitan datos del paciente de acuerdo a lo requerido en el{" "}
    <AnswerLink
      href="https://www.bcn.cl/leychile/navegar?idNorma=13613"
      text="Decreto 466"
    />
    .
  </>,
  "Crear órdenes de exámenes y procedimientos que sean aceptados por todos los establecimientos médicos.",
  "Emitir certificados médicos que ayuden a los pacientes en los más diversos fines.",
  "Enviarte correos y mensajes de aviso con las solicitudes pendientes, mejoras del servicio y actualizaciones de información.",
  "Operar, evaluar y mejorar nuestro negocio: incluyendo desarrollar nuevos servicios, manejar nuestras comunicaciones, aprender de nuestros visitantes, analizar nuestros servicios y facilitar la funcionalidad de nuestra aplicación web.",
];

interface AccordionDatum {
  question: string;
  answer: React.ReactNode;
}

const accordionData: AccordionDatum[] = [
  {
    question: "¿Qué necesito para crearme una cuenta?",
    answer: (
      <AnswerContainer>
        <AnswerText>
          Para crear una cuenta en MekiDoc necesitas ser un médico o cirujano
          dentista inscrito en el Registro Nacional de Prestadores Individuales
          de Salud (
          <AnswerLink href="https://rnpi.superdesalud.gob.cl/#" text="RNPI" />
          ).
        </AnswerText>
        <AnswerText>
          Además, al crear la cuenta te pediremos que verifiques tu identidad en
          tres pasos para que tengas Firma Electrónica Avanzada.
        </AnswerText>
      </AnswerContainer>
    ),
  },
  {
    question: "¿Qué documentos médicos puedo crear?",
    answer: (
      <AnswerContainer>
        <AnswerText>
          Podrás emitir recetas electrónicas simples, retenidas y magistrales,
          además de certificados y órdenes médicas.
        </AnswerText>
        <AnswerText>
          MekiDoc cuenta con Firma Electrónica Avanzada para las recetas, de
          manera que cumple con las orientaciones para validación de recetas
          electrónicas publicadas por el Instituto de Salud Pública, las que
          puedes revisar{" "}
          <AnswerLink
            href="https://www.ispch.cl/sites/default/files/InstructivoVRE02-03112020A.pdf"
            text="pinchando aquí"
          />
          .
        </AnswerText>
      </AnswerContainer>
    ),
  },
  {
    question: "¿Se pueden emitir recetas cheque?",
    answer: (
      <AnswerText>
        No, porque actualmente la regulación no permite emitir recetas cheques
        electrónicas. Si necesitas compartir digitalmente una receta cheque,
        tendrás que crearla físicamente y digitalizarla en el sistema que creó
        la{" "}
        <AnswerLink
          href="https://seremienlinea.minsal.cl/recetas"
          text="SEREMI"
        />
        .
      </AnswerText>
    ),
  },
  {
    question: "¿Usar MekiDoc tiene algún costo?",
    answer: (
      <AnswerText>
        MekiDoc es totalmente gratuito para los doctores y sus pacientes.
      </AnswerText>
    ),
  },
  {
    question: "¿En qué farmacias son válidas las recetas creadas en MekiDoc?",
    answer: (
      <AnswerContainer>
        <AnswerText>
          Las recetas de MekiDoc pueden utilizarse en cualquier farmacia, pues
          cuentan con Firma Electrónica Avanzada y cumplen con toda la normativa
          vigente (Decreto N°466 y Ley N°19.799) y las{" "}
          <AnswerLink
            href="https://www.ispch.cl/sites/default/files/InstructivoVRE02-03112020A.pdf"
            text="orientaciones"
          />{" "}
          del Instituto de Salud Pública.
        </AnswerText>
        <AnswerText>
          Al ir a una farmacia, el profesional Químico Farmacéutico deberá
          escanear el código QR de tu receta, o entrar a{" "}
          <AnswerLink href="https://mekidoc.cl/app/farmacia" /> e ingresar el
          código de verificación. Ahí podrá revisar el detalle de la
          prescripción, expender el medicamento y retener la receta cuando se
          requiera.
        </AnswerText>
      </AnswerContainer>
    ),
  },
  {
    question: "¿Qué relación tienen con Farmacia Meki?",
    answer: (
      <AnswerContainer>
        <AnswerText>
          MekiDoc es un proyecto de la{" "}
          <AnswerLink href="https://farmaciameki.cl" text="Farmacia Meki" /> que
          busca que los doctores puedan conocer el precio, disponibilidad y
          alternativas bioequivalentes de los medicamentos que recetan a sus
          pacientes. Y también ayudarlos con la comunicación con sus pacientes y
          la emisión de otros documentos.
        </AnswerText>
        <AnswerText>
          Todos los documentos médicos que se emiten en MekiDoc se pueden
          utilizar en cualquier farmacia o centro médico.
        </AnswerText>
      </AnswerContainer>
    ),
  },
  {
    question: "¿Cómo se manejan los datos de doctores y pacientes?",
    answer: (
      <AnswerContainer>
        <AnswerText>
          Todos los datos, de doctores y pacientes, que recopila MekiDoc tienen
          por objetivo poder emitir los documentos médicos de forma segura y
          mejorar el servicio.
        </AnswerText>
        <AnswerText>
          A continuación detallamos los fines que tienen los datos solicitados
          al crearse cuenta y al emitir documentos:
        </AnswerText>
        <AnswerText>
          <AnswerList>
            {dataTreatmentAnswers.map((answer, index) => (
              <li key={`data-treatment-${index}`}>{answer}</li>
            ))}
          </AnswerList>
        </AnswerText>
        <AnswerText>
          Todos los datos recolectados están protegidos por los derechos
          consagrados en la Ley 19.628 sobre Protección de la Vida Privada y la
          Ley 20.584 sobre Derechos y Deberes del Paciente.
        </AnswerText>
        <AnswerText>
          Además, MekiDoc emplea altos estándares de seguridad para el proteger
          la confidencialidad de los datos: en el ingreso y tránsito de toda la
          información que pasa por MekiDoc se utiliza el mecanismo de seguridad
          TLS 1.3 con encriptación de punta a punta.
        </AnswerText>
      </AnswerContainer>
    ),
  },
  {
    question: "¿Cómo puedo darme de baja?",
    answer: (
      <AnswerText>
        Puedes darte de baja en cualquier momento, solo debes{" "}
        <AnswerLink
          href={contactUsLink(unsuscribeMessage)}
          text="pinchar aquí"
        />
        .
      </AnswerText>
    ),
  },
  {
    question: "¿Otras preguntas?",
    answer: (
      <AnswerText>
        Si tienes otras preguntas puedes escribirnos directamente{" "}
        <AnswerLink
          href={contactUsLink(anotherFAQMessage)}
          text=" pinchando aquí"
        />
        .
      </AnswerText>
    ),
  },
];

export const FAQSection: React.FC = () => {
  return (
    <SectionWrapper>
      <SectionTitle>Preguntas Frecuentes</SectionTitle>
      <AccordionContainer>
        {accordionData.map(({ question, answer }, index) => (
          <StyledAccordion key={`FAQ-${index}`}>
            <AccordionSummary
              expandIcon={<ExpandIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <QuestionText>{question}</QuestionText>
            </AccordionSummary>
            <AccordionDetails>{answer}</AccordionDetails>
          </StyledAccordion>
        ))}
      </AccordionContainer>
    </SectionWrapper>
  );
};

import { Document, Page } from "react-pdf";
import { Box, Typography, styled } from "@mui/material";
import { theme as oldTheme } from "../../config";

export const FullScreen = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.grey[100],
  zIndex: 9999,
}));

// Styled components for react-pdf
// We need to override the width and height of the Document and Page components with !important
export const StyledDocument = styled(Document)<{ blur?: boolean }>((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100% !important",
  height: "100% !important",
  minHeight: "100% !important",
  gap: "16px",
  ...(props.blur && {
    filter: "blur(2.5px)",
  }),
}));

export const StyledPage = styled(Page)({
  width: "100% !important",
  height: "100% !important",
  canvas: {
    width: "100% !important",
    height: "100% !important",
  },
});

// TODO: This WaterMark should be created at the backend
export const WaterMarkStyle = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "32px",
  fontWeight: 500,
  color: oldTheme.pallete.errors.primary,
});

export const DefaultWhiteText = styled(Typography)({
  color: "white",
});

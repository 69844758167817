import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { api, queryClient } from "../context";
import { Patient } from "../interfaces";

interface EditPatientPayload {
  patientCode: string;
  updates: Patient;
}

export const useEditPatient = (prevRoute: string, requestId: string) => {
  const navigate = useNavigate();
  const editPatientFn = (editPatientPayload: EditPatientPayload) => {
    return api<Patient>({
      method: "PUT",
      url: `/patients/edit`,
      data: editPatientPayload,
    });
  };
  return useMutation({
    mutationFn: editPatientFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["request", requestId],
      });
      navigate(prevRoute);
    },
    onError: (error) => {
      console.log("Error editing patient: ", error);
    },
  });
};

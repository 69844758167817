import axios from "axios";
import copy from "copy-to-clipboard";
import { AlertColor } from "@mui/material";
import { BASE_URL, PATIENT_APP_URL } from "../environment";

interface shareDoctorLinkProps {
  doctorCode: string;
  setShowCopiedLinkModal: (value: React.SetStateAction<boolean>) => void;
  setSnackBarState: (
    value: React.SetStateAction<{
      open: boolean;
      type: AlertColor;
    }>
  ) => void;
  avoidColdstart?: boolean;
}

export async function shareDoctorLink({
  doctorCode,
  setShowCopiedLinkModal,
  setSnackBarState,
  avoidColdstart = true,
}: shareDoctorLinkProps) {
  try {
    if (avoidColdstart) {
      // Avoid coldstart by querying this lambda endpoint
      axios.get(`${BASE_URL}/doctors/${doctorCode}`);
    }
    const shareLink = `${PATIENT_APP_URL}/${doctorCode}`;
    const shareData = {
      text: shareLink,
    };
    if (navigator.share && navigator.canShare(shareData)) {
      await navigator.share(shareData);
    } else {
      const successfulCopy = copy(shareLink);
      if (successfulCopy) {
        setShowCopiedLinkModal(true);
      } else {
        throw new Error("Failed to copy link to clipboard");
      }
    }
  } catch (error) {
    if (!(error instanceof DOMException && error.name === "AbortError")) {
      setSnackBarState({ open: true, type: "error" });
    }
  }
}

import axios from "axios";
import fileDownload from "js-file-download";
import {
  PDFData,
  Patient,
  RequestType,
  requestTypeSimplifiedTranslation,
} from "@interfaces";

interface ConvertPresignedUrlToFileProps {
  url: string;
  filename: string;
}

export async function convertPresignedUrlToFile({
  url,
  filename,
}: ConvertPresignedUrlToFileProps) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Failed to fetch file from S3: " + response.status);
  }
  const blob = await response.blob();
  return new File([blob], filename, { type: blob.type });
}

interface GeneratePdfNameProps {
  documentType: RequestType;
  patient: Patient;
  documentNameDetails?: string;
}

export function generatePdfName({
  documentType,
  patient,
  documentNameDetails,
}: GeneratePdfNameProps) {
  const document = requestTypeSimplifiedTranslation[documentType];
  const patientInfo = `${patient.name}_${patient.firstSurname}_${patient.secondSurname}.pdf`;
  const pdfName = [document, documentNameDetails ?? "", patientInfo].join("_");
  return pdfName.replace(/ /g, "_").replace(/_+/g, "_");
}

async function downloadFile(url: string, filename: string) {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    const blob = response.data;
    fileDownload(blob, filename);
    console.log({ msg: "downloadFile - success", filename });
  } catch (error) {
    alert("Error al descargar el archivo");
    console.error({ msg: "downloadFile - error", error });
  }
}

export async function downloadFilesHandler(pdfData: PDFData[]) {
  console.log({ msg: "downloadFilesHandler", pdfData });
  await Promise.all(
    pdfData.map(async ({ url, documentName }) => {
      await downloadFile(url, documentName);
    })
  );
}

export function printFile(url: string) {
  window.open(url, "_blank");
}

export function canShareFiles(files: File[]) {
  return files
    .map((file) => {
      return navigator.canShare({ files: [file] });
    })
    .every(Boolean);
}

export function handlePagesManagement(
  prevArray: number[],
  index: number,
  value: number
) {
  const newArray = [...prevArray];
  newArray[index] = value;
  return newArray;
}

import { PharmaceuticalChemist } from ".";

export interface PrescriptionDetail {
  id?: string;
  name?: string;
  laboratory?: string;
  activePrinciple: string;
  concentration: string;
  posologyQuantity: number;
  posologyFormat: string;
  frequencyQuantity: number;
  frequencyPeriod: string;
  consumptionType: string;
  durationQuantity: number;
  durationPeriod: string;
  quantityToDispense: number;
  indications: string;
  alternatives: string[];
}

export enum PrescriptionStatus {
  ISSUED = "ISSUED",
  DISPENSED = "DISPENSED",
  HELD = "HELD",
  CANCELED = "CANCELED",
}

export const PrescriptionTypeMapper: Record<string, string> = {
  SIMPLE: "Simple",
  HELD: "Retenida",
};

export interface PrescriptionHolding {
  id: string;
  prescriptionId: string;
  pharmaceuticalChemistCode: string;
  pharmaceuticalChemist?: PharmaceuticalChemist;
  pharmacyRut: string;
  pharmacyName: string;
  holdingDate: number;
  activePrinciple?: string;
  concentration?: string;
  quantityToDispense?: number;
  details?: string;
}
export interface Prescription {
  id: string;
  doctorCode: string;
  patientCode: string;
  createdAt: number;
  requestId: string;
  details: PrescriptionDetail[];
  type: "SIMPLE" | "HELD";
  prescriptionStatus: PrescriptionStatus;
  s3uri?: string;
  holding?: PrescriptionHolding;
  holdingDate?: number;
}

export type PrescriptionSeverity = "success" | "error" | "info";

export const PrescriptionStatusChipSeverityMapper: Record<
  PrescriptionStatus,
  PrescriptionSeverity
> = {
  [PrescriptionStatus.ISSUED]: "success",
  [PrescriptionStatus.DISPENSED]: "info",
  [PrescriptionStatus.HELD]: "error",
  [PrescriptionStatus.CANCELED]: "error",
};

export const PrescriptionQFHeaderMapper: Record<PrescriptionStatus, string> = {
  [PrescriptionStatus.ISSUED]: "Validación de receta",
  [PrescriptionStatus.DISPENSED]: "Dispensación de receta",
  [PrescriptionStatus.HELD]: "Retención de receta",
  [PrescriptionStatus.CANCELED]: "Validación de receta",
};

export const PrescriptionStatusChipTitleMapper: Record<
  PrescriptionStatus,
  string
> = {
  [PrescriptionStatus.ISSUED]: "Receta electrónica válida",
  [PrescriptionStatus.DISPENSED]: "Receta dispensada",
  [PrescriptionStatus.HELD]: "Receta retenida",
  [PrescriptionStatus.CANCELED]: "Receta anulada",
};

export const PrescriptionStatusChipActionMapper: Record<
  PrescriptionStatus,
  string
> = {
  [PrescriptionStatus.ISSUED]: "",
  [PrescriptionStatus.DISPENSED]: "timbre",
  [PrescriptionStatus.HELD]: "retención",
  [PrescriptionStatus.CANCELED]: "cancelación",
};

export const PrescriptionQFButtonMapper: Record<PrescriptionStatus, string> = {
  [PrescriptionStatus.ISSUED]: "",
  [PrescriptionStatus.DISPENSED]: "Dispensar",
  [PrescriptionStatus.HELD]: "Retener",
  [PrescriptionStatus.CANCELED]: "",
};

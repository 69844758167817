import { styled } from "@mui/material";
import { CommonPrescription, DocumentProps } from "../../../interfaces";
import { DetailsCard } from "./Details";
import { CardContainer, CardSectionTitle, CardSpacedRow } from "../styles";

const StyledCardSectionTitle = styled(CardSectionTitle)({
  padding: "16px 16px 0px",
});

export const PrescriptionInfo: React.FC<{
  prescriptionData: Pick<
    CommonPrescription,
    | "requestId"
    | "id"
    | "type"
    | "prescriptionStatus"
    | "details"
    | "holdingDate"
  >;
  prescriptionPDF: DocumentProps;
}> = ({ prescriptionData, prescriptionPDF }) => {
  return (
    <CardContainer padding={"0px"}>
      <CardSpacedRow>
        <StyledCardSectionTitle>Prescripción</StyledCardSectionTitle>
      </CardSpacedRow>
      <DetailsCard
        prescriptionData={prescriptionData}
        prescriptionPDF={prescriptionPDF}
      />
    </CardContainer>
  );
};

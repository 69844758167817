import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import { Global } from "@emotion/react";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  ExtraWhiteSpace,
  Header,
  PatientSummaryAccordion,
  NavigateBeforeButton,
  FullScreenLoader,
  AuthRequiredModal,
  PatientRequestCommentAccordion,
  MainActionButton,
} from "@components";
import { PrescriptionDetailCard } from "./components/PrescriptionDetailCard";
import {
  patientDefaultValues,
  RequestStatus,
  Request,
  HistoricRequestCardDetails,
  RequestType,
} from "@interfaces";
import { api, queryClient, useDoctorContext } from "@contexts";
import { useMedicineSearchContext } from "../MedicineSearch/context/MedicineSearchContext";
import { useRequest } from "@hooks";
import { useMutation } from "@tanstack/react-query";
import { removeFromLocalStorage, saveToLocalStorage } from "@utils";

const SectionSubtitle = styled(Typography)({
  fontWeight: 500,
  padding: "16px 0px",
});

const PrescriptionDetailsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const MainActionButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  padding: "8px 0px",
});

export function PrescriptionCreate() {
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  const doctor = useDoctorContext();
  const location = useLocation();
  const { detailAdded = false, medicineType = "" } = location.state || {};
  const [isDetailAdded, setIsDetailAdded] = useState<boolean>(detailAdded);
  const { setSearchText } = useMedicineSearchContext();

  const {
    data: request,
    isPending: isPendingRequest,
    isFetching: isFetchingRequest,
  } = useRequest();

  const { requestDetails, heldDetails, simpleDetails, patient, isDraft } =
    useMemo(() => {
      const requestDetails = request?.details || [];
      const heldDetails = requestDetails.filter(
        (detail) => detail.prescriptionType === "HELD"
      );
      const simpleDetails = requestDetails.filter(
        (detail) => detail.prescriptionType !== "HELD"
      );
      const patient = request ? request.patient : patientDefaultValues;
      const isDraft = request?.status === RequestStatus.DRAFT;
      return { requestDetails, heldDetails, simpleDetails, patient, isDraft };
    }, [request]);

  const handleBack = useCallback(() => {
    if (isDraft) {
      navigate(`/app/nuevo-documento/paciente`, {
        state: { documentType: RequestType.PRESCRIPTION },
      });
    } else {
      navigate(`/app/solicitudes/${requestId}/gestionar/seleccionar-documento`);
    }
    removeFromLocalStorage("requestType");
  }, [navigate, isDraft, requestId]);

  const [showFeaAuthModal, setShowFeaAuthModal] = useState<boolean>(false);

  const handleSubmit = useCallback(async () => {
    try {
      if (!doctor.isFeaAuthenticated) {
        setShowFeaAuthModal(true);
        return;
      }
      saveToLocalStorage({
        key: "requestType",
        value: RequestType.PRESCRIPTION,
      });
      navigate(`/app/solicitudes/${requestId}/receta/firma`);
    } catch (error) {
      console.log(error);
    }
  }, [doctor, requestId, navigate]);

  const { mutate: deleteDetail } = useMutation({
    mutationFn: (detail: HistoricRequestCardDetails) =>
      api({
        url: `/requests/${requestId}/detail/${detail.id}`,
        method: "DELETE",
      }),
    onMutate: async (detail) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ["request", requestId] });
      // Remove the detail from the request
      const updatedRequest: Request = {
        ...(request ?? ({} as Request)),
        details: request?.details?.filter((d) => d.id !== detail.id),
      };
      // Optimistically update to the new value
      queryClient.setQueryData(["request", requestId], updatedRequest);
      return { request: updatedRequest, previousRequest: request };
    },
    onSuccess: () => {
      setIsDetailAdded(false);
    },
    onError: (error, _, context) => {
      console.error("Error deleting request detail", error);
      queryClient.setQueryData(
        ["request", requestId],
        context?.previousRequest
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["request", requestId] });
    },
  });

  const handleDelete = useCallback(
    (detail: HistoricRequestCardDetails) => {
      deleteDetail(detail);
    },
    [deleteDetail]
  );

  const isFetchingDetails = isDetailAdded && isFetchingRequest;

  // Clear the search bar when the user navigates to this screen
  useEffect(() => {
    setSearchText("");
  }, [setSearchText]);

  console.log({
    msg: "Request Information:",
    request,
    requestDetails,
    heldDetails,
    simpleDetails,
  });

  const renderPrescriptionDetailCardSkeleton = (quantity: number) =>
    Array.from({ length: quantity }).map((_, i) => (
      <PrescriptionDetailCard.Skeleton key={i} />
    ));

  if (isPendingRequest) {
    return <FullScreenLoader title="Cargando información..." />;
  }

  return (
    <>
      <Header
        title={"Resumen receta"}
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <PatientSummaryAccordion
          patient={patient}
          prevRoute={`/app/solicitudes/${requestId}/receta/crear`}
          requestId={requestId}
          isOpen={isDraft && !(detailAdded || requestDetails.length > 0)}
          canEdit={isDraft}
        />
        <PatientRequestCommentAccordion
          request={request}
          canReject={false}
          isExpanded={true}
        />
        <RouterLink to={"buscar"}>
          <MainActionButtonContainer>
            <MainActionButton
              variant="outlined"
              size="large"
              disabled={isFetchingDetails}
            >
              Agregar medicamento
            </MainActionButton>
          </MainActionButtonContainer>
        </RouterLink>
        {/* Skeleton for the first detail added */}
        {isFetchingDetails &&
          heldDetails.length === 0 &&
          simpleDetails.length === 0 && (
            <>
              <SectionSubtitle>
                {medicineType === "HELD" ? "Receta retenida" : "Receta simple"}
              </SectionSubtitle>
              <PrescriptionDetailsContainer>
                <PrescriptionDetailCard.Skeleton />
              </PrescriptionDetailsContainer>
            </>
          )}

        {/* Skeleton for when a held detail already exists */}
        {request && heldDetails.length > 0 && (
          <>
            <SectionSubtitle>Receta retenida</SectionSubtitle>
            <PrescriptionDetailsContainer>
              {isFetchingDetails
                ? renderPrescriptionDetailCardSkeleton(
                    medicineType === "HELD"
                      ? heldDetails.length + 1
                      : heldDetails.length
                  )
                : heldDetails.map((detail) => {
                    return (
                      <PrescriptionDetailCard
                        key={detail.id}
                        detail={detail}
                        handleDelete={
                          [RequestStatus.PENDING, RequestStatus.DRAFT].includes(
                            request.status
                          )
                            ? handleDelete
                            : undefined
                        }
                      />
                    );
                  })}
            </PrescriptionDetailsContainer>
            {isFetchingDetails &&
              simpleDetails.length === 0 &&
              medicineType === "SIMPLE" && (
                <>
                  <SectionSubtitle>Receta simple</SectionSubtitle>
                  <PrescriptionDetailCard.Skeleton />
                </>
              )}
          </>
        )}

        {/* Skeleton for when a simple detail already exists */}
        {request && simpleDetails.length > 0 && (
          <>
            {isFetchingDetails &&
              heldDetails.length === 0 &&
              medicineType === "HELD" && (
                <>
                  <SectionSubtitle>Receta retenida</SectionSubtitle>
                  <PrescriptionDetailCard.Skeleton />
                </>
              )}
            <SectionSubtitle>Receta simple</SectionSubtitle>
            <PrescriptionDetailsContainer>
              {isFetchingDetails
                ? renderPrescriptionDetailCardSkeleton(
                    medicineType === "SIMPLE"
                      ? simpleDetails.length + 1
                      : simpleDetails.length
                  )
                : simpleDetails.map((detail) => {
                    return (
                      <PrescriptionDetailCard
                        key={detail.id}
                        detail={detail}
                        handleDelete={
                          [RequestStatus.PENDING, RequestStatus.DRAFT].includes(
                            request.status
                          )
                            ? handleDelete
                            : undefined
                        }
                      />
                    );
                  })}
            </PrescriptionDetailsContainer>
          </>
        )}
        <ExtraWhiteSpace />
        {requestDetails.length > 0 && (
          <BottomActionArea>
            <BottomActionButton
              className="small"
              onClick={handleSubmit}
              disabled={isFetchingDetails}
            >
              Generar receta
            </BottomActionButton>
          </BottomActionArea>
        )}
      </Body>

      <AuthRequiredModal
        requestId={requestId}
        isOpen={showFeaAuthModal}
        onClose={() => setShowFeaAuthModal(false)}
      />
    </>
  );
}

import { api, queryClient } from "../context";
import {
  NewRequestPayload,
  NewRequestResponse,
  requestTypeNavigateParser,
  RequestType,
} from "../interfaces";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

export const createRequestFn = (requestPayload: NewRequestPayload) => {
  return api<NewRequestResponse>({
    method: "POST",
    url: "/patients/request",
    data: requestPayload,
  });
};

function redirectToDocumentCreate(
  documentType: RequestType,
  requestId: string
) {
  switch (documentType) {
    case RequestType.PRESCRIPTION:
      return `/app/solicitudes/${requestId}/${requestTypeNavigateParser[documentType]}/crear/buscar`;
    case RequestType.COMPOUNDED_PRESCRIPTION:
      return `/app/solicitudes/${requestId}/${requestTypeNavigateParser[documentType]}/crear`;
    case RequestType.MEDICAL_CERTIFICATE:
      return `/app/solicitudes/${requestId}/${requestTypeNavigateParser[documentType]}/crear`;
    case RequestType.MEDICAL_ORDER:
      return `/app/solicitudes/${requestId}/${requestTypeNavigateParser[documentType]}/crear/buscar`;
    default:
      return `/app/solicitudes/${requestId}/${requestTypeNavigateParser[documentType]}/crear`;
  }
}

export const useCreateRequest = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: createRequestFn,
    onSuccess(requestResponse) {
      const { request } = requestResponse;
      const { id: requestId, documentType } = request;
      queryClient.setQueryData(["request", requestId], request);
      navigate(redirectToDocumentCreate(documentType, requestId));
    },
    onError(error) {
      console.log("Error creating request: ", error);
      navigate("/app/solicitudes");
    },
  });
};

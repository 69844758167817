import { Patient } from "./Patient";
import { Prescription } from "./Prescription";
import { CompoundedPrescription } from "./CompoundedPrescription";
import { RequestStatus, RequestType } from "./Request";

export interface MedicalOrder {
  effectiveDate: string;
  documentDetails: string[];
  diagnosticHypothesis?: string;
  indications?: string;
}

export interface MedicalCertificate {
  effectiveDate: string;
  documentDetails: string;
}

export type Document =
  | Prescription
  | CompoundedPrescription
  | MedicalOrder
  | MedicalCertificate;

export interface PDFData {
  url: string;
  documentName: string;
}
export interface DocumentPDFDataResponse {
  requestPDFData: PDFData[];
}

export const DocumentCreationEndpoint: Record<RequestType, string> = {
  [RequestType.PRESCRIPTION]: "prescriptions",
  [RequestType.COMPOUNDED_PRESCRIPTION]: "compoundedPrescriptions",
  [RequestType.MEDICAL_ORDER]: "medicalOrders",
  [RequestType.MEDICAL_CERTIFICATE]: "medicalCertificates",
};

export const DocumentCreationButtonAction: Record<RequestType, string> = {
  [RequestType.PRESCRIPTION]: "Generar receta",
  [RequestType.COMPOUNDED_PRESCRIPTION]: "Generar receta magistral",
  [RequestType.MEDICAL_ORDER]: "Generar orden",
  [RequestType.MEDICAL_CERTIFICATE]: "Generar certificado",
};

export const DocumentCreationLoaderTitle: Record<RequestType, string> = {
  [RequestType.PRESCRIPTION]: "Generando receta",
  [RequestType.COMPOUNDED_PRESCRIPTION]: "Generando receta magistral",
  [RequestType.MEDICAL_ORDER]: "Generando orden médica",
  [RequestType.MEDICAL_CERTIFICATE]: "Generando certificado médico",
};

interface CreationSuccessMessage {
  document: string;
  action: string;
}
export const DocumentCreationSuccessTitle: Record<
  RequestType,
  CreationSuccessMessage
> = {
  [RequestType.PRESCRIPTION]: {
    document: "Receta",
    action: "enviada al paciente",
  },
  [RequestType.COMPOUNDED_PRESCRIPTION]: {
    document: "Receta magistral",
    action: "enviada al paciente",
  },
  [RequestType.MEDICAL_ORDER]: {
    document: "Orden",
    action: "enviada al paciente",
  },
  [RequestType.MEDICAL_CERTIFICATE]: {
    document: "Certificado",
    action: "enviado al paciente",
  },
};

// In the future Medical Order and Medical Certificate will be different
export interface MedicalCertificateResponse extends MedicalCertificate {
  id: string;
  doctorCode: string;
  patientCode: string;
  requestId: string;
  createdAt: number;
}

export interface MedicalOrderResponse extends MedicalOrder {
  id: string;
  doctorCode: string;
  patientCode: string;
  requestId: string;
  createdAt: number;
}

export interface MedicalOrderProcedure {
  id: string;
  group: string;
  subgroup: string;
  item: string;
  code: string;
  description: string;
}

export interface SharedDocumentProperties {
  id: string;
  doctorCode: string;
  patientCode: string;
  status: RequestStatus;
  requestedAt: number;
  patient: Patient;
  comment: string;
  indications: string;
}

export type PrescriptionPayload = SharedDocumentProperties & {
  documentType: RequestType.PRESCRIPTION;
  documents: Prescription[];
};

export type CompoundedPrescriptionPayload = SharedDocumentProperties & {
  documentType: RequestType.COMPOUNDED_PRESCRIPTION;
  documents: CompoundedPrescription[];
};

export type MedicalCertificatePayload = SharedDocumentProperties & {
  documentType: RequestType.MEDICAL_CERTIFICATE;
  documents: MedicalCertificateResponse[];
};

export type MedicalOrderPayload = SharedDocumentProperties & {
  documentType: RequestType.MEDICAL_ORDER;
  documents: MedicalOrderResponse[];
};

export type DocumentsPayload = SharedDocumentProperties &
  (
    | {
        documentType: RequestType.PRESCRIPTION;
        documents: Prescription[];
      }
    | {
        documentType: RequestType.COMPOUNDED_PRESCRIPTION;
        documents: CompoundedPrescription[];
      }
    | {
        documentType: RequestType.MEDICAL_CERTIFICATE;
        documents: MedicalCertificateResponse[];
      }
    | {
        documentType: RequestType.MEDICAL_ORDER;
        documents: MedicalOrderResponse[];
      }
  );

export interface DocumentProps {
  documentId: string;
  documentUrl: string;
  documentName: string;
}

export type CommonPrescription = Prescription | CompoundedPrescription;

export const CommonPrescriptionValidationButtonMapper: Record<string, string> =
  {
    SIMPLE: "Dispensar receta",
    HELD: "Retener receta",
    COMPOUNDED_PRESCRIPTION: "Retener receta",
  };

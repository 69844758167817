import { useQuery } from "@tanstack/react-query";
import { api } from "../context";
import { DocumentPDFDataResponse, PDFData } from "../interfaces";
import { millisecondsInMinute } from "date-fns";

interface UseGetPDFsProps {
  requestId: string;
  documentPDFData?: PDFData[];
}

export const useGetPDFs = ({ requestId, documentPDFData }: UseGetPDFsProps) => {
  return useQuery({
    queryKey: ["request-pdf-data", requestId],
    queryFn: () =>
      api<DocumentPDFDataResponse>({
        url: `/requests/${requestId}/pdf-data`,
      }),
    select: (data) => data.requestPDFData,
    enabled: !documentPDFData,
    staleTime: 5 * millisecondsInMinute,
    initialData: documentPDFData
      ? { requestPDFData: documentPDFData }
      : undefined,
  });
};

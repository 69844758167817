import { styled } from "@mui/material";

const MekiDocLogo = styled("img", {
  shouldForwardProp: (prop) => prop !== "size",
})<{ size: "small" | "medium" }>(({ size }) => ({
  width: size === "small" ? "85px" : "142px",
  height: size === "small" ? "16px" : "26px",
}));

interface MekiDocIconProps {
  size: "small" | "medium";
}

const MekidocIcon = ({ size }: MekiDocIconProps) => (
  <MekiDocLogo src="/MekiDoc-logo.svg" alt="Logo MekiDoc" size={size} />
);

export default MekidocIcon;

import { useQuery } from "@tanstack/react-query";
import { api, useAuth } from "../context";
import { Doctor } from "../interfaces";
import { millisecondsInHour } from "date-fns";

interface UseDoctorResponse {
  doctor: Doctor;
}

export const useDoctor = () => {
  const { isUserAuthed } = useAuth();
  return useQuery({
    queryKey: ["doctor"],
    queryFn: () =>
      api<UseDoctorResponse>({
        url: "/info",
      }),
    select: (data) => data.doctor,
    enabled: isUserAuthed,
    staleTime: 1 * millisecondsInHour,
    retry: (_) => {
      return false;
    },
  });
};

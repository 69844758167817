import { Skeleton } from "@mui/material";

export function RequestsSkeleton() {
  return (
    <>
      <Skeleton variant="rounded" height={85} />
      <Skeleton variant="rounded" height={85} />
      <Skeleton variant="rounded" height={85} />
    </>
  );
}

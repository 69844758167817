import { createContext, useContext } from "react";
import { Doctor } from "../interfaces";
import { useDoctor, useIsScreenBiggerThanSM } from "../hooks";
import { LandingPage } from "../screens";
import { MekiDocSplashScreen } from "../components/MekiDocSplashScreen";

const DoctorContext = createContext<Doctor | null>(null);

export const useDoctorContext = () => {
  const currentUser = useContext(DoctorContext);
  if (!currentUser) {
    throw new Error("DoctorContext: No value provided");
  }
  return currentUser;
};

export const DoctorContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: doctor, isPending, isError } = useDoctor();
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  if (isPending) {
    return isScreenBiggerThanSM ? (
      <LandingPage showSplashScreen />
    ) : (
      <MekiDocSplashScreen />
    );
  }
  if (isError) {
    // TODO: Add a better Error Screen (logout user, show error message, etc.)
    return <>Error...</>;
  }
  return (
    <DoctorContext.Provider value={doctor}>{children}</DoctorContext.Provider>
  );
};

import {
  FieldValues,
  UseFormClearErrors,
  UseFormSetValue,
  Path,
  PathValue,
  UseFormWatch,
  UseFormTrigger,
} from "react-hook-form";

export type NumericInputType = "integer" | "decimal" | "document_number";

export function parseNumericInputValueToString(
  value: string,
  numericInputType: NumericInputType
) {
  let formattedValue = value;
  if (numericInputType === "decimal") {
    // Replace commas with dots
    formattedValue = formattedValue.replace(",", ".");
    // Remove all characters except numbers and dots
    formattedValue = formattedValue.replace(/[^0-9.]/g, "");
    // Allow only one dot
    formattedValue = formattedValue.replace(/(\..*)\./g, "$1");
    // Allow only three decimal places
    formattedValue = formattedValue.replace(/(\.[0-9]{3})./g, "$1");
  } else {
    // Remove all characters except numbers
    formattedValue = formattedValue.replace(/[^0-9]/g, "");
    if (numericInputType === "document_number") {
      // Format with dots after every 3 digits
      formattedValue = formattedValue.replace(/(\d{3})(?=\d)/g, "$1.");
      // Limit to 11 characters (including dots)
      formattedValue = formattedValue.slice(0, 11);
    }
  }
  return formattedValue;
}

export function parseStringToNumericInputValue(
  value: string,
  numericInputType: NumericInputType
) {
  if (numericInputType === "document_number") {
    return value as unknown as number;
  } else {
    return parseFloat(value);
  }
}

export function handleNumericInputChange<T extends FieldValues>(
  attributeName: Path<T>,
  value: PathValue<T, Path<T>>,
  clearErrors: UseFormClearErrors<T>,
  setValue: UseFormSetValue<T>
) {
  if (value) clearErrors(attributeName);
  setValue(attributeName, value);
}

export function replaceCurrentPhoneCode(phone: string, prefix: string) {
  if (!phone || !prefix) return phone;
  // If the phone number starts with a +, replace the whole prefix:
  if (phone.startsWith("+")) {
    return phone.replace(prefix, "");
  } else {
    // If the phone number doesn't start with a +, replace only the numbers:
    const prefixNumbers = prefix.replace(/\D/g, "");
    if (phone.substring(0, prefixNumbers.length).includes(prefixNumbers)) {
      return phone.substring(prefixNumbers.length);
    } else return phone;
  }
}

export function capitalizeParametValue<T extends FieldValues>(
  parameterName: Path<T>,
  watch: UseFormWatch<T>,
  setValue: UseFormSetValue<T>,
  trigger: UseFormTrigger<T>
): void {
  const value = watch(parameterName) as string;
  if (value) {
    const capitalizedValue = value
      .split(/([ \-''])/)
      .map((segment) => {
        return segment.match(/[ \-'']/)
          ? segment
          : segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase();
      })
      .join("") as PathValue<T, typeof parameterName>;
    setValue(parameterName, capitalizedValue);
  }
  trigger(parameterName);
}

import { theme } from "./config/theme";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { awsconfig } from "./config/awsconfig";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import LogRocket from "logrocket";
import packageJson from "../package.json";
import ScrollToTop from "./components/ScrollToTop";

import { FetchProvider } from "./context/fetch";
import { AuthProvider, useAuth } from "./context/auth";
import { MedicineSearchProvider } from "./screens/MedicineSearch/context/MedicineSearchContext";
import { queryClient } from "./context";

import { LandingPage } from "./screens/LandingPage";
import { MobileLogin } from "./screens/Auth/MobileLogin";
import { Login } from "./screens/Auth/Login";
import { RegistrationForm } from "./screens/Registration/RegistrationForm";
import { VerifyAuthCode } from "./screens/Auth/VerifyCode";
import { BiometricRegistration } from "./screens/Auth/BiometricRegistration";
import { Requests } from "./screens/Requests";
import { PrescriptionCreate } from "./screens/PrescriptionForm/PrescriptionCreate";
import { SearchMedicines } from "./screens/MedicineSearch";
import { PrescriptionTreatment } from "./screens/PrescriptionForm/PrescriptionTreatment";
import { AddMedicineManually } from "./screens/PrescriptionForm/AddMedicineManually";
import { SearchProcedure } from "./screens/MedicalOrder/SearchProcedure";
import { AddProcedureManually } from "./screens/MedicalOrder/AddProcedureManually";
import { PersonalData } from "./screens";
import {
  RequestManagement,
  SelectDocument,
} from "./screens/Requests/RequestManagement";
import {
  CompoundedPrescriptionCreate,
  MedicalCertificateCreate,
  MedicalOrderCreate,
} from "./screens";
import { HistoricRequest } from "./screens/Requests/HistoricRequests/HistoricRequest";
import { NewDocumentType } from "./screens/NewDocumentRequest/NewDocumentType";
import { NewDocumentPatient } from "./screens/NewDocumentRequest/NewDocumentPatient";
import { AddPatientInfo } from "./screens/NewDocumentRequest/AddPatientInfo";
import { EditPatientInfo } from "./screens/NewDocumentRequest/EditPatientInfo";
import {
  PrescriptionCodeVerificationScreen,
  PrescriptionValidationScreen,
  QFDataFormScreen,
} from "./screens/PrescriptionValidation";
import { NotFound } from "./screens/NotFound404";
import { QueryClientProvider } from "@tanstack/react-query";

import {
  AddAddressScreen,
  CreatePinScreen,
  IdentityVerification,
  SignRecipeScreen,
  SuccessfulAuth,
  ValidationChallengeScreen,
} from "./screens/FeaAuth";
import { ENV } from "./environment";
import { RequestType } from "./interfaces";
import { DocumentCreationSuccess, PDFViewer } from "./components";
import { DoctorContextProvider } from "./context/doctorContext";
import { SidebarMenuContextProvider } from "./context";
import { useEffect } from "react";
import { useIsScreenBiggerThanSM } from "./hooks";

if (ENV !== "development" && process.env.REACT_APP_LOG_ROCKET_ID) {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID);
}

console.log({
  version: packageJson.version,
});

Amplify.configure(awsconfig);

const AppRouter = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="" element={<PublicOnlyRoute />}>
        <Route path="" element={<LandingPage />} />
        <Route path="/app">
          <Route path="mobile-login" element={<MobileLogin />} />
          <Route path="" element={<Login />} />
          <Route path="codigo" element={<VerifyAuthCode />} />
          <Route path="registro" element={<RegistrationForm />} />
          <Route path="farmacia">
            <Route path="" element={<PrescriptionCodeVerificationScreen />} />
            <Route path=":prescriptionId">
              <Route path="" element={<PrescriptionValidationScreen />} />
              <Route path="info-retencion" element={<QFDataFormScreen />} />
            </Route>
          </Route>
          <Route path="visualizacion-documento" element={<PDFViewer />} />
        </Route>
      </Route>

      <Route path="/app/registro-biometrico" element={<PrivateRoute />}>
        <Route path="" element={<BiometricRegistration />} />
      </Route>

      <Route path="/app/verificacion-identidad" element={<PrivateRoute />}>
        <Route path="" element={<IdentityVerification />} />
      </Route>

      <Route path="/app/nuevo-documento" element={<PrivateRoute />}>
        <Route path="" element={<NewDocumentType />} />
        <Route path="paciente" element={<NewDocumentPatient />} />
        <Route path="paciente/agregar" element={<AddPatientInfo />} />
        <Route path="paciente/editar" element={<EditPatientInfo />} />
      </Route>

      <Route path="/app/solicitudes" element={<PrivateRoute />}>
        <Route path="" element={<Requests />} />
        <Route path=":requestId">
          <Route path="gestionar">
            <Route path="" element={<RequestManagement />} />
            <Route path="seleccionar-documento" element={<SelectDocument />} />
          </Route>

          <Route path="receta">
            <Route path="crear" element={<PrescriptionCreate />} />
            <Route path="crear/buscar" element={<SearchMedicines />} />
            <Route
              path="crear/tratamiento"
              element={<PrescriptionTreatment />}
            />
            <Route path="añadir-manual" element={<AddMedicineManually />} />
            <Route path="firma" element={<SignRecipeScreen />} />
            <Route
              path="exito"
              element={
                <DocumentCreationSuccess
                  requestType={RequestType.PRESCRIPTION}
                />
              }
            />
          </Route>
          <Route path="receta-magistral">
            <Route path="crear" element={<CompoundedPrescriptionCreate />} />
            <Route path="firma" element={<SignRecipeScreen />} />
            <Route
              path="exito"
              element={
                <DocumentCreationSuccess
                  requestType={RequestType.COMPOUNDED_PRESCRIPTION}
                />
              }
            />
          </Route>
          <Route path="orden-medica">
            <Route path="crear" element={<MedicalOrderCreate />} />
            <Route path="crear/buscar" element={<SearchProcedure />} />
            <Route path="añadir-manual" element={<AddProcedureManually />} />
            <Route
              path="exito"
              element={
                <DocumentCreationSuccess
                  requestType={RequestType.MEDICAL_ORDER}
                />
              }
            />
          </Route>
          <Route path="certificado">
            <Route path="crear" element={<MedicalCertificateCreate />} />
            <Route
              path="exito"
              element={
                <DocumentCreationSuccess
                  requestType={RequestType.MEDICAL_CERTIFICATE}
                />
              }
            />
          </Route>
          <Route path="resumen" element={<HistoricRequest />} />
        </Route>
        <Route path="visualizacion-documento" element={<PDFViewer />} />
      </Route>

      <Route
        path="/app/registro/firma-electronica-avanzada"
        element={<PrivateRoute />}
      >
        <Route
          path="validation-challenge"
          element={<ValidationChallengeScreen />}
        />
        <Route path="crear-pin" element={<CreatePinScreen />} />
        <Route path="direccion" element={<AddAddressScreen />} />
        <Route path="exito" element={<SuccessfulAuth />} />
      </Route>

      <Route path="/app/datos-personales" element={<PrivateRoute />}>
        <Route path="" element={<PersonalData />} />
        <Route path="cambiar-direccion" element={<AddAddressScreen />} />
      </Route>

      <Route path="*" element={<Navigate to="/app/404" />} />

      <Route path="/app/404" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

const muiTheme = createTheme({
  palette: { primary: { main: theme.pallete.text.primary } },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
      xxl: 1536,
    },
  },
  typography: { fontFamily: "Poppins" },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <FetchProvider>
        <AuthProvider>
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <MedicineSearchProvider>
              <SidebarMenuContextProvider>
                <AppRouter />
              </SidebarMenuContextProvider>
            </MedicineSearchProvider>
          </ThemeProvider>
        </AuthProvider>
      </FetchProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;

function PrivateRoute() {
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const navigate = useNavigate();

  const { setIsUserAuthed } = useAuth();

  useEffect(() => {
    async function checkAuth() {
      try {
        await Auth.currentAuthenticatedUser();
        setIsUserAuthed(true);
      } catch (error) {
        navigate("/");
      }
    }
    checkAuth();
    // dont put navigate on the dependencies array, we want this only to happen on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DoctorContextProvider>
      {isScreenBiggerThanSM ? <LandingPage /> : <Outlet />}
    </DoctorContextProvider>
  );
}

function PublicOnlyRoute() {
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const { setIsUserAuthed } = useAuth();
  const navigate = useNavigate();
  // TODO: In the future, when we have sessions for different roles,
  // maybe we should check the user role before allow access or redirect him to the correct page
  const location = useLocation();
  const path = location.pathname;
  const noRedirectPaths = ["/app/farmacia", "/app/visualizacion-documento"];
  useEffect(() => {
    async function checkAuth() {
      try {
        await Auth.currentAuthenticatedUser();
        setIsUserAuthed(true);
        if (
          noRedirectPaths.some((noRedirectPath) =>
            path.startsWith(noRedirectPath)
          )
        )
          return;
        navigate("/app/solicitudes");
      } catch (error) {
        console.log("User is Not Logged In");
      }
    }
    checkAuth();
    // dont put navigate on the dependencies array, we want this only to happen on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isScreenBiggerThanSM ? <LandingPage /> : <Outlet />;
}

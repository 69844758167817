import { Box, ListItemText, Select, Typography, styled } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { theme } from "../config";

export const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  paddingTop: "16px",
  flex: 1,
});

export const InputRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
});

interface InputLabelProps {
  disabled?: boolean;
}
export const InputLabel = styled("label")<InputLabelProps>(({ disabled }) => ({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "23px",
  paddingBottom: "4px",
  color: disabled ? theme.pallete.text.disabled : theme.pallete.text.black,
}));

export const StyledInfoIcon = styled(InfoOutlinedIcon)({
  color: theme.pallete.text.black,
  cursor: "pointer",
  width: "20px",
  height: "20px",
  marginBottom: "4px",
});

export const PhoneInputWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const PhoneInputContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

export const PhonePrefixSelect = styled(Select)({
  width: "150px",
  maxHeight: "56px",
  marginRight: "10px",
  background: theme.pallete.background.white,
});

export const IconListItemText = styled(ListItemText)({
  fontSize: "32px",
  marginRight: "10px",
  maxWidth: "16px",
});

export const StyledDetails = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "16px",
  justifyContent: "center",
  border: `1px solid #e0e0e0`,
  borderRadius: "8px",
  gap: "16px",
});

export const NameContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  marginTop: "16px",
  marginBottom: "16px",
  border: `1px solid #eaeaea`,
  borderRadius: "5px",
  padding: "8px 16px",
});

export const NameTitle = styled("p")({
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "left",
  lineHeight: "23px",
});

export const Name = styled("p")({
  fontSize: "16px",
  fontWeight: 400,
  textAlign: "left",
});

export const AccordionsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const DetailColumn = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
});

export const Detail = styled(Box)({});

export const RequestDetailRowContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

export const DetailTitle = styled("h3")({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "23px",
  textWrap: "nowrap",
  color: theme.pallete.text.black,
  margin: "0 0 2px 0",
});

export const RequestDetailTitle = styled(DetailTitle)({
  fontWeight: 600,
});

export const DetailComment = styled("p")({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "23px",
  textWrap: "wrap",
  color: theme.pallete.text.gray,
});

export const ManageRequestContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "16px",
});

export const RelativeContainer = styled(Box)({
  position: "relative",
});

export const ErrorMessage = styled("span")({
  position: "absolute",
  fontSize: "12px",
  color: theme.pallete.errors.primary,
});

export const AuthFeedbackCenteredContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "24px 0px 36px 0px",
  gap: "16px",
  [theme.breakpoints.up(375)]: {
    width: "260px",
    height: "136px",
  },
}));

export const AuthFeedbackMessage = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  textAlign: "center",
});

export const StyledSuccessIcon = styled(CheckCircleIcon)({
  fontSize: "36px",
  color: theme.pallete.success.primary,
});

export const StyledErrorIcon = styled(ReportProblemOutlinedIcon)({
  fontSize: "36px",
  color: theme.pallete.errors.primary,
});

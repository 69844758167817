import { browserName, osName, osVersion } from "react-device-detect";

export const isSafari =
  /^(?!.*(?:android|chrome|opr|opt|opx|edgios|crios|fxios)).*safari/i.test(
    navigator.userAgent
  );

export const getDetectedDeviceData = () => {
  return { browserName, osName, osVersion };
};

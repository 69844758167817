import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ReactGA from "react-ga4";
import { Auth } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { client } from "@passwordless-id/webauthn";
import {
  AuthFeedback,
  AuthInitialStateFeedback,
  AuthMethod,
  AuthStep,
  ChallengeType,
  Doctor,
  DoctorBaseAttributes,
  FetchPayload,
  ManageAuthFeedback,
  StartAuthChallengeProps,
  VerificationMethod,
} from "@interfaces";
import {
  getDetectedDeviceData,
  parseFormattedRut,
  resolveAuthChallengeFeedback,
} from "@utils";
import { useFetch } from "./fetch";
import { queryClient } from "./queryClient";
import {
  ENV,
  MEKIDOC_GOOGLE_ADS_ID,
  DOCTOR_REGISTRATION_CONVERSION_ID,
} from "../environment";

interface DoctorPayload {
  doctor: Doctor;
}

interface AuthState {
  user?: CognitoUserSession;
  isUserAuthed: boolean;
  setIsUserAuthed: React.Dispatch<React.SetStateAction<boolean>>;
  startAuthChallenge: ({
    email,
    authMethod,
  }: StartAuthChallengeProps) => Promise<boolean>;
  authChallengeFeedback: AuthFeedback;
  handleAuthChallengeFeedback: ({
    authMethod,
    authStep,
    changeAfterDelay,
    resetToInitialState,
  }: ManageAuthFeedback) => Promise<void>;
  signIn: (code: string) => Promise<boolean>;
  magicLinkSignIn: (email: string, authCode: string) => Promise<void>;
  biometricSignUp: (doctor: Doctor) => Promise<boolean>;
  biometricSignIn: (doctor: Doctor) => Promise<boolean>;
  logout: () => Promise<void>;
  createDoctor: (
    doctorPayload: DoctorPayload,
    authMethod: AuthMethod
  ) => Promise<Doctor>;
  getDoctorFromCommonStack: (
    verificationMethod: VerificationMethod,
    value: string
  ) => Promise<Doctor | null>;
  getDoctorFromRegistrationStack: (
    rut: string
  ) => Promise<DoctorBaseAttributes | null>;
}

const AuthContext = createContext<AuthState>({
  user: undefined,
  isUserAuthed: false,
  setIsUserAuthed: () => {},
  startAuthChallenge: async () => false,
  authChallengeFeedback: AuthInitialStateFeedback,
  handleAuthChallengeFeedback: async () => {},
  signIn: async () => false,
  magicLinkSignIn: async () => {},
  biometricSignUp: async () => false,
  biometricSignIn: async () => false,
  logout: async () => {},
  createDoctor: async (request: DoctorPayload) => {
    throw new Error("create Doctor not implemented");
  },
  getDoctorFromCommonStack: async (
    verificationMethod: VerificationMethod,
    value: string
  ) => null,
  getDoctorFromRegistrationStack: async (rut: string) => null,
});

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used inside provider");
  }
  return context;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const fetch = useFetch();
  // This user is the Congnito User we use follow the CustomAuthChallenge
  // Notice that is of "any" type, because Amplify returns "CognitoUser | any" in the auth functions
  const [user, setUser] = useState<any>();
  const [isUserAuthed, setIsUserAuthed] = useState(false);
  const [authChallengeFeedback, setAuthChallengeFeedback] =
    useState<AuthFeedback>(AuthInitialStateFeedback);

  const getDoctorFromCommonStack = useCallback(
    async (verificationMethod: VerificationMethod, value: string) => {
      try {
        if (verificationMethod === VerificationMethod.EMAIL) {
          const response = await fetch<Doctor>(`/auth?email=${value}`);
          return response;
        } else {
          const response = await fetch<{ payload: { doctor: Doctor } }>(
            `/doctors?rut=${value}`
          );
          return response.payload.doctor;
        }
      } catch (error) {
        console.error({
          msg: "Error getting doctor from common stack",
          error,
        });
        return null;
      }
    },
    [fetch]
  );

  const signUp = useCallback(async (email: string, phone: string) => {
    await Auth.signUp({
      username: email,
      password: `password${Math.random().toString().slice(0, 8)}`,
      attributes: {
        email: email,
        phone_number: phone,
      },
    });
  }, []);

  const signIn = useCallback(
    async (code: string): Promise<boolean> => {
      if (!user || !code) {
        return false;
      }
      const challengeResult = await Auth.sendCustomChallengeAnswer(user, code, {
        challenge: ChallengeType.RESOLVING_AUTH_CHALLENGE,
        providedUserCode: code,
      });
      if (!challengeResult.signInUserSession) {
        return false;
      } else {
        const currentAuthUser = await Auth.currentAuthenticatedUser();
        setIsUserAuthed(true);
        setUser(currentAuthUser);
        return true;
      }
    },
    [user]
  );

  const handleAuthChallengeFeedback = useCallback(
    async ({
      authMethod,
      authStep,
      changeAfterDelay,
      resetToInitialState,
    }: ManageAuthFeedback) => {
      const delay = 2 * 1000;
      const resolveFeedback = async () => {
        const authChallengeFeedback = resolveAuthChallengeFeedback({
          authMethod,
          authStep,
          resetToInitialState,
        });
        setAuthChallengeFeedback(authChallengeFeedback);
        if (authStep === AuthStep.SUCCESS || authStep === AuthStep.ERROR) {
          await new Promise((resolve) => setTimeout(resolve, delay));
          setAuthChallengeFeedback(AuthInitialStateFeedback);
        }
      };
      if (changeAfterDelay) {
        setTimeout(resolveFeedback, delay);
      } else {
        await resolveFeedback();
      }
    },
    [setAuthChallengeFeedback]
  );

  const startAuthChallenge = useCallback(
    async ({
      email,
      authMethod,
    }: StartAuthChallengeProps): Promise<boolean> => {
      // Define only one attribute to sign in for all the users
      const signInAttribute = email;
      let authChallengeStarted = false;
      try {
        handleAuthChallengeFeedback({
          authMethod,
          authStep: AuthStep.PENDING,
        });
        const userToStartChallenge = await Auth.signIn(signInAttribute);
        await Auth.sendCustomChallengeAnswer(
          userToStartChallenge,
          "select authMethod",
          {
            authMethod,
            challenge: ChallengeType.CHOOSE_AUTH_METHOD,
            ...getDetectedDeviceData(),
          }
        );
        setUser(userToStartChallenge);
        authChallengeStarted = true;
      } catch (error) {
        console.error({
          msg: "Error starting auth challenge",
          error,
        });
      } finally {
        handleAuthChallengeFeedback({
          authMethod,
          authStep: authChallengeStarted ? AuthStep.PENDING : AuthStep.ERROR,
          resetToInitialState: authChallengeStarted,
          changeAfterDelay: true,
        });
        return authChallengeStarted;
      }
    },
    [handleAuthChallengeFeedback]
  );

  const magicLinkSignIn = useCallback(
    async (email: string, authCode: string) => {
      handleAuthChallengeFeedback({
        authMethod: AuthMethod.MAGIC_LINK,
        authStep: AuthStep.VALIDATING,
      });
      const userToStartChallenge = await Auth.signIn(email);
      const challengeResult = await Auth.sendCustomChallengeAnswer(
        userToStartChallenge,
        authCode,
        {
          authMethod: AuthMethod.MAGIC_LINK,
          challenge: ChallengeType.RESOLVING_AUTH_CHALLENGE,
        }
      );
      if (!challengeResult.signInUserSession) {
        await handleAuthChallengeFeedback({
          authMethod: AuthMethod.MAGIC_LINK,
          authStep: AuthStep.ERROR,
        });
        await Auth.signOut();
        return;
      } else {
        const currentAuthUser = await Auth.currentAuthenticatedUser();
        setIsUserAuthed(true);
        setUser(currentAuthUser);
        await handleAuthChallengeFeedback({
          authMethod: AuthMethod.MAGIC_LINK,
          authStep: AuthStep.SUCCESS,
        });
        return;
      }
    },
    [handleAuthChallengeFeedback]
  );

  const biometricSignUp = useCallback(
    async (doctor: Doctor) => {
      const { rut, email, phone } = doctor;
      const formattedRut = parseFormattedRut(rut);
      try {
        handleAuthChallengeFeedback({
          authMethod: AuthMethod.BIOMETRIC_REGISTRATION,
          authStep: AuthStep.PENDING,
        });
        // Generate challenge to sign with user biometric data
        const { challenge } = await fetch<{ challenge: string }>(
          `/biometric-challenge/${formattedRut}`
        );
        // Associate the user biometric data with the challenge
        const registation = await client.register(formattedRut, challenge, {
          // Prompt the user to choose between local or roaming device.
          // The UI and user interaction in this case is platform specific.
          authenticatorType: "both",
          userVerification: "required",
        });
        handleAuthChallengeFeedback({
          authMethod: AuthMethod.BIOMETRIC_REGISTRATION,
          authStep: AuthStep.VALIDATING,
        });
        // Register encrypted user credentials
        await fetch(`/biometric-registration`, {
          method: "POST",
          body: JSON.stringify({
            registration: {
              ...registation,
              userRut: formattedRut,
              userEmail: email,
              userPhone: phone,
            },
            origin: window.location.origin,
          }),
        });
        await handleAuthChallengeFeedback({
          authMethod: AuthMethod.BIOMETRIC_REGISTRATION,
          authStep: AuthStep.SUCCESS,
        });
        return true;
      } catch (error) {
        const userCancellationMessage =
          "The operation either timed out or was not allowed.";
        if (error instanceof DOMException) {
          if (error.message.includes(userCancellationMessage)) {
            console.error({
              msg: "User canceled biometric registration",
              error,
            });
          }
        } else {
          console.error({
            msg: "Biometric registration error",
            error,
          });
        }
        await handleAuthChallengeFeedback({
          authMethod: AuthMethod.BIOMETRIC_REGISTRATION,
          authStep: AuthStep.ERROR,
        });
        return false;
      } finally {
        queryClient.invalidateQueries({
          queryKey: ["doctor"],
        });
      }
    },
    [fetch, handleAuthChallengeFeedback]
  );

  const biometricSignIn = useCallback(
    async (doctor: Doctor) => {
      const { rut, email } = doctor;
      const formattedRut = parseFormattedRut(rut);
      try {
        handleAuthChallengeFeedback({
          authMethod: AuthMethod.BIOMETRIC_AUTH,
          authStep: AuthStep.PENDING,
        });
        const { credentialsIds } = await fetch<{ credentialsIds: string[] }>(
          `/biometric-credentials/${formattedRut}`
        );
        // If there are no user credentials, we can't sign in with biometric data
        if (!credentialsIds.length) {
          return false;
        }
        // Generate challenge to sign with user biometric data
        const { challenge } = await fetch<{ challenge: string }>(
          `/biometric-challenge/${formattedRut}`
        );
        // Sign the challenge with the user biometric data
        const encodedAuthentication = await client.authenticate(
          credentialsIds,
          challenge,
          {
            // Prompt the user to choose between local or roaming device.
            // The UI and user interaction in this case is platform specific.
            authenticatorType: "both",
            userVerification: "required",
          }
        );
        handleAuthChallengeFeedback({
          authMethod: AuthMethod.BIOMETRIC_AUTH,
          authStep: AuthStep.VALIDATING,
        });
        // Verify in the server that the biometric data matches the user
        await fetch(`/biometric-login`, {
          method: "POST",
          body: JSON.stringify({
            authentication: encodedAuthentication,
            origin: window.location.origin,
          }),
        });
        // If the server response is successful, authenticate the user
        const userToStartChallenge = await Auth.signIn(email);
        const challengeResult = await Auth.sendCustomChallengeAnswer(
          userToStartChallenge,
          AuthMethod.BIOMETRIC_AUTH,
          {
            authMethod: AuthMethod.BIOMETRIC_AUTH,
            challenge: ChallengeType.RESOLVING_AUTH_CHALLENGE,
          }
        );
        if (!challengeResult.signInUserSession) {
          await handleAuthChallengeFeedback({
            authMethod: AuthMethod.BIOMETRIC_AUTH,
            authStep: AuthStep.ERROR,
          });
          await Auth.signOut();
          return false;
        } else {
          const currentAuthUser = await Auth.currentAuthenticatedUser();
          setIsUserAuthed(true);
          setUser(currentAuthUser);
          await handleAuthChallengeFeedback({
            authMethod: AuthMethod.BIOMETRIC_AUTH,
            authStep: AuthStep.SUCCESS,
          });
          return true;
        }
      } catch (error) {
        const userCancellationMessage =
          "The operation either timed out or was not allowed.";
        if (error instanceof DOMException) {
          if (error.message.includes(userCancellationMessage)) {
            console.error({
              msg: "User canceled biometric registration",
              error,
            });
          }
        } else {
          console.error({
            msg: "Biometric registration error",
            error,
          });
        }
        await handleAuthChallengeFeedback({
          authMethod: AuthMethod.BIOMETRIC_AUTH,
          authStep: AuthStep.ERROR,
        });
        return false;
      } finally {
        queryClient.invalidateQueries({
          queryKey: ["doctor"],
        });
      }
    },
    [fetch, handleAuthChallengeFeedback]
  );

  const logout = useCallback(async () => {
    try {
      await Auth.signOut();
      await queryClient.resetQueries(
        { queryKey: ["doctor"] },
        { cancelRefetch: true }
      );
      setIsUserAuthed(false);
      setUser(null);
    } catch (error) {
      console.error({
        msg: "Error logging out",
        error,
      });
    }
  }, []);

  const createDoctor = useCallback(
    async (
      doctorPayload: DoctorPayload,
      authMethod: AuthMethod
    ): Promise<Doctor> => {
      const response = await fetch<FetchPayload<DoctorPayload>>(
        `/doctors/create-user`,
        {
          method: "POST",
          body: JSON.stringify(doctorPayload),
        }
      );
      if (!response.payload.doctor) {
        throw Error("No doctor created");
      }
      const { doctor } = response.payload;
      const { email, phone } = doctor;
      await signUp(email, phone);
      await startAuthChallenge({
        email,
        authMethod,
      });
      if (ENV === "production") {
        ReactGA.gtag("event", "conversion", {
          send_to: `${MEKIDOC_GOOGLE_ADS_ID}/${DOCTOR_REGISTRATION_CONVERSION_ID}`,
        });
      }
      return doctor;
    },
    [fetch, startAuthChallenge, signUp]
  );

  const getDoctorFromRegistrationStack = useCallback(
    async (rut: string) => {
      try {
        const payload = await fetch<DoctorBaseAttributes>(
          `/registration/validate-doctor?rut=${rut}`
        );
        return Object.keys(payload).length > 0 ? payload : null;
      } catch (error) {
        console.error({
          msg: "Error getting doctor from registration stack",
          error,
        });
        return null;
      }
    },
    [fetch]
  );

  const value = useMemo(
    () => ({
      user,
      isUserAuthed,
      setIsUserAuthed,
      startAuthChallenge,
      authChallengeFeedback,
      handleAuthChallengeFeedback,
      signIn,
      magicLinkSignIn,
      biometricSignUp,
      biometricSignIn,
      logout,
      createDoctor,
      getDoctorFromCommonStack,
      getDoctorFromRegistrationStack,
    }),
    [
      user,
      isUserAuthed,
      setIsUserAuthed,
      startAuthChallenge,
      authChallengeFeedback,
      handleAuthChallengeFeedback,
      signIn,
      magicLinkSignIn,
      biometricSignUp,
      biometricSignIn,
      logout,
      createDoctor,
      getDoctorFromCommonStack,
      getDoctorFromRegistrationStack,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Global } from "@emotion/react";
import { useDoctorContext } from "@contexts";
import { useRequest } from "@hooks";
import {
  CompoundedPrescriptionDetails,
  Patient,
  RequestStatus,
  RequestType,
  patientDefaultValues,
} from "@interfaces";
import {
  Body,
  Header,
  PatientSummaryAccordion,
  NavigateBeforeButton,
  AuthRequiredModal,
} from "@components";
import { MedicineTreatmentForm } from "../PrescriptionForm/components/MedicineTreatmentForm";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from "@utils";

export function CompoundedPrescriptionCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const doctor = useDoctorContext();
  const { requestId = "" } = useParams();
  const { data: request } = useRequest();

  const [showFeaAuthModal, setShowFeaAuthModal] = useState<boolean>(false);

  const patient: Patient = useMemo(() => {
    return request ? request.patient : patientDefaultValues;
  }, [request]);

  const isDraft = useMemo(() => {
    return request?.status === RequestStatus.DRAFT;
  }, [request]);

  const handleBack = useCallback(() => {
    if (isDraft) {
      navigate(`/app/nuevo-documento/paciente`, {
        state: { documentType: RequestType.COMPOUNDED_PRESCRIPTION },
      });
    } else {
      navigate(`/app/solicitudes/${requestId}/gestionar/seleccionar-documento`);
    }
    removeFromLocalStorage("requestType");
    removeFromLocalStorage("compoundedPrescriptionDetails");
  }, [navigate, isDraft, requestId]);

  const handleSubmit = useCallback(
    async (formData: CompoundedPrescriptionDetails) => {
      if (!doctor.isFeaAuthenticated) {
        setShowFeaAuthModal(true);
        return;
      }
      saveToLocalStorage({
        key: "requestType",
        value: RequestType.COMPOUNDED_PRESCRIPTION,
      });
      saveToLocalStorage({
        key: "compoundedPrescriptionDetails",
        value: formData,
        stringifyJson: true,
      });
      navigate(`/app/solicitudes/${requestId}/receta-magistral/firma`);
    },
    [doctor, requestId, navigate]
  );

  return (
    <>
      <Header
        title={"Receta magistral"}
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <PatientSummaryAccordion
          patient={patient}
          prevRoute={`/app/solicitudes/${requestId}/receta-magistral/crear`}
          requestId={requestId}
          isOpen={isDraft}
          canEdit={isDraft}
        />
        <MedicineTreatmentForm
          prefilledMedicineAttributes={
            location.state ||
            getFromLocalStorage({
              key: "compoundedPrescriptionDetails",
              parseJson: true,
            }) ||
            {}
          }
          formManagement={{
            showCompoundedIngredients: true,
            onSubmit: handleSubmit,
            isLoading: false,
          }}
        />
      </Body>
      <AuthRequiredModal
        requestId={requestId}
        isOpen={showFeaAuthModal}
        onClose={() => setShowFeaAuthModal(false)}
      />
    </>
  );
}

import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, InputAdornment, Skeleton, styled } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Global } from "@emotion/react";
import {
  NewRequestPayload,
  Patient,
  RequestStatus,
  RequestType,
} from "@interfaces";
import {
  Body,
  FullScreenLoader,
  Header,
  MainActionButton,
  NavigateBeforeButton,
  SecondaryActionButton,
} from "@components";
import { Card } from "./components/Card";
import { Search } from "../Requests/styled";
import { useDoctorContext } from "@contexts";
import { useCreateRequest, useRequests } from "@hooks";
import Fuse from "fuse.js";
import { format } from "rut.js";
import { theme } from "../../config";

const PatientsWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const MainActionButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  paddingTop: "24px",
});

const PatientCardsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const EmptyRequestWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "screenCentered",
})(({ screenCentered }: { screenCentered?: boolean }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  paddingTop: screenCentered ? "72px" : "8px",
  color: theme.pallete.text.gray,
}));

const EmptyRequestContainer = styled(EmptyRequestWrapper)({
  gap: "8px",
  padding: "0",
});

const StyledInfoIcon = styled(InfoOutlinedIcon)({
  fontSize: 24,
});

const SkeletonsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  marginTop: "16px",
});

interface NewDocumentPatientProps {
  documentType: RequestType;
}

export function NewDocumentPatient() {
  const navigate = useNavigate();
  const doctor = useDoctorContext();

  const location = useLocation();
  const { documentType = RequestType.PRESCRIPTION }: NewDocumentPatientProps =
    location.state || {};
  const { data: requests, isPending: isPendingRequests } = useRequests();
  const { mutate: createRequest, isPending: isCreatingRequest } =
    useCreateRequest();
  const [search, setSearch] = useState("");

  const allPatients = (requests ?? [])
    .map((request) => request.patient)
    .filter(
      (patient, index, array) =>
        array.findIndex(
          (p) => p.rut === patient.rut && p.name === patient.name
        ) === index
    );

  const fusePatients = new Fuse(
    allPatients.map((patient) => {
      return {
        ...patient,
        fRut: format(patient.rut),
        cleanRut: patient.rut.replace(/[.-]/g, ""),
      };
    }),
    {
      keys: [
        "name",
        "firstSurname",
        "secondSurname",
        "fRut",
        "cleanRut",
        "rut",
      ],
      threshold: 0.1,
    }
  );

  const patients =
    search.length > 0
      ? fusePatients.search(search).map((result) => result.item)
      : allPatients;

  const hideAddPatientButton = allPatients.length > 0 && patients.length === 0;

  const handleAddPatient = useCallback(() => {
    navigate("/app/nuevo-documento/paciente/agregar", {
      state: {
        documentType,
        ...(/^[\d.-]+$/.test(search) && { searchedRut: search }),
      },
    });
  }, [navigate, documentType, search]);

  const handlePatientCardClick = useCallback(
    (patient: Patient) => {
      const newRequestPayload: NewRequestPayload = {
        doctorCode: doctor.code,
        patient: {
          ...patient,
          streetNumber: "",
          chronicDiseases: "",
          allergies: "",
        },
        request: {
          documentType: documentType,
          doctorCode: doctor.code,
          status: RequestStatus.DRAFT,
          comment: "",
        },
      };

      createRequest(newRequestPayload);
    },
    [documentType, doctor, createRequest]
  );

  if (isCreatingRequest) {
    return <FullScreenLoader title="Cargando..." />;
  }

  return (
    <>
      <Header
        title="Paciente"
        titleColor="black"
        left={
          <NavigateBeforeButton
            onClick={() => navigate("/app/nuevo-documento")}
          />
        }
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <PatientsWrapper>
          {!hideAddPatientButton && (
            <MainActionButtonContainer>
              <MainActionButton
                variant="outlined"
                size="large"
                onClick={handleAddPatient}
                disabled={isPendingRequests}
              >
                Agregar paciente
              </MainActionButton>
            </MainActionButtonContainer>
          )}

          {!isPendingRequests &&
            (allPatients.length > 0 ? (
              <>
                <Search
                  placeholder="Buscar por nombre o RUT"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon
                          style={{ color: theme.pallete.text.black }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {patients.length > 0 ? (
                  <PatientCardsContainer>
                    {patients.map((patient) => (
                      <Card
                        key={patient.rut}
                        name={patient.name}
                        lastName={`${patient.firstSurname} ${patient.secondSurname}`}
                        rut={patient.rut}
                        onClick={() => handlePatientCardClick(patient)}
                      />
                    ))}
                  </PatientCardsContainer>
                ) : (
                  <EmptyRequestWrapper>
                    <EmptyRequestContainer>
                      <StyledInfoIcon />
                      No se encontraron resultados
                    </EmptyRequestContainer>

                    <SecondaryActionButton
                      onClick={handleAddPatient}
                      disabled={isPendingRequests}
                      textDecoration="underline"
                    >
                      Agregar nuevo paciente
                    </SecondaryActionButton>
                  </EmptyRequestWrapper>
                )}
              </>
            ) : (
              <EmptyRequestWrapper screenCentered>
                <EmptyRequestContainer>
                  <StyledInfoIcon />
                  No has agregado pacientes
                </EmptyRequestContainer>
              </EmptyRequestWrapper>
            ))}
          {isPendingRequests && <NewDocumentPatientSkeleton />}
        </PatientsWrapper>
      </Body>
    </>
  );
}

function NewDocumentPatientSkeleton() {
  return (
    <SkeletonsContainer>
      <Skeleton variant="rectangular" height={48} />
      <PatientCardsContainer>
        {Array.from({ length: 3 }).map((_, index) => (
          <Card.Skeleton key={index} />
        ))}
      </PatientCardsContainer>
    </SkeletonsContainer>
  );
}

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { styled, Box, Modal, Paper, CircularProgress } from "@mui/material";
import { AuthStep } from "@interfaces";
import { MekiDocSplashScreen } from "@components";
import {
  Header,
  Hero,
  FeatureCardsSection,
  SecuritySection,
  FeatureExplanationSection,
  FAQSection,
  Footer,
} from "./components";
import { AuthFeedbackModal } from "@screens/Auth/components";
import { useAuth } from "@contexts";
import { StyledErrorIcon } from "@styles/styled";

const LandingContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

const FirstSectionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  background: `linear-gradient(180deg, #DFD8FF 0%, #F0EDFC 71%, #FFFFFF 100%)`,
  justifyContent: "center",
  width: "100%",
  minHeight: "636px",
  [theme.breakpoints.up("sm")]: {
    minHeight: "540px",
  },
  [theme.breakpoints.up("lg")]: {
    minHeight: "835px",
  },
  [theme.breakpoints.up("xl")]: {
    minHeight: "881px",
  },
}));

const FirstSectionContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: `${theme.breakpoints.values.xxl}px`,
  flex: 1,
  padding: "32px 5% 0",
}));

const ModalBg = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  height: "90%",
  overflowY: "auto",
  width: "580px",
  [theme.breakpoints.up(620)]: {
    width: "600px",
  },
  backgroundColor: "#F5F5F5",
}));

const loginRoutes = ["/app", "/app/codigo", "/app/farmacia"];

export const LandingPage: React.FC<{ showSplashScreen?: boolean }> = ({
  showSplashScreen,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isLandingPageRoute = useMemo(
    () => location.pathname === "/",
    [location]
  );
  const isLoginRoute = useMemo(
    () => loginRoutes.includes(location.pathname),
    [location]
  );
  const goToLandingRoute = useCallback(
    () => navigate("/"),
    // navigate changes when its context changes, but we don't need the context and want to avoid
    // unnecesary rerenders. don't put it in dependecy unless you have a good reason to do it
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    magicLinkSignIn,
    authChallengeFeedback: { message: authFeedback, authStep },
  } = useAuth();
  const [usingMagicLink, setUsingMagicLink] = useState(false);
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get("email");
  const authCode = searchParams.get("code");

  const feedbackIcon = (authStep: AuthStep) => {
    switch (authStep) {
      case AuthStep.ERROR:
        return <StyledErrorIcon />;
      default:
        return <CircularProgress size={24} color="primary" />;
    }
  };

  useEffect(() => {
    async function signInWithMagicLink() {
      if (userEmail && authCode) {
        setUsingMagicLink(true);
        await magicLinkSignIn(userEmail, authCode);
        navigate("/app/solicitudes");
        setUsingMagicLink(false);
      }
    }
    signInWithMagicLink();
  }, [userEmail, authCode, magicLinkSignIn, navigate]);

  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (location.pathname !== "/") {
      modalRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <LandingContainer>
      <Modal
        open={
          !isLandingPageRoute || Boolean(showSplashScreen && !usingMagicLink)
        }
        id="container-modal"
        onClose={isLoginRoute ? goToLandingRoute : undefined}
      >
        <ModalBg ref={modalRef}>
          {showSplashScreen && <MekiDocSplashScreen />}
          {!isLandingPageRoute && !showSplashScreen && <Outlet />}
        </ModalBg>
      </Modal>
      <FirstSectionContainer>
        <FirstSectionContent>
          <Header />
          <Hero />
        </FirstSectionContent>
      </FirstSectionContainer>
      <FeatureCardsSection />
      <SecuritySection />
      <FeatureExplanationSection />
      <FAQSection />
      <Footer />

      {usingMagicLink && (
        <AuthFeedbackModal
          isOpen={usingMagicLink}
          authFeedback={authFeedback}
          authStep={authStep}
          feedbackIcon={feedbackIcon}
        />
      )}
    </LandingContainer>
  );
};

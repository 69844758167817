import { Box, ClickAwayListener, Tooltip, styled } from "@mui/material";
import React, { PropsWithChildren } from "react";

type TooltipPlacement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

interface TooltipProps {
  title: string | React.ReactElement;
  placement?: TooltipPlacement;
  maxWidth?: string;
  children: React.ReactElement;
  [x: string]: any;
}

export const TooltipComponent: React.FC<PropsWithChildren<TooltipProps>> = ({
  title,
  placement,
  maxWidth,
  children,
  ...props
}) => {
  return (
    <Tooltip
      {...props}
      title={title}
      placement={placement ?? "bottom"}
      componentsProps={{
        tooltip: {
          sx: {
            marginTop: "0px !important",
            maxWidth: maxWidth ?? "275px",
            bgcolor: "#323441",
            color: "#FFFFFF",
          },
        },
      }}
      style={{ pointerEvents: "auto", cursor: "pointer" }}
    >
      {children}
    </Tooltip>
  );
};

const TooltipContainer = styled(Box)({
  display: "flex",
});

interface ClickableTooltipProps extends TooltipProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ClickableTooltip: React.FC<
  PropsWithChildren<ClickableTooltipProps>
> = ({ title, placement, maxWidth, open, setOpen, children, ...props }) => {
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <TooltipContainer
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        onClick={handleTooltipOpen}
      >
        <TooltipComponent
          {...props}
          title={title}
          open={open}
          onClose={handleTooltipClose}
          placement={placement ?? "bottom-start"}
          PopperProps={{
            disablePortal: true,
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          {children}
        </TooltipComponent>
      </TooltipContainer>
    </ClickAwayListener>
  );
};

import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TransitionEvent, useRef, useState } from "react";
import { theme } from "../config";

const AccordionContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #eaeaea;
  padding: 16px;
  flex: 0;
  background-color: ${theme.pallete.background.white};
`;
const AccordionSummary = styled.div`
  display: flex;
  justify-content: space-between;
  margin: -8px -16px -8px -16px;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  align-items: center;
`;
const AccordionTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  margin: 0;
`;

const AccordionSubtitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  margin: 0;
  padding-top: 8px;
  color: ${theme.pallete.text.black};
`;

interface CloseableProps {
  open: boolean;
}
interface AccordionDetailsProps extends CloseableProps {
  maxHeight: number;
}
const AccordionDetails = styled.div<AccordionDetailsProps>`
  ${(props) =>
    props.open ? `max-height: ${props.maxHeight}px;` : "max-height: 0;"}
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
`;
const StyledExpandIcon = styled(ExpandMoreIcon)<CloseableProps>`
  ${(props) => (props.open ? "transform: rotate(-180deg);" : "")}
  transition: 0.3s;
`;

interface AccordionProps {
  children: React.ReactNode;
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  initialMaxHeight?: number;
  expanded?: boolean;
}
export function Accordion({
  children,
  title,
  subtitle,
  initialMaxHeight = 500,
  expanded,
}: AccordionProps) {
  const [open, setOpen] = useState(expanded || false);
  const [maxHeight, setMaxHeight] = useState<number>(initialMaxHeight);
  const detailsRef = useRef<HTMLDivElement>(null);
  const handleMaxHeight = (e: TransitionEvent<HTMLDivElement>) => {
    const height = e.currentTarget.getBoundingClientRect().height;
    if (height === 0) return;
    setMaxHeight(height);
  };
  return (
    <AccordionContainer>
      <AccordionSummary onClick={() => setOpen(!open)}>
        <div>
          <AccordionTitle>{title}</AccordionTitle>
          {subtitle && <AccordionSubtitle>{subtitle}</AccordionSubtitle>}
        </div>
        <StyledExpandIcon open={open} />
      </AccordionSummary>
      <AccordionDetails
        onTransitionEnd={handleMaxHeight}
        open={open}
        maxHeight={maxHeight}
        ref={detailsRef}
      >
        {children}
      </AccordionDetails>
    </AccordionContainer>
  );
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, CircularProgress, Link, Typography, styled } from "@mui/material";
import { Global } from "@emotion/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ErrorMessage } from "@styles/styled";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  CodeInput,
  FullScreenLoader,
  Header,
  SnackBar,
  NavigateBeforeButton,
  ExtraWhiteSpace,
} from "@components";
import { AuthMethod, ChallengeType, RequestType } from "@interfaces";
import { api, queryClient, useDoctorContext } from "@contexts";
import {
  getFromLocalStorage,
  getUnitForQuantity,
  onContactUsClick,
  redirectToPinScreenResolver,
} from "@utils";
import { needHelpMessage } from "../../constants";
import { theme as oldTheme } from "../../config";
import { millisecondsInSecond } from "date-fns";
import { Auth } from "aws-amplify";

type CreateCertificateStatus =
  | "NOT_STARTED"
  | "CREATING_CERTIFICATE_APPLICATION"
  | "DOWNLOADING_CERTIFICATE"
  | "SUCCESS"
  | "ERROR";

const PinContainer = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  marginTop: "64px",
});

const PinLabel = styled(Typography)({
  textAlign: "center",
  padding: "16px",
});

const PinErrorContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ContactUsContainer = styled(Box)({
  paddingTop: "20px",
});

const Bold = styled("span")({
  fontWeight: 600,
});

const PinDisclaimerContainer = styled(Box)({
  display: "flex",
  padding: "32px 0px 16px 0px",
});

const PinDisclaimer = styled(Typography)({
  fontSize: "14px",
  textAlign: "center",
  color: oldTheme.pallete.text.gray,
});

const TitleScreenLoader = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: 500,
  textAlign: "center",
});

const SubtitleLoader = styled(Typography)({
  textAlign: "center",
});

export function CreatePinScreen() {
  const navigate = useNavigate();
  const doctor = useDoctorContext();
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get("requestId");
  const [pin, setPin] = useState("");
  const [isPollingStatus, setIsPollingStatus] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(30);
  const [isCreatingMagicLink, setIsCreatingMagicLink] = useState(false);

  const requestType = getFromLocalStorage<RequestType>({
    key: "requestType",
  }) as RequestType;

  const { mutate: createCertificate, isPending: creatingCertIsPending } =
    useMutation({
      mutationFn: (pin: string) => {
        setIsPollingStatus(true);
        return api({
          method: "post",
          url: "/esign/create-certificate",
          data: { pin },
        });
      },
    });

  const { data: certificateCreationStatus } = useQuery({
    queryKey: ["certificateCreationStatus"],
    queryFn: () =>
      api<{ status: CreateCertificateStatus }>({
        method: "get",
        url: "/esign/create-certificate/status",
      }),
    enabled: isPollingStatus,
    refetchInterval: 3 * millisecondsInSecond,
    initialData: { status: "NOT_STARTED" },
  });

  const { isCertificateReadyToUse, isCertificateFailed } = useMemo(() => {
    if (!certificateCreationStatus)
      return { isCertificateReadyToUse: false, isCertificateFailed: false };
    const { status } = certificateCreationStatus;
    return {
      isCertificateReadyToUse: status === "SUCCESS",
      isCertificateFailed: status === "ERROR",
    };
  }, [certificateCreationStatus]);

  const doctorAddressNeeded = useMemo(() => {
    const { region, commune, street } = doctor;
    return !region || !commune || !street;
  }, [doctor]);

  useEffect(() => {
    function handleRedirectAfterCertificateCreation() {
      if (isCertificateReadyToUse) {
        queryClient.invalidateQueries({ queryKey: ["doctor"] });
        const redirectTo = redirectToPinScreenResolver(requestId, requestType);
        if (doctorAddressNeeded) {
          const queryParams = requestId ? `?requestId=${requestId}` : "";
          navigate(`../direccion${queryParams}`, {
            state: { successfullAuth: true, redirectTo },
          });
        } else {
          navigate(redirectTo);
        }
      }
    }
    handleRedirectAfterCertificateCreation();
  }, [
    isCertificateReadyToUse,
    doctorAddressNeeded,
    requestId,
    requestType,
    navigate,
  ]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleNextScreen = useCallback(async () => {
    if (pin.length === 4) {
      setIsCreatingMagicLink(true);
      const user = await Auth.currentAuthenticatedUser();
      const userWithSession = await Auth.signIn(user.username);
      await Auth.sendCustomChallengeAnswer(
        userWithSession,
        "create magic link",
        {
          authMethod: AuthMethod.MAGIC_LINK,
          challenge: ChallengeType.CHOOSE_AUTH_METHOD,
        }
      );
      setIsCreatingMagicLink(false);
      createCertificate(pin);
    }
  }, [pin, createCertificate]);

  const handlePinChange = (val: string) => {
    setPin(val);
  };

  const isWaitingForCertificateCreation =
    isCreatingMagicLink ||
    creatingCertIsPending ||
    (isPollingStatus &&
      !["SUCCESS", "ERROR"].includes(certificateCreationStatus.status));

  useEffect(() => {
    function updateRemainingSeconds() {
      let timerId: NodeJS.Timer | null = null;
      if (isWaitingForCertificateCreation && remainingSeconds > 0) {
        timerId = setInterval(() => {
          setRemainingSeconds((prev) => Math.max(prev - 1, 0));
        }, 1000);
      }
      return () => {
        if (timerId) clearInterval(timerId);
      };
    }
    return updateRemainingSeconds();
  }, [isWaitingForCertificateCreation, remainingSeconds]);

  if (isWaitingForCertificateCreation)
    return (
      <FullScreenLoader>
        <TitleScreenLoader>
          {remainingSeconds
            ? "Estamos registrando tu firma electrónica"
            : "Estamos demorando un poco más de lo esperado."}
        </TitleScreenLoader>
        <SubtitleLoader>
          {remainingSeconds
            ? `Este proceso puede durar ${remainingSeconds} ${getUnitForQuantity(
                "segundo",
                remainingSeconds
              )}`
            : "Danos unos segundos más para finalizar el registro."}
        </SubtitleLoader>
      </FullScreenLoader>
    );

  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Header
        title="Verificación de identidad"
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Body>
        <PinContainer>
          <PinLabel>
            Crea un <Bold>PIN de 4 dígitos</Bold>. Te lo pediremos cada vez que
            quieras firmar una receta
          </PinLabel>
          <CodeInput
            code={pin}
            handleCodeChange={handlePinChange}
            numInputs={4}
            isValid={!isCertificateFailed}
            type={"number"}
          />
          {isCertificateFailed && (
            <PinErrorContainer>
              <ErrorMessage>
                Ha ocurrido un error con la creación de tu PIN
              </ErrorMessage>
              <ContactUsContainer>
                Si el problema persiste{" "}
                <Link
                  onClick={() => {
                    onContactUsClick(needHelpMessage);
                  }}
                >
                  contáctanos
                </Link>
              </ContactUsContainer>
            </PinErrorContainer>
          )}
          <PinDisclaimerContainer>
            <PinDisclaimer>
              <Bold>Elige un PIN que te sea fácil de recordar.</Bold> En caso de
              olvidarlo tendrás que volver a verificar tu identidad
            </PinDisclaimer>
          </PinDisclaimerContainer>
        </PinContainer>

        <ExtraWhiteSpace />

        <SnackBar
          snackBarState={{
            open: isCertificateReadyToUse,
            type: "success",
          }}
          alerts={{
            success: "Registro exitoso",
          }}
        />
        <BottomActionArea>
          <BottomActionButton
            onClick={handleNextScreen}
            disabled={pin.length !== 4 || isWaitingForCertificateCreation}
          >
            {isWaitingForCertificateCreation ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Siguiente"
            )}
          </BottomActionButton>
        </BottomActionArea>
      </Body>
    </>
  );
}

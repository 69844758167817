import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import { Global } from "@emotion/react";
import { useDoctorContext } from "@contexts";
import {
  Body,
  CodeInput,
  ErrorMessage,
  FullScreenLoader,
  Header,
  Modal,
  NavigateBeforeButton,
  SecondaryActionButton,
} from "@components";
import { useRequest, useReEnlistment } from "@hooks";
import { useSignCompoundedPrescription, useSignPrescription } from "./hooks";
import {
  RequestType,
  PinState,
  defaultPinState,
  DocumentCreationLoaderTitle,
  extractBlockingTime,
  CompoundedPrescriptionDetails,
} from "@interfaces";
import { getFromLocalStorage, redirectToPinScreenResolver } from "@utils";

const PinWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "64px",
});

const PinLabel = styled(Typography)({
  textAlign: "center",
  padding: "16px",
});

const PinContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "32px",
});

const StyledErrorMessage = styled(ErrorMessage)({
  fontSize: "14px",
  marginTop: "8px",
});

const BoldText = styled("span")({
  fontWeight: 600,
});

export function SignRecipeScreen() {
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  const { data: request } = useRequest();
  const doctor = useDoctorContext();
  const [pinState, setPinState] = useState<PinState>(defaultPinState);
  const [showForgottenPinModal, setShowForgottenPinModal] = useState(false);
  const [showBlockedPinModal, setShowBlockedPinModal] = useState(false);
  const [showFailedReEnlistmentModal, setShowFailedReEnlistmentModal] =
    useState(false);
  const [esignErrorMessage, setEsignErrorMessage] = useState("");
  const [hasNecessaryData, setHasNecessaryData] = useState(false);

  const requestType = getFromLocalStorage<RequestType>({
    key: "requestType",
  }) as RequestType;
  const compoundedPrescriptionDetails =
    getFromLocalStorage<CompoundedPrescriptionDetails>({
      key: "compoundedPrescriptionDetails",
      parseJson: true,
    }) as CompoundedPrescriptionDetails;

  const { mutate: signRecipe, isPending: isCreatingPrescription } =
    useSignPrescription({
      setPinState,
      setShowBlockedPinModal,
      setEsignErrorMessage,
    });
  const {
    mutate: signCompoundedPrescription,
    isPending: isCreatingCompoundedPrescription,
  } = useSignCompoundedPrescription({
    setPinState,
    setShowBlockedPinModal,
    setEsignErrorMessage,
  });

  const {
    reEnlistmentMutation: { mutate: reEnlistment, isPending: isUnenrolling },
    isStartingEsignFlow,
  } = useReEnlistment(requestId);

  const handleReEnlistment = useCallback(() => {
    reEnlistment(doctor, {
      onError: () => {
        setShowForgottenPinModal(false);
        setShowBlockedPinModal(false);
        setShowFailedReEnlistmentModal(true);
      },
    });
  }, [doctor, reEnlistment]);

  const handleBack = useCallback(() => {
    // TODO: add requestId so we can go back even if we are coming from other route
    navigate(-1);
  }, [navigate]);

  const handlePinChange = async (val: string) => {
    setPinState({
      pin: val,
      error: false,
      errorMessage: "",
    });
    if (request && val.length === 4) {
      if (requestType === RequestType.PRESCRIPTION && request.details) {
        signRecipe({ request, requestDetailData: request.details, pin: val });
      } else if (
        requestType === RequestType.COMPOUNDED_PRESCRIPTION &&
        compoundedPrescriptionDetails
      ) {
        signCompoundedPrescription({
          requestId,
          compoundedPrescriptionDetails,
          pin: val,
        });
      }
    }
  };

  useEffect(() => {
    function redirectToAddressScreenIfNeeded() {
      if (!request) return;
      const { region, commune, street } = doctor;
      const doctorAddressNeeded = !region || !commune || !street;
      if (doctorAddressNeeded) {
        const editAddressUrl = `/app/registro/firma-electronica-avanzada/direccion?requestId=${requestId}`;
        const redirectTo = redirectToPinScreenResolver(requestId, requestType);
        navigate(editAddressUrl, {
          state: {
            redirectTo,
          },
        });
      } else {
        setHasNecessaryData(true);
      }
    }
    redirectToAddressScreenIfNeeded();
  }, [request, requestType, requestId, doctor, navigate]);

  if (!hasNecessaryData)
    return <FullScreenLoader title="Comprobando información" />;
  if (isCreatingPrescription)
    return (
      <FullScreenLoader
        title={DocumentCreationLoaderTitle[RequestType.PRESCRIPTION]}
      />
    );
  if (isCreatingCompoundedPrescription)
    return (
      <FullScreenLoader
        title={DocumentCreationLoaderTitle[RequestType.COMPOUNDED_PRESCRIPTION]}
      />
    );
  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Header
        title="Firmar receta"
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Body>
        <PinWrapper>
          <PinLabel>Ingresa tu PIN de 4 dígitos para firmar la receta</PinLabel>
          <PinContainer>
            <CodeInput
              code={pinState.pin}
              handleCodeChange={handlePinChange}
              numInputs={4}
              isValid={!pinState.error}
              type={"number"}
              secretInputs={true}
            />
            {pinState.error && (
              <StyledErrorMessage>{pinState.errorMessage}</StyledErrorMessage>
            )}
            <SecondaryActionButton
              onClick={() => setShowForgottenPinModal(true)}
            >
              Olvidé mi PIN
            </SecondaryActionButton>
          </PinContainer>
        </PinWrapper>

        <Modal
          title="Tu PIN ha sido bloqueado"
          content={
            <>
              <BoldText>Verifica tu identidad</BoldText> para crear uno nuevo o
              espera {extractBlockingTime(esignErrorMessage)} para que se
              desbloquee
            </>
          }
          isOpen={showBlockedPinModal}
          onClose={() => setShowBlockedPinModal(false)}
          buttonProps={{
            buttonText: "Verificar identidad",
            onClick: handleReEnlistment,
            loading: isUnenrolling || isStartingEsignFlow,
          }}
          secondaryButtonProps={{
            buttonText: "Volver atrás y esperar",
            onClick: () => setShowBlockedPinModal(false),
          }}
        />

        <Modal
          title="Restablecer PIN"
          content={
            <>
              Para restablecer tu PIN es necesario volver a{" "}
              <BoldText>verificar tu identidad en 3 pasos</BoldText>
            </>
          }
          isOpen={showForgottenPinModal}
          onClose={() => setShowForgottenPinModal(false)}
          buttonProps={{
            buttonText: "Verificar identidad",
            onClick: handleReEnlistment,
            loading: isUnenrolling || isStartingEsignFlow,
          }}
          secondaryButtonProps={{
            buttonText: "Volver atrás",
            onClick: () => setShowForgottenPinModal(false),
          }}
        />

        <Modal
          title="Ha ocurrido un error"
          content="No se pudo iniciar el proceso de verificación de identidad. Por favor, inténtalo de nuevo."
          isOpen={showFailedReEnlistmentModal}
          onClose={() => setShowFailedReEnlistmentModal(false)}
          buttonProps={{
            buttonText: "Cerrar",
            onClick: () => setShowFailedReEnlistmentModal(false),
          }}
        />
      </Body>
    </>
  );
}

import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { api } from "../context";

export type CreateClaveUnicaURL = { url: string };

type MutationProps = {
  requestId?: string;
  isReEnlistment?: boolean;
};

export const useStartEsignFlow = () => {
  // Show loading spinner depending on the state of the esign flow
  // but adding a delay to consider the redirection to the 'clave unica' login
  const [isStartingEsignFlow, setIsStartingEsignFlow] = useState(false);
  return {
    startEsignFlowMutation: useMutation({
      mutationFn: (variables: MutationProps) =>
        api<CreateClaveUnicaURL>({
          method: "post",
          url: "/esign/clave-unica-url",
          data: {
            requestId: variables?.requestId ?? null,
            isReEnlistment: variables?.isReEnlistment ?? false,
          },
        }),
      onMutate: () => {
        setIsStartingEsignFlow(true);
      },
      onSuccess(data) {
        window.location.href = data.url;
      },
      onSettled: () => {
        const timeoutId = setTimeout(() => {
          setIsStartingEsignFlow(false);
        }, 5000);
        return () => clearTimeout(timeoutId);
      },
    }),
    isStartingEsignFlow,
  };
};

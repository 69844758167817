import { useMutation } from "@tanstack/react-query";
import { api, queryClient } from "../context";
import { useStartEsignFlow } from "./useStartEsignFlow";
import { Doctor } from "../interfaces";

export const useReEnlistment = (requestId: string) => {
  const {
    startEsignFlowMutation: { mutate: startEsignFlow },
    isStartingEsignFlow,
  } = useStartEsignFlow();
  const unenrollmentFn = (doctor: Doctor) => {
    return api<Doctor>({
      method: "PUT",
      url: "/doctors/unenrollment",
      data: { doctor },
    });
  };
  return {
    reEnlistmentMutation: useMutation({
      mutationFn: unenrollmentFn,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["doctor"] });
        startEsignFlow({ requestId, isReEnlistment: true });
      },
      onError: (error) => {
        console.log("Error on unenrollment: ", error);
      },
    }),
    isStartingEsignFlow,
  };
};

import { useCallback, useEffect, useState } from "react";
import {
  FieldErrors,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import {
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  IconListItemText,
  InputLabel,
  InputRow,
  PhoneInputContainer,
  PhoneInputWrapper,
  PhonePrefixSelect,
  StyledInfoIcon,
} from "../../styles/styled";
import { ClickableTooltip, SingleFormInput } from "../../components";
import { replaceCurrentPhoneCode, trimString } from "../../utils";
import phoneCodes from "../../data/phoneCodes.json";

interface PhoneOwner {
  phone: string;
  [key: string]: any;
}

interface PhoneCodeProps {
  name: string;
  icon: string;
  prefix: string;
}

interface PhoneCodeState {
  currentPhoneCode: PhoneCodeProps;
  setCurrentPhoneCode: React.Dispatch<React.SetStateAction<PhoneCodeProps>>;
}

interface PhoneInputProps<T extends PhoneOwner> {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  trigger: UseFormTrigger<T>;
  clearErrors: UseFormClearErrors<T>;
  phoneCodeState: PhoneCodeState;
  showTooltip?: boolean;
}

export const PhoneInput = <T extends PhoneOwner>({
  register,
  errors,
  watch,
  setValue,
  trigger,
  clearErrors,
  phoneCodeState: { currentPhoneCode, setCurrentPhoneCode },
  showTooltip = false,
}: PhoneInputProps<T>) => {
  const phone = watch("phone" as Path<T>);
  const phoneCodeList = phoneCodes.phoneCodes;
  const [showCurrentPhoneCode, setShowCurrentPhoneCode] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const changeTooltipOpenState = () => setTooltipOpen((prev) => !prev);

  const replaceCurrentPhoneCodeCallback = useCallback(
    (phone: string, currentPhoneCodePrefix: string) => {
      const currentPhoneValue = replaceCurrentPhoneCode(
        phone,
        currentPhoneCodePrefix
      );
      setValue("phone" as Path<T>, currentPhoneValue as PathValue<T, Path<T>>);
    },
    [setValue]
  );

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const selectedPhoneCode = event.target.value as string;
      const newPhoneCode = {
        name: "",
        icon: selectedPhoneCode.split(" ")[0],
        prefix: selectedPhoneCode.split(" ")[1],
      };
      setCurrentPhoneCode(newPhoneCode);
      replaceCurrentPhoneCodeCallback(phone, newPhoneCode.prefix);
    },
    [phone, setCurrentPhoneCode, replaceCurrentPhoneCodeCallback]
  );

  useEffect(
    function ShowPhoneCodeLogic() {
      if (!phone) {
        setShowCurrentPhoneCode(false);
        return;
      }
      const isNumber = /^\+?\d+$/.test(phone);
      if (
        isNumber &&
        !showCurrentPhoneCode &&
        ((phone.length >= 3 && !phone.startsWith("+")) ||
          (phone.length >= 4 && phone.startsWith("+")))
      ) {
        const phoneCode =
          phoneCodeList.find((code) => phone.startsWith(code.prefix)) ??
          currentPhoneCode;
        setCurrentPhoneCode(phoneCode);
        setShowCurrentPhoneCode(true);
        replaceCurrentPhoneCodeCallback(phone, phoneCode.prefix);
        return;
      }
    },
    [
      phone,
      phoneCodeList,
      currentPhoneCode,
      setCurrentPhoneCode,
      showCurrentPhoneCode,
      setShowCurrentPhoneCode,
      replaceCurrentPhoneCodeCallback,
    ]
  );

  return (
    <PhoneInputWrapper>
      <InputRow>
        <InputLabel>Número de teléfono</InputLabel>
        {showTooltip && (
          <ClickableTooltip
            title={
              "Lo utilizarás para iniciar sesión y si necesitas soporte técnico. No lo compartiremos con tus pacientes"
            }
            open={tooltipOpen}
            setOpen={setTooltipOpen}
          >
            <StyledInfoIcon onClick={changeTooltipOpenState} />
          </ClickableTooltip>
        )}
      </InputRow>
      <PhoneInputContainer>
        {showCurrentPhoneCode && (
          <PhonePrefixSelect
            variant="outlined"
            size="small"
            value={currentPhoneCode.icon + " " + currentPhoneCode.prefix}
            renderValue={(selected: any) => {
              return selected;
            }}
            onChange={handleSelectChange}
          >
            {phoneCodeList?.map((option) => (
              <MenuItem
                value={`${option.icon} ${option.prefix}`}
                key={option.name}
              >
                <IconListItemText>{option.icon}</IconListItemText>
                <ListItemText>{trimString(option.name, 25)}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {option.prefix}
                </Typography>
              </MenuItem>
            ))}
          </PhonePrefixSelect>
        )}

        <SingleFormInput
          parameterName="phone"
          errorText="Debes escribir tu número de teléfono."
          register={register}
          errors={errors}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            trigger("phone" as Path<T>);
            setValue(
              "phone" as Path<T>,
              event.target.value.replace(/\s/g, "") as PathValue<T, Path<T>>
            );
          }}
          validate={(value) => {
            const phoneRegex = /^\+?[0-9 ]+$/;
            if (!phoneRegex.exec(value) && value !== "+") {
              return "El teléfono solo puede contener números";
            }
            const phoneWithoutPrefix = value
              .replace(currentPhoneCode.prefix, "")
              .replace(/\s/g, "");
            if (phoneWithoutPrefix.length < 9) {
              return "Teléfono incompleto";
            }
            if (phoneWithoutPrefix.length > 9) {
              return "Teléfono muy largo";
            }
            clearErrors("phone" as Path<T>);
            return true;
          }}
        />
      </PhoneInputContainer>
    </PhoneInputWrapper>
  );
};

import { useNavigate } from "react-router-dom";
import { Body, Header, NavigateBeforeButton } from "../../components";
import { RequestType } from "../../interfaces";
import styled from "@emotion/styled";
import { Card } from "./components/Card";
import { Global } from "@emotion/react";
import { useCallback } from "react";

const DocumentTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
  gap: 16px;
`;

export function NewDocumentType() {
  const navigate = useNavigate();

  const handleDocumentTypeClick = useCallback(
    (documentType: RequestType) => {
      navigate(`/app/nuevo-documento/paciente`, {
        state: { documentType },
      });
    },
    [navigate]
  );

  return (
    <>
      <Header
        title="Tipo de documento"
        titleColor="black"
        left={
          <NavigateBeforeButton onClick={() => navigate("/app/solicitudes")} />
        }
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <DocumentTypesContainer>
          {Object.values(RequestType).map((documentType) => (
            <Card
              key={documentType}
              documentType={documentType}
              onClick={() => handleDocumentTypeClick(documentType)}
            />
          ))}
        </DocumentTypesContainer>
      </Body>
    </>
  );
}

import styled from "@emotion/styled";
import {
  RequestType,
  requestTypeExtendedTranslation,
} from "../../../interfaces";
import { theme } from "../../../config";

interface ContainerProps {
  background: string;
}

const Container = styled.div<ContainerProps>`
  color: ${theme.pallete.background.white};
  background: ${(props) => props.background};
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  border-radius: 24px;
  padding: 2px 8px;
`;

interface RequestTypeProps {
  type: RequestType;
}

export function RequestTypeChip({ type }: RequestTypeProps) {
  return (
    <Container background={theme.pallete.documentTypes[type]}>
      {requestTypeExtendedTranslation[type].toUpperCase()}
    </Container>
  );
}

export enum VerificationMethod {
  EMAIL = "email",
  RUT = "rut",
}

export enum ChallengeType {
  CHOOSE_AUTH_METHOD = "CHOOSE_AUTH_METHOD",
  PROVIDE_AUTH_CODE = "PROVIDE_AUTH_CODE",
  RESENDING_AUTH_CODE = "RESEND_AUTH_CODE",
  RESOLVING_AUTH_CHALLENGE = "RESOLVING_AUTH_CHALLENGE",
}

export enum AuthMethod {
  EMAIL = "email",
  PHONE_NUMBER = "phone_number",
  MAGIC_LINK = "magic_link",
  BIOMETRIC_REGISTRATION = "biometric_registration",
  BIOMETRIC_AUTH = "biometric_auth",
}

export enum AuthStep {
  PENDING = "PENDING",
  VALIDATING = "VALIDATING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export interface AuthFeedback {
  message: string;
  authStep: AuthStep;
}

export const AuthInitialStateFeedback: AuthFeedback = {
  message: "",
  authStep: AuthStep.PENDING,
};

export const AuthFeedbacks: Record<
  AuthMethod,
  Record<AuthStep, AuthFeedback>
> = {
  [AuthMethod.EMAIL]: {
    [AuthStep.PENDING]: {
      message: "Enviando email...",
      authStep: AuthStep.PENDING,
    },
    [AuthStep.VALIDATING]: {
      message: "Validando código...",
      authStep: AuthStep.VALIDATING,
    },
    [AuthStep.SUCCESS]: {
      message: "Código de verificación correcto",
      authStep: AuthStep.SUCCESS,
    },
    [AuthStep.ERROR]: {
      message: "Error enviando email",
      authStep: AuthStep.ERROR,
    },
  },
  [AuthMethod.PHONE_NUMBER]: {
    [AuthStep.PENDING]: {
      message: "Enviando SMS...",
      authStep: AuthStep.PENDING,
    },
    [AuthStep.VALIDATING]: {
      message: "Validando código...",
      authStep: AuthStep.VALIDATING,
    },
    [AuthStep.SUCCESS]: {
      message: "Código de verificación correcto",
      authStep: AuthStep.SUCCESS,
    },
    [AuthStep.ERROR]: {
      message: "Error enviando SMS",
      authStep: AuthStep.ERROR,
    },
  },
  [AuthMethod.MAGIC_LINK]: {
    [AuthStep.PENDING]: {
      message: "",
      authStep: AuthStep.PENDING,
    },
    [AuthStep.VALIDATING]: {
      message: "Validando código...",
      authStep: AuthStep.VALIDATING,
    },
    [AuthStep.SUCCESS]: {
      message: "Iniciando sesión...",
      authStep: AuthStep.SUCCESS,
    },
    [AuthStep.ERROR]: {
      message: "Link inválido o expirado",
      authStep: AuthStep.ERROR,
    },
  },
  [AuthMethod.BIOMETRIC_REGISTRATION]: {
    [AuthStep.PENDING]: {
      message: "Solicitando biometría...",
      authStep: AuthStep.PENDING,
    },
    [AuthStep.VALIDATING]: {
      message: "Registrando biometría...",
      authStep: AuthStep.VALIDATING,
    },
    [AuthStep.SUCCESS]: {
      message: "Biometría registrada",
      authStep: AuthStep.SUCCESS,
    },
    [AuthStep.ERROR]: {
      message: "Error al obtener biometría",
      authStep: AuthStep.ERROR,
    },
  },
  [AuthMethod.BIOMETRIC_AUTH]: {
    [AuthStep.PENDING]: {
      message: "Solicitando biometría...",
      authStep: AuthStep.PENDING,
    },
    [AuthStep.VALIDATING]: {
      message: "Validando biometría...",
      authStep: AuthStep.VALIDATING,
    },
    [AuthStep.SUCCESS]: {
      message: "Iniciando sesión...",
      authStep: AuthStep.SUCCESS,
    },
    [AuthStep.ERROR]: {
      message: "Error al obtener biometría",
      authStep: AuthStep.ERROR,
    },
  },
};

export interface ManageAuthFeedback {
  authMethod?: AuthMethod;
  authStep?: AuthStep;
  changeAfterDelay?: boolean;
  resetToInitialState?: boolean;
}

export interface ResolveAuthCallbackFeedback {
  success: boolean;
  authMethod: AuthMethod;
}

export interface StartAuthChallengeProps {
  email: string;
  authMethod: AuthMethod;
}

import { SvgIcon, SvgIconProps } from "@mui/material";

export const BlueCircleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="600"
      height="600"
      viewBox="0 0 600 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M300 600C465.685 600 600 465.685 600 300C600 134.315 465.685 0 300 0C134.315 0 0 134.315 0 300C0 465.685 134.315 600 300 600Z"
        fill="#67D1FF"
        fill-opacity="0.08"
      />
    </svg>
  </SvgIcon>
);

import { useCallback, useState } from "react";
import { Box, Skeleton, styled } from "@mui/material";
import { Request, RequestStatus } from "@interfaces";
import {
  Accordion,
  CancelRequestModal,
  SecondaryActionButton,
} from "@components";
import {
  DetailColumn,
  DetailComment,
  ManageRequestContainer,
} from "../styles/styled";

const AccordionContainer = styled(Box)({
  padding: "0px 0px 8px 0px",
});

const Detail = styled(Box)({
  marginTop: "8px",
});

interface PatientRequestCommentAccordionProps {
  request: Request | undefined;
  canReject: boolean;
  isExpanded: boolean;
}

// TODO: En refactor de request y request details, hacer que este componente se encargue de manejar el estado de la solicitud
export function PatientRequestCommentAccordion({
  request,
  canReject,
  isExpanded,
}: PatientRequestCommentAccordionProps) {
  const [rejectLoading, setRejectLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const rejectRequest = useCallback(() => {
    try {
      setRejectLoading(true);
      setOpenModal(true);
    } catch (e) {
      console.error(e);
    } finally {
      setRejectLoading(false);
    }
  }, []);

  if (!request || !request?.comment) return null;

  return (
    <>
      <AccordionContainer>
        <Accordion title="Detalle solicitud" expanded={isExpanded}>
          <DetailColumn>
            <Detail>
              <DetailComment>{request.comment}</DetailComment>
            </Detail>

            {/* {request.attachments?.length !== 0 ? (
                  <Attachments>
                    <DetailTitle>Adjuntos</DetailTitle>
                    {request.attachments?.map((attachment, i) => (
                      <Link
                        href={attachment.url}
                        target="_blank"
                        key={attachment.id}
                      >
                        <SelectedFileSummary>
                          TODO: Add icon for each file type
                          <PDFIcon key={`${i}-icon-${attachment.name}`} />
                          <SelectedFileTitle
                            key={`${i}-SelectedFileTitle-${attachment}`}
                          >
                            {_.truncate(attachment.name, { length: 30 })}
                          </SelectedFileTitle>
                        </SelectedFileSummary>
                      </Link>
                    ))}
                  </Attachments>
                ) : null} */}

            {canReject && request.status === RequestStatus.PENDING && (
              <ManageRequestContainer>
                <SecondaryActionButton
                  onClick={rejectRequest}
                  disabled={rejectLoading}
                >
                  Rechazar solicitud
                </SecondaryActionButton>
              </ManageRequestContainer>
            )}
          </DetailColumn>
        </Accordion>
      </AccordionContainer>

      <CancelRequestModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        request={request}
      />
    </>
  );
}

PatientRequestCommentAccordion.Skeleton =
  function PatientRequestCommentAccordionSkeleton() {
    return (
      <>
        <AccordionContainer>
          <Accordion title={"Detalle solicitud"} expanded={true}>
            <DetailColumn>
              <Detail>
                <DetailComment
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Skeleton variant="text" width={300} height={100} />
                </DetailComment>
              </Detail>

              {/* <Attachments>
                  <DetailTitle>Adjuntos</DetailTitle>
                  <SelectedFileSummary>
                    <Skeleton variant="rectangular" height={24} width={24} />
                    <Skeleton variant="text" width={80} />
                  </SelectedFileSummary>
                </Attachments> */}
            </DetailColumn>
          </Accordion>
        </AccordionContainer>
      </>
    );
  };

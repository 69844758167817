import MeiliSearch from "meilisearch";

const meiliSearchHost = process.env.REACT_APP_MEILI_SEARCH_HOST ?? "";
const meiliSearchApiKey = process.env.REACT_APP_MEILI_SEARCH_API_KEY ?? "";
const suggestionsIndexName =
  process.env.REACT_APP_MEILI_SEARCH_PRODUCTS_INDEX_NAME ?? "";
const proceduresSuggestionsIndexName =
  process.env.REACT_APP_MEILI_SEARCH_PROCEDURES_INDEX_NAME ?? "";

export const meiliSearchConfig = {
  host: meiliSearchHost,
  apiKey: meiliSearchApiKey,
  client: new MeiliSearch({
    host: meiliSearchHost,
    apiKey: meiliSearchApiKey,
  }),
  indexes: {
    suggestions: suggestionsIndexName,
  },
};

export const meiliProcedureSearchConfig = {
  host: meiliSearchHost,
  apiKey: meiliSearchApiKey,
  client: new MeiliSearch({
    host: meiliSearchHost,
    apiKey: meiliSearchApiKey,
  }),
  indexes: {
    suggestions: proceduresSuggestionsIndexName,
  },
};

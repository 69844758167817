import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { api, queryClient } from "@contexts";
import { Doctor } from "@interfaces";

interface DoctorEditableFields
  extends Pick<
    Doctor,
    | "region"
    | "commune"
    | "street"
    | "signature"
    | "showPwaAlert"
    | "showBiometricRegistration"
    | "biometricIsEnabled"
  > {}

interface useEditDoctorProps {
  code: string;
  redirectTo?: string;
  redirectionState?: Record<string, any>;
}

export const useEditDoctor = ({
  code,
  redirectTo,
  redirectionState = {},
}: useEditDoctorProps) => {
  const navigate = useNavigate();
  const editDoctorFn = (
    doctorEditableFieldsPayload: Partial<DoctorEditableFields>
  ) => {
    return api<Doctor>({
      url: `/doctors/${code}`,
      method: "PUT",
      data: doctorEditableFieldsPayload,
    });
  };
  return useMutation({
    mutationFn: editDoctorFn,
    onMutate: async (doctorEditableFieldsPayload) => {
      await queryClient.cancelQueries({ queryKey: ["doctor"] });
      const previousDoctor = queryClient.getQueryData<Doctor>([
        "doctor",
      ]) as Doctor;
      const updatedDoctor: Doctor = {
        ...previousDoctor,
        ...doctorEditableFieldsPayload,
      };
      queryClient.setQueryData<Doctor>(["doctor"], updatedDoctor);
      return { previousDoctor };
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["doctor"],
      });
      if (redirectTo) navigate(redirectTo, { state: redirectionState });
    },
    onError: (error, _, context) => {
      console.log("Error editing doctor: ", error);
      queryClient.setQueryData(["doctor"], context?.previousDoctor);
    },
  });
};

import { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormControl, TextField, styled } from "@mui/material";
import { Global } from "@emotion/react";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  ExtraWhiteSpace,
  Header,
  NavigateBeforeButton,
  InputFieldWrapper,
} from "@components";
import { FormContainer } from "@styles/styled";
import { theme as oldTheme } from "@config";

const Input = styled(TextField)({
  backgroundColor: oldTheme.pallete.background.white,
  fontSize: "1rem",
  fontWeight: 400,
  "& .MuiInputBase-root": {
    padding: "8px 12px",
    fontSize: "1rem",
    fontWeight: 400,
  },
  "& textarea": {
    minHeight: "200px",
  },
});

interface LocationState {
  attachedProcedures: string[];
  searchedQuery: string;
}

export function AddProcedureManually() {
  const location = useLocation();
  const { attachedProcedures = [], searchedQuery = "" }: LocationState =
    location.state || {};

  const navigate = useNavigate();
  const { requestId = "" } = useParams();

  const [manuallyAddedProcedure, setManuallyAddedProcedure] =
    useState<string>(searchedQuery);

  const handleBack = useCallback(() => {
    navigate(`/app/solicitudes/${requestId}/orden-medica/crear/buscar`, {
      state: { repeatedDocumentDetails: attachedProcedures },
    });
  }, [attachedProcedures, navigate, requestId]);

  const onSubmit = (manualProcedure: string) => {
    if (manualProcedure === "") return;
    const isProcedureAttached = attachedProcedures.some(
      (attachedProcedure) => attachedProcedure === manualProcedure
    );
    if (!isProcedureAttached) {
      attachedProcedures.push(manualProcedure);
    }
  };

  return (
    <>
      <Header
        title="Agregar manualmente"
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <FormContainer onSubmit={() => {}}>
          <InputFieldWrapper label="Detalle del examen o procedimiento">
            <FormControl fullWidth>
              <Input
                multiline
                value={manuallyAddedProcedure}
                onChange={(e) => setManuallyAddedProcedure(e.target.value)}
              />
            </FormControl>
          </InputFieldWrapper>
          <ExtraWhiteSpace />
          <BottomActionArea>
            <BottomActionButton
              onClick={() => {
                onSubmit(manuallyAddedProcedure);
                navigate(`/app/solicitudes/${requestId}/orden-medica/crear`, {
                  state: { repeatedDocumentDetails: attachedProcedures },
                });
              }}
            >
              Siguiente
            </BottomActionButton>
          </BottomActionArea>
        </FormContainer>
      </Body>
    </>
  );
}

import { Procedure } from "@interfaces/Search";

export const parseProcedureSuggestions = (procedure: Procedure) => {
  return `${procedure.description} - ${procedure.code}`;
};

export const eraseProcedureCode = (str: string) => {
  const parts = str.split(" - ");
  if (parts.length > 1) {
    parts.pop();
  }
  return parts.join(" - ");
};

import { Box, Typography, styled } from "@mui/material";
import {
  FeaIcon,
  IdentityVerificationIcon,
  SecurityAndPrivacyIcon,
} from "@icons";
import { theme as oldTheme } from "@config/theme";

const SectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  padding: "64px 32px",
  gap: "80px",
  background: oldTheme.pallete.text.primaryExtraLight,
  [theme.breakpoints.up("md")]: {
    padding: "64px 16px",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "64px 32px",
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  textAlign: "center",
  color: oldTheme.pallete.text.primaryDark,
  fontSize: "2rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.125rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.25rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "3rem",
  },
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "80px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "space-around",
    gap: "0px",
  },
  [theme.breakpoints.up("xl")]: {
    justifyContent: "space-evenly",
  },
}));

const InfoContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "32px",
});

const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: 400,
  color: oldTheme.pallete.text.primaryDark,
  textAlign: "center",
  maxWidth: "390px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    width: "250px",
    fontSize: "1.25rem",
  },
  [theme.breakpoints.up("lg")]: {
    width: "340px",
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    width: "390px",
  },
}));

const InfoTextHighlight = styled("span")({
  fontWeight: 600,
  color: oldTheme.pallete.text.primaryDark,
});

interface Props {
  Icon: React.ElementType;
  info: React.ReactNode;
}

const securityInfo = [
  {
    Icon: IdentityVerificationIcon,
    info: (
      <>
        <InfoTextHighlight>Verificación de identidad</InfoTextHighlight> en 3
        pasos
      </>
    ),
  },
  {
    Icon: FeaIcon,
    info: (
      <>
        <InfoTextHighlight>Firma Electrónica Avanzada</InfoTextHighlight> para
        las recetas
      </>
    ),
  },
  {
    Icon: SecurityAndPrivacyIcon,
    info: (
      <>
        Altos estándares de{" "}
        <InfoTextHighlight>seguridad y privacidad de datos</InfoTextHighlight>
      </>
    ),
  },
] as const;

const SecurityInfo: React.FC<Props> = ({ Icon, info }) => {
  return (
    <InfoContainer>
      <Icon sx={{ width: "66px", height: "66px" }} />
      <InfoText>{info}</InfoText>
    </InfoContainer>
  );
};

export const SecuritySection: React.FC = () => {
  return (
    <SectionWrapper>
      <SectionTitle>Crea documentos más seguros</SectionTitle>
      <SectionContainer>
        {securityInfo.map(({ Icon, info }, index) => (
          <SecurityInfo
            key={`security-info-${index}`}
            Icon={Icon}
            info={info}
          />
        ))}
      </SectionContainer>
    </SectionWrapper>
  );
};

import { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  PrescriptionDetail,
  SearchGeneric,
  SearchProduct,
} from "../../interfaces";
import {
  Body,
  Header,
  scrollToTop,
  NavigateBeforeButton,
} from "../../components";
import { Box, styled } from "@mui/material";
import { SearchedMedicineCard } from "../MedicineSearch/components";
import { queryClient } from "../../context";
import { useMedicineSearchContext } from "../MedicineSearch/context/MedicineSearchContext";
import { Global } from "@emotion/react";
import { generatePrescriptionDetail } from "./utils/prescriptionDetails";
import { useAddPrescriptionDetail } from "../../hooks";
import { MedicineTreatmentForm } from "./components/MedicineTreatmentForm";

const MedicineCardContainer = styled(Box)({
  border: "1px solid #eaeaea",
  borderRadius: "8px",
  overflow: "hidden",
  margin: "16px 0px",
});

interface LocationState {
  medicine: SearchProduct | SearchGeneric;
}

export function PrescriptionTreatment() {
  const navigate = useNavigate();
  const location = useLocation();
  const { requestId = "" } = useParams();
  const { searchResults, setSearchResults } = useMedicineSearchContext();
  const { mutate: addDetail, isPending: isAddingDetail } =
    useAddPrescriptionDetail();

  const [settingTreatment, setSettingTreatment] = useState<boolean>(true);

  const { medicine }: LocationState = location.state || {};
  const { activePrinciple, concentration, prescriptionType, unit } = medicine;
  const isSearchProduct = medicine ? "name" in medicine : false;
  const name = isSearchProduct ? (medicine as SearchProduct).name : undefined;
  const medicineQuantity = isSearchProduct
    ? (medicine as SearchProduct).quantity ?? 30
    : null;

  const onSubmit = useCallback(
    (formData: PrescriptionDetail) => {
      setSettingTreatment(false);
      scrollToTop();
      setSearchResults(undefined);
      addDetail(
        {
          requestId,
          prescriptionDetail: generatePrescriptionDetail(medicine, formData),
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["request", requestId] });
            navigate(`/app/solicitudes/${requestId}/receta/crear`, {
              state: {
                detailAdded: true,
                medicineType: prescriptionType === "HELD" ? "HELD" : "SIMPLE",
              },
            });
          },
        }
      );
    },
    [
      setSearchResults,
      addDetail,
      navigate,
      requestId,
      medicine,
      prescriptionType,
    ]
  );

  const handleBack = useCallback(() => {
    if (settingTreatment) {
      navigate(`/app/solicitudes/${requestId}/receta/crear/buscar`, {
        state: { searchResults },
      });
    } else {
      setSettingTreatment(true);
      scrollToTop();
    }
  }, [navigate, requestId, searchResults, settingTreatment]);

  return (
    <>
      <Header
        title="Tratamiento"
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <>
          {settingTreatment && (
            <MedicineCardContainer>
              <SearchedMedicineCard
                medicine={medicine}
                isTreatmentCard={true}
              />
            </MedicineCardContainer>
          )}
          <MedicineTreatmentForm
            prefilledMedicineAttributes={{
              activePrinciple,
              concentration,
              name,
              unit,
              medicineQuantity,
              prescriptionType,
            }}
            formManagement={{
              onSubmit,
              isLoading: isAddingDetail,
            }}
          />
        </>
      </Body>
    </>
  );
}

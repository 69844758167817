import { Fragment, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Global } from "@emotion/react";
import {
  AlertColor,
  Box,
  InputAdornment,
  Typography,
  styled,
  IconButton,
  CircularProgress,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ShareIcon from "@mui/icons-material/Share";
import {
  EmptyRequest,
  RequestsContainer,
  Search,
  StyledInfoIcon,
  WhiteBackgroundBody,
} from "./styled";
import {
  AuthRequiredModal,
  Body,
  Header,
  HomeActionButton,
  Modal,
  NewDocumentIcon,
  SnackBar,
} from "@components";
import {
  DownloadPWAModal,
  PendingFeaAuthAlert,
  RequestCard,
  RequestsSkeleton,
  SidebarMenu,
} from "./components";
import { AuthFeedbackModal } from "@screens/Auth/components";
import { AuthStep, Request, RequestStatus } from "@interfaces";
import { useAuth, useDoctorContext, useSidebarMenuContext } from "@contexts";
import { useEditDoctor, useIsScreenBiggerThanSM, useRequests } from "@hooks";
import { isSafari, shareDoctorLink } from "@utils";
import { theme as oldTheme } from "../../config";
import { ENV } from "../../environment";
import LogRocket from "logrocket";
import Fuse from "fuse.js";
import { format } from "rut.js";
import { StyledErrorIcon, StyledSuccessIcon } from "@styles/styled";

const HomeInteractionsContainer = styled(Box)({
  display: "flex",
  gap: "16px",
  padding: "16px 0px",
});

const InteractionTextContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  fontWeight: 500,
});

const StyledShareIcon = styled(ShareIcon)({
  color: "white",
});

const Row = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "16px",
});

const FilterListButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "isFilterActive",
})(({ isFilterActive }: { isFilterActive: boolean }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  ...(isFilterActive && {
    color: "white",
    backgroundColor: `${oldTheme.pallete.text.primary} !important`,
  }),
}));

const FilterText = styled(Typography)({
  fontSize: "12px",
  fontWeight: 500,
  color: oldTheme.pallete.text.black,
  marginBottom: "8px",
});

export function Requests() {
  const navigate = useNavigate();
  const doctor = useDoctorContext();
  const location = useLocation();
  const { documentCreated = false } = location.state || {};

  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const showAddToHomeScreenBaseConditions = isSafari && !isScreenBiggerThanSM;
  const [showAddToHomeScreen, setShowAddToHomeScreen] = useState(
    showAddToHomeScreenBaseConditions &&
      doctor.showPwaAlert !== false &&
      documentCreated
  );

  const {
    authChallengeFeedback: { message: authFeedback, authStep },
  } = useAuth();
  const feedbackIcon = (authStep: AuthStep) => {
    switch (authStep) {
      case AuthStep.SUCCESS:
        return <StyledSuccessIcon />;
      case AuthStep.ERROR:
        return <StyledErrorIcon />;
      default:
        return <CircularProgress size={24} color="primary" />;
    }
  };

  const [showFeaAuthModal, setShowFeaAuthModal] = useState<boolean>(false);

  const [search, setSearch] = useState("");
  const [showCopiedLinkModal, setShowCopiedLinkModal] = useState(false);
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    type: "success" as AlertColor,
  });
  const [isFilterActive, setFilterIsActive] = useState(false);
  const { isSidebarMenuOpen, setIsSidebarMenuOpen } = useSidebarMenuContext();

  const { data: allRequests, isPending: isPendingRequests } = useRequests();
  const { mutate: editDoctor } = useEditDoctor({
    code: doctor.code,
    redirectTo: "/app/solicitudes",
  });

  const requestsData = (allRequests ?? []).filter((request) => {
    if (isFilterActive) return request.status === RequestStatus.PENDING;
    if (!isFilterActive) return request.status !== RequestStatus.DRAFT;
    return false;
  });

  // Fuse search
  const fuseRequest = new Fuse(
    requestsData.map((request) => {
      return {
        ...request,
        fStatus: {
          DRAFT: "borrador",
          PENDING: "pendiente",
          ACCEPTED: "aceptado",
          REJECTED: "rechazado",
        }[request.status],
        fRut: format(request.patient.rut),
        cleanRut: request.patient.rut.replace(/[.-]/g, ""),
        fDocumentType: {
          PRESCRIPTION: "receta",
          COMPOUNDED_PRESCRIPTION: "receta magistral",
          MEDICAL_CERTIFICATE: "certificado",
          MEDICAL_ORDER: "orden",
        }[request.documentType],
        managementSummary: request.managementSummary,
      };
    }),
    {
      keys: [
        "patient.name",
        "patient.firstSurname",
        "patient.secondSurname",
        "fRut",
        "cleanRut",
        "patient.rut",
        "fStatus",
        "status",
        "fDocumentType",
        "documentType",
        "managementSummary",
      ],
      threshold: 0.1,
    }
  );

  // Filter requests by search
  const requests =
    search.length > 0
      ? fuseRequest.search(search).map((result) => result.item)
      : requestsData;

  // Group requests by date
  const groupedRequestsByDate = requests.reduce<Record<string, Request[]>>(
    (all, request) => {
      const isoDate = new Date(request.requestedAt).toISOString();
      const date = isoDate.split("T")[0];
      return {
        ...all,
        [date]: [...(all[date] || []), request],
      };
    },
    {}
  );

  const dates = Object.keys(groupedRequestsByDate).sort().reverse();

  const onShare = useCallback(async () => {
    if (!doctor.isFeaAuthenticated) {
      setShowFeaAuthModal(true);
      return;
    }
    await shareDoctorLink({
      doctorCode: doctor.code,
      setShowCopiedLinkModal,
      setSnackBarState,
    });
  }, [doctor]);

  const onNewPrescription = useCallback(() => {
    if (!doctor.isFeaAuthenticated) {
      setShowFeaAuthModal(true);
      return;
    }
    navigate(`/app/nuevo-documento`);
  }, [doctor, navigate]);

  const handleClosePwaPrompt = useCallback(() => {
    setShowAddToHomeScreen(false);
    editDoctor({
      showPwaAlert: false,
    });
  }, [editDoctor]);

  if (ENV !== "development" && process.env.REACT_APP_LOG_ROCKET_ID) {
    LogRocket.identify(doctor.code, {
      name: `${doctor.name} ${doctor.lastName}`,
      email: doctor.email,
      code: doctor.code,
    });
  }

  return (
    <>
      <SidebarMenu
        onPatientLinkClick={onShare}
        onNewDocumentClick={onNewPrescription}
        showAddToHomeScreenBaseConditions={showAddToHomeScreenBaseConditions}
        setShowAddToHomeScreen={setShowAddToHomeScreen}
      />
      <Header
        useMekiDocIcon={true}
        left={
          <IconButton
            onClick={() => {
              setIsSidebarMenuOpen(true);
            }}
          >
            <MenuIcon />
          </IconButton>
        }
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body overflow={isSidebarMenuOpen ? "hidden" : "auto"}>
        <WhiteBackgroundBody>
          <HomeInteractionsContainer>
            <HomeActionButton onClick={onShare} icon={<StyledShareIcon />}>
              <InteractionTextContainer>
                Formulario para pacientes
              </InteractionTextContainer>
            </HomeActionButton>
            <HomeActionButton
              onClick={onNewPrescription}
              icon={<NewDocumentIcon />}
            >
              <InteractionTextContainer>
                Nuevo documento
              </InteractionTextContainer>
            </HomeActionButton>
          </HomeInteractionsContainer>
        </WhiteBackgroundBody>

        <Row>
          <Search
            placeholder="Paciente, documento, etc"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon style={{ color: oldTheme.pallete.text.black }} />
                </InputAdornment>
              ),
            }}
          />
          <FilterListButton
            onClick={() => setFilterIsActive((prev) => !prev)}
            isFilterActive={isFilterActive}
          >
            <FilterListIcon />
          </FilterListButton>
        </Row>
        {isFilterActive && <FilterText>Filtrado por pendientes</FilterText>}
        <RequestsContainer>
          {!isPendingRequests &&
            (Object.keys(groupedRequestsByDate).length > 0 ? (
              dates.map((date) => {
                return (
                  <Fragment key={date}>
                    {groupedRequestsByDate[date]
                      .sort((a, b) => b.requestedAt - a.requestedAt)
                      .map((request) => (
                        <RequestCard
                          key={request.id}
                          id={request.id}
                          name={request.patient.name}
                          lastName={`${request.patient.firstSurname} ${request.patient.secondSurname}`}
                          rut={request.patient.rut}
                          requestedAt={new Date(request.requestedAt)}
                          status={request.status}
                          requestType={request.documentType}
                          managementSummary={request.managementSummary}
                        />
                      ))}
                  </Fragment>
                );
              })
            ) : (
              <EmptyRequest>
                <StyledInfoIcon />
                {requestsData.length > 0
                  ? "No se encontraron solicitudes"
                  : isFilterActive
                  ? "No tienes solicitudes pendientes"
                  : "No tienes solicitudes"}
              </EmptyRequest>
            ))}
          {isPendingRequests && <RequestsSkeleton />}
        </RequestsContainer>

        {!isPendingRequests && doctor.isFeaAuthenticated === false && (
          <PendingFeaAuthAlert />
        )}

        <Modal
          title="Link copiado"
          content="Pégalo en WhatsApp o en un correo para enviarlo al paciente."
          isOpen={showCopiedLinkModal}
          onClose={() => setShowCopiedLinkModal(false)}
          buttonProps={{
            buttonText: "Aceptar",
            onClick: () => {
              setShowCopiedLinkModal(false);
            },
          }}
        />

        <AuthRequiredModal
          isOpen={showFeaAuthModal}
          onClose={() => setShowFeaAuthModal(false)}
        />

        <SnackBar
          snackBarState={snackBarState}
          onClose={() => setSnackBarState({ ...snackBarState, open: false })}
          alerts={{
            success: "Link copiado, compártelo con tu paciente",
            error: "Ocurrió un error al copiar el link",
          }}
          style={{ margin: "0px" }}
        />
        <DownloadPWAModal
          isOpen={showAddToHomeScreen}
          onClose={handleClosePwaPrompt}
        />
        {authFeedback && (
          <AuthFeedbackModal
            isOpen={true}
            authFeedback={authFeedback}
            authStep={authStep}
            feedbackIcon={feedbackIcon}
          />
        )}
      </Body>
    </>
  );
}

import styled from "@emotion/styled";
import { theme } from "../../config/theme";
import { TextField } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as ShareIcon } from "../../assets/icons/shareIcon.svg";

export const ShareLinkIcon = styled(ShareIcon)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: ${theme.pallete.text.black};
`;

export const Search = styled(TextField)`
  margin: 16px 0px 16px 0px;
  width: 100%;
  background-color: #fff;

  input {
    height: 40px;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    padding: 4px 16px;
    color: ${theme.pallete.text.black};
  }
`;

export const RequestsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  padding-bottom: 8px;
`;

export const EmptyRequest = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 72px 0;
  color: ${theme.pallete.text.gray};
`;
export const StyledInfoIcon = styled(InfoOutlinedIcon)`
  font-size: 24px;
`;

export const WhiteBackgroundBody = styled.div`
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px;
  padding: 16px 16px 0px 16px;
  background-color: ${theme.pallete.background.white};
`;

export interface Patient {
  rut: string;
  name: string;
  firstSurname: string;
  secondSurname: string;
  birthdate: string;
  weight: string;
  email: string;
  phone: string;
  region: string;
  commune: string;
  street: string;
  streetNumber: string;
  chronicDiseases: string;
  allergies: string;
  code: string;
}

export type PatientBaseAttributes = Omit<
  Patient,
  "streetNumber" | "chronicDiseases" | "allergies" | "code"
>;

export const patientDefaultValues: Patient = {
  rut: "",
  name: "",
  firstSurname: "",
  secondSurname: "",
  birthdate: "",
  weight: "",
  email: "",
  phone: "",
  region: "",
  commune: "",
  street: "",
  streetNumber: "",
  chronicDiseases: "",
  allergies: "",
  code: "",
};

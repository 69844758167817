import { useCallback, useState } from "react";
import { PDFData } from "@interfaces";
import {
  canShareFiles,
  convertPresignedUrlToFile,
  downloadFilesHandler,
  isSafari,
} from "@utils";
import { useIsScreenBiggerThanSM } from "./useIsScreenBiggerThanSM";

export const useShareOrDownloadFiles = () => {
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const [isConvertingFiles, setIsConvertingFiles] = useState(false);
  const [isDownloadFilesFallbackActive, setIsDownloadFilesFallbackActive] =
    useState(false);

  const convertFiles = useCallback(async (pdfData: PDFData[]) => {
    setIsConvertingFiles(true);
    const pdfFiles: File[] = await Promise.all(
      pdfData.map(({ url, documentName }) =>
        convertPresignedUrlToFile({
          url,
          filename: documentName,
        })
      )
    );
    setIsConvertingFiles(false);
    return pdfFiles;
  }, []);

  const shareOrDownloadFilesCallback = useCallback(
    async (pdfFiles: File[], pdfData: PDFData[]) => {
      try {
        if (!isScreenBiggerThanSM && canShareFiles(pdfFiles)) {
          const shareData = {
            files: pdfFiles,
          };
          await navigator.share(shareData);
        } else {
          throw new Error("The system doesn't support sharing these files.");
        }
      } catch (error) {
        const userAgent = navigator.userAgent;
        console.log({
          msg: "shareOrDownloadFilesCallback",
          error,
          userAgent,
          isSafari,
        });
        if (!(error instanceof DOMException && error.name === "AbortError")) {
          if (!isScreenBiggerThanSM) setIsDownloadFilesFallbackActive(true);
          await downloadFilesHandler(pdfData);
        }
      }
    },
    [isScreenBiggerThanSM]
  );

  return {
    convertFiles,
    isConvertingFiles,
    shareOrDownloadFilesCallback,
    isDownloadFilesFallbackActive,
    setIsDownloadFilesFallbackActive,
  };
};

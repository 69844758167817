import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link, styled } from "@mui/material";
import { Global } from "@emotion/react";
import {
  FetchPayload,
  ItemType,
  MedicineSearch,
  MedicineSuggestion,
  SearchGeneric,
  SearchProduct,
  Suggestion,
} from "@interfaces";
import {
  Body,
  DialogContent,
  Modal,
  ModalStyleType,
  SearchHeader,
  scrollToTop,
} from "@components";
import { PreliminarySearchResults } from "@components/Search";
import { MedicineSearchResults } from "./MedicineSearchResults";
import { useFetch } from "@contexts";
import { useMedicineSearchContext } from "./context/MedicineSearchContext";
import { productSubCategoriesDetails } from "@utils";
import { mostSearchedMedicinesQueries } from "@constants";
import { meiliSearchConfig, theme as oldTheme } from "@config";

const StyledLink = styled(Link)({
  color: oldTheme.pallete.text.link,
  cursor: "pointer",
  textDecoration: "underline",
});

export function SearchMedicines() {
  const navigate = useNavigate();
  const fetch = useFetch();
  const { requestId = "" } = useParams();

  const { searchText, setSearchText, searchResults, setSearchResults } =
    useMedicineSearchContext();
  const prevSearchText = useRef<string>(searchText);

  const [showPreliminaryResults, setShowPreliminaryResults] = useState<boolean>(
    Boolean(searchText)
  );
  const [preliminaryResults, setPreliminaryResults] = useState<Suggestion[]>(
    []
  );

  const [selectedMedicineData, setSelectedMedicineData] =
    useState<MedicineSearch>();
  const [clickedProduct, setClickedProduct] = useState<SearchProduct>();

  const [loading, setLoading] = useState<boolean>(false);
  const [showLowStockModal, setShowLowStockModal] = useState<boolean>(false);
  const [showCheckPrescriptionModal, setShowPrescriptionTypeCheckModal] =
    useState<boolean>(false);

  const client = useMemo(() => meiliSearchConfig.client, []);

  const parseAndFilterDuplicatedSuggestions = useCallback(
    (suggestions: MedicineSuggestion[]) => {
      const seenSuggestions = new Set<string>();
      return suggestions.reduce<MedicineSuggestion[]>((result, item) => {
        const parsedSuggestionText =
          item.activePrincipleCount >= 4 &&
          item.itemType === ItemType.GENERIC &&
          item.associatedProduct
            ? item.associatedProduct[0]
            : item.text;

        if (!seenSuggestions.has(parsedSuggestionText)) {
          result.push({ ...item, text: parsedSuggestionText });
          seenSuggestions.add(parsedSuggestionText);
        }
        return result;
      }, []);
    },
    []
  );

  const search = useCallback(async () => {
    if (!searchText) return;
    const filteredSearchText = searchText.replace(/\bde\b/g, "");
    try {
      const params = new URLSearchParams();
      params.set("query", filteredSearchText.toLowerCase());

      // Get sorted suggestions
      const suggestions = await client
        .index(meiliSearchConfig.indexes.suggestions)
        .search(filteredSearchText, {
          limit: 100,
          sort: [
            "activePrincipleCount:asc",
            "formatPriority:asc",
            "concentration:asc",
          ],
        });

      const medicineSuggestions: MedicineSuggestion[] = suggestions.hits.map(
        ({ text, itemType, activePrincipleCount, associatedProduct }) => ({
          text,
          itemType,
          activePrincipleCount,
          associatedProduct,
        })
      );
      const parsedMedicineSuggestions =
        parseAndFilterDuplicatedSuggestions(medicineSuggestions);
      setPreliminaryResults(parsedMedicineSuggestions);
    } catch (error) {
      console.log(error);
      setPreliminaryResults([]);
    }
  }, [searchText, client, parseAndFilterDuplicatedSuggestions]);

  useEffect(() => {
    void search();
  }, [search]);

  const handleBack = useCallback(() => {
    if (selectedMedicineData !== undefined) {
      setShowPreliminaryResults(true);
      setSearchResults(undefined);
      setSelectedMedicineData(undefined);
      setLoading(false);
      scrollToTop();
    } else {
      navigate(`/app/solicitudes/${requestId}/receta/crear`);
    }
  }, [navigate, requestId, selectedMedicineData, setSearchResults]);

  const onPreliminarySearchItemClick = useCallback(
    (item: Suggestion) => {
      setLoading(true);
      const params = new URLSearchParams();
      params.set("query", item.text.toLowerCase());
      setSearchText(item.text);
      fetch<FetchPayload<MedicineSearch>>("/search?" + params.toString())
        .then((res) => {
          setSelectedMedicineData({
            ...res.payload,
          });
          setSearchResults(res.payload);
        })
        .catch((error) => {
          console.log(error);
          setSelectedMedicineData({
            preliminarySearchResults: [],
            finalSearchResults: {
              searchType: ItemType.GENERIC,
              processedMedicines: [],
            },
            searchedQuery: "",
          });
          setSearchResults(undefined);
        })
        .finally(() => {
          setLoading(false);
          setShowPreliminaryResults(false);
          scrollToTop();
        });
    },
    [fetch, setSearchResults, setSearchText]
  );

  const onGenericMedicineClick = useCallback(
    (generic: SearchGeneric) => {
      if (generic.prescriptionType === "CHECK") {
        setShowPrescriptionTypeCheckModal(true);
        return;
      }
      navigate(`/app/solicitudes/${requestId}/receta/crear/tratamiento`, {
        state: {
          medicine: generic,
        },
      });
    },
    [navigate, requestId]
  );

  const onMedicineClick = useCallback(
    (product: SearchProduct) => {
      const productSubCategories = productSubCategoriesDetails(product);
      const showCheckPrescriptionModalCondition =
        product.prescriptionType === "CHECK";
      const showLowStockModalCondition =
        !showLowStockModal &&
        (product.availability.status ?? "") === "SOLD_OUT" &&
        productSubCategories.isMedicine &&
        !productSubCategories.isInAnotherAllowedSubCategories;

      if (showCheckPrescriptionModalCondition) {
        setShowPrescriptionTypeCheckModal(true);
        return;
      }
      if (showLowStockModalCondition) {
        setClickedProduct(product);
        setShowLowStockModal(true);
        return;
      }

      navigate(`/app/solicitudes/${requestId}/receta/crear/tratamiento`, {
        state: {
          medicine: product,
        },
      });
    },
    [navigate, requestId, showLowStockModal]
  );

  useEffect(() => {
    if (
      searchText.length === 0 ||
      searchText.length < prevSearchText.current.length
    ) {
      setShowPreliminaryResults(true);
      setSearchResults(undefined);
      setSelectedMedicineData(undefined);
      setClickedProduct(undefined);
    }
    prevSearchText.current = searchText;
  }, [searchText, searchText.length, setSearchResults]);

  useEffect(() => {
    if (searchResults !== undefined) {
      setSelectedMedicineData(searchResults);
      setShowPreliminaryResults(false);
      scrollToTop();
    }
  }, [searchResults, selectedMedicineData]);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScrollPos) {
        // Remove focus from input (to hide keyboard on mobile)
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  if (loading) {
    return (
      <>
        <SearchHeader
          searchText={searchText}
          setSearchText={setSearchText}
          onClick={() => setShowPreliminaryResults(true)}
          handleBack={handleBack}
          placeholder={"Busca nombre o principio activo"}
        />
        <MedicineSearchResults.Skeleton />
      </>
    );
  }
  return (
    <>
      <SearchHeader
        searchText={searchText}
        setSearchText={setSearchText}
        onClick={() => setShowPreliminaryResults(true)}
        handleBack={handleBack}
        placeholder={"Busca nombre o principio activo"}
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        {showPreliminaryResults && (
          <PreliminarySearchResults
            searchText={searchText}
            preliminarySearchResults={preliminaryResults}
            onItemClick={onPreliminarySearchItemClick}
            showMostSearchedQueries={searchText.length === 0}
            mostSearchedQueries={mostSearchedMedicinesQueries}
            addManuallyItemText="Agregar medicamento"
          />
        )}

        {!showPreliminaryResults && selectedMedicineData && (
          <MedicineSearchResults
            searchText={searchText}
            data={selectedMedicineData}
            onGenericMedicineClick={onGenericMedicineClick}
            onMedicineClick={onMedicineClick}
          />
        )}

        {showLowStockModal && (
          <Modal
            title="Baja disponibilidad"
            content="Este medicamento está difícil de encontrar o agotado en este momento"
            isOpen={showLowStockModal}
            onClose={() => setShowLowStockModal(false)}
            buttonProps={{
              buttonText: "Continuar",
              onClick: () => {
                setShowLowStockModal(false);
                clickedProduct && onMedicineClick(clickedProduct);
              },
            }}
          />
        )}

        {showCheckPrescriptionModal && (
          <Modal
            title="No está permitido emitir recetas cheque electrónicas"
            isOpen={showCheckPrescriptionModal}
            onClose={() => setShowPrescriptionTypeCheckModal(false)}
            buttonProps={{
              buttonText: "Volver atrás",
              onClick: () => {
                setShowPrescriptionTypeCheckModal(false);
              },
            }}
            modalStyles={{
              title: "16px",
              type: ModalStyleType.CENTERED,
            }}
          >
            <DialogContent fontSize="14px">
              Puedes digitalizar recetas cheque físicas en{" "}
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <StyledLink
                onClick={() =>
                  window.open(
                    "https://seremienlinea.minsal.cl/recetas/",
                    "_blank"
                  )
                }
              >
                https://seremienlinea.minsal.cl/recetas/
              </StyledLink>
            </DialogContent>
          </Modal>
        )}
      </Body>
    </>
  );
}

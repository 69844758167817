import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import {
  PDFViewer,
  PDFViewerProps,
  SecondaryActionButton,
} from "../../../components";
import { PrescriptionDetailCard } from "../../../screens/PrescriptionForm/components/PrescriptionDetailCard";
import {
  CompoundedPrescriptionPayload,
  DocumentProps,
  requestTypeExtendedTranslation,
} from "../../../interfaces";
import { useGetPDFs } from "../../../hooks";
import { generatePdfName } from "../../../utils";

const DetailsWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "8px 0px",
  gap: "8px",
});

const Row = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const DetailsTitle = styled(Typography)({
  fontWeight: 600,
});

const ButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const ShowDocumentButton = styled(SecondaryActionButton)({
  padding: "0px",
});

interface Props {
  data: CompoundedPrescriptionPayload;
  isFetching: boolean;
}

export const HistoricCompoundedPrescription = ({ data, isFetching }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    id: requestId,
    patient,
    documentType,
    documents: compoundedPrescriptions,
  } = data;

  const {
    id: documentId,
    details: {
      compoundedIngredients,
      posologyQuantity,
      posologyFormat,
      frequencyQuantity,
      frequencyPeriod,
      durationQuantity,
      durationPeriod,
      indications,
    },
    prescriptionStatus,
    holdingDate,
  } = compoundedPrescriptions[0];

  const [documentData, setDocumentData] = useState<DocumentProps>(
    {} as DocumentProps
  );

  const {
    data: pdfData,
    isPending,
    isSuccess,
    isError,
  } = useGetPDFs({
    requestId,
  });

  const onClose = () => setDocumentData({} as DocumentProps);

  if (Object.keys(documentData).length !== 0) {
    const prevRoute = location.pathname;
    if (isPending) {
      return <PDFViewer.Skeleton prevRoute={prevRoute} />;
    } // TODO: If isError, maybe add a modal with an error message
    else if (isError) {
      onClose();
    } else if (isSuccess) {
      const navigationState: PDFViewerProps = {
        documentProps: {
          ...documentData,
          documentUrl: pdfData[0].url,
        },
        pdfRenderingProps: {
          title: requestTypeExtendedTranslation[documentType],
          prevRoute,
        },
        prescriptionData: {
          requestId,
          prescriptionId: documentId,
          prescriptionStatus,
          holdingDate,
          isFetching,
        },
      };
      navigate("/app/solicitudes/visualizacion-documento", {
        state: navigationState,
      });
    }
  }

  return (
    <DetailsWrapper>
      <Row>
        <DetailsTitle>Receta magistral</DetailsTitle>
        <ButtonContainer>
          <ShowDocumentButton
            onClick={() =>
              setDocumentData({
                ...documentData,
                documentId: documentId,
                documentName: generatePdfName({
                  documentType,
                  patient,
                }),
              })
            }
            disabled={isError}
          >
            Ver documento
          </ShowDocumentButton>
        </ButtonContainer>
      </Row>
      <PrescriptionDetailCard
        detail={{
          compoundedIngredients,
          posologyQuantity,
          posologyFormat,
          frequencyQuantity,
          frequencyPeriod,
          durationQuantity,
          durationPeriod,
          indications,
        }}
      />
    </DetailsWrapper>
  );
};

HistoricCompoundedPrescription.Skeleton = () => {
  return (
    <DetailsWrapper>
      <Row>
        <DetailsTitle>Receta magistral</DetailsTitle>
        <ButtonContainer>
          <ShowDocumentButton>Ver documento</ShowDocumentButton>
        </ButtonContainer>
      </Row>
      <PrescriptionDetailCard.Skeleton />
    </DetailsWrapper>
  );
};

import { Box, Typography, styled } from "@mui/material";
import { Body, BottomActionArea } from "@components";
import { theme as oldTheme } from "../../config";

export const GappedBody = styled(Body)({
  color: oldTheme.pallete.text.black,
  gap: "16px",
});

export const CardContainer = styled(Box)<{ padding?: string }>(
  ({ theme, padding }) => ({
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    padding: padding || "16px",
    background: theme.palette.common.white,
  })
);

export const GappedCardContainer = styled(CardContainer)<{ gap?: string }>(
  (props) => ({
    gap: props.gap || "16px",
  })
);

export const CardSpacedRow = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export const CardInfoRow = styled(Box)({
  display: "flex",
  aligjnItems: "center",
  gap: "4px",
  flexWrap: "wrap",
});

export const CardSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 600,
}));

export const CardSectionSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 500,
}));

export const CardInfoText = styled(Typography)<{ paddingTop?: string }>(
  ({ theme, paddingTop }) => ({
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
    paddingTop: paddingTop || "2px",
  })
);

export const CardInfoPreLineText = styled(CardInfoText)({
  whiteSpace: "pre-line",
});

export const GappedBottomActionArea = styled(BottomActionArea)({
  display: "flex",
  gap: "16px",
});

export const MarginContainer = styled(Box)<{ margin?: string }>(
  ({ margin }) => ({
    margin: margin || "16px",
  })
);

import { DoctorBaseAttributes } from "../../interfaces";
import { UserLoginFeedback, SignUpResolution } from "./interfaces";

export const signUpResolutionToFeedbackMapping: Record<
  SignUpResolution,
  UserLoginFeedback
> = {
  NO_DOCTOR: {
    serverErrorMessage:
      "El RUT ingresado no se encuentra en el Registro Nacional de Prestadores Individuales de Salud",
    contactUsMessage:
      "Hola, estoy teniendo problemas al iniciar sesión en MekiDoc debido a que mi RUT no se encuentra en el Registro Nacional de Prestadores Individuales de Salud. ¿Podrías darme más detalles de como solucionarlo?",
    canUseMekiDoc: false,
  },
  DOCTOR_CANNOT_ISSUE_PRESCRIPTIONS: {
    serverErrorMessage:
      "El RUT ingresado no está habilitado para emitir recetas médicas.",
    contactUsMessage:
      "Hola, estoy teniendo problemas al iniciar sesión en MekiDoc debido a que mi RUT no está habilitado para emitir recetas médicas. ¿Podrías darme más detalles de como solucionarlo?",
    canUseMekiDoc: false,
  },
  CAN_USE_MEKIDOC: {
    serverErrorMessage: "",
    contactUsMessage: "",
    canUseMekiDoc: true,
  },
};

export const determineSignUpResolution = (
  doctorBaseAttributes: DoctorBaseAttributes | null
) => {
  if (!doctorBaseAttributes) {
    return "NO_DOCTOR";
  }
  const speciality = doctorBaseAttributes.speciality.toLowerCase();

  // Check if doctor is a surgeon (Médico Cirujano | Médica Cirujana | Cirujano Dentista | Cirujana Dentista)
  const isSurgeon = speciality.includes("cirujan");
  const isInventuresTester = speciality.includes("título profesional"); // TODO: Remove this when the app is ready
  if (isSurgeon || isInventuresTester) {
    return "CAN_USE_MEKIDOC";
  }

  return "DOCTOR_CANNOT_ISSUE_PRESCRIPTIONS";
};

export const canUseMekiDocCallback = (
  doctorBaseAttributes: DoctorBaseAttributes | null
) => {
  const signUpResolution = determineSignUpResolution(doctorBaseAttributes);
  return signUpResolutionToFeedbackMapping[signUpResolution];
};

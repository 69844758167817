import { Divider } from "@mui/material";
import {
  PharmaceuticalChemist,
  PrescriptionHolding,
  PrescriptionStatus,
} from "../../../interfaces";
import {
  GappedCardContainer,
  CardInfoText,
  CardSectionTitle,
  CardContainer,
  CardSectionSubtitle,
  CardInfoPreLineText,
} from "../styles";
import { format as formatRut } from "rut.js";
import { getUnitForQuantity } from "../../../utils";

type Props = {
  prescriptionHolding: PrescriptionHolding;
  holderData: PharmaceuticalChemist;
  status: PrescriptionStatus;
  isCompoundedPrescription?: boolean;
};

export const HolderInformation: React.FC<Props> = ({
  prescriptionHolding: {
    pharmacyRut,
    pharmacyName,
    quantityToDispense,
    details,
    activePrinciple,
  },
  holderData: { name, lastName, rut, email },
  status,
  isCompoundedPrescription,
}) => {
  return (
    <GappedCardContainer gap={"2px"}>
      <CardSectionTitle>
        {status === PrescriptionStatus.HELD
          ? "Retenido por:"
          : "Dispensado por:"}
      </CardSectionTitle>
      <CardInfoText>
        {name} {lastName}
      </CardInfoText>
      <CardInfoText>{formatRut(rut)}</CardInfoText>
      <CardInfoText>{email}</CardInfoText>
      <CardInfoText>{pharmacyName}</CardInfoText>
      <CardInfoText>{pharmacyRut}</CardInfoText>
      <CardContainer padding={"16px 0px"}>
        <Divider />
      </CardContainer>
      {status === PrescriptionStatus.HELD && quantityToDispense ? (
        <>
          <CardSectionSubtitle>Medicamento dispensado</CardSectionSubtitle>
          {/* TODO: Add active principle and concentration separately */}
          <CardInfoPreLineText>{`${activePrinciple}`}</CardInfoPreLineText>
          <CardInfoText>
            {isCompoundedPrescription
              ? `Dispensación: ${quantityToDispense}`
              : `${quantityToDispense} ${getUnitForQuantity(
                  "cajaDispensada",
                  quantityToDispense
                )}`}
          </CardInfoText>
        </>
      ) : (
        <>
          <CardSectionSubtitle>Comentarios</CardSectionSubtitle>
          <CardInfoText>{details}</CardInfoText>
        </>
      )}
    </GappedCardContainer>
  );
};

import { Patient } from "./Patient";
import { Medicine, PrescriptionType } from "./Search";
import { theme as oldTheme } from "../config/theme";

export enum RequestStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export const StatusTranslation: Record<RequestStatus, string> = {
  DRAFT: "Borrador",
  PENDING: "Pendiente",
  ACCEPTED: "Emitida",
  REJECTED: "Rechazada",
};

export const StatusPerRequestType: Record<
  RequestType,
  Record<RequestStatus, string>
> = {
  PRESCRIPTION: { ...StatusTranslation },
  COMPOUNDED_PRESCRIPTION: { ...StatusTranslation },
  MEDICAL_CERTIFICATE: {
    ...StatusTranslation,
    ACCEPTED: "Emitido",
    REJECTED: "Rechazado",
  },
  MEDICAL_ORDER: { ...StatusTranslation },
};

export enum RequestType {
  PRESCRIPTION = "PRESCRIPTION",
  COMPOUNDED_PRESCRIPTION = "COMPOUNDED_PRESCRIPTION",
  MEDICAL_ORDER = "MEDICAL_ORDER",
  MEDICAL_CERTIFICATE = "MEDICAL_CERTIFICATE",
}

export const requestTypeExtendedTranslation: Record<RequestType, string> = {
  [RequestType.PRESCRIPTION]: "Receta médica",
  [RequestType.COMPOUNDED_PRESCRIPTION]: "Receta magistral",
  [RequestType.MEDICAL_ORDER]: "Orden médica",
  [RequestType.MEDICAL_CERTIFICATE]: "Certificado médico",
};

export const requestTypeTranslation: Record<RequestType, string> = {
  [RequestType.PRESCRIPTION]: "Receta",
  [RequestType.COMPOUNDED_PRESCRIPTION]: "Receta magistral",
  [RequestType.MEDICAL_ORDER]: "Orden médica",
  [RequestType.MEDICAL_CERTIFICATE]: "Certificado médico",
};

export const requestTypeSimplifiedTranslation: Record<RequestType, string> = {
  [RequestType.PRESCRIPTION]: "Receta",
  [RequestType.COMPOUNDED_PRESCRIPTION]: "Receta magistral",
  [RequestType.MEDICAL_ORDER]: "Orden",
  [RequestType.MEDICAL_CERTIFICATE]: "Certificado",
};

export const requestTypeNavigateParser: Record<RequestType, string> = {
  [RequestType.PRESCRIPTION]: "receta",
  [RequestType.COMPOUNDED_PRESCRIPTION]: "receta-magistral",
  [RequestType.MEDICAL_ORDER]: "orden-medica",
  [RequestType.MEDICAL_CERTIFICATE]: "certificado",
};

export const RequestTypeColorMapper: Record<RequestType, string> = {
  [RequestType.PRESCRIPTION]: oldTheme.pallete.documentTypes.PRESCRIPTION,
  [RequestType.COMPOUNDED_PRESCRIPTION]:
    oldTheme.pallete.documentTypes.COMPOUNDED_PRESCRIPTION,
  [RequestType.MEDICAL_ORDER]: oldTheme.pallete.documentTypes.MEDICAL_ORDER,
  [RequestType.MEDICAL_CERTIFICATE]:
    oldTheme.pallete.documentTypes.MEDICAL_CERTIFICATE,
};

export interface Request {
  id: string;
  doctorCode: string;
  patientCode: string;
  status: RequestStatus;
  details?: RequestDetail[];
  attachments?: RequestAttachment[];
  requestedAt: number;
  rejectedAt?: number;
  patient: Patient;
  comment: string;
  indications: string;
  documentType: RequestType;
  managementSummary?: string;
}

export interface RequestDetail {
  id?: string;
  requestId?: string;

  name?: string;
  activePrinciple: string;
  concentration: string;
  laboratory?: string;
  quantity?: number;
  unit?: string;

  posologyQuantity: number;
  posologyFormat: string;
  frequencyQuantity: number;
  frequencyPeriod: string;
  consumptionType: string;
  durationQuantity: number;
  durationPeriod: string;
  quantityToDispense: number;
  indications: string;
  prescriptionType?: PrescriptionType;
  slug?: string;
  isGeneric?: boolean;
  medicine: Medicine;
}

export interface HistoricRequestCardDetails {
  id?: string;
  requestId?: string;

  name?: string;
  activePrinciple?: string;
  concentration?: string;
  laboratory?: string;

  compoundedIngredients?: string;

  posologyQuantity: number;
  posologyFormat: string;
  frequencyQuantity: number;
  frequencyPeriod: string;
  durationQuantity: number;
  durationPeriod: string;
  consumptionType?: string;
  quantityToDispense?: number;
  indications?: string;
}

export interface RequestAttachment {
  id: string;
  name: string;
  requestId: string;
  url: string;
  createdAt: number;
  type?: string;
}

export interface RequestCreationPayload {
  documentType: RequestType;
  doctorCode: string;
  status: RequestStatus;
  comment: string;
}

export interface NewRequestPayload {
  doctorCode: string;
  patient: Patient;
  request: RequestCreationPayload;
  uploadedFiles?: File[];
}

export interface NewRequestResponse {
  request: Request;
}

import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Skeleton, Typography, styled } from "@mui/material";
import {
  DocumentProps,
  PrescriptionStatus,
  PrescriptionType,
  PrescriptionPayload,
  RequestType,
  PrescriptionTypeMapper,
} from "@interfaces";
import {
  ExtraWhiteSpace,
  PDFViewer,
  PDFViewerProps,
  SecondaryActionButton,
} from "@components";
import { PrescriptionDetailCard } from "../../PrescriptionForm/components/PrescriptionDetailCard";
import { useGetPDFs } from "@hooks";
import { generatePdfName } from "@utils";

const SummaryContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const Row = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const SectionTitle = styled(Typography)({
  fontWeight: 600,
  padding: "8px 0px",
});

const PrescriptionDetailsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  margin: "8px 0",
});

const ShowPrescriptionButton = styled(SecondaryActionButton)({
  minWidth: "auto",
  justifyContent: "end",
  padding: "0px 4px",
});

const SkeletonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "24px",
  gap: "24px",
});

interface ReducedPrescriptionData {
  pdfUrl: string;
  prescriptionType: PrescriptionType;
  prescriptionStatus: PrescriptionStatus;
  holdingDate?: number;
}

interface Props {
  data: PrescriptionPayload;
  isFetching: boolean;
}

export const HistoricPrescription = ({ data, isFetching }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: requestId, patient, documents: prescriptions } = data;
  const [documentData, setDocumentData] = useState<DocumentProps>(
    {} as DocumentProps
  );

  const {
    data: pdfData,
    isPending,
    isSuccess,
    isError,
  } = useGetPDFs({
    requestId,
  });

  const reducedPrescriptionData: Record<string, ReducedPrescriptionData> =
    useMemo(() => {
      return prescriptions.reduce(
        (
          reducedPrescription,
          { id, type: prescriptionType, prescriptionStatus, holdingDate }
        ) => {
          const pdfUrl = pdfData
            ?.map(({ url }) => url)
            .find((url) => url.includes(id));
          if (pdfUrl) {
            reducedPrescription[id] = {
              pdfUrl,
              prescriptionType,
              prescriptionStatus,
              holdingDate,
            };
          }
          return reducedPrescription;
        },
        {} as Record<string, ReducedPrescriptionData>
      );
    }, [prescriptions, pdfData]);

  const onClose = () => setDocumentData({} as DocumentProps);

  if (Object.keys(documentData).length !== 0) {
    const prevRoute = location.pathname;
    if (isPending) {
      return <PDFViewer.Skeleton prevRoute={prevRoute} />;
    } // TODO: If isError, maybe add a modal with an error message
    else if (isError) {
      onClose();
    } else if (isSuccess) {
      const selectedPrescription =
        reducedPrescriptionData[documentData.documentId];
      const selectedPrescriptionTitle =
        selectedPrescription.prescriptionType === "SIMPLE"
          ? "Receta simple"
          : "Receta retenida";
      const navigationState: PDFViewerProps = {
        documentProps: {
          ...documentData,
          documentUrl: selectedPrescription.pdfUrl,
        },
        pdfRenderingProps: {
          title: selectedPrescriptionTitle,
          prevRoute,
        },
        prescriptionData: {
          requestId,
          prescriptionId: documentData.documentId,
          prescriptionStatus: selectedPrescription.prescriptionStatus,
          holdingDate: selectedPrescription.holdingDate,
          isFetching,
        },
      };
      navigate("/app/solicitudes/visualizacion-documento", {
        state: navigationState,
      });
    }
  }

  return (
    <SummaryContainer>
      <div>
        {prescriptions.map(({ id, details, type }) => {
          const title = type === "SIMPLE" ? "Receta simple" : "Receta retenida";
          return (
            <div key={`${title}-${id}`}>
              <SectionTitle>
                <Row>
                  {title}
                  <ShowPrescriptionButton
                    onClick={() =>
                      setDocumentData({
                        ...documentData,
                        documentId: id,
                        documentName: generatePdfName({
                          documentType: RequestType.PRESCRIPTION,
                          patient,
                          documentNameDetails: PrescriptionTypeMapper[type],
                        }),
                      })
                    }
                    disabled={isError}
                  >
                    Ver receta
                  </ShowPrescriptionButton>
                </Row>
              </SectionTitle>
              <PrescriptionDetailsContainer>
                {details.map((detail) => {
                  return (
                    <PrescriptionDetailCard key={detail.id} detail={detail} />
                  );
                })}
              </PrescriptionDetailsContainer>
            </div>
          );
        })}
      </div>
      <ExtraWhiteSpace />
    </SummaryContainer>
  );
};

HistoricPrescription.Skeleton = function HistoricPrescriptionSkeleton() {
  return (
    <SkeletonContainer>
      {Array.from({ length: 2 }).map((_, i) => (
        <div key={i}>
          <Row>
            <Skeleton variant="rectangular" height={20} width={160} />
            <ShowPrescriptionButton>Ver receta</ShowPrescriptionButton>
          </Row>
          <PrescriptionDetailsContainer>
            <PrescriptionDetailCard.Skeleton />
          </PrescriptionDetailsContainer>
        </div>
      ))}
    </SkeletonContainer>
  );
};

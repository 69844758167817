import React from "react";
import { useStartEsignFlow } from "@hooks/useStartEsignFlow";
import { Modal } from "@components/Modal";
import { DialogContent, Box, styled } from "@mui/material";

const TextContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: 16,
});

const BoldSpan = styled("span")({
  fontWeight: 600,
});

interface AuthRequiredModalProps {
  requestId?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const AuthRequiredModal: React.FC<AuthRequiredModalProps> = ({
  requestId,
  isOpen,
  onClose,
}) => {
  const {
    startEsignFlowMutation: { mutate: startEsignFlow },
    isStartingEsignFlow,
  } = useStartEsignFlow();

  return (
    <Modal
      title="Verificación"
      isOpen={isOpen}
      onClose={onClose}
      buttonProps={{
        buttonText: "Continuar",
        onClick: () => startEsignFlow({ requestId }),
        loading: isStartingEsignFlow,
      }}
    >
      <DialogContent style={{ padding: "16px 0px" }}>
        <TextContainer>
          <div>
            Para emitir tu <BoldSpan>primer documento médico</BoldSpan>{" "}
            necesitamos verificar tu identidad en 3 pasos
          </div>
          <div>
            Este proceso se hace <BoldSpan>una sola vez</BoldSpan>
          </div>
        </TextContainer>
      </DialogContent>
    </Modal>
  );
};

import { useNavigate, useParams } from "react-router-dom";
import { Body, Header, NavigateBeforeButton } from "../../../components";
import { Card } from "../../NewDocumentRequest/components/Card";
import { RequestType } from "../../../interfaces";
import { Global } from "@emotion/react";
import { Box, styled } from "@mui/material";
import { useCallback } from "react";

const DocumentTypesContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "32px 0px",
  gap: "16px",
});

function redirectToResolver(requestType: RequestType, requestId: string) {
  switch (requestType) {
    case RequestType.PRESCRIPTION:
      return `/app/solicitudes/${requestId}/receta/crear/buscar`;
    case RequestType.COMPOUNDED_PRESCRIPTION:
      return `/app/solicitudes/${requestId}/receta-magistral/crear`;
    case RequestType.MEDICAL_ORDER:
      return `/app/solicitudes/${requestId}/orden-medica/crear/buscar`;
    case RequestType.MEDICAL_CERTIFICATE:
      return `/app/solicitudes/${requestId}/certificado/crear`;
    default:
      return `/app/solicitudes/${requestId}/gestionar/seleccionar-documento`;
  }
}

export function SelectDocument() {
  const navigate = useNavigate();
  const { requestId = "" } = useParams();
  const handleDocumentTypeClick = useCallback(
    (documentType: RequestType) => {
      navigate(redirectToResolver(documentType, requestId));
    },
    [navigate, requestId]
  );
  return (
    <>
      <Header
        title="Tipo de documento"
        titleColor="black"
        left={
          <NavigateBeforeButton
            onClick={() => navigate(`/app/solicitudes/${requestId}/gestionar`)}
          />
        }
      />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <DocumentTypesContainer>
          {Object.values(RequestType).map((documentType) => (
            <Card
              key={documentType}
              documentType={documentType}
              onClick={() => handleDocumentTypeClick(documentType)}
            />
          ))}
        </DocumentTypesContainer>
      </Body>
    </>
  );
}

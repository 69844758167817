import { SvgIcon, SvgIconProps } from "@mui/material";

export const RedCircleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="443"
      height="600"
      viewBox="0 0 443 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M143 600C308.685 600 443 465.685 443 300C443 134.315 308.685 0 143 0C-22.6854 0 -157 134.315 -157 300C-157 465.685 -22.6854 600 143 600Z"
        fill="#E94964"
        fill-opacity="0.08"
      />
    </svg>
  </SvgIcon>
);

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { GappedBottomActionArea } from "../styles";
import {
  CommonPrescription,
  CommonPrescriptionValidationButtonMapper,
  PDFData,
  PrescriptionStatus,
} from "@interfaces";
import {
  BottomActionButton,
  MainActionButton,
  ShareDocumentsErrorModal,
} from "@components";
import { useIsScreenBiggerThanSM, useShareOrDownloadFiles } from "@hooks";
import { printFile } from "@utils";

interface Props {
  prescriptionPDFData: {
    prescriptionPDFReadUrl: string;
    prescriptionPDFName: string;
  };
  prescription: Pick<CommonPrescription, "prescriptionStatus" | "type">;
}

export const PrescriptionBottomActionArea: React.FC<Props> = ({
  prescriptionPDFData: { prescriptionPDFReadUrl, prescriptionPDFName },
  prescription: { prescriptionStatus, type: prescriptionType },
}) => {
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const {
    convertFiles,
    isConvertingFiles,
    shareOrDownloadFilesCallback,
    isDownloadFilesFallbackActive,
    setIsDownloadFilesFallbackActive,
  } = useShareOrDownloadFiles();
  const navigate = useNavigate();
  const onSubmit = (prescriptionManagment: PrescriptionStatus) =>
    navigate("info-retencion", {
      state: {
        prescriptionManagment,
      },
    });

  const handleMainActionClick = useCallback(async () => {
    const pdfData: PDFData[] = [
      {
        url: prescriptionPDFReadUrl,
        documentName: prescriptionPDFName,
      },
    ];
    const pdfFiles = await convertFiles(pdfData);
    await shareOrDownloadFilesCallback(pdfFiles, pdfData);
  }, [
    prescriptionPDFReadUrl,
    prescriptionPDFName,
    convertFiles,
    shareOrDownloadFilesCallback,
  ]);

  return (
    <>
      {prescriptionStatus === PrescriptionStatus.ISSUED ? (
        <GappedBottomActionArea>
          <BottomActionButton
            onClick={() =>
              onSubmit(
                prescriptionType === "HELD" ||
                  prescriptionType === "COMPOUNDED_PRESCRIPTION"
                  ? PrescriptionStatus.HELD
                  : PrescriptionStatus.DISPENSED
              )
            }
          >
            {CommonPrescriptionValidationButtonMapper[prescriptionType]}
          </BottomActionButton>
        </GappedBottomActionArea>
      ) : (
        <GappedBottomActionArea>
          <MainActionButton
            onClick={handleMainActionClick}
            disabled={isConvertingFiles}
          >
            {isConvertingFiles ? (
              <CircularProgress size={24} color="inherit" />
            ) : isScreenBiggerThanSM ? (
              "Descargar"
            ) : (
              "Compartir"
            )}
          </MainActionButton>
          {isScreenBiggerThanSM && (
            <MainActionButton
              onClick={() => {
                printFile(prescriptionPDFReadUrl);
              }}
            >
              Imprimir
            </MainActionButton>
          )}
        </GappedBottomActionArea>
      )}
      <ShareDocumentsErrorModal
        isOpen={isDownloadFilesFallbackActive}
        onClose={() => setIsDownloadFilesFallbackActive(false)}
      />
    </>
  );
};

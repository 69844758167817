import {
  AppBar as AppBarMui,
  Box,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import { theme as oldTheme } from "../config/theme";
import MekidocIcon from "../icons/MekiDocIcon";

interface TitleProps {
  color: keyof typeof oldTheme.pallete.text;
}

const Title = styled(Typography)<TitleProps>(({ color }) => ({
  fontSize: "19px",
  fontWeight: 600,
  lineHeight: "29px",
  textAlign: "center",
  color: oldTheme.pallete.text[color as keyof typeof oldTheme.pallete.text],
}));

export const AppBar = styled(AppBarMui)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1,
  padding: "10px",
  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.2)",
  backgroundColor: "white",
  minHeight: "64px",
});

const LeftContainer = styled(Box)({
  position: "absolute",
  left: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const RightContainer = styled(Box)({
  position: "absolute",
  right: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const SkeletonContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "auto",
  minHeight: "56px",
});

interface HeaderProps {
  title?: string;
  titleColor?: keyof typeof oldTheme.pallete.text;
  useMekiDocIcon?: boolean;
  left?: React.ReactNode;
  right?: React.ReactNode;
}
export function Header({
  title,
  titleColor,
  useMekiDocIcon,
  left,
  right,
}: HeaderProps) {
  return (
    <AppBar position="sticky" color="transparent">
      <LeftContainer>{left}</LeftContainer>
      {useMekiDocIcon ? (
        <MekidocIcon size={"small"} />
      ) : title !== "" ? (
        <Title color={titleColor ?? "primary"}>{title}</Title>
      ) : (
        <SkeletonContainer>
          <Skeleton variant="text" width={"175px"} height={"32px"} />
        </SkeletonContainer>
      )}
      <RightContainer>{right}</RightContainer>
    </AppBar>
  );
}

import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Global } from "@emotion/react";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  ExtraWhiteSpace,
  Header,
  InputFieldWrapper,
  SingleFormInput,
  SnackBar,
  NavigateBeforeButton,
} from "@components";
import { useDoctorContext, useSidebarMenuContext } from "@contexts";
import { useEditDoctor } from "@hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { regionsWithCommunes } from "../../utils/regionsWithCommunes";
import { regions } from "../../utils/regions";
import { FormContainer } from "../../styles/styled";
import { theme as oldTheme } from "../../config/theme";
import { z } from "zod";

const schema = z
  .object({
    region: z
      .string({ required_error: "Debes ingresar una región" })
      .min(1, "Debes ingresar una región"),
    commune: z
      .string({ required_error: "Debes ingresar una comuna" })
      .min(1, "Debes ingresar una comuna"),
    street: z
      .string({ required_error: "Debes ingresar una dirección" })
      .min(1, "Debes ingresar una dirección"),
  })
  .required();

type AddressData = z.infer<typeof schema>;

const Subtitle = styled(Typography)({
  marginTop: "16px",
  color: oldTheme.pallete.text.gray,
});

const WhiteTextField = styled(TextField)({ background: "white" });

interface LocationState {
  successfullAuth: boolean;
  redirectTo: string;
}
export function AddAddressScreen() {
  const [searchParams] = useSearchParams();
  const doctor = useDoctorContext();
  const { setIsSidebarMenuOpen } = useSidebarMenuContext();
  const location = useLocation();
  const { successfullAuth, redirectTo }: LocationState = location.state || {
    successfullAuth: false,
    redirectTo: "/app/solicitudes",
  };
  const [showSuccessfullAuthSnackbar, setShowSuccessfullAuthSnackbar] =
    useState(successfullAuth);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
    setValue,
    watch,
  } = useForm<AddressData>({
    resolver: zodResolver(schema),
    defaultValues: {
      region: doctor.region ?? "",
      commune: doctor.commune ?? "",
      street: doctor.street ?? "",
    },
  });
  const region = watch("region");
  const commune = watch("commune");
  const navigate = useNavigate();
  const requestId = searchParams.get("requestId");
  const { mutate: editDoctor, isPending } = useEditDoctor({
    code: doctor.code,
    redirectTo: successfullAuth ? "../exito" : redirectTo,
    ...(successfullAuth && { redirectionState: { redirectTo } }),
  });

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  const childrenCommunes = useMemo(
    () =>
      regionsWithCommunes.filter(({ nombre }) => nombre === region).at(0)
        ?.communes ?? [],
    [region]
  );
  const regionAutocompleteVal = useMemo(
    () => regions.find(({ nombre }) => nombre === region) ?? null,
    [region]
  );
  const communeAutocompleteVal = useMemo(
    () => childrenCommunes.find(({ nombre }) => nombre === commune) ?? null,
    [commune, childrenCommunes]
  );

  const onSubmit: SubmitHandler<AddressData> = useCallback(
    async (data) => {
      setIsSidebarMenuOpen(false);
      editDoctor({
        region: data.region,
        commune: data.commune,
        street: data.street,
      });
    },
    [setIsSidebarMenuOpen, editDoctor]
  );

  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Header
        title="Dirección de tu consulta"
        titleColor="black"
        left={<NavigateBeforeButton onClick={handleBack} />}
      />
      <Body>
        <Subtitle>Aparecerá en las recetas como indica la regulación</Subtitle>

        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <InputFieldWrapper label="Región" error={errors.region?.message}>
            {
              // TODO: see if this component can be done in a more reusable way
            }
            <Controller
              name={"region"}
              control={control}
              defaultValue={undefined}
              render={({ ...props }) => (
                <Autocomplete
                  disablePortal
                  value={regionAutocompleteVal}
                  options={regions}
                  getOptionLabel={({ nombre }) => nombre}
                  renderInput={(params) => (
                    <WhiteTextField
                      error={Boolean(errors.region)}
                      {...params}
                    />
                  )}
                  onChange={(_, data) => {
                    setValue("commune", "");
                    if (data?.nombre) clearErrors("region");
                    props.field.onChange(data?.nombre);
                  }}
                />
              )}
            />
          </InputFieldWrapper>
          {Boolean(region) && (
            <InputFieldWrapper label="Comuna" error={errors.commune?.message}>
              <Controller
                name={"commune"}
                control={control}
                defaultValue={undefined}
                render={({ ...props }) => (
                  <Autocomplete
                    disablePortal
                    value={communeAutocompleteVal}
                    options={childrenCommunes}
                    getOptionLabel={({ nombre }) => nombre}
                    renderInput={(params) => (
                      <WhiteTextField
                        error={Boolean(errors.commune)}
                        {...params}
                      />
                    )}
                    onChange={(_, data) => {
                      if (data?.nombre) clearErrors("commune");
                      props.field.onChange(data?.nombre);
                    }}
                  />
                )}
              />
            </InputFieldWrapper>
          )}
          {Boolean(commune) && (
            <SingleFormInput
              title="Dirección consulta"
              parameterName="street"
              placeholder=""
              errorText="Debes ingresar una dirección"
              register={register}
              errors={errors}
              inputType="text"
            />
          )}

          <ExtraWhiteSpace />

          <BottomActionArea>
            <BottomActionButton type="submit" disabled={isPending}>
              {isPending ? (
                <CircularProgress size={24} color="inherit" />
              ) : requestId ? (
                "Siguiente"
              ) : (
                "Guardar"
              )}
            </BottomActionButton>
          </BottomActionArea>
        </FormContainer>
      </Body>
      <SnackBar
        snackBarState={{
          open: showSuccessfullAuthSnackbar,
          type: "success",
        }}
        onClose={() => setShowSuccessfullAuthSnackbar(false)}
        alerts={{
          success: "Registro exitoso",
        }}
      />
    </>
  );
}

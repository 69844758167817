import styled from "@emotion/styled";

export const Body = styled.div<{ overflow?: string }>`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 8px 16px;
  max-width: 600px;
  flex: 1;
  ${({ overflow }) => overflow && `overflow: ${overflow};`}
`;

import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { CircularProgress, FormControl } from "@mui/material";
import { Global } from "@emotion/react";
import { AuthMethod, AuthStep, VerificationMethod } from "@interfaces";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  NavigateBeforeButton,
} from "@components";
import { AuthFeedbackModal } from "../components";
import { useAuth } from "@contexts";
import { useIsScreenBiggerThanSM } from "@hooks";
import {
  checkIfBiometricAuthIsAvailable,
  resolveNoBiometricAuthMethod,
  onContactUsClick,
} from "@utils";
import { canUseMekiDocCallback } from "../utils";
import { StyledSuccessIcon } from "@styles/styled";
import {
  ContentContainer,
  InputLabel,
  SingleInput,
  ErrorMessage,
  NewLoginRegisterText,
  NewAuthContainer,
  NewLoginErrorContainer,
  NewLoginErrorWrapper,
  StyledErrorIcon,
  NewLoginErrorTitle,
  NewLoginContactUsText,
  NewLoginContactUsParagraph,
  LoginHeader,
} from "../styled";
import { failedLoginUI, failedLoginWsp } from "@constants";
import { theme } from "@config/theme";
import MekiDocIcon from "@icons/MekiDocIcon";
import { format, validate } from "rut.js";

interface AuthRequest {
  rut: string;
}

export function Login() {
  const navigate = useNavigate();
  const isScreenBiggerThanSM = useIsScreenBiggerThanSM();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<AuthRequest>();

  const [authMethod, setAuthMethod] = useState<AuthMethod>(
    AuthMethod.BIOMETRIC_AUTH
  );
  const {
    authChallengeFeedback: { message: authFeedback, authStep },
    startAuthChallenge,
    biometricSignIn,
    getDoctorFromRegistrationStack,
    getDoctorFromCommonStack,
  } = useAuth();
  const feedbackIcon = (authStep: AuthStep) => {
    switch (authStep) {
      case AuthStep.SUCCESS:
        return <StyledSuccessIcon />;
      case AuthStep.ERROR:
        return <StyledErrorIcon />;
      default:
        return <CircularProgress size={24} color="primary" />;
    }
  };

  const userRutInput = watch("rut");
  const [showRutError, setShowRutError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [contactUsMessage, setContactUsMessage] = useState<string>("");
  const [serverErrorMessage, setServerErrorMessage] = useState<string | null>(
    null
  );

  const clearErrorsAndResetLoginState = useCallback(() => {
    clearErrors("rut");
    setShowRutError(false);
    setServerErrorMessage("");
    setContactUsMessage("");
  }, [
    clearErrors,
    setShowRutError,
    setServerErrorMessage,
    setContactUsMessage,
  ]);

  const handleRutOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue("rut", e.target.value);
      clearErrorsAndResetLoginState();
    },
    [setValue, clearErrorsAndResetLoginState]
  );

  const handleRutBlur = useCallback(() => {
    if (!userRutInput) return;
    if (!validate(userRutInput)) {
      setShowRutError(true);
      return;
    }
    setShowRutError(false);
    setValue("rut", format(userRutInput));
  }, [userRutInput, setValue]);

  const onSubmit: SubmitHandler<AuthRequest> = useCallback(
    async (data) => {
      if (!data.rut || loading) return;
      if (!validate(data.rut)) {
        return setShowRutError(true);
      }
      setLoading(true);
      try {
        const existingDoctor = await getDoctorFromCommonStack(
          VerificationMethod.RUT,
          data.rut
        );
        if (!existingDoctor) {
          throw Error("Doctor has no account");
        }

        // Check if biometric auth is available and if the doctor has it enabled
        const isBiometricAuthAvailable =
          await checkIfBiometricAuthIsAvailable();
        const canUseBiometricLogin =
          existingDoctor.biometricIsEnabled && isBiometricAuthAvailable;
        if (canUseBiometricLogin) {
          const biometricSignInSuccess = await biometricSignIn(existingDoctor);
          if (biometricSignInSuccess) {
            return navigate("/app/solicitudes");
          }
        }

        // If biometric auth is not available or the doctor has it disabled, we fallback to email or phone number auth
        const authMethod = resolveNoBiometricAuthMethod();
        setAuthMethod(authMethod);
        const authChallengeStarted = await startAuthChallenge({
          email: existingDoctor.email,
          authMethod,
        });
        if (!authChallengeStarted) throw Error("Failed to login");

        return navigate("/app/codigo", {
          state: { doctor: existingDoctor, authMethod, accountCreated: false },
        });
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === "Doctor has no account") {
            try {
              // TODO: Having logIn and signUp in the same screen couples the logic too much. Separate them

              // Doctor doesn't exist in common resources table. We need to do a signUp if possible
              const doctorBaseAttributes = await getDoctorFromRegistrationStack(
                data.rut
              );
              const { serverErrorMessage, contactUsMessage, canUseMekiDoc } =
                canUseMekiDocCallback(doctorBaseAttributes);

              if (canUseMekiDoc) {
                return navigate("/app/registro", {
                  state: doctorBaseAttributes,
                });
              }

              // Doctor does not exist in 'Registro Nacional de Prestadores Individuales de Salud' or cant issue prescriptions
              setError("rut", {
                type: "manual",
                message: "",
              });
              setServerErrorMessage(serverErrorMessage);
              setContactUsMessage(contactUsMessage);
              setLoading(false);
            } catch (err) {
              setError("rut", {
                type: "manual",
                message: "",
              });
              setServerErrorMessage(failedLoginUI);
              setContactUsMessage(failedLoginWsp);
            }
          } else {
            setError("rut", {
              type: "manual",
              message: "",
            });
            setServerErrorMessage(failedLoginUI);
            setContactUsMessage(failedLoginWsp);
            return;
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [
      loading,
      getDoctorFromRegistrationStack,
      getDoctorFromCommonStack,
      biometricSignIn,
      startAuthChallenge,
      navigate,
      setServerErrorMessage,
      setError,
    ]
  );

  const handleRutKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter") return;
      if (!userRutInput) return;
      // Remove focus from input (to hide keyboard on mobile)
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      if (!validate(userRutInput)) {
        setShowRutError(true);
        return;
      }
      setShowRutError(false);
      setValue("rut", format(userRutInput));
      onSubmit({ rut: format(userRutInput) });
    },
    [userRutInput, setShowRutError, setValue, onSubmit]
  );

  useEffect(() => {
    if (!userRutInput) {
      clearErrorsAndResetLoginState();
    }
  }, [userRutInput, clearErrorsAndResetLoginState]);

  const handleBack = useCallback(() => {
    isScreenBiggerThanSM ? navigate("/") : navigate("/app/mobile-login");
  }, [isScreenBiggerThanSM, navigate]);

  return (
    <>
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <ContentContainer>
          <LoginHeader>
            <NavigateBeforeButton onClick={handleBack} />
            <MekiDocIcon size={"medium"} />
          </LoginHeader>
          <NewAuthContainer onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
              <InputLabel>Ingresa tu RUT</InputLabel>
              <SingleInput
                {...register("rut", {
                  required: "Debes ingresar tu RUT.",
                })}
                onKeyDown={handleRutKeyDown}
                onBlur={handleRutBlur}
                onChange={handleRutOnChange}
                error={showRutError || !!errors.rut}
              />
              {showRutError && !!!errors.rut && (
                <ErrorMessage>RUT no válido</ErrorMessage>
              )}
              {!showRutError && !!errors.rut && (
                <ErrorMessage>{errors.rut.message}</ErrorMessage>
              )}
            </FormControl>

            {!serverErrorMessage && (
              <NewLoginRegisterText>
                Para validar tus datos con la Superintendencia de Salud
              </NewLoginRegisterText>
            )}

            {serverErrorMessage && userRutInput && (
              <>
                <NewLoginErrorWrapper color={theme.pallete.background.lightRed}>
                  <NewLoginErrorContainer>
                    <StyledErrorIcon />
                    <NewLoginErrorTitle>
                      {serverErrorMessage}
                    </NewLoginErrorTitle>
                  </NewLoginErrorContainer>
                </NewLoginErrorWrapper>
                <NewLoginContactUsParagraph>
                  Si tienes problemas{" "}
                  <NewLoginContactUsText
                    onClick={() => onContactUsClick(contactUsMessage)}
                  >
                    contáctanos
                  </NewLoginContactUsText>
                </NewLoginContactUsParagraph>
              </>
            )}
            <BottomActionArea>
              <BottomActionButton
                onClick={handleSubmit(onSubmit)}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Siguiente"
                )}
              </BottomActionButton>
            </BottomActionArea>
          </NewAuthContainer>

          {authFeedback && authMethod === AuthMethod.BIOMETRIC_AUTH && (
            <AuthFeedbackModal
              isOpen={true}
              authFeedback={authFeedback}
              authStep={authStep}
              feedbackIcon={feedbackIcon}
            />
          )}
        </ContentContainer>
      </Body>
    </>
  );
}

import { Box, CircularProgress, Typography, styled } from "@mui/material";
import {
  Body,
  BottomActionArea,
  BottomActionButton,
  Header,
} from "../../components";
import { Global } from "@emotion/react";
import { useStartEsignFlow } from "../../hooks";
import { theme as oldTheme } from "../../config";

const DescriptionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "24px 8px",
  gap: "32px",
});

const StepsWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  gap: "8px",
  backgroundColor: oldTheme.pallete.background.white,
});

const StepContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "70px",
  padding: "0px 16px",
  gap: "26px",
});

const StepContent = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "90%",
  gap: "16px",
  fontSize: "14px",
});

const StepNumber = styled(Box)({
  minWidth: "24px",
  minHeight: "24px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: oldTheme.pallete.text.primaryExtraLight,
  color: oldTheme.pallete.text.primary,
  fontWeight: 600,
});

const IconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "10%",
  minWidth: "10%",
});

const StepIcon = styled("img")({
  width: "45px",
  height: "45px",
  alignSelf: "center",
});

const ImportantInfo = styled(Typography)({
  fontSize: "14px",
  color: oldTheme.pallete.text.gray,
});

const Bold = styled("span")({
  fontWeight: 600,
});

export function IdentityVerification() {
  const {
    startEsignFlowMutation: { mutate: startEsignFlow },
    isStartingEsignFlow,
  } = useStartEsignFlow();
  return (
    <>
      <Header title="Verificación de identidad" titleColor="black" />
      <Global styles={{ body: { backgroundColor: "#F5F5F5" } }} />
      <Body>
        <DescriptionContainer>
          <Typography>
            Tendrás que hacerlo <Bold>UNA SOLA VEZ</Bold> para crear tu Firma
            Electrónica Avanzada.
          </Typography>

          <StepsWrapper>
            <StepContainer>
              <StepContent>
                <StepNumber>1</StepNumber>
                Inicia sesión con Clave única
              </StepContent>
              <IconContainer>
                <StepIcon src="/esign/clave-unica-icon.svg" />
              </IconContainer>
            </StepContainer>

            <StepContainer>
              <StepContent>
                <StepNumber>2</StepNumber>
                Completa el Nº de serie de tu cédula de identidad
              </StepContent>
              <IconContainer>
                <StepIcon src="/esign/ci-icon.svg" />
              </IconContainer>
            </StepContainer>

            <StepContainer>
              <StepContent>
                <StepNumber>3</StepNumber>
                Responde preguntas del Registro Civil
              </StepContent>
              <IconContainer>
                <StepIcon src="/esign/questions-icon.svg" />
              </IconContainer>
            </StepContainer>
          </StepsWrapper>

          <ImportantInfo>
            La información entregada no será almacenada ni empleada para fines
            distintos a verificar tu identidad.
          </ImportantInfo>
        </DescriptionContainer>

        <BottomActionArea>
          <BottomActionButton
            onClick={() => startEsignFlow({})}
            disabled={isStartingEsignFlow}
          >
            {isStartingEsignFlow ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Verificar identidad"
            )}
          </BottomActionButton>
        </BottomActionArea>
      </Body>
    </>
  );
}
